import { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { Done, Clear } from '@material-ui/icons';

const Keywords = props => {
  const result = [];

  Object.keys(props.keywords).forEach(function(key) {
    result.push(
      <div key={key}>
        {key}:
        <span style={{ verticalAlign: 'middle' }}>
          {props.keywords[key] ? <Done /> : <Clear />}
        </span>
      </div>,
    );
  });
  return result;
};

class KeywordChecker extends Component {
  render() {
    return (
      <div>
        <div style={{ textAlign: 'center', margin: '1px' }}>
          {this.props.header}
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Page</TableCell>
              <TableCell>Keywords Found</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.output.map((row, index) => {
              return (
                <TableRow key={`resultRow_${index}`}>
                  <TableCell component="th" scope="row">
                    {row.output.page}
                  </TableCell>
                  <TableCell>
                    <Keywords keywords={row.output.keywords} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    );
  }
}

KeywordChecker.propTypes = {
  output: PropTypes.array.isRequired,
  header: PropTypes.string.isRequired,
};

export default KeywordChecker;
