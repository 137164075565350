import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  content: {
    wordBreak: 'break-all',
  },
}));
export default ({ label, content }: { label?: string; content: string }) => {
  const classes = useStyles();

  return (
    <div>
      {label && <Typography variant="caption">{label}</Typography>}
      <Typography variant="body1" className={classes.content} component="div">
        {content || 'N/A'}
      </Typography>
    </div>
  );
};
