export default [
  {
    source: 'url',
    type: 'search',
    alwaysOn: true,
  },
  {
    source: 'redirect_to',
    type: 'search',
  },
];
