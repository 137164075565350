import { Suspense, useEffect, useState } from 'react';
import { Tabs, Tab, AppBar, makeStyles, Theme } from '@material-ui/core';
import { PreloaderCallback } from '../../atoms';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    background: 'transparent',
    '& > div': {
      background: 'transparent',
      marginTop: theme.spacing(1.5),
      marginLeft: 0,
      marginRight: 0,
    },
  },
  tabPanelWrapper: {
    background: '#fff',
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  tabsWrapper: {
    marginLeft: -theme.spacing(2),
    marginRight: -theme.spacing(2),
    '& .MuiTabs-scrollButtons': {
      background: theme.palette.secondary.dark,
    },
    '& span[class*="MuiTabs-indicator"]': {
      display: 'none',
    },
    '& button': {
      background: theme.palette.primary.light,
      color: theme.palette.text.primary,
      '&.Mui-selected': {
        background: '#fff',
        color: theme.palette.text.primary,
      },
      '&:first-of-type': {
        borderTopLeftRadius: theme.spacing(0.5),
      },

      '&:last-of-type': {
        borderTopRightRadius: theme.spacing(0.5),
      },
    },
  },
}));

const usePlainVariantStyles = makeStyles((theme: Theme) => ({
  header: {
    background: 'transparent',
    '& > div': {
      background: 'transparent',
      marginTop: theme.spacing(1.5),
      marginLeft: 0,
      marginRight: 0,
    },
  },
  tabPanelWrapper: {
    background: '#fff',
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  tabsWrapper: {
    marginLeft: -theme.spacing(2),
    marginRight: -theme.spacing(2),
    '& .MuiTabs-scrollButtons': {
      background: theme.palette.secondary.dark,
    },
    '& span[class*="MuiTabs-indicator"]': {
      background: theme.palette.primary.main,
    },
    '& button': {
      color: theme.palette.text.primary,
      padding: '0 12px',
      margin: '10px 0',
      minHeight: 30,
      '&:not(:last-of-type)': {
        borderRight: '1px solid #e0e0e0',
      },

      '&.Mui-selected': {
        color: theme.palette.primary.main,
      },
    },
  },
}));

const TabbedView = ({
  children,
  lazy,
  variant,
  header,
  renderHeader,
  onChangeTab,
  tabsVariant = 'scrollable',
}: {
  children: JSX.Element[] | JSX.Element;
  lazy?: boolean;
  variant?: 'light';
  layout?: 'flex';
  rounded?: boolean;
  boxed?: boolean;
  header?: JSX.Element;
  renderHeader?: (activeTab: string | number) => JSX.Element;
  onChangeTab?: (activeTab: string | number) => void;
  tabsVariant?: 'scrollable' | 'standard' | 'fullWidth';
}) => {
  const defaultClasses = useStyles();
  const plainVariantClasses = usePlainVariantStyles();
  const classes = variant === 'light' ? plainVariantClasses : defaultClasses;
  const childrenList = Array.isArray(children) ? children : [children];
  const [activeTab, setActiveTab] = useState(
    childrenList[0].props.name ? childrenList[0].props.name : 0,
  );
  useEffect(() => {
    if (onChangeTab) {
      onChangeTab(activeTab);
    }
  }, [activeTab, onChangeTab]);

  const Header = () => {
    return (
      <AppBar position="static" elevation={0} className={classes.header}>
        <Tabs
          value={activeTab}
          onChange={(e, activeTab) => setActiveTab(activeTab)}
          variant={tabsVariant}
          className={classes.tabsWrapper}
        >
          {childrenList.map((child, index) => {
            const label = child.props.label || child.props.name || index;
            const name = child.props.name || index;
            return (
              <Tab
                icon={child.props.icon}
                key={`tabbel-list-header-${index}`}
                value={name}
                label={label}
              />
            );
          })}
        </Tabs>
      </AppBar>
    );
  };

  return (
    <div>
      <Header />
      {renderHeader && renderHeader(activeTab)}
      {header && header}
      {childrenList.map((child, index) => (
        <div key={`tabbed-list${index}`} className={classes.tabPanelWrapper}>
          {(child.props.name && child.props.name === activeTab) ||
          index === activeTab ? (
            <div>
              {lazy ? (
                <Suspense fallback={<PreloaderCallback />}>{child}</Suspense>
              ) : (
                <>{child}</>
              )}
            </div>
          ) : (
            ''
          )}
        </div>
      ))}
    </div>
  );
};

export default TabbedView;
