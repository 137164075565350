import { TextField } from 'react-admin';
import { List } from '../../ui/molecules';
import { DateField, TrimmedTextField, UrlField } from '../../ui/atoms';

const Backlinks = props => {
  return (
    <List
      responsive={['url', 'source_url']}
      sort={{ field: 'url', order: 'ASC' }}
      listkey={'url'}
      {...props}
      advancedFilters
      defaultColumns={[
        'url',
        'source_url',
        'text',
        'added',
        'publish_date',
        'status',
      ]}
    >
      <UrlField source="url" label="Target URL" />
      <UrlField source="source_url" label="Backlink" />
      <TrimmedTextField source="text" label="Anchor" limit="60" />
      <DateField source="added" label="Added" format="DD-MM-YYYY" />
      <DateField
        source="publish_date"
        label="Publish date"
        format="DD-MM-YYYY"
      />
      <TextField source="status" />
    </List>
  );
};

export default Backlinks;
