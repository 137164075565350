import { Paper } from '@material-ui/core';
import { CreatedUpdatedBox, Grid } from '../../ui/molecules';
import { InfoCard, SinglePageHeader } from '../../ui/atoms';
import Domains from './Domains';

export default ({ record }) => {
  return (
    <Paper
      style={{
        padding: 8,
        marginTop: 12,
      }}
    >
      <Grid>
        <SinglePageHeader
          label="registrant"
          title={record.registrant_name}
          subtitle={`Domains: ${record.domains_count}`}
          lg={8}
        />
        <CreatedUpdatedBox record={record} lg={4} />
        <InfoCard w={6} label="Email" content={record.email} />
        <InfoCard w={6} label="Company" content={record.company} />
        <InfoCard w={6} label="Domains Count" content={record.domains_count} />
      </Grid>
      <Grid>
        <Domains record={record} />
      </Grid>
    </Paper>
  );
};
