import { Tooltip } from '@material-ui/core';

export default (props: any) => {
  const cdn = props?.record?.[props.source] ?? null;

  if (cdn) {
    return (
      <Tooltip title="CDN Enabled">
        <span
          style={{
            color: 'green',
            border: '2px solid',
            padding: '2px',
            fontSize: '11px',
            fontWeight: 900,
            borderRadius: '3px',
          }}
        >
          CDN
        </span>
      </Tooltip>
    );
  }

  return null;
};
