import { useCallback, useEffect, useRef, useState } from 'react';

export function useIsIdle() {
  const [isIdle, setIsIdle] = useState(false);
  useEffect(() => {
    let timeout: any;
    setIsIdle(false);
    const resetTimeout = () => {
      clearTimeout(timeout);
      setIsIdle(false);
      timeout = setTimeout(() => setIsIdle(true), 60000);
    };
    window.onmousemove = resetTimeout;
    window.onkeypress = resetTimeout;
    return () => clearTimeout(timeout);
  }, []);
  return isIdle;
}

export function useIntervalWithIdle() {
  const intervalId = useRef<any>();
  const isIdle = useIsIdle();
  const clear = useCallback(() => clearInterval(intervalId.current), []);
  useEffect(() => {
    return () => {
      clear();
    };
  }, [clear]);
  return [
    useCallback(
      (callback: () => void) => {
        if (process.env.REACT_APP_ENV === 'production') {
          const interval = 30000;
          callback();
          clear();
          if (!isIdle) {
            intervalId.current = setInterval(() => {
              callback();
            }, interval);
          }
        }
      },
      [clear, isIdle],
    ),
    clear,
  ] as const;
}
