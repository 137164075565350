import React, { cloneElement, Suspense } from 'react';
import {
  CardActions,
  CircularProgress,
  makeStyles,
  Select,
} from '@material-ui/core';
import { EditButton, ListButton, ShowButton } from 'react-admin';
import WordpressActions from '../../../views/Websites/WPActions/WordpressActions';
import DeleteButtonWithConfirmation from '../../../components/Helpers/DeleteButtonWithConfirmation';
import StaticGeneratorMenu from '../StaticGeneratorMenu';

interface IProps {
  basePath?: string;
  filterToList?: string;
  data?: any;
  resource?: string;
  record?: any;
  updateWpData?: boolean;
  edit?: boolean;
  show?: boolean;
  list?: boolean;
  restart?: boolean;
  delete?: boolean;
  staticGeneratorButton?: boolean;
  services?: Object;
  handleRestart?: any;
  customActions?: JSX.Element[];
  lazyCustomActions?: ((props: any) => JSX.Element)[];
}

const useStyles = makeStyles(() => ({
  root: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    background: '#fff',
  },
}));

const Toolbar = ({
  basePath,
  data,
  resource,
  customActions,
  updateWpData,
  edit,
  show,
  list,
  restart,
  services,
  staticGeneratorButton,
  handleRestart,
  lazyCustomActions,
  filterToList = '',
  ...props
}: IProps) => {
  const classes = useStyles();

  return (
    <Suspense fallback={<CircularProgress size="small" />}>
      <CardActions classes={classes}>
        {customActions &&
          customActions.map(
            (Action, i): JSX.Element =>
              cloneElement(Action, {
                record: data,
                ...props,
                key: `action-${i}`,
              }),
          )}
        {lazyCustomActions &&
          lazyCustomActions.map((Comp, i) => (
            <Comp key={`comp-${i}`} record={data} {...props} />
          ))}
        {updateWpData && <WordpressActions record={data} />}
        {edit && <EditButton basePath={basePath} record={data} />}
        {show && <ShowButton basePath={basePath} record={data} />}
        {staticGeneratorButton && <StaticGeneratorMenu record={data} />}
        {list && (
          <ListButton basePath={`${basePath}${filterToList}`} record={data} />
        )}
        {restart && (
          <Select
            native
            inputProps={{ name: 'Service' }}
            value={'Select'}
            onChange={handleRestart}
          >
            <option key={'default'}>Restart Service</option>
            {services &&
              Object.keys(services).map(element => {
                return (
                  <option
                    key={services[element].name}
                    value={services[element].realName}
                  >
                    {services[element].name}
                  </option>
                );
              })}
          </Select>
        )}
        {props.delete && (
          <DeleteButtonWithConfirmation
            basePath={basePath}
            record={data}
            resource={resource}
          />
        )}
      </CardActions>
    </Suspense>
  );
};

export default Toolbar;
