import { createSelector } from 'reselect';
import { getAdminData, AdminDataType } from './getAdminData';

export interface ResourceType {
  data: any;
  list: any;
  props: any;
}

export const getResources = createSelector(
  getAdminData,
  (adminData: AdminDataType): ResourceType => {
    return adminData.resources;
  },
);

export const getResourcesList = createSelector(
  getAdminData,
  (adminData: AdminDataType): Array<ResourceType> => {
    return Object.values(adminData.resources);
  },
);
