import {
  EditButton,
  FunctionField,
  ReferenceField,
  TextField,
} from 'react-admin';
import { AdvancedFilter, List } from '../../ui/molecules';
import { DateField } from '../../ui/atoms';

const filters = [
  {
    source: 'title',
    type: 'search',
    alwaysOn: true,
  },
  {
    source: 'message',
    type: 'search',
    alwaysOn: true,
  },
  {
    source: 'type',
    type: 'select',
    choices: [
      { id: 'INFO', name: 'Info' },
      { id: 'WARNING', name: 'Warning' },
      { id: 'ALERT', name: 'Alert' },
    ],
    alwaysOn: true,
  },
];

export default props => (
  <List
    responsive={['title', 'message', 'type', 'created_by']}
    sort={{ field: 'created_at', order: 'DESC' }}
    listkey={'id'}
    {...props}
    advancedFilters
    filters={<AdvancedFilter customfilters={filters} />}
    defaultColumns={['title', 'message', 'created_by']}
  >
    <TextField source="title" />
    <FunctionField
      render={row => (
        <div
          style={{ maxWidth: 500 }}
          dangerouslySetInnerHTML={{ __html: row.message }}
        />
      )}
      source="message"
    />
    <TextField source="type" />
    <TextField source="link" sortable={false} />
    <ReferenceField source="user_id" reference="ums/api/v1/users" link={'show'}>
      <TextField source="name" />
    </ReferenceField>
    <TextField source="recipients" />
    <DateField source="created_at" />
    <ReferenceField
      source="created_by"
      reference="ums/api/v1/users"
      sortable={false}
      link={'show'}
    >
      <TextField source="name" />
    </ReferenceField>
    <EditButton />
  </List>
);
