import { Component } from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@material-ui/core';
import WpLoginField from '../../components/Helpers/WpLoginField';

class Domains extends Component {
  render() {
    const { domains } = this.props.record;

    return (
      <Paper>
        {domains &&
          domains.length > 0 &&
          domains.map((domain, index) => {
            domain.url = `https://${domain.name}`;
            return (
              <div key={index}>
                <WpLoginField
                  style={{ cursor: 'pointer', fontWeight: 'bold' }}
                  label="unicode_name"
                  urlField="url"
                  record={domain}
                />
              </div>
            );
          })}
      </Paper>
    );
  }
}

Domains.propTypes = {
  record: PropTypes.object.isRequired,
};

export default Domains;
