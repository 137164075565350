import { ArrayInput } from 'react-admin';
import { SimpleFormIterator } from 'react-admin';
import { TextInput, SimpleForm } from 'react-admin';
import { Grid, FormToolbar } from '../../ui/molecules';
import { Validation } from '../../ui/organisms';

export default props => (
  <SimpleForm {...props} redirect={'show'} w={12} toolbar={<FormToolbar />}>
    <Grid fullWidth>
      <Validation w={12}>
        <TextInput fullWidth source="homepage" />
        <ArrayInput source="nameserver_domain_names" w={12}>
          <SimpleFormIterator>
            <TextInput source="domain" label="Domain name" />
          </SimpleFormIterator>
        </ArrayInput>
      </Validation>
    </Grid>
  </SimpleForm>
);
