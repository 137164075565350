import { stringify } from 'querystring';
import { Component } from 'react';
import PropTypes from 'prop-types';
import { getJson } from '../../components/Helpers/API';
import withAbort from '../../components/Helpers/withAbort';

class Domains extends Component {
  constructor(props) {
    super(props);

    this.state = {
      domains: [],
    };

    this.loadData = this.loadData.bind(this);
  }

  loadData() {
    const $this = this;

    getJson(
      `/dms/api/v1/domains?${stringify({
        filter: JSON.stringify({
          nameservers: { search: $this.props.record.id },
        }),
        perPage: 1,
      })}`,
      {
        signal: this.props.signal,
      },
    )
      .then(response => {
        $this.setState({ domains: response.meta.total });
      })
      .catch(e => {
        console.error(e);
      });
  }

  componentDidMount() {
    this.loadData();
  }

  render() {
    const { domains } = this.state;

    return <div>{domains}</div>;
  }
}

Domains.propTypes = {
  record: PropTypes.object.isRequired,
};

export default withAbort(Domains);
