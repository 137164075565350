import { Button, IconButton, SvgIcon, Tooltip } from '@material-ui/core';
import { useCallback } from 'react';
import { getJson } from '../../../components/Helpers/API';

export default (props: any): JSX.Element => {
  const handleRunScan = useCallback(
    e => {
      e.stopPropagation();
      getJson(`/dms/api/v1/websites/${props?.record?.id}/scan`, {
        method: 'POST',
      })
        .then(response => {
          window.location.href = `/#/dms/api/v1/cron-jobs/${response.cron_id}/show`;
        })
        .catch(e => console.error(e))
        .finally(() => {});
    },
    [props?.record?.id],
  );

  if (props.label) {
    return (
      <Button
        variant="outlined"
        onClick={handleRunScan}
        style={{
          padding: '6.7px 10px',
          marginRight: 10,
          color: '#676767',
          marginTop: '0.8px',
        }}
      >
        {props.label}
      </Button>
    );
  }

  return (
    <Tooltip title="Run Scan">
      <IconButton size="small" onClick={handleRunScan}>
        <SvgIcon>
          <svg
            width="24px"
            height="23px"
            viewBox="0 0 24 18"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Run Scan</title>
            <g
              id="Icons"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              <g id="Rounded" transform="translate(-508.000000, -377.000000)">
                <g id="Action" transform="translate(100.000000, 100.000000)">
                  <g
                    id="-Round-/-Action-/-perm_scan_wifi"
                    transform="translate(408.000000, 274.000000)"
                  >
                    <g transform="translate(0.000000, 0.000000)">
                      <polygon id="Path" points="0 0 24 0 24 24 0 24" />
                      <path
                        d="M12,3 C7.41,3 3.86,4.53 0.89,6.59 C0.4,6.92 0.3,7.59 0.67,8.05 L10.45,20.09 C11.25,21.07 12.75,21.08 13.55,20.09 L23.33,8.07 C23.7,7.61 23.6,6.94 23.11,6.61 C20.14,4.54 16.59,3 12,3 Z M12,16 C11.45,16 11,15.55 11,15 L11,11 C11,10.45 11.45,10 12,10 C12.55,10 13,10.45 13,11 L13,15 C13,15.55 12.55,16 12,16 Z M11,8 L11,6 L13,6 L13,8 L11,8 Z"
                        id="🔹Icon-Color"
                        fill="#1D1D1D"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </SvgIcon>
      </IconButton>
    </Tooltip>
  );
};
