import { TextField } from 'react-admin';
import { AdvancedFilter, List } from '../../../ui/molecules';
import { ReferenceField } from '../../../ui/atoms';
import filters from './filters.map';

export default props => (
  <List
    responsive={['word']}
    sort={{ field: 'percentage', order: 'DESC' }}
    listkey={'word'}
    {...props}
    advancedFilters
    filters={<AdvancedFilter customfilters={filters} />}
    defaultColumns={['word', 'count', 'percentage', 'website_id']}
  >
    <TextField source="word" />
    <TextField source="count" />
    <TextField source="total_links" />
    <TextField source="percentage" />
    <ReferenceField
      source="website_id"
      reference="dms/api/v1/websites"
      basePath="/dms/api/v1/websites"
      label="Website"
      allowEmpty
      referenceSource="hostname"
      link={'show'}
    />
  </List>
);
