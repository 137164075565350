import { TextField } from '@material-ui/core';
import {
  Autocomplete,
  ToggleButton,
  ToggleButtonGroup,
} from '@material-ui/lab';
import { Header } from '../../ui/molecules';
import { WPIcon } from '../../ui/atoms';

export function DashboardHeader(props) {
  return (
    <Header>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginRight: 10,
          }}
        >
          <div>
            <WPIcon label="Login to WP" record={props.selectedMoneySite} />
            <ToggleButtonGroup
              size="small"
              value={props.comparison}
              exclusive
              onChange={props.handleChangeComparison}
            >
              <ToggleButton
                fontSize="small"
                value="yesterday"
                aria-label="yesterday"
              >
                Yesterday
              </ToggleButton>
              <ToggleButton
                fontSize="small"
                value="monthToDate"
                aria-label="monthToDate"
              >
                Month-to-date
              </ToggleButton>
              <ToggleButton
                fontSize="small"
                value="thirtyDays"
                aria-label="thirtyDays"
              >
                30-Days
              </ToggleButton>
              <ToggleButton
                fontSize="small"
                value="sevenDays"
                aria-label="sevenDays"
              >
                7-Days
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>
        <Autocomplete
          style={{ width: 300 }}
          size="small"
          options={props.moneySites}
          value={props.selectedMoneySite || null}
          onChange={props.handleChangeMoneySite}
          getOptionLabel={option => {
            if (!option.domain) {
              console.log('Domain is missing in option:', option);
            }
            return option.friendly_name
              ? option.friendly_name
              : option.domain
              ? option.domain.unicode_name
              : '';
          }}
          disableClearable
          renderInput={params => (
            <TextField
              {...params}
              variant="outlined"
              label="Select MoneySite"
            />
          )}
        />
      </div>
    </Header>
  );
}
