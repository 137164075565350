import { useEffect, useState, memo, FC } from 'react';
import { Chip, Avatar, CircularProgress } from '@material-ui/core';
import { Bookmark } from '@material-ui/icons';
import { GET_ONE } from 'react-admin';
import dataProvider from '../../../dataProvider';

type Props = {
  id: string;
  onDelete: (id: string) => void;
  record?: any;
  source?: string;
};
const ReferenceWebsiteChip: FC<Props> = ({ id, onDelete, record, source }) => {
  const [site, setSite] = useState<any>();
  const [loading, setLoading] = useState(false);
  const ID = id || (record && source && record[source]);
  useEffect(() => {
    if (ID && !site) {
      setLoading(true);
      dataProvider(GET_ONE, 'dms/api/v1/websites', { id: ID }).then(r => {
        setSite(r.data);
        setLoading(false);
      });
    }
  }, [ID, site]);
  if (loading) {
    return <CircularProgress size={10} />;
  }
  if (!site) {
    return null;
  }
  return (
    <Chip
      label={site?.url || ''}
      color="primary"
      onDelete={onDelete}
      avatar={
        <Avatar>
          <Bookmark />
        </Avatar>
      }
    />
  );
};

export default memo(ReferenceWebsiteChip);
