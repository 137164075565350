import { useState, useEffect, useMemo } from 'react';
import { stringify } from 'query-string';

interface Props {
  url: string;
  filter: { [key: string]: any };
  pagination: { page: number; perPage: number };
  sort?: { sortBy: string; sortOrder: 'asc' | 'desc' } | {};
}

export function useUrlWithFilters({
  url: urlProp,
  filter: filterProp = {},
  pagination: paginationProp = { page: 0, perPage: 9999 },
  sort: sortProp,
}: Props) {
  const [url, setUrl] = useState<string | null>(null);
  const [filter, setFilter] = useState<Props['filter']>(filterProp);
  const [pagination, setPagination] = useState(paginationProp);
  const [sort, setSort] = useState({});
  const hasFilters = Object.keys(filter).length > 0;

  const paginationQuery = useMemo(
    () => ({
      page: pagination.page + 1,
      perPage: pagination.perPage,
    }),
    [pagination],
  );

  useEffect(() => {
    if (sortProp && Object.keys(sortProp).length > 0) {
      setSort(sortProp);
    }
  }, [sortProp]);

  const sortQuery = useMemo(
    () => ({
      ...(sortProp && Object.keys(sortProp).length > 0 ? sortProp : {}),
    }),
    [sortProp],
  );

  const filterQuery = useMemo(
    () => ({
      filter: JSON.stringify(filter),
    }),
    [filter],
  );

  useEffect(() => {
    if (urlProp) {
      setUrl(
        `${urlProp}?${stringify({
          ...paginationQuery,
          ...sortQuery,
          ...(hasFilters ? filterQuery : {}),
        })}`,
      );
    }
  }, [
    pagination,
    filter,
    filterQuery,
    hasFilters,
    paginationQuery,
    sortQuery,
    urlProp,
    sort,
  ]);

  return { url, filter, setFilter, setPagination, setSort };
}
