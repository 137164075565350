import { Filter } from 'react-admin';
import SingleFilter from './SingleFilter';

export default (props: any) => {
  return (
    <Filter {...props} style={{ flexGrow: 1, margin: '0 12px' }}>
      {props.customfilters &&
        props.customfilters.map((filterProp: any, key: number) => {
          if (filterProp.alwaysOn) {
            const mappedSource =
              filterProp.type === 'min' || filterProp.type === 'max'
                ? filterProp.source + '.' + filterProp.type
                : filterProp.source;
            return (
              <SingleFilter {...filterProp} source={mappedSource} key={key} />
            );
          }
          return null;
        })}
    </Filter>
  );
};
