import { TextField } from 'react-admin';
import { AdvancedFilter, List } from '../../ui/molecules';
import { ReferenceField } from '../../ui/atoms';
import DomainsCount from './DomainsCount';

const filters = [
  {
    source: 'nameserver',
    type: 'search',
    alwaysOn: true,
  },
];

export default props => (
  <List
    responsive={['nameserver']}
    sort={{ field: 'nameserver', order: 'ASC' }}
    listkey={'id'}
    {...props}
    advancedFilters
    filters={<AdvancedFilter customfilters={filters} />}
    defaultColumns={['nameserver']}
  >
    <TextField source="id" />
    <TextField source="nameserver" />
    <DomainsCount record={props.record} />
    <ReferenceField
      source="registrar_id"
      reference="dms/api/v1/registrars"
      basePath="/dms/api/v1/registrars"
      label="Registrar"
      allowEmpty
      referenceSource="registrar"
      link={'show'}
    />
  </List>
);
