import { Tooltip, Avatar } from '@material-ui/core';
import {
  CheckCircleOutline,
  PauseCircleOutlineOutlined,
  RemoveCircleOutline,
} from '@material-ui/icons';

const MonitorStatusField = ({ source, ...props }: any) => {
  if (props.record[source] === 'up') {
    return (
      <Tooltip title="Up">
        <Avatar style={{ background: '#309435' }}>
          <CheckCircleOutline />
        </Avatar>
      </Tooltip>
    );
  }
  if (props.record[source] === 'down') {
    return (
      <Tooltip title="Down">
        <Avatar style={{ background: '#af2626' }}>
          <RemoveCircleOutline />
        </Avatar>
      </Tooltip>
    );
  }
  return (
    <Tooltip title="Not added yet">
      <Avatar>
        <PauseCircleOutlineOutlined />
      </Avatar>
    </Tooltip>
  );
};

export default MonitorStatusField;
