import { useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';
import shortid from 'shortid';
import SinglePageHeader from '../SinglePageHeader';
import SingleWarning from './SingleWarning';

export interface Warning {
  external_backlink_id?: string;
  _id?: { $oid: string };
  id?: string;
  message: string;
  data?: object[];
  found?: string;
  expected?: string;
  ignore?: boolean;
  important?: boolean;
}

const useStyles = makeStyles((theme: any) => ({
  warningsChip: {
    minWidth: 30,

    '& small': {
      position: 'absolute',
      top: -5,
      right: 0,
      textShadow: `2px 2px 0px ${theme.palette.primary.dark},
      -2px -2px 0 ${theme.palette.primary.dark},
      -1px -2px 0 ${theme.palette.primary.dark},
      1px -2px 0 ${theme.palette.primary.dark},
      0 -2px 0 ${theme.palette.primary.dark},
      2px -2px 0 ${theme.palette.primary.dark}
      `,
    },
  },
  dialogContent: {
    backgroundColor: theme.palette.grey['300'],
    borderTop: `1px solid ${theme.palette.primary.dark}`,
    padding: 20,
  },
}));

export default (props: any) => {
  const [open, setOpen] = useState(false);
  const { record = {} } = props;
  const classes = useStyles();
  const sources = props.source && props.source.split(', ');
  const warnings =
    sources &&
    sources
      .map((source: string) => record && record[source])
      .flat()
      .filter((w: any) => w);
  const color = record.errors || record.alerts ? '#FF3A29' : '#fa8e02';
  const hasImportant =
    warnings &&
    warnings.some((warning: Warning) => warning && warning.important);

  const closeDialog = (e: Event) => {
    setOpen(false);
    e.stopPropagation();
  };

  const Description = ({ open }: { open: boolean }) => (
    <Dialog
      open={open}
      onClose={closeDialog}
      fullWidth
      maxWidth="lg"
      onClick={e => e.stopPropagation()}
    >
      <DialogTitle>
        <SinglePageHeader label="Warnings found for" title={record.url} />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {warnings.map(
          (singleWarning: Warning) =>
            singleWarning && (
              <SingleWarning
                key={shortid.generate()}
                singleWarning={singleWarning}
              />
            ),
        )}
      </DialogContent>
    </Dialog>
  );

  return (
    <div>
      <Description open={open} />
      {warnings.length ? (
        <Button
          className={classes.warningsChip}
          color="primary"
          size="small"
          style={{ backgroundColor: color }}
          onClick={e => {
            e.stopPropagation();
            setOpen(true);
          }}
        >
          {hasImportant && <small>!</small>}
          {warnings.length}
        </Button>
      ) : null}
    </div>
  );
};
