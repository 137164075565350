import humanizeDuration from 'humanize-duration';
import { Watch } from '@material-ui/icons';
import dayjs, { utc } from 'dayjs';
import { Card, ChipsCloud, SinglePageHeader, Spacer } from '../../ui/atoms';
import { CreatedUpdatedBox, Grid } from '../../ui/molecules';
import Output from './Output';

dayjs.extend(utc);

export default ({ record, basePath }) => (
  <Grid>
    <Spacer height={4} w={12} />
    <SinglePageHeader
      label="Command"
      title={record.command}
      subtitle={record.description}
      lg={8}
    />
    <CreatedUpdatedBox record={record} lg={4} />
    <ChipsCloud w={12}>
      <Watch
        content={dayjs
          .utc(record.start_time)
          .local()
          .fromNow()}
        label="Started:"
      />
      <Watch
        content={humanizeDuration(record.total_time * 1000)}
        label="Time spent:"
      />
      <Watch
        content={dayjs
          .utc(record.ent_time)
          .local()
          .format('DD, MMM YYYY @ HH:mm')}
        label="End time:"
      />
    </ChipsCloud>
    <Card w={12}>
      <Output cron={record} basePath={basePath} />
    </Card>
  </Grid>
);
