import {
  Card,
  CircularProgress,
  Typography,
  useTheme,
} from '@material-ui/core';
import { memo, useCallback } from 'react';
import Chart from 'react-google-charts';
import { Trending } from '../../../../ui/atoms';
import { TabbedView } from '../../../../ui/molecules';
import HelpTooltip from '../HelpTooltip';
import Toolbar from '../Toolbar';
import useConversionsMetric from './useConversionsMetric';

const ConversionsMetric = ({ selectedMoneySite, comparison }) => {
  const theme = useTheme();

  const {
    conversionsMetric = [],
    avgs,
    avgsToCompare,
    loaded,
    error,
  } = useConversionsMetric({
    selectedMoneySite,
    comparison,
  });

  const tabs = [
    {
      label: 'C/SU',
      data: conversionsMetric.map(d => d[1]),
      color: theme.palette.primary.main,
    },
    {
      label: 'C/FTD',
      data: conversionsMetric.map(d => d[2]),
      color: theme.palette.primary.main,
    },
    {
      label: 'SU/FTD',
      data: conversionsMetric.map(d => d[3]),
      color: theme.palette.primary.main,
    },
  ];

  const renderTrend = useCallback((avg, avgToCompare) => {
    const perc =
      avg && avg - avgToCompare
        ? ((avg - avgToCompare) * 100) / avgToCompare
        : 0;
    return (
      <Trending
        trend={perc > 0 ? 'up' : perc === 0 ? 'flat' : 'down'}
        percentage
        allowNegative
        value={perc.toFixed(2)}
        variant="body2"
        iconSize="default"
      />
    );
  }, []);

  if (!loaded) {
    return (
      <Card>
        <div style={{ padding: '24px', textAlign: 'center' }}>
          <CircularProgress />
        </div>
      </Card>
    );
  }

  if ((loaded && !conversionsMetric.length) || error) {
    return (
      <Card>
        <div style={{ padding: '24px', textAlign: 'center' }}>
          <Typography variant="h2">
            <span role="img" aria-label="no data">
              🤷‍♂️
            </span>
          </Typography>
          <Typography variant="h6">No earning registered</Typography>
        </div>
      </Card>
    );
  }

  return (
    <Card>
      <div style={{ position: 'relative' }}>
        <Toolbar>
          <Typography variant="h6">Conversions Metric</Typography>
          <HelpTooltip
            source="Voonix APIs"
            description="Conversions metrics in percentage for the selected time range - Change in % in the top menu is vs. the comparable previous period, e.g last 30 days vs. the previous 30 days."
          />
        </Toolbar>
        <TabbedView tabsVariant="fullWidth" variant="light">
          {tabs.map((tab, i) => (
            <Chart
              name={tab.label}
              icon={renderTrend(avgs[i], avgsToCompare[i])}
              key={tab.label}
              width={'100%'}
              height={'350px'}
              chartType="AreaChart"
              loader={<CircularProgress />}
              data={[
                ['date', tab.label, { role: 'tooltip', type: 'string' }],
                ...conversionsMetric.map(metric => [
                  metric[0],
                  metric[i + 1] / 100,
                  `${metric[0]}\n${metric[i + 1].toFixed(2)}%`,
                ]),
              ]}
              options={{
                hAxis: {
                  title: 'Date',
                },
                vAxis: {
                  title: 'Percentage',
                  format: 'percent',
                },
                colors: [tab.color],
              }}
            />
          ))}
        </TabbedView>
      </div>
    </Card>
  );
};

export default memo(ConversionsMetric);
