import { Avatar, Chip } from '@material-ui/core';
import { Person, Public } from '@material-ui/icons';
import { FunctionField } from 'ra-ui-materialui';
import { TextField } from 'react-admin';
import { constants } from '../../constants';
import { EditButton, DateField, ReferenceArrayField } from '../../ui/atoms';
import { AdvancedFilter, List } from '../../ui/molecules';

const filters = [
  {
    source: 'name',
    type: 'search',
    alwaysOn: true,
  },
  {
    source: 'email',
    type: 'search',
  },
  {
    source: 'roles',
    type: 'reference',
    reference: 'ums/api/v1/roles',
    resource: 'ums/api/v1/roles',
    label: 'Role',
    optionText: 'name',
  },
  {
    source: 'target_markets',
    type: 'select',
    choices: constants.MARKETS,
  },
];

export default props => (
  <List
    responsive={['name', 'roles']}
    sort={{ field: 'name', order: 'ASC' }}
    listkey={'name'}
    {...props}
    advancedFilters
    filters={<AdvancedFilter customfilters={filters} />}
    defaultColumns={[
      'name',
      'last_name',
      'email',
      'roles',
      'last_activity',
      'last_login',
    ]}
  >
    <TextField source="name" />
    <TextField source="last_name" />
    <TextField source="email" />
    <ReferenceArrayField
      label="Roles"
      reference="ums/api/v1/roles"
      source="roles"
      referenceSource="name"
      chip
      avatar={<Person />}
    />
    <DateField source="last_activity" />
    <DateField source="last_login" />
    <FunctionField
      source="target_markets"
      render={row =>
        (row.target_markets || []).map(market => (
          <Chip
            key={market}
            component="div"
            style={{ marginRight: 5, marginBottom: 5 }}
            color="primary"
            avatar={
              <Avatar component="span">
                <Public />
              </Avatar>
            }
            label={market}
          />
        ))
      }
    />
    <EditButton />
  </List>
);
