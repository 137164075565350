import { useState, useEffect } from 'react';

export const useVersion = () => {
  const [version, setVersion] = useState<string>('');
  useEffect(() => {
    if (process.env.REACT_APP_ENV !== 'development') {
      fetch(
        `${
          process.env.REACT_APP_API_URL
        }/version.txt?v=${new Date().getTime()}`,
      ).then(r => {
        if (r.status > 199 && r.status < 399) {
          r.text().then(response => setVersion(response));
        }
      });
    }
  }, []);
  return version;
};

export const useNewVersion = () => {
  const [version, setVersion] = useState<string>('');
  useEffect(() => {
    if (process.env.REACT_APP_ENV !== 'development') {
      setInterval(() => {
        fetch(
          `${
            process.env.REACT_APP_API_URL
          }/version.txt?v=${new Date().getTime()}`,
        ).then(r => {
          if (r.status > 199 && r.status < 399) {
            r.text().then(response => setVersion(response));
          }
        });
      }, 60000);
    }
  }, []);
  return version;
};
