import { useCallback } from 'react';
import { getJson } from '../components/Helpers/API';

type UseComments = {
  project_id: string;
};

type Comment = {
  author: string;
  authorName: string;
  id: string;
  createdAt: string;
};

type Req = {
  content?: string;
  createdAt?: string;
  modifiedAt?: string;
  commentUid?: string;
  conversationUid?: string;
};

type DoneProps = {
  conversationUid?: string;
  commentUid?: string;
  canEdit?: boolean;
  canDelete?: boolean;
  reason?: string;
  conversation?: { uid: string; comments: Comment[] };
};

type Fail = (error: string | Error) => void;
type Done = (doneProps: DoneProps) => void;

export function useComments({ project_id }: UseComments) {
  const tinycomments_create = useCallback(
    async (req: Req, done: Done, fail: Fail) => {
      const conversation_created = new Event('conversation_created');
      try {
        const response = await getJson('/mms/api/v1/conversations', {
          method: 'POST',
          body: JSON.stringify({
            project_id,
            comments: [
              {
                author: 'author 1',
                authorName: 'author',
                createdAt: req.createdAt,
                content: req.content,
              },
            ],
          }),
        });
        if (!response || !response.$oid) {
          console.error('response create', response);
          fail('Something went wrong');
        }
        await done({ conversationUid: response.$oid });
        document.dispatchEvent(conversation_created);
      } catch (e) {
        console.error('response create', e);
        fail(e);
      }
    },
    [project_id],
  );

  const tinycomments_reply = useCallback(
    async (req: Req, done: Done, fail: Fail) => {
      try {
        const response = await getJson(
          `/mms/api/v1/conversations/${req.conversationUid}/reply`,
          {
            method: 'POST',
            body: JSON.stringify({
              createdAt: req.createdAt,
              content: req.content,
            }),
          },
        );
        if (!response || !response.comment_uid) {
          console.error('response reply', response);
          fail('Something went wrong');
        }
        done({ commentUid: response.comment_uid });
      } catch (e) {
        console.error('response reply', e);
        fail(e);
      }
    },
    [],
  );

  const tinycomments_edit_comment = useCallback(
    async (req: Req, done: Done, fail: Fail) => {
      try {
        const response = await getJson(
          `/mms/api/v1/conversations/${req.conversationUid}/reply/${req.commentUid}`,
          {
            method: 'PUT',
            body: JSON.stringify({
              modifiedAt: req.modifiedAt,
              content: req.content,
            }),
          },
        );
        if (!response || !response.comment_uid) {
          console.error('response edit', response);
          fail('Something went wrong');
        }
        done({ canEdit: true, reason: response.reason });
      } catch (e) {
        console.error('response edit', e);
        fail(e);
      }
    },
    [],
  );

  const tinycomments_lookup = useCallback(
    async (req: Req, done: Done, fail: Fail) => {
      try {
        const response = await getJson(
          `/mms/api/v1/conversations/${req.conversationUid}`,
        );
        if (!response || !response.id) {
          console.error('response lookup', response);
          fail('Something went wrong');
        }
        done({
          conversation: {
            uid: response.id,
            comments: response.comments,
          },
        });
      } catch (e) {
        console.error('response lookup', e);
        fail(e);
      }
    },
    [],
  );

  const tinycomments_delete = useCallback(async (req: Req, done: Done) => {
    done({ canDelete: false, reason: "Comments can't be deleted" });
    console.error('Delete functionalities are temporarely disabled!');
    return;
    // try {
    //   const response = await getJson(
    //     `/mms/api/v1/conversations/${req.conversationUid}`,
    //     { method: "DELETE" }
    //   );
    //   if (!response) {
    //     console.error("response", response);
    //     fail("Something went wrong");
    //   }
    //   done({ canDelete: true, reason: response.reason });
    // } catch (e) {
    //   fail(e);
    // }
  }, []);

  const tinycomments_delete_all = useCallback(async (req: Req, done: Done) => {
    done({ canDelete: false, reason: "Comments can't be deleted" });
    console.error('Delete functionalities are temporarely disabled!');
    return;
    // try {
    //   const response = await getJson(
    //     `/mms/api/v1/conversations/all/${project_id}`,
    //     { method: "DELETE" }
    //   );
    //   if (!response) {
    //     console.error("response", response);
    //     fail("Something went wrong");
    //   }
    //   done({ canDelete: true, reason: response.reason });
    // } catch (e) {
    //   fail(e);
    // }
  }, []);

  const tinycomments_delete_comment = useCallback(
    async (req: Req, done: Done) => {
      done({ canDelete: false, reason: "Comments can't be deleted" });
      console.error('Delete functionalities are temporarely disabled!');
      return;
      // try {
      //   const response = await getJson(
      //     `/mms/api/v1/conversations/${req.conversationUid}/reply/${req.commentUid}`,
      //     { method: "DELETE" }
      //   );
      //   if (!response) {
      //     console.error("response", response);
      //     fail("Something went wrong");
      //   }
      //   done({ canDelete: true, reason: response.reason });
      // } catch (e) {
      //   fail(e);
      // }
    },
    [],
  );

  return {
    tinycomments_create,
    tinycomments_reply,
    tinycomments_edit_comment,
    tinycomments_lookup,
    tinycomments_delete,
    tinycomments_delete_all,
    tinycomments_delete_comment,
  };
}
