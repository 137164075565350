import { TextField, NumberField, BulkDeleteButton } from 'react-admin';
import { Fragment } from 'react';
import {
  UrlField,
  EditButton,
  ButtonField,
  BooleanField,
} from '../../ui/atoms';
import { AdvancedFilter, List } from '../../ui/molecules';
import filters from './filters.map';
import MergePlugins from './MergePlugins';

const ListBulkActionButtons = props => (
  <Fragment>
    <MergePlugins {...props} />
    <BulkDeleteButton {...props} />
  </Fragment>
);

export default props => (
  <div>
    <List
      responsive={['title', 'active_installs']}
      sort={{ field: 'name', order: 'DESC' }}
      listkey={'url'}
      {...props}
      advancedFilters
      filters={<AdvancedFilter customfilters={filters} />}
      defaultColumns={[
        'name',
        'total_installs',
        'active_installs',
        'inactive_installs',
        'latest_version',
      ]}
      bulkActionButtons={<ListBulkActionButtons />}
    >
      <ButtonField source="name" />
      <TextField source="title" />
      <UrlField source="plugin_uri" />
      <TextField source="description" />
      <TextField source="author" />
      <UrlField source="author_uri" />
      <TextField source="text_domain" />
      <TextField source="author_name" />
      <TextField source="slug" />
      <TextField source="source" />
      <ButtonField source="total_installs" />
      <NumberField source="active_installs" />
      <NumberField source="inactive_installs" />
      <TextField source="latest_version" />
      <BooleanField source="auto_update" />
      <BooleanField source="public" />
      <EditButton />
    </List>
  </div>
);
