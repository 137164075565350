import { Paper } from '@material-ui/core';
import { Grid } from '../../ui/molecules';
import { SinglePageHeader, Card, ReferenceField } from '../../ui/atoms';
import Domains from './Domains';

export default ({ record }) => {
  return (
    <Paper
      style={{
        padding: 8,
        marginTop: 12,
      }}
    >
      <Grid>
        <SinglePageHeader label="Nameserver" title={record.nameserver} lg={8} />
        <SinglePageHeader
          label="Registrar"
          title={
            <ReferenceField
              source="registrar_id"
              reference="dms/api/v1/registrars"
              basePath="/dms/api/v1/registrars"
              allowEmpty
              referenceSource="registrar"
              link={'show'}
            />
          }
          lg={4}
        />
        <Card w={12}>
          <Domains record={record} />
        </Card>
      </Grid>
    </Paper>
  );
};
