import { Avatar, Chip } from '@material-ui/core';
import { Link } from '@material-ui/icons';
import {
  FunctionField,
  ReferenceArrayField,
  SingleFieldList,
  TextField,
} from 'react-admin';
import { EditButton } from '../../ui/atoms';
import { AdvancedFilter, List } from '../../ui/molecules';

const filters = [
  {
    source: 'name',
    type: 'search',
    alwaysOn: true,
  },
];

export default props => (
  <List
    responsive={['name']}
    sort={{ field: 'name', order: 'ASC' }}
    listkey={'name'}
    {...props}
    advancedFilters
    filters={<AdvancedFilter customfilters={filters} />}
    defaultColumns={['name']}
  >
    <TextField source="name" />
    <TextField source="description" />
    <ReferenceArrayField
      label="Permissions"
      reference="ums/api/v1/permissions"
      source="permissions"
      referenceSource="route"
    >
      <SingleFieldList>
        <FunctionField
          label="Route"
          render={permission => (
            <div style={{ paddingBottom: '8px' }}>
              <Chip
                component="span"
                color="primary"
                avatar={
                  <Avatar component="span">
                    <Link />
                  </Avatar>
                }
                label={permission.method + ' ' + permission.route}
              />
            </div>
          )}
        />
      </SingleFieldList>
    </ReferenceArrayField>
    <EditButton />
  </List>
);
