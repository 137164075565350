import { stringify } from 'querystring';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core';
import { getJson } from '../../../../components/Helpers/API';
import {
  useCompareRange,
  useFormattedCompareDate,
} from '../../../../hooks/useCompareDate';

const useGSC = ({ selectedMoneySite, comparison }) => {
  const [loaded, setLoaded] = useState(false);
  const startDate = dayjs()
    .subtract(66, 'day')
    .format('YYYY-MM-DD');
  const compareDate = useFormattedCompareDate(comparison, 'YYYYMMDD');
  const rangeInDays = useCompareRange(comparison);
  const theme = useTheme();

  const [ctr, setCtr] = useState();
  const [impressions, setImpressions] = useState();
  const [clicks, setClicks] = useState();

  const [initData, setInitData] = useState();

  const fetchData = useCallback(() => {
    setLoaded(false);
    setInitData([]);
    getJson(
      `/analytics/api/v1/google-analytics/search-report/${
        selectedMoneySite.id
      }?${stringify({
        start_date: startDate,
        end_date: dayjs()
          .subtract(1, 'days')
          .format('YYYY-MM-DD'),
        dimensions: 'date',
      })}`,
    )
      .then(res => setInitData(res.data || []))
      .catch(err => console.error(err))
      .finally(() => setLoaded(true));
  }, [startDate, selectedMoneySite]);

  const generateData = useCallback(
    data => {
      const { rows } = data;
      const dataToDate = rows
        ? rows.filter(d => {
            return (
              d.keys &&
              d.keys.length > 0 &&
              Number(dayjs(d.keys[0]).format('YYYYMMDD')) >= Number(compareDate)
            );
          })
        : [];

      const dataToCompare = rows
        ? rows.filter(d => {
            return (
              d.keys &&
              d.keys.length > 0 &&
              Number(dayjs(d.keys[0]).format('YYYYMMDD')) <
                Number(compareDate) &&
              Number(dayjs(d.keys[0]).format('YYYYMMDD')) >=
                Number(
                  dayjs(compareDate)
                    .subtract(rangeInDays, 'days')
                    .format('YYYYMMDD'),
                )
            );
          })
        : [];
      setCtr({
        data: [
          ['date', 'value', { role: 'tooltip', type: 'string' }],
          ...dataToDate.reduce(
            (a, b) => [
              ...a,
              [
                dayjs(b.keys[0]).format('DD MMM'),
                b.ctr,
                `${dayjs(b.keys[0]).format('DD MMM')}\n${(b.ctr * 100).toFixed(
                  2,
                )}%`,
              ],
            ],
            [],
          ),
        ],
        avg:
          dataToDate.reduce((a, b) => {
            a = a + b.ctr * 100;
            return a;
          }, 0) / dataToDate.length,
        avgToCompare:
          dataToCompare.reduce((a, b) => {
            a = a + b.ctr * 100;
            return a;
          }, 0) / dataToCompare.length,
        label: 'CTR',
        color: theme.palette.primary.main,
        options: {
          vAxis: { format: '##.##%' },
        },
      });
      setImpressions({
        data: [
          ['date', 'value'],
          ...dataToDate.reduce(
            (a, b) => [
              ...a,
              [dayjs(b.keys[0]).format('DD MMM'), b.impressions],
            ],
            [],
          ),
        ],
        avg:
          dataToDate.reduce((a, b) => {
            a = a + b.impressions;
            return a;
          }, 0) / dataToDate.length,
        avgToCompare:
          dataToCompare.reduce((a, b) => {
            a = a + b.impressions;
            return a;
          }, 0) / dataToDate.length,
        label: 'Impressions',
        color: theme.palette.primary.main,
      });
      setClicks({
        data: [
          ['date', 'value'],
          ...dataToDate.reduce(
            (a, b) => [...a, [dayjs(b.keys[0]).format('DD MMM'), b.clicks]],
            [],
          ),
        ],
        avg:
          dataToDate.reduce((a, b) => {
            a = a + b.clicks;
            return a;
          }, 0) / dataToDate.length,
        avgToCompare:
          dataToCompare.reduce((a, b) => {
            a = a + b.clicks;
            return a;
          }, 0) / dataToDate.length,
        label: 'Clicks',
        color: theme.palette.primary.main,
      });
    },
    [compareDate, rangeInDays, theme.palette.primary.main],
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (initData) {
      generateData(initData);
    }
  }, [initData, generateData]);

  return { ctr, impressions, clicks, loaded };
};

export default useGSC;
