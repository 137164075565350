import { createSelector } from 'reselect';
import { capitalize } from '../../components/Helpers/utils';
import { getGlobalState, TypeGlobalState } from './globalStateSelector';

export interface LocationType {
  pathname: string;
}

export interface LocationWalkerType {
  resource: string;
  formatterResource: string;
  currentResourceId: string;
  action: string;
}

export const getLocation = createSelector(
  getGlobalState,
  (globalState: TypeGlobalState): LocationType => {
    return globalState.router.location || {};
  },
);

export const getLocationWalker = createSelector(
  getLocation,
  ({ pathname }: LocationType): LocationWalkerType => {
    const location = pathname.replace('/dms/api/v1/', '');
    const locationList = location.split('/');

    return {
      resource: `dms/api/v1/${locationList[0]}`,
      formatterResource: capitalize(locationList[0]) || '',
      currentResourceId: capitalize(locationList[1]) || '',
      action: capitalize(locationList[2]) || '',
    };
  },
);
