import { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import CopyIcon from '@material-ui/icons/FileCopy';
import { showNotification, GET_LIST, GET_MANY } from 'react-admin';

import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import dataProvider from '../../dataProvider';

const styles = {
  button: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  label: {
    paddingLeft: '0.5em',
  },
  labelRightIcon: {
    paddingRight: '0.5em',
  },
  smallIcon: {
    fontSize: 20,
  },
  mediumIcon: {
    fontSize: 22,
  },
  largeIcon: {
    fontSize: 24,
  },
};

class Copy extends Component {
  handleClick = () => {
    const {
      resource,
      filter,
      sort,
      showNotification,
      listkey,
      reference,
      referenceName,
    } = this.props;
    const pagination = { page: 1, perPage: 5000 };
    const clipboard = navigator.clipboard;
    showNotification('Getting your data ready...');

    const getReferences = async (records, reference) => {
      const reference_data = await dataProvider(GET_MANY, reference, {
        ids: records,
      });
      clipboard
        .writeText(
          reference_data.data.map(record => record[referenceName]).join('\n'),
        )
        .then(() => {
          showNotification('Items copied to clipboard');
        })
        .catch(err => {
          // This can happen if the user denies clipboard permissions:
          console.error('Could not copy Items: ', err);
        });
    };

    dataProvider(GET_LIST, resource, { filter, sort, pagination })
      .then(response => {
        const key = listkey ? listkey : 'name';
        const data = response.data.map(record => record[key]);
        if (reference && referenceName) {
          getReferences(data, reference);
        } else {
          clipboard
            .writeText(data.join('\n'))
            .then(() => {
              showNotification('Items copied to clipboard');
            })
            .catch(err => {
              // This can happen if the user denies clipboard permissions:
              console.error('Could not copy Items: ', err);
            });
        }
      })
      .catch(e => {
        console.error(e);
        showNotification('Error: failed retrieving data', 'warning');
      });
  };

  render() {
    const { classes, className, ...rest } = this.props;
    return (
      <Button size="small" color="primary" onClick={this.handleClick}>
        <CopyIcon className={classnames(classes.smallIcon, className)} />
        {rest.label && (
          <span className={classnames(classes.label, className)}>Copy</span>
        )}
      </Button>
    );
  }
}

export default compose(
  connect(null, {
    showNotification,
  }),
  withStyles(styles),
)(Copy);
