import jsonExport from 'jsonexport/dist';
import { downloadCSV } from 'react-admin';
import { asyncForEach } from './utils';

interface IProps {
  records: any[];
  fetchRelatedRecords: Function;
  referenceFields: { field: string; collection: string; reference: string }[];
  resource: string;
}

export default ({
  records,
  fetchRelatedRecords,
  referenceFields,
  resource,
}: IProps): void => {
  let data = records;
  const getReferences = async () => {
    await asyncForEach(referenceFields, async (reference: any) => {
      const reference_data = await fetchRelatedRecords(
        records,
        reference.field,
        reference.collection,
      );
      data = data.map((record: any) => {
        const initial =
          record[reference.field] instanceof Array
            ? record[reference.field]
            : [record[reference.field]];
        return {
          ...record,
          [reference.field]: initial
            .map((ref: any) => reference_data?.[ref]?.[reference?.reference])
            .join(' - '),
        };
      });
    });
    jsonExport(data, {}, (err: any, csv: any) => {
      if (err) {
        console.error(err);
        return;
      }
      downloadCSV(csv, resource);
    });
  };
  if (records.length > 100) {
    const no_ref = window.confirm(
      "You've requested too much data for referencial export, export will be done with no references. To export referenced data, please restrict data using filters (max 100 entries at a time)",
    );
    if (!no_ref) {
      return;
    }
    jsonExport(data, {}, (err: any, csv: any) => {
      if (err) {
        console.error(err);
        return;
      }
      downloadCSV(csv, resource);
    });
  } else {
    getReferences();
  }
};
