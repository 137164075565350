import { FC, memo, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { refreshView } from 'react-admin';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from '@material-ui/core';
import { Refresh } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { getJson } from '../../../components/Helpers/API';

type Props = {
  record: any;
  resource: string;
  disabled?: boolean;
};

const RefreshButton: FC<Props> = ({ record, resource, disabled }) => {
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<string>();
  const [error, setError] = useState<string | null>(null);
  const handleRefresh = () => {
    if (resource && record.id) {
      setOpenDialog(true);
      setLoading(true);
      setError(null);
      setResponse('');
      getJson(`/${resource}/${record.id}/refresh`)
        .then(res => {
          const failedOutput = res.data.failed_job_output
            .map((data: any) => `${data.output.message}\n`)
            .join();
          const output = res.data.job_output
            .map((data: any) => `${data.output.message}\n`)
            .join();
          setLoading(false);
          setResponse(failedOutput || output);
        })
        .catch(() => {
          setError('Something went wrong with the refresh');
        })
        .finally(() => setLoading(false));
    }
  };

  const closeDialog = useCallback(() => {
    if (response) {
      dispatch(refreshView());
    }
    setOpenDialog(false);
  }, [response, dispatch]);

  return (
    <>
      <Button
        color="primary"
        size="small"
        onClick={handleRefresh}
        disabled={disabled}
      >
        <Refresh />
      </Button>
      <Dialog open={openDialog} onClose={closeDialog} fullWidth>
        <DialogTitle style={{ fontSize: 15 }}>
          Refreshing {record.backlink}
        </DialogTitle>
        <DialogContent>
          <Divider />
          <div style={{ padding: '8px', textAlign: 'center' }}>
            {loading ? <CircularProgress /> : response}
            {error ? <Alert severity="error">{error}</Alert> : null}
          </div>
          <Divider />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary" disabled={loading}>
            Close
          </Button>
          <Button onClick={handleRefresh} color="primary" disabled={loading}>
            Try Again
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

RefreshButton.displayName = 'RefreshButton';

export default memo(RefreshButton);
