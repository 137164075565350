import { TextInput, SimpleForm, BooleanInput } from 'react-admin';
import { Grid, FormToolbar } from '../../ui/molecules';
import { Validation } from '../../ui/organisms';

export default props => {
  return (
    <SimpleForm {...props} redirect={'show'} w={12} toolbar={<FormToolbar />}>
      <Grid fullWidth>
        <Validation w={12}>
          <TextInput
            fullWidth={true}
            source="name"
            required
            w={12}
            helperText="Hostname of the domain e.g.: google.com"
          />
          <TextInput
            source="notes"
            label="Notes"
            w={12}
            multiline
            rows={8}
            fullWidth
          />
          <BooleanInput
            source="expiration_alerts"
            label="Expiration Alerts"
            w={12}
            multiline
            rows={8}
            fullWidth
          />
        </Validation>
      </Grid>
    </SimpleForm>
  );
};
