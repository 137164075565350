import {
  ChipField,
  ReferenceArrayField,
  SingleFieldList,
  TextField,
} from 'react-admin';
import { Avatar } from '@material-ui/core';
import { Bookmarks } from '@material-ui/icons';
import { ReactElement } from 'react';

interface IProps {
  label: string;
  reference: string;
  source: string;
  referenceSource: string;
  chip?: boolean;
  avatar?: JSX.Element;
  component?: ReactElement;
}

export default ({
  label,
  reference,
  source,
  referenceSource,
  chip,
  avatar,
  component,
  ...props
}: IProps) => {
  const Component = component ?? TextField;
  return (
    <ReferenceArrayField
      label={label}
      reference={reference}
      source={source}
      {...props}
    >
      <SingleFieldList style={{ margin: 0 }} linkType="show">
        {chip ? (
          <ChipField
            source={referenceSource}
            color="primary"
            avatar={<Avatar>{avatar ? avatar : <Bookmarks />}</Avatar>}
          />
        ) : (
          <Component source={referenceSource} style={{ marginRight: 5 }} />
        )}
      </SingleFieldList>
    </ReferenceArrayField>
  );
};
