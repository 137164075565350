import { memo } from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';

type Props = {
  record: any;
  source: string;
  key?: string;
  column?: string;
  ellipsis?: boolean;
  limit?: number;
};

const useStyles = makeStyles(() => ({
  text: {
    width: 200,
    overflow: 'hidden',
    wordBreak: 'break-word',
  },
}));

const TrimmedText = (props: Props) => {
  const s = (props.source ? props.source : props.column) || props.key;
  const { limit } = props;
  let text = (s && props.record?.[s]) || '';
  const classes = useStyles();
  if (s && s.split('.').length > 1) {
    text = s.split('.').reduce(function(a: any, b: any) {
      return a[b];
    }, props.record);
  }
  const trimmed = text.trim();

  if (text) {
    return (
      <Tooltip placement="top" title={text}>
        <span
          className={classes.text}
          dangerouslySetInnerHTML={{
            __html:
              limit && trimmed.length > limit
                ? trimmed.substring(0, limit) + '...'
                : trimmed,
          }}
        />
      </Tooltip>
    );
  }

  return null;
};

export default memo(TrimmedText);
