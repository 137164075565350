import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { getJson } from '../components/Helpers/API';

type UseDuplicate = {
  record: any;
  basePath: string;
  excludedFields?: string[];
  changedFields?: any;
};

export const useDuplicate = () => {
  const history = useHistory();
  const handleDuplicate = useCallback(
    (
      e,
      { record, basePath, excludedFields = [], changedFields }: UseDuplicate,
    ) => {
      if (e && e.stopPropagation) {
        e.stopPropagation();
      }
      if (!record) {
        console.error('No record passed');
        return;
      }
      if (!basePath) {
        console.error('base path required');
        return;
      }
      const excludedFieldsWithID = [
        'id',
        'created_by',
        'created_at',
        ...excludedFields,
      ];
      const newRecord: any = {};
      for (const field of Object.keys(record)) {
        if (!excludedFieldsWithID.includes(field)) {
          newRecord[field] = record[field];
        }
        if (changedFields && changedFields[field]) {
          newRecord[field] = changedFields[field];
        }
      }
      if (newRecord.title) {
        newRecord.title = newRecord.title + '_2';
      }

      getJson(`${basePath}`, {
        method: 'POST',
        body: JSON.stringify(newRecord),
      })
        .then(res => history.push(`${basePath}/${res.data.id}`))
        .catch(e => console.error('Error creating new record: ', e));
    },
    [history],
  );
  return handleDuplicate;
};
