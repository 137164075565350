import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { DoubleArrow } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import {
  getLocationWalker,
  LocationWalkerType,
} from '../../../store/selectors/getLocation';
import { getRecordLabelById } from '../../../store/selectors/getRecord';

interface IProps {
  resource: string;
  currentResource: string;
  action: string;
}

const useStyles = makeStyles(theme => ({
  breadcrumbs: {
    background: theme.palette.secondary.light,
    color: theme.palette.secondary.main,
    padding: theme.spacing(1) / 3,
    paddingRight: theme.spacing(1),
    margin: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    borderRadius: 2,
    textTransform: 'capitalize',
    [theme.breakpoints.down('xs')]: {
      margin: 0,
      background: theme.palette.primary.dark,
      borderRadius: 0,
      color: theme.palette.secondary.light,
    },
  },
  icon: {
    fontSize: 10,
    margin: theme.spacing(1) / 3,
  },
}));

export default (): JSX.Element | null => {
  const classes = useStyles();
  const [breadcrumb, setBreadcrumb] = useState<IProps>({
    resource: '',
    currentResource: '',
    action: '',
  });

  const useWalker = () =>
    useSelector((state: any): LocationWalkerType => getLocationWalker(state));

  const useResource = (props: { reference: string; id: string }) =>
    useSelector((state: any) => getRecordLabelById(state, props));

  const locationWalker = useWalker();

  const currentResourceLabel = useResource({
    reference: locationWalker.resource,
    id: locationWalker.currentResourceId,
  });

  useEffect(() => {
    setBreadcrumb({
      resource: locationWalker.formatterResource,
      currentResource: currentResourceLabel
        ? currentResourceLabel
        : locationWalker.currentResourceId,
      action: locationWalker.action,
    });
  }, [locationWalker, currentResourceLabel]);
  return (
    <>
      {Object.values(breadcrumb).length ? (
        <div className={classes.breadcrumbs}>
          {Object.values(breadcrumb).map((crumb, key) => (
            <span key={key} style={{ display: 'flex', alignItems: 'center' }}>
              {crumb && crumb !== '' ? (
                <>
                  <DoubleArrow className={classes.icon} />{' '}
                  <small>{crumb}</small>{' '}
                </>
              ) : null}
            </span>
          ))}
        </div>
      ) : null}
    </>
  );
};
