export const columns = [
  { field: 'keyword', headerName: 'Keyword' },
  { field: 'display_name', headerName: 'Display Name' },
  {
    field: 'search_type',
    headerName: 'Search Type',
    valueGetter: params =>
      params.data.search_type === 1 ? 'Desktop' : 'Mobile',
  },
  {
    field: 'search_volume',
    headerName: 'Search Volume',
    valueGetter: params => params.data.search_volume.search_volume,
  },
];
