import { FC, memo } from 'react';
import { ReferenceWebsiteChip } from '../../atoms';

type Props = {
  updateSelected: (ids: string[]) => void;
  selected_ids: string[];
};

const SelectedWebsites: FC<Props> = ({ updateSelected, selected_ids }) => {
  const handleDeleteSite = (id: string) => {
    const newSel = Array.from(selected_ids);
    newSel.splice(newSel.indexOf(id), 1);
    updateSelected(newSel);
  };

  return (
    <div style={{ padding: '24px', display: 'flex', flexWrap: 'wrap' }}>
      {' '}
      {/* 3 units * 8px/unit */}
      {selected_ids.length ? (
        selected_ids.map((id, key) => (
          <div key={key} style={{ padding: '0 10px 10px 0' }}>
            <ReferenceWebsiteChip
              id={id}
              onDelete={() => handleDeleteSite(id)}
            />
          </div>
        ))
      ) : (
        <div style={{ textAlign: 'center', padding: 20, width: '100%' }}>
          Please select sites
        </div>
      )}
    </div>
  );
};

export default memo(SelectedWebsites);
