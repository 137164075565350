import {
  NumberInput,
  SelectInput,
  SimpleForm,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
} from 'react-admin';
import { FormToolbar, Grid } from '../../ui/molecules';
import { Validation } from '../../ui/organisms';
import { constants } from '../../constants';

export default props => {
  const filterToList = window.sessionStorage.getItem('filterToList');
  return (
    <SimpleForm
      {...props}
      redirect={'show'}
      w={12}
      toolbar={<FormToolbar filterToList={filterToList} />}
    >
      <Grid fullWidth>
        <Validation w={12}>
          <TextInput
            source="url"
            label="Url (or IP)"
            required
            w={12}
            fullWidth
          />
          <SelectInput
            w={4}
            source="type"
            label="Type"
            choices={[
              { id: 'keyword', name: 'Keyword' },
              { id: 'http', name: 'Http' },
              { id: 'ping', name: 'Ping' },
            ]}
            fullWidth
          />
          <SelectInput
            w={4}
            source="gateway"
            label="Location (optional)"
            choices={constants.COUNTRIES}
            fullWidth
          />
          <TextInput source="value" label="Keyword" w={4} fullWidth />
          <NumberInput source="retries" label="Retries" w={6} fullWidth />
          <NumberInput source="interval" label="interval" w={6} fullWidth />
          <ArrayInput source="alert_emails" w={6}>
            <SimpleFormIterator>
              <TextInput source="[]" label="Email" />
            </SimpleFormIterator>
          </ArrayInput>
          <ArrayInput source="alert_phone_numbers" w={6}>
            <SimpleFormIterator>
              <TextInput source="[]" label="Phone number" />
            </SimpleFormIterator>
          </ArrayInput>
        </Validation>
      </Grid>
    </SimpleForm>
  );
};
