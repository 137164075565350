import { createElement, FC, memo, useCallback, useState } from 'react';
import {
  Checkbox,
  Collapse,
  IconButton,
  makeStyles,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import ObjectsByString from '../../../components/Helpers/ObjectsByString';

type Field = {
  label: string;
  key: string;
  component?: any;
  source?: string;
  render?: (params: any, setChildData: (data: any) => void) => JSX.Element;
  renderChild?: (params: any, childData: any) => JSX.Element;
  limit?: number;
  subCollection?: string;
};

type Props = {
  row: any;
  onSelect?: (row: any) => {};
  selected?: any[];
  fields: Field[];
  onRowClick?: (row: any) => void;
  limitedFields: string[];
  zebra?: boolean;
  footerRow?: boolean;
  rowCount?: number;
};

const useStyle = makeStyles({
  zebra: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#eaeaea',
    },
  },
  footerRow: {
    backgroundColor: '#d5d5d5',
    borderTop: '2px solid #333',
    borderBottom: '2px solid #333',
  },
});

const Row: FC<Props> = ({
  row,
  footerRow,
  onSelect,
  selected,
  fields,
  onRowClick,
  limitedFields,
  zebra,
  rowCount,
  ...props
}) => {
  const theme = useTheme();
  const classes = useStyle();
  const [childData, setChildData] = useState<any>(null);
  const [expandedField, setExpandedField] = useState<string>('');
  const getId = useCallback((row: any) => (row.id ? row.id : row._id.$oid), []);

  const handleSelect = useCallback(
    (event: any) => {
      const target: any = event.target;
      event.stopPropagation();
      onSelect && onSelect(target.value);
    },
    [onSelect],
  );

  const toggleExpandedField = useCallback(
    (key: string) => {
      if (expandedField === key) {
        setExpandedField('');
        return;
      }
      setExpandedField(key);
    },
    [expandedField],
  );

  const renderExpandedContent = useCallback(() => {
    const field = fields.find(f => f.key === expandedField);
    return (
      (field && field.renderChild && field.renderChild(row, childData)) || null
    );
  }, [childData, expandedField, fields, row]);

  const renderCellContent = useCallback(
    (field: Field) => {
      const limit = limitedFields.includes(field.key) ? field.limit : 999;

      if (field.render) {
        return field.render({ ...row, limit }, setChildData);
      }
      if (field.component) {
        return createElement(field.component, {
          record: field.subCollection ? row[field.subCollection] || {} : row,
          setChildData,
          ...props,
          ...field,
          limit,
        });
      }
      try {
        return ObjectsByString(row, field.key);
      } catch (e) {
        console.warn(e);
        return 'N/A';
      }
    },
    [row, props, limitedFields],
  );

  const handleRowClick = useCallback(
    e => {
      e.stopPropagation();
      if (onRowClick) {
        onRowClick(row);
      }
    },
    [row, onRowClick],
  );

  return (
    <>
      {footerRow && (
        <TableRow>
          <TableCell colSpan={fields.length} />
        </TableRow>
      )}
      <TableRow
        hover={!footerRow}
        onClick={!footerRow ? handleRowClick : undefined}
        style={{ cursor: onRowClick && !footerRow ? 'pointer' : undefined }}
        className={
          zebra ? classes.zebra : footerRow ? classes.footerRow : undefined
        }
      >
        {rowCount && <TableCell>{rowCount}</TableCell>}
        {onSelect && (
          <TableCell>
            <Checkbox
              checked={selected ? selected.includes(getId(row)) : undefined}
              value={getId(row)}
              onClick={handleSelect}
            />
          </TableCell>
        )}
        {fields.map(field => (
          <TableCell key={field.key}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {renderCellContent(field)}
              {field.renderChild && field.renderChild(row, childData) ? (
                <IconButton
                  aria-label="expand row"
                  size="small"
                  disabled={field.renderChild(row, childData) === undefined}
                  onClick={() => toggleExpandedField(field.key)}
                >
                  {expandedField === field.key ? (
                    <KeyboardArrowUp />
                  ) : (
                    <KeyboardArrowDown />
                  )}
                </IconButton>
              ) : null}
              {row.errors && row.errors[field.key] && (
                <Typography
                  color="primary"
                  variant="caption"
                  style={{ display: 'block', fontSize: 10 }}
                >
                  {Array.isArray(row.errors[field.key])
                    ? row.errors[field.key].join(', ')
                    : row.errors[field.key]}
                </Typography>
              )}
            </div>
          </TableCell>
        ))}
      </TableRow>
      {fields.some(field => field.renderChild) && (
        <TableRow style={{ background: theme.palette.secondary.light }}>
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={fields.length}
          >
            <Collapse in={expandedField !== ''} timeout="auto" unmountOnExit>
              <div style={{ marginRight: '16px' }}>{renderExpandedContent}</div>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default memo(Row);
