import { Collapse, Divider, IconButton, Typography } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { useState } from 'react';
import AgGrid from '../../../ui/organisms/AgGrid';
import AddNotification from '../AddNotification';
import { columns } from './columns';

export function Keywords({ record }) {
  const [openKeywords, setOpenKeywords] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const toggleKeywords = () => setOpenKeywords(!openKeywords);

  return (
    <div>
      <div style={{ paddingTop: '16px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h5">Keywords</Typography>
          <ExpandSection open={openKeywords} toggle={toggleKeywords} />
        </div>
        <Divider />
        <div>
          <AddNotification
            id={record.id}
            domain_name={
              record.display_name ? record.display_name : record.domain
            }
            selectedKeywords={selectedRows}
          />
        </div>
        <Collapse in={openKeywords} timeout="auto" unmountOnExit>
          <div style={{ padding: '16px' }}>
            <AgGrid
              onRowSelected={e => setSelectedRows(e.api.getSelectedRows())}
              search
              fit
              rows={record.keywords || []}
              columns={columns}
            />
          </div>
        </Collapse>
      </div>
    </div>
  );
}

function ExpandSection({ open, toggle }) {
  const Icon = open ? ExpandLess : ExpandMore;
  return (
    <IconButton onClick={toggle}>
      <Icon />
    </IconButton>
  );
}
