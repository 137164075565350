import { makeStyles, Paper, Typography } from '@material-ui/core';
import { localizeUtcDate } from '../../../components/Helpers/utils';

const useStyles = makeStyles((theme: any) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100%',
    overflow: 'hidden',
    borderRadius: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    '&:last-child': {
      marginRight: 0,
    },
  },
  header: {
    textAlign: 'center',
    color: theme.palette.primary.dark,
    marginTop: theme.spacing(1),
  },
  date: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    textAlign: 'center',
    padding: theme.spacing(0.5),
    margin: 0,
    justifyContent: 'space-around',
  },
}));

export default ({
  label,
  date,
}: {
  label: string;
  date: string;
}): JSX.Element => {
  const classes = useStyles();
  return (
    <Paper className={classes.card}>
      <Typography variant="caption" className={classes.header}>
        {label}
      </Typography>
      <Typography gutterBottom className={classes.date}>
        {localizeUtcDate(date, 'Do, MMM YYYY')}
        <br />
        {localizeUtcDate(date, '@ HH:mm')}
      </Typography>
    </Paper>
  );
};
