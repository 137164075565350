import { useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Divider,
  TextField,
} from '@material-ui/core';
import { useRefresh } from 'ra-core';
import withAbort from '../../components/Helpers/withAbort';
import { getJson } from '../../components/Helpers/API';
import { Grid } from '../../ui/molecules';

const AddNotification = ({ id, domain_name, selectedKeywords }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const refresh = useRefresh();

  useEffect(() => {
    setData(
      selectedKeywords.reduce(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (acc, { ranks, competitor_ranks, ...keyword }) => ({
          ...acc,
          [keyword.id]: keyword,
        }),
        {},
      ),
    );
  }, [selectedKeywords]);

  function postData() {
    setLoading(true);
    getJson(`/dms/api/v1/accuranker-domains/${id}/notifications`, {
      method: 'POST',
      body: JSON.stringify({
        keywords: data,
        domain_id: id,
        domain_name: domain_name,
        email,
        phone_number: phoneNumber,
      }),
    })
      .catch(console.error)
      .finally(() => {
        setLoading(false);
        refresh();
      });
  }

  if (loading) {
    return (
      <CircularProgress style={{ margin: '40px auto', display: 'block' }} />
    );
  }

  if (!id || !selectedKeywords.length) {
    return null;
  }

  return (
    <div>
      <Grid>
        {Object.values(data)?.map(keyword => (
          <div
            key={keyword.id}
            style={{ paddingTop: '16px', paddingBottom: '16px' }}
          >
            <TextField
              label="Set a display value"
              variant="outlined"
              size="small"
              fullWidth
              value={keyword.display_name}
              onChange={event => {
                setData({
                  ...data,
                  [keyword.id]: {
                    id: keyword.id,
                    search_type: keyword.search_type,
                    search_volume: keyword.search_volume,
                    search_engine: keyword.search_engine,
                    keyword: keyword.keyword,
                    display_name: event.target.value,
                  },
                });
              }}
              helperText={`Display value for: ${keyword.keyword} (${
                keyword.search_type === 1 ? 'Desktop' : 'Mobile'
              })`}
            />
          </div>
        ))}
      </Grid>
      <Divider style={{ marginBottom: 10 }} />
      <Grid>
        <TextField
          size="small"
          variant="outlined"
          fullWidth
          label="Phone number"
          value={phoneNumber}
          onChange={e => {
            setPhoneNumber(e.target.value);
          }}
        />
        <TextField
          size="small"
          variant="outlined"
          fullWidth
          label="Email"
          value={email}
          onChange={e => {
            setEmail(e.target.value);
          }}
        />
      </Grid>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button color="primary" variant="contained" onClick={postData}>
          Add new notification
        </Button>
      </div>
    </div>
  );
};

export default withAbort(AddNotification);
