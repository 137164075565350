import { lazy } from 'react';
import { TabbedIndex } from '../../ui/molecules';
const List = lazy(() => import('./List'));
const Alerts = lazy(() => import('./Alerts/index'));

export default props => (
  <TabbedIndex>
    <List name="External backlinks" {...props} />
    <Alerts name="Alerts" {...props} />
    <Alerts name="Errors" errors {...props} />
  </TabbedIndex>
);
