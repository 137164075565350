import { FunctionField, SingleFieldList, TextField } from 'react-admin';
import { Grid, CreatedUpdatedBox } from '../../ui/molecules';
import { Spacer, SinglePageHeader, ReferenceArrayField } from '../../ui/atoms';

export default ({ record }) => (
  <Grid>
    <Spacer height={4} w={12} />
    <SinglePageHeader label="Name" title={record.name} lg={8} />
    <CreatedUpdatedBox record={record} lg={4} />
    <TextField source="description" />
    <ReferenceArrayField
      label="Permissions"
      reference="ums/api/v1/permissions"
      source="permissions"
      referenceSource="route"
    >
      <SingleFieldList>
        <FunctionField
          label="Route"
          render={record => `${record.method}: ${record.route}`}
        />
      </SingleFieldList>
    </ReferenceArrayField>
  </Grid>
);
