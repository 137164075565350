import { useState, lazy, Suspense } from 'react';
import { Create } from 'react-admin';
import { Button } from '@material-ui/core';
import Toolbar from '../../ui/molecules/Toolbar';
import { PreloaderCallback } from '../../ui/atoms';
import Form from './Form';
const WebsiteWizard = lazy(() => import('../DeployWebsite'));

export default props => {
  const [wizard, setWizard] = useState(false);
  const WidzardButton = () => (
    <Button onClick={() => setWizard(!wizard)} color="primary" size="small">
      {wizard ? 'Use form' : 'Use Wizard'}
    </Button>
  );
  return (
    <div>
      {wizard ? (
        <Create
          {...props}
          actions={
            <Toolbar
              list
              customActions={[<WidzardButton key="website-widzard-btn" />]}
            />
          }
        >
          <Suspense fallback={<PreloaderCallback />}>
            <WebsiteWizard />
          </Suspense>
        </Create>
      ) : (
        <Create
          actions={
            <Toolbar
              list
              customActions={[<WidzardButton key="website-widzard-btn" />]}
            />
          }
          {...props}
        >
          <Form title={'Add website'} />
        </Create>
      )}
    </div>
  );
};
