import { Component } from 'react';
import T from 'prop-types';
import { FieldTitle } from 'react-admin';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Button,
} from '@material-ui/core';
import IconClose from '@material-ui/icons/Close';

class SelectionDialog extends Component {
  onColumnClicked = ({ target: { value: columnName } }) => {
    this.props.onColumnClicked(columnName);
  };

  render() {
    const { columns, selection, onClose, resource, onConfirm } = this.props;

    return (
      <Dialog
        maxWidth="xs"
        aria-labelledby="ra-columns-dialog-title"
        onClose={onClose}
        open
      >
        <DialogTitle id="ra-columns-dialog-title">Configuration</DialogTitle>
        <DialogContent style={{ maxHeight: '60vh' }}>
          <FormGroup>
            {columns.map(({ source, label }) => (
              <FormControlLabel
                key={source}
                control={
                  <Checkbox
                    checked={!!selection[source]}
                    onChange={this.onColumnClicked}
                    value={source}
                  />
                }
                label={
                  <FieldTitle
                    label={label}
                    source={source}
                    resource={resource}
                  />
                }
              />
            ))}
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={onConfirm} color="primary" size="small">
            Apply
          </Button>
          <Button onClick={this.props.onClose} color="primary" size="small">
            <IconClose />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

SelectionDialog.propTypes = {
  columns: T.arrayOf(
    T.shape({
      label: T.string,
      source: T.string.isRequired,
    }),
  ).isRequired,
  selection: T.object,
};

export default SelectionDialog;
