import { useReducer, useState, useEffect, useCallback } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Chip,
} from '@material-ui/core';
import * as textCase from 'text-case';
import { FilterList } from '@material-ui/icons';
import { SimpleForm, TopToolbar } from 'react-admin';
import SingleAdvancedFilter from './SingleAdvancedFilter';
import filterReducer from './filters.redurer';

const useStyles = makeStyles({
  advancedFilterModal: {
    '& > div': {
      display: 'flex',
      flexWrap: 'wrap',
      '& .ra-input': {
        minWidth: '33%',
        padding: '0 5px',
        boxSizing: 'border-box',
        '&.ra-input-separator': {
          width: '100%',
        },
      },
    },
  },
});

export default (props: any) => {
  const [open, setOpen] = useState(false);
  const { setFilters } = props;
  const classes = useStyles();
  const getInitialState = () => {
    if (!window.location.href.includes('filter=')) {
      return {};
    }
    const query = window.location.href.split('?')[1];
    if (!query) {
      return {};
    }
    const filter = query.split('filter=')[1].split('&')[0];
    if (!filter) {
      return {};
    }
    return JSON.parse(decodeURIComponent(filter));
  };
  const [filters, dispatch] = useReducer(filterReducer, getInitialState());
  const [retrigger, setRetrigger] = useState(false);
  const applyFilters = useCallback(() => {
    setFilters(filters);
    setOpen(false);
  }, [filters, setFilters]);
  const changeAdvFilter = (val: any, filter: any) => {
    const isEmptyValue = Array.isArray(val) ? val.length === 0 : !val;
    dispatch({
      type: isEmptyValue ? 'remove' : filter.type,
      payload: {
        source: filter.source,
        value: filter.textCase ? textCase[filter.textCase](val) : val,
      },
    });
  };
  const handleRemove = (filter: string) => {
    dispatch({
      type: 'remove',
      payload: {
        source: filter,
        val: null,
      },
    });
    setRetrigger(true);
  };

  useEffect(() => {
    if (retrigger) {
      applyFilters();
      setRetrigger(false);
    }
  }, [retrigger, applyFilters]);

  return (
    <TopToolbar>
      {Object.keys(filters).map(filter => {
        const filterInScope = props.customfilters.find(
          (f: any) => f.source === filter,
        );
        return (
          <Chip
            onDelete={() => handleRemove(filter)}
            label={(filterInScope?.label ?? filter).replace('_', ' ')}
            style={{ marginRight: 10, textTransform: 'capitalize' }}
            key={filter}
          />
        );
      })}
      <Button
        style={{ margin: '0 10px' }}
        size="small"
        color="primary"
        onClick={() => setOpen(true)}
      >
        <FilterList />
      </Button>
      <Dialog maxWidth="lg" open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Advanced Filters</DialogTitle>
        <DialogContent>
          <SimpleForm
            onSubmit={() => {}}
            toolbar={null}
            resource={props.resource}
            style={{ padding: 0 }}
            className={classes.advancedFilterModal}
          >
            {props.customfilters &&
              props.customfilters.map((filter: any, k: number) => {
                return (
                  <SingleAdvancedFilter
                    key={k}
                    {...filter}
                    type={filter.type}
                    source={filter.source}
                    state={filters}
                    onChange={(val: any) => changeAdvFilter(val, filter)}
                  />
                );
              })}
          </SimpleForm>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={applyFilters}>
            Filter
          </Button>
        </DialogActions>
      </Dialog>
    </TopToolbar>
  );
};
