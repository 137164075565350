import { Paper, TextField, Typography } from '@material-ui/core';
import { Dashboard } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { Spacer } from '../../ui/atoms';

export function NoSelectedSite({
  selectedMoneySite,
  moneySites,
  handleChangeSelectedMoneySite,
}) {
  return (
    <Paper style={{ width: 400, margin: '30px auto' }}>
      <div style={{ textAlign: 'center', padding: '40px', color: '#868686' }}>
        <Dashboard style={{ fontSize: '100px' }} color="primary" />
        <Spacer height={20} />
        <Typography variant="h5" style={{ fontWeight: 900 }}>
          Please select a Money Site
        </Typography>
        <Spacer height={20} />
        <Autocomplete
          style={{
            width: 300,
            margin: '0 auto',
            backgroundColor: '#fff',
            padding: 10,
            borderRadius: 5,
          }}
          size="small"
          options={moneySites.sort((a, b) => {
            if (a.hostname < b.hostname) {
              return -1;
            }
            if (a.hostname > b.hostname) {
              return 1;
            }
            return 0;
          })}
          value={selectedMoneySite || null}
          onChange={handleChangeSelectedMoneySite}
          disableClearable
          getOptionLabel={option => option.hostname}
          renderInput={params => (
            <TextField
              {...params}
              variant="outlined"
              label="Select MoneySite"
            />
          )}
        />
      </div>
    </Paper>
  );
}
