import { cloneElement, useEffect, useState } from 'react';
import { Show } from 'react-admin';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { getCurrentRecord } from '../../../store/selectors/getRecord';

export default (props: any) => {
  const useCurrentRecord = () =>
    useSelector((state: any) => getCurrentRecord(state));
  const currentRecord = useCurrentRecord();

  const [record, setRecord] = useState<any>({});
  useEffect(() => {
    setRecord(currentRecord);
  }, [props, currentRecord]);

  return (
    <Show {...props} component={Box}>
      {cloneElement(props.children, { record })}
    </Show>
  );
};
