import { Paper } from '@material-ui/core';
import { CreatedUpdatedBox, Grid } from '../../ui/molecules';
import { Card, SinglePageHeader } from '../../ui/atoms';
import HostnameHref from './HostnameHref';

export default ({ record }) => {
  return (
    <Paper
      style={{
        padding: 8,
        marginTop: 12,
      }}
    >
      <Grid>
        <SinglePageHeader
          label="Hostname"
          title={record.id}
          subtitle={`Last ip: ${record.last_doc.ip_client}`}
          lg={8}
        />
        <CreatedUpdatedBox record={record} lg={4} />
        <Card w={12}>
          <HostnameHref record={record} />
        </Card>
      </Grid>
    </Paper>
  );
};
