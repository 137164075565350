import { Button, Menu } from '@material-ui/core';
import Sync from '@material-ui/icons/Sync';
import { Component } from 'react';
import {
  CDNField,
  RCIcon,
  RunScan,
  SSLField,
  WarningsField,
  WPIcon,
} from '../../../ui/atoms';
import UpdateWpData from './UpdateWpData';
import FakeWpVersion from './FakeWpVersion';

class WordpressActions extends Component {
  state = {
    anchorEl: null,
  };

  handleClick = event => {
    event.stopPropagation();
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = e => {
    e.stopPropagation();
    this.setState({ anchorEl: null });
  };

  render() {
    const {
      record,
      color = 'primary',
      size = 'small',
      lite = false,
    } = this.props;
    const { anchorEl } = this.state;

    return (
      <>
        <RCIcon record={record} />
        <WPIcon record={record} />
        <RunScan record={record} />
        {!lite && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row-reverse',
              gap: '8px',
            }}
          >
            <WarningsField label="" source="alerts" record={record} />
            <SSLField label="SSL" source="url" record={record} />
            <CDNField label="CDN" source="cdn_provider_id" record={record} />
          </div>
        )}
        <Button
          color={color}
          aria-label="Menu"
          onClick={this.handleClick}
          size={size}
        >
          <Sync style={{ fontSize: 20 }} /> WP
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <UpdateWpData record={record} />
          <FakeWpVersion record={record} />
        </Menu>
      </>
    );
  }
}

export default WordpressActions;
