import { Label, Language } from '@material-ui/icons';
import { Paper, Typography } from '@material-ui/core';
import { Grid, CreatedUpdatedBox } from '../../ui/molecules';
import { SinglePageHeader, ChipsCloud } from '../../ui/atoms';
import Domains from './Domains';

export default ({ record }) => {
  return (
    <Paper
      style={{
        padding: 8,
        marginTop: 12,
      }}
    >
      <Grid>
        <SinglePageHeader
          label="registrar"
          title={record.registrar}
          subtitle={`Domains: ${record.domains_count}`}
          lg={8}
        />
        <CreatedUpdatedBox record={record} lg={4} />
        <Typography variant="caption" w={4}>
          Nameserver Domain Names
        </Typography>
        <Typography variant="caption" w={4}>
          Other Registrar Names
        </Typography>
        <Typography variant="caption" w={4}>
          Nameservers
        </Typography>
        <ChipsCloud w={4}>
          {(record.nameserver_domain_names || []).map(ns => (
            <Language content={ns.domain} label="Domain name:" />
          ))}
        </ChipsCloud>
        <ChipsCloud w={4}>
          {(record.other_registrar_names || []).map(or => (
            <Label content={or.name} label="Name:" />
          ))}
        </ChipsCloud>
        <ChipsCloud w={4}>
          {(record.nameservers || []).map(ns => (
            <Label content={ns.nameserver} label="NS:" />
          ))}
        </ChipsCloud>
        <Domains record={record} w={12} />
      </Grid>
    </Paper>
  );
};
