const wpLogin = wpUrl => {
  const token = localStorage.getItem('id_token');
  const authType = localStorage.getItem('auth_type');
  const encodedToken = Buffer.from(`Bearer ${token}`).toString('base64');

  if (wpUrl) {
    window.open(`${wpUrl}/wp-login.php?k=${encodedToken}&t=${authType}`);
  }
};

const WpLoginField = props => {
  const { record, label, urlField, style } = props;

  if (record && label && urlField) {
    return (
      <div
        style={style ?? {}}
        onClick={e => {
          e.stopPropagation();
          wpLogin(record[urlField]);
        }}
      >
        {record[label]}
      </div>
    );
  }

  return null;
};

export default WpLoginField;
