import { useDispatch } from 'react-redux';
import { showNotification } from 'react-admin';
import { useCallback } from 'react';

type UseNotification = {
  text?: string | { message: string };
  variant?: 'warning' | 'error' | 'info';
};

export function useNotification() {
  const dispatch = useDispatch();
  const notification = useCallback(
    ({ text, variant }: UseNotification) => {
      if (!text) {
        dispatch(showNotification('Generic error!', variant));
        return;
      }
      if (typeof text === 'string') {
        dispatch(showNotification(text, variant));
        return;
      }
      if (text.message) {
        dispatch(showNotification(text.message, variant));
      }
    },
    [dispatch],
  );
  return notification;
}
