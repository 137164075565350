import { useGetOne } from 'react-admin';
import { Paper } from '@material-ui/core';
import { Grid, CreatedUpdatedBox } from '../../ui/molecules';
import { SinglePageHeader, InfoCard } from '../../ui/atoms';

export default ({ record }) => {
  const { data: website } = useGetOne('dms/api/v1/websites', record.website_id);
  return (
    <Paper
      style={{
        padding: 8,
        marginTop: 12,
      }}
    >
      <Grid>
        <SinglePageHeader label="Website" title={website.hostname} lg={8} />
        <CreatedUpdatedBox record={record} lg={4} />
        <InfoCard
          w={12}
          label="Frequency"
          content={`Every: ${record.frequency_every} ${record.frequency_unit}`}
        />
      </Grid>
    </Paper>
  );
};
