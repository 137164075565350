import {
  AccountBalance,
  Autorenew,
  Bookmarks,
  Dns,
  Domain,
  Info,
  LineStyle,
  OpenInBrowser,
  People,
  PermMedia,
  Person,
  Security,
  Spellcheck,
  SpellcheckOutlined,
  Storage,
  Subject,
  SupervisorAccount,
  SwapHoriz,
  Timeline,
  Timer,
  Warning,
  Web,
} from '@material-ui/icons';
import Domains from './views/Domains/Domains';
import CronJobs from './views/CronJobs/CronJobs';
import Repositories from './views/Repositories/Repositories';
import Tags from './views/Tags/Tags';
import ProxyServers from './views/ProxyServers/ProxyServers';
import Servers from './views/Servers/Servers';
import IpAddresses from './views/IpAddresses/IpAddresses';
import Users from './views/Users/Users';
import Websites from './views/Websites/Websites';
import Plugins from './views/Plugins/Plugins';
import Themes from './views/Themes/Themes';
import ExternalBacklinks from './views/ExternalBacklinks/ExternalBacklinks';
import DnsRecords from './views/DnsRecords/DnsRecords';
import Registrars from './views/Registrars/Registrars';
import Registrants from './views/Registrants/Registrants';
import Nameservers from './views/Nameservers/Nameservers';
import Signature from './views/Signature/Signature';
import WordpressUsers from './views/WordpressUsers/WordpressUsers';
import Notifications from './views/Notifications/Notifications';
import Environments from './views/Environments/Environments';
import { ListArtisanActions } from './ui/organisms';
import DeindexChecker from './views/SEO/Backlinks/DeindexChecker';
import KeywordChecker from './views/SEO/Backlinks/KeywordChecker';
import AffiliateLinks from './views/AffiliateLinks/AffiliateLinks';
import Posts from './views/Posts/Posts';
import Roles from './views/Roles/Roles';
import Permissions from './views/Permissions/Permissions';
import FreelancerWebsites from './views/FreelancerWebsites/FreelancerWebsites';
import PublicProducts from './views/PublicProducts/PublicProducts';
import UptimeMonitors from './views/UptimeMonitors/UptimeMonitors';
import AhrefsMetrics from './views/AhrefsMetrics/AhrefsMetrics';
import LighthouseMetrics from './views/LighthouseMetrics/LighthouseMetrics';
import Analytics from './views/Analytics/Analytics';
import LinkProviderWebsites from './views/LinkProviderWebsites/LinkProviderWebsites';
import DisavowedDomains from './views/DisavowedDomains/DisavowedDomains';
import GoogleAnalyticsProperties from './views/GoogleAnalyticsProperties/GoogleAnalyticsProperties';
import Links from './views/Links/Links';
import AnchorTextDiversity from './views/SEO/AnchorTextDiversity/AnchorTextDiversity';
import AccurankerDomains from './views/AccurankerDomains/AccurankerDomains';
import ServiceDiscovery from './views/ServiceDiscovery/ServiceDiscovery';
import OpenAi from './views/OpenAi/OpenAi';
import NarrativaSchedules from './views/NarrativaSchedules/NarrativaSchedules';

export const resources = [
  {
    name: 'dms/api/v1/websites',
    options: { label: 'Websites', parent: 'Websites' },
    icon: Web,
    data: { ...Websites },
  },
  {
    name: 'dms/api/v1/posts',
    options: { label: 'Posts and pages', parent: 'Websites' },
    icon: Subject,
    data: { ...Posts },
  },
  {
    name: 'dms/api/v1/plugins',
    options: { label: 'Plugins', parent: 'Wordpress' },
    icon: PermMedia,
    data: { ...Plugins },
  },
  {
    name: 'dms/api/v1/themes',
    options: { label: 'Themes', parent: 'Wordpress' },
    icon: LineStyle,
    data: { ...Themes },
  },
  {
    name: 'dms/api/v1/wp-users',
    options: { label: 'Users', parent: 'Wordpress' },
    icon: Security,
    data: { ...WordpressUsers },
  },
  {
    name: 'dms/api/v1/dns-records',
    options: { label: 'DnsRecords', parent: 'Websites' },
    icon: Dns,
    data: { ...DnsRecords },
  },
  {
    name: 'dms/api/v1/affiliate-links',
    options: { label: 'Affiliate Links', parent: 'Websites' },
    icon: AccountBalance,
    data: { ...AffiliateLinks },
  },
  {
    name: 'dms/api/v1/registrars',
    options: { label: 'Registrars', parent: 'Websites' },
    icon: SupervisorAccount,
    data: { ...Registrars },
  },
  {
    name: 'dms/api/v1/registrants',
    options: { label: 'Registrants', parent: 'Websites' },
    icon: Person,
    data: { ...Registrants },
  },
  {
    name: 'dms/api/v1/nameservers',
    options: { label: 'Nameservers', parent: 'Websites' },
    icon: Storage,
    data: { ...Nameservers },
  },
  {
    name: 'dms/api/v1/domains',
    options: { label: 'Domains', parent: 'Websites' },
    icon: Domain,
    data: { ...Domains },
  },
  {
    name: 'analytics/api/v1/requests',
    options: { label: 'KC Analytics', parent: 'Websites' },
    icon: Domain,
    data: { ...Analytics },
  },
  {
    name: 'analytics/api/v1/google-analytics-properties',
    options: { label: 'GA Properties', parent: 'SEO and Tools' },
    icon: Domain,
    data: { ...GoogleAnalyticsProperties },
  },
  {
    name: 'umms/api/v1/monitors',
    options: { label: 'Monitors', parent: 'Uptime Monitoring' },
    icon: Autorenew,
    data: { ...UptimeMonitors },
  },
  {
    name: 'dms/api/v1/external-backlinks',
    options: { label: 'External Backlinks', parent: 'SEO and Tools' },
    icon: OpenInBrowser,
    data: { ...ExternalBacklinks },
  },
  {
    name: 'dms/api/v1/backlinks/keyword-checker',
    options: { label: 'Keyword Checker', parent: 'SEO and Tools' },
    icon: SpellcheckOutlined,
    data: { list: KeywordChecker },
  },
  {
    name: 'dms/api/v1/link-provider-websites',
    options: { label: 'Link Provider Websites', parent: 'SEO and Tools' },
    icon: SpellcheckOutlined,
    data: { ...LinkProviderWebsites },
  },
  {
    name: 'dms/api/v1/disavowed-domains',
    options: { label: 'Disavowed Domains', parent: 'SEO and Tools' },
    icon: SpellcheckOutlined,
    data: { ...DisavowedDomains },
  },
  {
    name: 'dms/api/v1/backlinks/deindex-checker',
    options: { label: 'Deindexation Checker', parent: 'SEO and Tools' },
    icon: Warning,
    data: { list: DeindexChecker },
  },
  {
    name: 'dms/api/v1/anchor-text-diversity',
    options: { label: 'Anchor Text Diversity', parent: 'SEO and Tools' },
    icon: Warning,
    data: { list: AnchorTextDiversity },
  },
  {
    name: 'dms/api/v1/ahrefs-metrics',
    options: { label: 'Ahrefs Metrics', parent: 'SEO and Tools' },
    icon: Timeline,
    data: { ...AhrefsMetrics },
  },
  {
    name: 'dms/api/v1/lighthouse-metrics',
    options: { label: 'Lighthouse Metrics', parent: 'SEO and Tools' },
    icon: Timeline,
    data: { ...LighthouseMetrics },
  },
  {
    name: 'dms/api/v1/servers',
    options: { label: 'Servers', parent: 'Servers' },
    icon: Storage,
    data: { ...Servers },
  },
  {
    name: 'dms/api/v1/ip-addresses',
    options: { label: 'IP Addresses', parent: 'Servers' },
    icon: Storage,
    data: { ...IpAddresses },
  },
  {
    name: 'dms/api/v1/cron-jobs',
    options: {
      label: 'DMS',
      parent: 'Cron Jobs',
      customActions: [<ListArtisanActions ms="dms" />],
    },
    icon: Timer,
    data: { ...CronJobs },
  },
  {
    name: 'umms/api/v1/cron-jobs',
    options: {
      label: 'Uptime Monitor',
      parent: 'Cron Jobs',
    },
    icon: Timer,
    data: { ...CronJobs },
  },
  {
    name: 'analytics/api/v1/cron-jobs',
    options: {
      label: 'Analytics',
      parent: 'Cron Jobs',
    },
    icon: Timer,
    data: { ...CronJobs },
  },
  {
    name: 'dms/api/v1/repositories',
    options: {
      label: 'Repositories',
      parent: 'Repositories',
    },
    icon: Timer,
    data: { ...Repositories },
  },
  {
    name: 'dms/api/v1/tags',
    options: {
      label: 'Tags',
      parent: 'Administration',
    },
    icon: Bookmarks,
    data: { ...Tags },
  },
  {
    name: 'dms/api/v1/proxy-servers',
    options: {
      label: 'Proxy Servers',
      parent: 'Servers',
    },
    icon: SwapHoriz,
    data: { ...ProxyServers },
  },
  {
    name: 'ums/api/v1/users',
    options: {
      label: 'Users',
      parent: 'Administration',
    },
    icon: People,
    data: { ...Users },
  },
  {
    name: 'ums/api/v1/service-discovery',
    options: {
      label: 'Service Discovery',
      parent: 'Administration',
    },
    icon: People,
    data: { ...ServiceDiscovery },
  },
  {
    name: 'ums/api/v1/roles',
    options: {
      label: 'Roles',
      parent: 'Administration',
    },
    icon: People,
    data: { ...Roles },
  },
  {
    name: 'ums/api/v1/permissions',
    options: {
      label: 'Permissions',
      parent: 'Administration',
    },
    icon: People,
    data: { ...Permissions },
  },
  {
    name: 'dms/api/v1/signature',
    options: {
      label: 'Signature',
      parent: 'Administration',
    },
    icon: Spellcheck,
    data: { ...Signature },
  },
  {
    name: 'nms/api/v1/notifications',
    options: {
      label: 'Notifications',
      parent: 'Administration',
    },
    icon: Info,
    data: { ...Notifications },
  },
  {
    name: 'dms/api/v1/envs',
    options: {
      label: 'Environments',
      parent: 'Administration',
    },
    icon: Info,
    data: { ...Environments },
  },
  {
    name: 'dms/api/v1/open-ai-content',
    options: { label: 'Open Ai', parent: 'Websites' },
    icon: Info,
    data: { ...OpenAi },
  },
  {
    name: 'dms/api/v1/narrativa/schedules',
    options: {
      label: 'AI Content Generator',
      parent: 'Websites',
    },
    icon: Info,
    data: { ...NarrativaSchedules },
  },
  {
    name: 'dms/api/v1/freelancer-search',
    options: { label: 'Freelancer Websites', parent: 'Websites' },
    icon: Web,
    data: { ...FreelancerWebsites },
  },
  {
    name: 'dms/api/v1/public-products-limited',
    options: { label: 'Public Products', parent: 'Websites' },
    icon: Web,
    data: { ...PublicProducts },
  },
  {
    name: 'dms/api/v1/links',
    options: { label: 'DM Links', parent: 'Websites' },
    icon: Web,
    data: { ...Links },
  },
  {
    name: 'dms/api/v1/accuranker-domains',
    options: { label: 'Accuranker Domains', parent: 'SEO and Tools' },
    icon: Web,
    data: { ...AccurankerDomains },
  },
  {
    name: 'dms/api/v1/marketplace-providers',
    options: { label: 'Marketplace Providers', parent: 'Websites' },
  },
  {
    name: 'dms/api/v1/installed-plugins',
    options: { label: 'Installed Plugins', parent: 'Websites' },
  },
  {
    name: 'dms/api/v1/sitemaps',
    options: { label: 'Sitemaps', parent: 'Websites' },
  },
  {
    name: 'dms/api/v1/websites/tlds',
    options: { label: 'Top-level domains', parent: 'Websites' },
  },
  {
    name: 'dms/api/v1/websites/earnings',
    options: { label: 'Earnings', parent: 'Websites' },
  },
  {
    name: 'dms/api/v1/websites/list-view',
    options: { label: 'List View', parent: 'Websites' },
  },
  {
    name: 'dms/api/v1/websites/indexed_status',
    options: { label: 'Indexed Status', parent: 'Websites' },
  },
  {
    name: 'dms/api/v1/external-backlink-status',
    options: { label: 'External Backlink Status', parent: 'Websites' },
  },
];
