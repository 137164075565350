import { useMemo } from 'react';
import { Button, makeStyles, Tooltip } from '@material-ui/core';
import { Input } from '@material-ui/icons';
import { UrlEnsure } from '../../../components/Helpers/utils';

const useStyles = makeStyles((theme: any) => ({
  urlField: {
    color: theme.palette.primary.dark,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textDecoration: 'none',
    textTransform: 'initial',
    letterSpacing: '0.5px',
    border: '1px solid',
    borderRadius: theme.spacing(1) / 2,
    padding: '3px 5px',
    display: 'inline-block',
    maxWidth: 'calc(100% - 12px)',
    verticalAlign: 'middle',
    '&:hover': {
      color: theme.palette.secondary.light,
      background: theme.palette.primary.dark,
    },
  },
}));

export default (props: any) => {
  const s = props.source ? props.source : props.column;
  let url = props.record[s];
  const { ellipsis = true, limit = 200 } = props;
  const classes = useStyles();
  if (s.split('.').length > 1) {
    url = s.split('.').reduce(function(a: any, b: any) {
      return a && a[b];
    }, props.record);
  }
  const isValidUrl = useMemo(() => url && url.indexOf('http') > -1, [url]);
  const safeDecodeURI = (url: string) => {
    try {
      return decodeURI(url);
    } catch (e) {
      return url;
    }
  };
  if (url) {
    let form_url = safeDecodeURI(url);

    if (props.hideProtocol) {
      form_url = form_url.replace('http://', '').replace('https://', '');
    }

    form_url =
      ellipsis && form_url.length >= limit
        ? form_url.substr(0, limit - 3) + '...'
        : form_url;
    return (
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'calc(100% - 60px) 1fr',
        }}
      >
        <Tooltip placement="top" title={props.tooltip || safeDecodeURI(url)}>
          <div style={{ overflow: 'hidden' }}>
            <span
              className={classes.urlField}
              style={props.disabled ? disabledStyle : undefined}
            >
              {props.content || form_url}
            </span>
          </div>
        </Tooltip>
        {isValidUrl || props.forceLink ? (
          <Button
            href={UrlEnsure(url)}
            target={props.target || '_blank'}
            rel="noopener noreferrer"
            size="small"
            onClick={e => e.stopPropagation()}
            disabled={props.disabled}
          >
            <Input />
          </Button>
        ) : null}
      </div>
    );
  }

  return null;
};

const disabledStyle = { color: 'grey', textDecoration: 'line-through' };
