import { Pagination } from 'react-admin';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    '& .page-number': {
      marginRight: 10,
    },
    '& .previous-page': {
      marginRight: 10,
    },
    '& .next-page': {
      marginLeft: 10,
      marginRight: 12,
    },
  },
});

export default (props: any) => {
  const classes = useStyles();
  return (
    <Pagination
      {...props}
      rowsPerPageOptions={[25, 50, 100]}
      classes={classes}
    />
  );
};
