import { Component } from 'react';
import PropTypes from 'prop-types';
import { getJson } from '../../components/Helpers/API';
import withAbort from '../../components/Helpers/withAbort';
import { AdvancedTable } from '../../ui/molecules';

class HostnameHref extends Component {
  constructor(props) {
    super(props);

    this.state = {
      paths: [],
    };
    this.loadData = this.loadData.bind(this);
  }

  loadData() {
    const $this = this;

    getJson(
      '/analytics/api/v1/requests/show-by-href/' + $this.props.record.id,
      {
        signal: this.props.signal,
      },
    )
      .then(response => {
        $this.setState({ paths: response });
      })
      .catch(e => {
        console.error(e);
      });
  }

  componentDidMount() {
    this.loadData();
  }

  render() {
    const { paths } = this.state;
    return (
      <div>
        <AdvancedTable
          items={paths.data}
          fields={[
            { key: '_id', label: 'Access' },
            { key: 'count', label: 'Count' },
          ]}
          zebra
        />
      </div>
    );
  }
}

HostnameHref.propTypes = {
  record: PropTypes.object.isRequired,
};

export default withAbort(HostnameHref);
