import { memo } from 'react';
import {
  Card,
  CircularProgress,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Spacer, Trending } from '../../../../ui/atoms';
import { AdvancedTable, TabbedView } from '../../../../ui/molecules';
import HelpTooltip from '../HelpTooltip';
import Toolbar from '../Toolbar';
import useRankSummary from './useRankSummary';
import { ReactComponent as Baidu } from './assets/Baidu.svg';
import { ReactComponent as Bing } from './assets/Bing.svg';
import { ReactComponent as Desktop } from './assets/Desktop.svg';
import { ReactComponent as Google } from './assets/Google.svg';
import { ReactComponent as Mobile } from './assets/Mobile.svg';
import { ReactComponent as Yandex } from './assets/Yandex.svg';
import { ReactComponent as YouTube } from './assets/YouTube.svg';

const iconStyle = makeStyles({
  icon: {
    width: 15,
    height: 15,
    fill: '#888',
    margin: 3,
  },
});

const Assets = {
  Baidu,
  Bing,
  Google,
  Yandex,
  YouTube,
};

const calculateAverage = items => {
  let tot = 0;
  let avg = 0;
  for (const item of items) {
    tot += item.rank;
    avg = tot / items.length;
  }
  return avg.toFixed(2);
};

const calculateAverageY = items => {
  let tot = 0;
  let avg = 0;
  for (const item of items) {
    tot += item.trend;
    avg = tot / items.length;
  }
  return avg.toFixed(2);
};

const RankSummary = ({ selectedMoneySite, comparison }) => {
  const { icon } = iconStyle();
  const { filteredKeywords, loaded } = useRankSummary({
    selectedMoneySite,
    comparison,
  });
  const filteredKeywordsDesktop = filteredKeywords
    .filter(k => k.search_type === 1 && k.search_volume > 1000)
    .splice(0, 10);
  const filteredKeywordsMobile = filteredKeywords
    .filter(k => k.search_type === 2 && k.search_volume > 1000)
    .splice(0, 10);

  const Trend = ({ trend }) => {
    if (trend === 0) {
      return <Trending trend="flat" value={trend} />;
    }
    return (
      <>
        {trend < 0 ? (
          <Trending trend="up" value={trend} />
        ) : (
          <Trending trend="down" value={trend} />
        )}
      </>
    );
  };
  const fields = [
    { key: 'keyword', label: 'Keyword' },
    { key: 'search_volume', label: 'Search volume' },
    {
      key: 'rank',
      label: 'Rank',
      render: row => {
        const Engine = Assets[row.search_engine];
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography align="right" style={{ width: 30 }}>
              {row.rank}
            </Typography>
            <Spacer width={10} />
            <Trend trend={row.trend} />
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              {Engine && <Engine className={icon} />}
            </div>
          </div>
        );
      },
    },
  ];
  const averageDesktop = {
    keyword: 'Average',
    rank: calculateAverage(filteredKeywordsDesktop),
    trend: calculateAverageY(filteredKeywordsDesktop),
  };
  const averageMobile = {
    keyword: 'Average',
    rank: calculateAverage(filteredKeywordsMobile),
    trend: calculateAverageY(filteredKeywordsMobile),
  };
  const tableTitle = 'Top rankings';
  const tableSubTitle = selectedMoneySite
    ? 'Top ranking keywords with 1000+ searches per month and respective position in the selected time range'
    : 'Top Money Sites';

  if (!loaded) {
    return (
      <Card>
        <div style={{ padding: '24px', textAlign: 'center' }}>
          <CircularProgress />
        </div>
      </Card>
    );
  }

  if (loaded && !filteredKeywords.length) {
    return (
      <Card>
        <div style={{ padding: '24px', textAlign: 'center' }}>
          <Typography variant="h2">
            <span role="img" aria-label="no data">
              🤷‍♂️
            </span>
          </Typography>
          <Typography variant="h6">No ranking keywords registered</Typography>
        </div>
      </Card>
    );
  }

  return (
    <Card>
      <div style={{ position: 'relative' }}>
        <Toolbar>
          <div>
            {tableTitle && <Typography variant="h6">{tableTitle}</Typography>}
            {tableSubTitle && (
              <Typography variant="caption">{tableSubTitle}</Typography>
            )}
          </div>
          <HelpTooltip
            source="AccuRanker APIs"
            description="Top ranking keywords and respective position in the selected time range"
          />
        </Toolbar>
        <TabbedView variant="light" tabsVariant="fullWidth">
          <AdvancedTable
            name="Mobile"
            label={<Mobile style={{ padding: 20 }} className={icon} />}
            items={filteredKeywordsMobile}
            footerRow={averageMobile}
            fields={fields}
          />
          <AdvancedTable
            name="Desktop"
            label={<Desktop style={{ padding: 20 }} className={icon} />}
            items={filteredKeywordsDesktop}
            footerRow={averageDesktop}
            fields={fields}
          />
        </TabbedView>
      </div>
    </Card>
  );
};

export default memo(RankSummary);
