import { useEffect, useState } from 'react';
import {
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
} from '@material-ui/core';
import {
  ArrowDownwardOutlined,
  ArrowForwardOutlined,
  ArrowUpwardOutlined,
} from '@material-ui/icons';
import { Line } from 'react-chartjs-2';
import { ChartDataset } from 'chart.js';
import { getJson } from '../../../components/Helpers/API';
import ObjectByString from '../../../components/Helpers/Functions';

interface IProps {
  record: any;
  source: string;
  label: string;
  compare: string;
  historicData: string;
}

export default ({
  record,
  source,
  label,
  compare,
  historicData,
}: IProps): JSX.Element => {
  const [open, setOpen] = useState(false);
  const value = ObjectByString(record, source);
  const compareObj = ObjectByString(record, compare);
  // Update the useState to properly type the initial state for `values`
  const [values, setValues] = useState<ChartDataset<'line'>>({
    data: [],
    label: '',
  });
  const [labels, setLabels] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      setLoading(true);
      getJson(`/dms/api/v1/ahrefs-metrics/${record.hostname}`).then(
        (r: any) => {
          const v: ChartDataset<'line'> = { data: [], label: label };
          let l: string[] = [];

          // Group entries by date
          const groupedByDate: { [key: string]: any[] } = {};
          r.data.doc.history.forEach((row: any) => {
            const date = row.date;
            if (!groupedByDate[date]) {
              groupedByDate[date] = [];
            }
            groupedByDate[date].push(row);
          });

          // Merge entries with the same date
          Object.entries(groupedByDate).forEach(([date, entries]) => {
            l.push(date);
            const mergedEntry = entries.reduce((acc: any, curr: any) => {
              const compareFields = historicData.split('.');
              let compareField = curr;
              compareFields.forEach(field => {
                if (compareField && compareField[field]) {
                  compareField = compareField[field];
                } else {
                  compareField = null;
                }
              });
              if (compareField) {
                acc[historicData] = compareField; // Assign the compareField value to acc[historicData]
              }
              return acc;
            }, {});
            // Check if the historicData field exists and is not null before pushing it into v.data
            if (mergedEntry[historicData]) {
              v.data.push(mergedEntry[historicData]);
            }
          });

          l = l.reverse();
          v.data = v.data.reverse();
          setValues(v);
          setLabels(l);
          setLoading(false);
        },
      );
    }
  }, [open, historicData, label, record.hostname, source]);

  const arrow =
    value > compareObj ? (
      <ArrowUpwardOutlined style={{ color: 'green' }} />
    ) : value < compareObj ? (
      <ArrowDownwardOutlined style={{ color: 'red' }} />
    ) : (
      <ArrowForwardOutlined />
    );

  const openDialog = (e: any) => {
    e.stopPropagation();
    setOpen(true);
  };

  const closeDialog = (e: any) => {
    e.stopPropagation();
    setOpen(false);
  };

  return (
    <div>
      <Dialog open={open} onClose={closeDialog}>
        <DialogContent
          style={{
            minWidth: 300,
            minHeight: 150,
            display: 'flex',
            flexGrow: 1,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <Line
              data={{
                labels: labels,
                datasets: [values],
              }}
              options={{
                scales: {
                  y: {
                    suggestedMin: Math.max(
                      0,
                      Math.min(
                        ...values.data
                          .filter(item => item !== null)
                          .map(item =>
                            typeof item === 'number'
                              ? item
                              : item && item.y !== null
                              ? item.y
                              : 0,
                          ),
                      ) * 0.8,
                    ),
                    suggestedMax:
                      Math.max(
                        ...values.data
                          .filter(item => item !== null)
                          .map(item =>
                            typeof item === 'number'
                              ? item
                              : item && item.y !== null
                              ? item.y
                              : 0,
                          ),
                      ) * 1.2,
                  },
                },
              }}
            />
          )}
        </DialogContent>
      </Dialog>
      <Chip
        label={ObjectByString(record, source)}
        onDelete={openDialog}
        onClick={openDialog}
        deleteIcon={arrow}
      />
    </div>
  );
};
