import { cloneElement, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm, useFormState } from 'react-final-form';
import { getErrors, ErrorType } from '../../../store/selectors/getErrors';
import { Grid } from '../../molecules';

export default ({
  children,
  onValidityChange,
}: {
  children: JSX.Element | JSX.Element[];
  onValidityChange?: (valid: boolean) => void;
}) => {
  const formState = useFormState();
  const childrenList = useMemo(
    () => (Array.isArray(children) ? children : [children]),
    [children],
  );
  const [validationErrors, setValidationErrors] = useState<any>({});
  const errors = useSelector((state: any): ErrorType => getErrors(state));
  const errorData = (errors && errors.data) || null;
  const form = useForm();
  useEffect(() => {
    form.subscribe(
      FormState => {
        const errors = {};
        Object.keys(FormState?.errors ?? {}).forEach(error => {
          if (FormState && FormState.touched && FormState.touched[error]) {
            errors[error] = FormState?.errors?.[error];
          }
        });
        setValidationErrors(errors);
      },
      {
        touched: true,
        visited: true,
        errors: true,
      },
    );
  }, [form]);

  useEffect(() => {
    onValidityChange && onValidityChange(formState.valid);
  }, [formState, onValidityChange]);

  return (
    <Grid>
      {childrenList.map(child => {
        if (child.props && child.props.source) {
          return cloneElement(child, {
            key: child.props.source,
            validate: (value: any) => {
              if (child.props.required && !value) {
                return 'Required';
              }
              return undefined;
            },
            error:
              (validationErrors && !!validationErrors[child.props.source]) ||
              (errorData && !!errorData[child.props.source]) ||
              child.props.error,
            helperText:
              (validationErrors && validationErrors[child.props.source]) ||
              (errorData && errorData[child.props.source]) ||
              child.props.helperText,
          });
        }
        return child;
      })}
    </Grid>
  );
};
