import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { IconButton } from '@material-ui/core';
import { ListAltTwoTone } from '@material-ui/icons';
import AgGrid from '../../../ui/organisms/AgGrid';
import { columns } from './columns';

export default function DenseKeywords({ keywords }) {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!open);

  return (
    <>
      <IconButton onClick={toggleOpen}>
        <ListAltTwoTone color="primary" />
      </IconButton>
      <Dialog open={open} onClose={toggleOpen} maxWidth="xl">
        <div style={{ padding: '16px', width: '900px' }}>
          <AgGrid
            fit
            search
            columns={columns}
            rowData={Object.values(keywords)}
          />
        </div>
      </Dialog>
    </>
  );
}
