import { lazy } from 'react';
import TabbedShow from '../../../ui/molecules/TabbedShow';
import { Show } from '../../../ui/organisms';
import Toolbar from '../../../ui/molecules/Toolbar';

const ShowSummary = lazy(() => import('./Summary'));
const Wordpress = lazy(() => import('./Wordpress/Wordpress'));
const WebsiteDirectory = lazy(() =>
  import('./WebsiteDirectory/WebsiteDirectory'),
);
const SEO = lazy(() => import('./SEO/SEO'));
const Dns = lazy(() => import('./DNS/Dns'));
const IndexHistory = lazy(() => import('./IndexHistory/IndexHistory'));

export default props => {
  return (
    <div>
      <Show
        {...props}
        actions={
          <Toolbar updateWpData edit list delete staticGeneratorButton />
        }
      >
        <TabbedShow lazy {...props}>
          <ShowSummary {...props} label="Overview" />
          <Wordpress {...props} label="Wordpress" />
          <WebsiteDirectory {...props} label="Directory" />
          <Dns {...props} label="DNS" />
          <IndexHistory {...props} label="Indexed History" />
          <SEO {...props} label="SEO" />
        </TabbedShow>
      </Show>
    </div>
  );
};
