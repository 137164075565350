import { makeStyles, Theme } from '@material-ui/core';
import { ReactElement } from 'react';

const useStyle = makeStyles((theme: Theme) => ({
  wrapper: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    flexDirection: 'column',
    background: theme.palette.background.default,
  },
}));

export function Wrapper(props: {
  children: ReactElement | ReactElement[];
}): ReactElement {
  const { wrapper } = useStyle();
  return (
    <div style={{ display: 'flex' }} className={wrapper}>
      {props.children}
    </div>
  );
}
