import { useMemo } from 'react';
import { TextField, ReferenceField } from 'react-admin';
import { GoToIcon, ShowButton } from '../../ui/atoms';
import { AdvancedFilter, List } from '../../ui/molecules';
import ContentPreview from './ContentPreview';
import filters from './filters.map';

export default props => {
  const filter = new URLSearchParams(decodeURI(props.location.search)).get(
    'filter',
  );
  const searchedType = useMemo(
    () =>
      !!filter && !!JSON.parse(filter).type && JSON.parse(filter).type.search,
    [filter],
  );
  const searchedContent = useMemo(
    () =>
      !!filter &&
      !!JSON.parse(filter).content &&
      JSON.parse(filter).content.search,
    [filter],
  );
  return (
    <List
      responsive={['name']}
      sort={{ field: 'name', order: 'ASC' }}
      listkey={'name'}
      {...props}
      advancedFilters
      filters={<AdvancedFilter customfilters={filters} />}
      bulkActionButtons={false}
      filter={
        !searchedType ? { type: { where_not: ['attachment'] } } : undefined
      }
      defaultColumns={[
        'title',
        'website_id',
        'status',
        'type',
        'word_count',
        'outbound_links',
        'website.hostname',
        'website.domain_rating',
      ]}
      expand={<ContentPreview searchedContent={searchedContent} />}
    >
      <GoToIcon label="Go to the page" />
      <TextField source="title" />
      <ReferenceField
        link="show"
        label="Website"
        reference="dms/api/v1/websites"
        source="website_id"
        allowEmpty
      >
        <TextField source="hostname" />
      </ReferenceField>
      <TextField source="website.domain_rating" label="DR" sortable={false} />
      <TextField source="status" />
      <TextField source="type" />
      <TextField source="word_count" />
      <TextField source="outbound_links" />
      <ShowButton />
    </List>
  );
};
