import { memo } from 'react';
import { CircularProgress, Typography } from '@material-ui/core';
import Chart from 'react-google-charts';
import useStyle from '../style';
import { Emoji } from '../../../../../ui/atoms';

const LinkSources = ({ sources }) => {
  const style = useStyle();

  return (
    <div className={style.section}>
      <Typography variant="h6">Links Source</Typography>
      <div className={style.main}>
        <div className={style.chartWrapper}>
          <Chart
            width={'110px'}
            height={'110px'}
            chartType="PieChart"
            loader={<CircularProgress />}
            data={[
              ['Source', 'Perc.'],
              ['Internal', sources.internal],
              ['External', sources.external],
            ]}
            options={{
              legend: 'none',
              chartArea: { width: '100%', height: '80%' },
              tooltip: {
                trigger: 'none',
              },
              slices: {
                0: {
                  color: '#4D8F4F',
                  textStyle: { fontSize: 10, bold: true },
                },
                1: {
                  color: '#D96157',
                  textStyle: { fontSize: 10, bold: true },
                },
              },
            }}
          />
          <div
            className={style.chartLegendWrapper}
            style={{ textAlign: 'center' }}
          >
            <Emoji emoji="🟢" />
            <Typography variant="caption">Intern.</Typography>
            <Emoji emoji="🔴" />
            <Typography variant="caption">Extern.</Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(LinkSources);
