import { CRUD_GET_LIST_SUCCESS } from 'react-admin';
import { takeEvery, put } from 'redux-saga/effects';

const countBacklinks = record => {
  let count = record.backlink ? 1 : 0;
  const pages =
    record.pages && Array.isArray(record.pages)
      ? record.pages.filter(page => page.page.indexOf('/wp-admin') === -1)
      : [];
  pages.forEach(page => (page.backlink ? count++ : false));
  return count;
};

const countPages = record => {
  const pages =
    record.pages && Array.isArray(record.pages)
      ? record.pages.filter(page => page.page.indexOf('/wp-admin') === -1)
      : [];
  return pages.length;
};

const countAdminHits = record => {
  let adminHits = 0;
  if (record && record.pages) {
    record.pages.forEach(page => {
      if (page.page.indexOf('/wp-admin') !== -1) {
        adminHits += page.hits;
      }
    });
  }
  return adminHits;
};

export default function* trackingSagas() {
  yield takeEvery([CRUD_GET_LIST_SUCCESS], updateTrackingResource);
}

export function* updateTrackingResource(action) {
  if (
    !action.filtered &&
    action.meta &&
    action.meta.resource === 'tms/api/v1/websites'
  ) {
    let newData = action.payload.data.map(i => ({
      ...i,
      backlinks_count: countBacklinks(i),
      pages_count: countPages(i),
      admin_hits: countAdminHits(i),
    }));

    if (action.requestPayload.filter.active) {
      newData = newData.filter(i => i.backlinks_count > 1);
    }

    yield put({
      ...action,
      filtered: true,
      payload: {
        ...action.payload,
        data: newData,
      },
    });
  }
}
