export default (state: any, action: any) => {
  switch (action.type) {
    case 'search':
      return {
        ...state,
        [action.payload.source]: { search: action.payload.value },
      };
    case 'exact':
    case 'checkbox':
    case 'select':
    case 'reference':
    case 'referenceAutocomplete':
      return {
        ...state,
        [action.payload.source]: action.payload.value,
      };
    case 'csv':
      return {
        ...state,
        [action.payload.source]: {
          where_in: action.payload.value.replace(/\s+/g, '').split(','),
        },
      };
    case 'not_null':
      const wantsNull = action.payload.value.find((c: any) => c.null);
      const wantsNotNull = action.payload.value.find((c: any) => !c.null);

      return {
        ...state,
        [action.payload.source]: {
          where_null: wantsNull ? true : undefined,
          where_not_null: wantsNotNull ? true : undefined,
        },
      };
    case 'remove':
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [action.payload.source]: any, ...rest } = state;
      return { ...rest };
    case 'min':
    case 'max':
      return {
        ...state,
        [action.payload.source]: {
          ...state[action.payload.source],
          [action.type]: action.payload.value,
        },
      };
    case 'min_date':
      return {
        ...state,
        [action.payload.source]: {
          ...state[action.payload.source],
          min: action.payload.value,
        },
      };
    case 'max_date':
      return {
        ...state,
        [action.payload.source]: {
          ...state[action.payload.source],
          max: action.payload.value,
        },
      };
    default:
      return state;
  }
};
