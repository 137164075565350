import { lazy, useCallback, useEffect, useState } from 'react';
import { Show } from '../../ui/organisms';
import { TabbedShow } from '../../ui/molecules';
import Toolbar from '../../ui/molecules/Toolbar';
import { getJson } from '../../components/Helpers/API';

const ShowSummary = lazy(() => import('./ShowSummary'));
const ShowWebsites = lazy(() => import('./ShowWebsites'));
const ShowIpAddresses = lazy(() => import('./ShowIpAddresses'));
const ShowOpCache = lazy(() => import('./ShowOPcache'));
const ShowDetails = lazy(() => import('./ShowDetails'));

export default props => {
  const [services, setServices] = useState([]);

  useEffect(() => {
    async function fetchIt() {
      const servs = await getJson(`/dms/api/v1/servers/${props.id}/services`);
      setServices(servs.data);
    }
    fetchIt();
  }, [props.id]);

  const handleRestart = useCallback(
    async e => {
      e.preventDefault();
      e.stopPropagation();
      const body = { realName: e.target.value, action: 'restart' };
      const res = await getJson(`/dms/api/v1/servers/${props.id}/services`, {
        method: 'POST',
        body: JSON.stringify(body),
      });
      alert(res.message);
    },
    [props.id],
  );

  return (
    <Show
      {...props}
      actions={
        <Toolbar
          edit
          list
          delete
          restart
          services={services}
          handleRestart={handleRestart}
        />
      }
    >
      <TabbedShow lazy {...props}>
        <ShowSummary {...props} label="Overview" />
        <ShowWebsites {...props} label="Websites" />
        <ShowIpAddresses {...props} label="IP Addresses" />
        <ShowOpCache {...props} label="OpCache" />
        <ShowDetails {...props} label="Details" />
      </TabbedShow>
    </Show>
  );
};
