import { isArray } from 'util';
import { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { ReadabilityField } from '..';

const useStyles = makeStyles<Theme, { expanded: boolean }>((theme: Theme) => ({
  wrapper: {
    maxHeight: ({ expanded }: { expanded: boolean }) => (expanded ? 999 : 100),
    overflow: 'hidden',
  },
  handle: {
    width: '100%',
    display: 'block',
    textAlign: 'center',
    background: theme.palette.primary.dark,
    boxShadow: '0 -10px 14px 5px #fff',
    position: 'relative',
    color: theme.palette.secondary.light,
    borderRadius: 10,
  },
}));

export default (props: any): JSX.Element => {
  const json = props.record[props.source];
  const jsonObj = JSON.parse(json);
  const [expanded, setExpanded] = useState(false);
  const classes = useStyles({ expanded });

  const beautifyError = (obj: string): string => {
    if (obj.toLowerCase().includes('failed')) {
      return 'Failed, check logs';
    }
    return obj;
  };

  const handleExpand = (e: MouseEvent) => {
    e.stopPropagation();
    setExpanded(!expanded);
  };

  const renderObjHeader = (obj: any): any => {
    if (isArray(obj)) {
      return obj.map(objk => renderObjHeader(objk));
    }
    if (typeof obj === 'object') {
      return Object.keys(obj).map((key: string) => (
        <ul key={key}>
          <li>
            <>
              <ReadabilityField content={key} />: {renderObjHeader(obj[key])}
            </>
          </li>
        </ul>
      ));
    }
    return <ReadabilityField key={obj} content={beautifyError(obj)} />;
  };

  if (jsonObj && typeof jsonObj === 'object') {
    return (
      <>
        <div className={classes.wrapper}>{renderObjHeader(jsonObj)}</div>
        <span className={classes.handle} onClick={(e: any) => handleExpand(e)}>
          {expanded ? '-' : '+'}
        </span>
      </>
    );
  }
  return <ReadabilityField content={json} />;
};
