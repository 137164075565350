import { Paper } from '@material-ui/core';
import { JsonToTable } from 'react-json-to-table';
import { Grid } from '../../ui/molecules';
import { DateCard, SinglePageHeader } from '../../ui/atoms';
import { Keywords } from './Keywords';
import { Notifications } from './Notifications';

export default ({ record }) => {
  return (
    <Paper
      style={{
        padding: 8,
        marginTop: 12,
      }}
    >
      <Grid>
        <SinglePageHeader
          label="Domain"
          title={record.domain}
          subtitle={record.display_name}
          lg={8}
        />
        <DateCard label="Last scraped:" date={record.last_scraped} />
      </Grid>
      <Notifications record={record} />
      <Keywords record={record} />

      <div>
        <h3>History</h3>
        <JsonToTable json={record.history} />
      </div>
    </Paper>
  );
};
