import { SimpleForm, TextInput } from 'react-admin';
import { Grid } from '../../ui/molecules';
import { Validation } from '../../ui/organisms';

export default props => {
  return (
    <SimpleForm {...props} redirect={'show'} w={12}>
      <Grid fullWidth>
        <Validation w={12}>
          <TextInput
            fullWidth
            source="url"
            label="Website"
            lg={3}
            md={3}
            xs={12}
          />
          <TextInput fullWidth source="target_market" lg={3} md={3} xs={12} />
          <TextInput fullWidth source="trust_flow" lg={3} md={3} xs={12} />
          <TextInput fullWidth source="citation_flow" lg={3} md={3} xs={12} />
          <TextInput fullWidth source="language" lg={3} md={3} xs={12} />
          <TextInput fullWidth source="price" lg={3} md={3} xs={12} />
          <TextInput fullWidth source="currency" lg={3} md={3} xs={12} />
          <TextInput fullWidth source="price_in_euro" lg={3} md={3} xs={12} />
          <TextInput fullWidth source="agency" lg={3} md={3} xs={12} />
          <TextInput fullWidth source="notes" lg={3} md={3} xs={12} />
        </Validation>
      </Grid>
    </SimpleForm>
  );
};
