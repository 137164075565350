import { ReferenceArrayInput } from 'react-admin';
import { AutocompleteArrayInput } from 'react-admin';
import TagQuickCreateButton from '../../../views/Websites/TagQuickCreateButton';
import { Spacer } from '../../atoms';

export default ({
  onChange,
  fluid,
}: {
  onChange?: Function;
  fluid?: boolean;
}) => {
  return (
    <>
      <div style={fluid ? { margin: '0 -16px' } : {}}>
        <Spacer height={10} />
        <ReferenceArrayInput
          source="tags"
          reference="dms/api/v1/tags"
          resource="dms/api/v1/tags"
          sort={{ field: 'name', order: 'ASC' }}
          perPage={999}
          label="Tags"
          allowEmpty
          filterToQuery={() => {}}
          onChange={onChange || undefined}
          style={{ padding: 0 }}
        >
          <AutocompleteArrayInput fullWidth />
        </ReferenceArrayInput>
        <Spacer height={10} />
        <TagQuickCreateButton />
      </div>
    </>
  );
};
