import { FC, memo } from 'react';
import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles(theme => ({
  header: {
    background: theme.palette.secondary.light,
    position: 'absolute',
    top: 0,
    left: 0,
    padding: '10px 25px',
    width: 'calc(100% - 50px)',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: 95,
    backgroundSize: 'cover',
    "& div[role='progressbar']": {
      display: 'none!important',
    },
  },
}));

const Header: FC = ({ children }) => {
  const classes = useStyle();
  return (
    <div className={classes.header}>
      <div style={{ padding: '9.6px' }}>{children}</div>
    </div>
  );
};

export default memo(Header);
