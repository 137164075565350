import { makeStyles } from '@material-ui/core';

export default makeStyles({
  button: {
    marginRight: 12,
    '&:last-child': {
      marginRight: 0,
    },
  },
  section: {
    margin: 10,
    padding: 10,
    background: '#f6f6f6',
  },
  config_step: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: 500,
    margin: '0 auto',
    padding: '15px 0',
    '& .divider': {
      flexGrow: 1,
      height: 1,
      borderBottom: '1px dotted #ccc',
      margin: '0 30px',
    },
  },
  snackbar: {
    marginBottom: 20,
    background: 'rgb(243, 81, 33)',
  },
  radio: {
    flexDirection: 'row',
    justifyContent: 'start',
  },
  select: {
    minWidth: 180,
  },
  nav_wrapper: {
    background: '#eaeaea',
    padding: 10,
    textAlign: 'center',
  },
  actionsCol: {
    padding: '0',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
});
