import { Component } from 'react';
import PropTypes from 'prop-types';
import { AdvancedTable } from '../../ui/molecules';

class Domains extends Component {
  render() {
    const { domains } = this.props.record;

    return (
      <div>
        <AdvancedTable items={domains} fields={[{ key: 'name' }]} zebra />
      </div>
    );
  }
}

Domains.propTypes = {
  record: PropTypes.object.isRequired,
};

export default Domains;
