import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { googleLogout } from '@react-oauth/google';
import { loadGapiInsideDOM } from 'gapi-script';
import { getJson } from '../../components/Helpers/API';

const AUTH_URL = process.env.REACT_APP_API_URL + '/ums/api/v1/';

const clearStorage = () => {
  [
    'expires_in',
    'exp',
    'auth_type',
    'id_token',
    'user_id',
    'username',
    'name',
    'roles',
    'email',
    'img',
  ].forEach(item => {
    localStorage.removeItem(item);
  });
};

async function updateLastLogin() {
  try {
    await getJson('/ums/api/v1/users/update-last-login');
  } catch (e) {
    console.error('Error updating last login:', e);
  }
}

async function getUserInfo() {
  try {
    const {
      data: { email, id, name, picture, full_roles },
    } = await getJson('/ums/api/v1/users/me');

    localStorage.setItem('user_id', id);
    localStorage.setItem('username', name);
    localStorage.setItem('name', name);
    localStorage.setItem(
      'roles',
      full_roles.map(role => role.name),
    );
    localStorage.setItem('email', email);
    localStorage.setItem('img', picture);
  } catch (e) {
    console.error('Error fetching user info:', e);
    logout();
  }
}

async function verifyGoogleToken() {
  const token = localStorage.getItem('id_token');
  try {
    const verifyUrl = `https://www.googleapis.com/oauth2/v3/tokeninfo?id_token=${token}`;
    const { hd, exp } = await fetch(verifyUrl).then(response => {
      if (!response.ok) {
        throw new Error('Token verification failed');
      }
      return response.json();
    });

    if (!['dilantimedia.com', 'alsen.se'].includes(hd)) {
      alert('Only company emails are allowed');
      logout();
      return;
    }

    const expiresIn = dayjs.unix(exp).diff(dayjs());
    localStorage.setItem('expires_in', expiresIn.toString());
    localStorage.setItem('exp', (exp * 1000).toString());
    localStorage.setItem('auth_type', 'google');
    localStorage.setItem('id_token', token);
    await updateLastLogin();
    await getUserInfo();
  } catch (e) {
    console.error('Error in Google token verification:', e);
    logout();
  }
}

const logout = () => {
  googleLogout();
  clearStorage();
  window.location.reload();
};

const isTokenExpired = () => {
  const exp = parseInt(localStorage.getItem('exp'), 10);
  return !exp || dayjs(exp).isBefore(dayjs().valueOf());
};

export function useAuthentication() {
  const [isGoogleApiInitialized, setGoogleApiInitialized] = useState(false);

  useEffect(() => {
    loadGapiInsideDOM().then(() => {
      window.gapi.load('auth2', () => {
        window.gapi.auth2
          .init({
            client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
          })
          .then(() => {
            setGoogleApiInitialized(true);
          });
      });
    });
  }, []);

  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    return localStorage.getItem('id_token') && !isTokenExpired();
  });

  const refreshToken = useCallback(async () => {
    const authType = localStorage.getItem('auth_type');
    try {
      if (authType === 'default') {
        const user_id = localStorage.getItem('user_id');
        const { access_token: newToken, expires_in } = await getJson(
          `/ums/api/v1/users/${user_id}/refresh-token`,
        );
        const exp = dayjs()
          .add(expires_in, 'seconds')
          .valueOf();
        localStorage.setItem('exp', exp.toString());
        localStorage.setItem('expires_in', (expires_in * 1000).toString());
        localStorage.setItem('id_token', newToken);
        await getUserInfo();
      } else if (authType === 'google' && isGoogleApiInitialized) {
        const googleUser = window.gapi.auth2
          .getAuthInstance()
          .currentUser.get();
        const authResponse = await googleUser.reloadAuthResponse();
        localStorage.setItem('exp', authResponse.expires_at.toString());
        localStorage.setItem('id_token', authResponse.id_token);
        console.log('Google token refreshed');
      }
    } catch (e) {
      console.error('Error refreshing token:', e);
      logout();
    }
  }, [isGoogleApiInitialized]);

  const scheduleTokenRefresh = useCallback(() => {
    const expiresIn = parseInt(localStorage.getItem('expires_in'), 10) || 0;
    setTimeout(refreshToken, expiresIn - 300_000); // Refresh 5 minutes before token expiration
  }, [refreshToken]);

  useEffect(() => {
    if (isAuthenticated && !isTokenExpired()) {
      scheduleTokenRefresh();
    }
  }, [isAuthenticated, scheduleTokenRefresh]);

  const googleLoginSuccess = useCallback(async response => {
    localStorage.setItem('id_token', response.credential);
    await verifyGoogleToken();
    setIsAuthenticated(true);
  }, []);

  const googleLoginFailure = useCallback(error => {
    console.error('Google Login failed:', error);
    logout();
  }, []);

  const login = useCallback(async ({ email, password }) => {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);
    try {
      const response = await fetch(`${AUTH_URL}auth/login`, {
        method: 'POST',
        body: formData,
      });
      const data = await response.json();
      if (data.access_token && data.expires_in) {
        const exp = dayjs()
          .add(data.expires_in, 'seconds')
          .valueOf();
        localStorage.setItem('exp', exp.toString());
        localStorage.setItem('expires_in', (data.expires_in * 1000).toString());
        localStorage.setItem('auth_type', 'default');
        localStorage.setItem('id_token', data.access_token);
        await getUserInfo();
        setIsAuthenticated(true);
      } else {
        throw new Error('Invalid token data');
      }
    } catch (e) {
      console.error('Login error:', e);
      alert('Login failed. Please try again.');
      clearStorage();
    }
  }, []);

  return {
    login,
    logout,
    isAuthenticated,
    googleLoginSuccess,
    googleLoginFailure,
  };
}
