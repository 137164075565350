import { Create } from 'react-admin';
import Form from './Form';

export default props => {
  return (
    <Create {...props}>
      <Form title={'Add affiliate link'} />
    </Create>
  );
};
