import { stringify } from 'querystring';
import { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { getJson } from '../../../../components/Helpers/API';
import { useFormattedCompareDate } from '../../../../hooks/useCompareDate';

const useRankSummary = ({ selectedMoneySite, comparison }) => {
  const [keywords, setKeywords] = useState([]);
  const [comparedKeywords, setComparedKeywords] = useState([]);
  const [filteredKeywords, setFilteredKeywords] = useState([]);
  const [error, setError] = useState();
  const [loaded, setLoaded] = useState(false);
  const compareDate = useFormattedCompareDate(comparison, 'YYYY-MM-DD');
  const fetchSites = useCallback(() => {
    getJson(
      `/dms/api/v1/websites/keywords?${stringify({
        filter: JSON.stringify({
          domain: selectedMoneySite.domain.unicode_name,
          date: dayjs()
            .subtract(1, 'day')
            .format('YYYY-MM-DD'),
          rank: { min: 1 },
        }),
        sortBy: 'rank',
        sortOrder: 'ASC',
      })}`,
    )
      .then(res => {
        if (Array.isArray(res.data)) {
          setKeywords(
            [...res.data].filter(k => k.rank).sort((a, b) => a.rank - b.rank),
          );
        } else {
          console.error('Something wrong with the earnings', res);
          setError(res);
        }
      })
      .catch(err => setError(err))
      .finally(() => setLoaded(true));
  }, [selectedMoneySite.domain.unicode_name]);

  const fetchCompared = useCallback(() => {
    if (keywords.length) {
      getJson(
        `/dms/api/v1/websites/keywords?${stringify({
          filter: JSON.stringify({
            domain: selectedMoneySite.domain.unicode_name,
            date: compareDate,
            keyword: { where_in: keywords.map(k => k.keyword) },
          }),
        })}`,
      )
        .then(res => {
          if (Array.isArray(res.data)) {
            setComparedKeywords(res.data);
          } else {
            console.error('Something wrong with the earnings', res);
            setError(res);
          }
        })
        .catch(err => setError(err))
        .finally(() => setLoaded(true));
    }
  }, [selectedMoneySite.domain.unicode_name, keywords, compareDate]);

  useEffect(() => {
    if (comparedKeywords) {
      const comparedObj = [...comparedKeywords].reduce((obj, key) => {
        obj[key.keyword] = key;
        return obj;
      }, {});
      setFilteredKeywords(
        keywords.map(k => ({
          ...k,
          trend: comparedObj[k.keyword]
            ? (k.rank || 0) - (comparedObj[k.keyword].rank || 0)
            : 0,
          prev: comparedObj[k.keyword],
        })),
      );
    }
  }, [comparedKeywords, keywords]);

  useEffect(() => {
    fetchSites();
  }, [fetchSites]);

  useEffect(() => {
    fetchCompared();
  }, [fetchCompared]);

  return {
    error,
    loaded,
    filteredKeywords,
    fetchSites,
  };
};

export default useRankSummary;
