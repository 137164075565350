import { lazy } from 'react';
import { Show } from '../../ui/organisms';
import { TabbedShow, Toolbar } from '../../ui/molecules';
const ShowSummary = lazy(() => import('./ShowSummary'));
const Whois = lazy(() => import('./Whois'));

export default props => (
  <Show actions={<Toolbar list delete />} {...props}>
    <TabbedShow lazy {...props}>
      <ShowSummary {...props} label="Overview" />
      <Whois {...props} label="WhoIs" />
    </TabbedShow>
  </Show>
);
