import { RefreshRounded, Timelapse } from '@material-ui/icons';
import { Paper } from '@material-ui/core';
import dayjs from 'dayjs';
import {
  ChipsCloud,
  DateCard,
  InfoCard,
  MonitorStatusField,
  SinglePageHeader,
} from '../../ui/atoms';
import { Grid } from '../../ui/molecules';
import Timeline from './Timeline';

export default props => {
  const { record } = props;

  return (
    <Paper
      style={{
        padding: 8,
        marginTop: 12,
      }}
    >
      <Grid>
        <SinglePageHeader
          raw
          label="Website"
          title={
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              {record.value}
              <MonitorStatusField record={record} source="status" />
            </div>
          }
          subtitle={record.gateway}
          lg={8}
        />
        <div style={{ display: 'flex', height: '100%' }}>
          <DateCard label="Last checked" date={record.last_check * 1000} />
          <DateCard
            label="Next check"
            date={dayjs().minute(record.next_check)}
          />
        </div>
        <ChipsCloud w={12}>
          <RefreshRounded content={record.retries} label="Retries:" />
          <Timelapse content={record.interval} label="Interval:" />
        </ChipsCloud>
        <InfoCard
          w={6}
          label="Alert Emails"
          content={record.alert_emails?.join('\n')}
        />
        <InfoCard
          w={6}
          label="Alert Phones"
          content={record.alert_phone_numbers}
        />
        <Timeline w={12} record={record} extended />
      </Grid>
    </Paper>
  );
};
