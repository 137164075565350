import { DateField, ReferenceField, TextField } from 'react-admin';
import { EditButton } from '../../ui/atoms';
import { AdvancedFilter, List } from '../../ui/molecules';
import filters from './filters.map';

export default props => (
  <List
    responsive={['displayName', 'website_id', 'created', 'updated']}
    sort={{ field: 'displayName', order: 'ASC' }}
    listkey={'id'}
    {...props}
    advancedFilters
    filters={<AdvancedFilter customfilters={filters} />}
    defaultColumns={['displayName', 'website_id', 'created', 'updated']}
  >
    <TextField source="displayName" />
    <ReferenceField
      source="website_id"
      reference="dms/api/v1/websites"
      link="show"
    >
      <TextField source="hostname" />
    </ReferenceField>
    <DateField source="updated" showTime />
    <DateField source="created" showTime />
    <EditButton />
  </List>
);
