import { useCallback } from 'react';
import { List, TextField } from '@material-ui/core';
import { useGetOne } from 'react-admin';
import { Notification } from '../../molecules';
import { getJson } from '../../../components/Helpers/API';
import { useNotification } from '../../../hooks';
import { NotificationProps } from './NotificationsButton';

type Props = {
  alerts: NotificationProps[];
  fetchAlerts: () => void;
};

const Alerts = ({ alerts, fetchAlerts }: Props) => {
  const notify = useNotification();
  const selectedMoneySite = localStorage.getItem('dashboardMoneySite');
  const { data: selectedMoneySiteData } = useGetOne(
    'dms/api/v1/websites',
    selectedMoneySite,
  );

  const handleToggleRead = useCallback(
    (e, notification: NotificationProps) => {
      e.stopPropagation();
      getJson(
        `/dms/api/v1/websites/${selectedMoneySite}/notifications/${notification.id}`,
        {
          method: 'PUT',
          body: JSON.stringify({
            resolved: notification.resolved === 'yes' ? 'no' : 'yes',
          }),
        },
      )
        .then(res => {
          if (res.data) {
            fetchAlerts();
          } else {
            notify({
              text: 'Error fetching alerts',
              variant: 'error',
            });
          }
        })
        .catch(e => notify({ text: e, variant: 'error' }));
    },
    [notify, fetchAlerts, selectedMoneySite],
  );
  if (!alerts.length) {
    return null;
  }

  return (
    <>
      <div style={{ padding: '16px' }}>
        <TextField
          size="small"
          fullWidth
          label="Selected moneysite"
          value={selectedMoneySiteData?.hostname ?? ''}
          variant="outlined"
          disabled
        />
      </div>
      <List>
        {alerts.map(notification => (
          <Notification
            key={notification.id}
            notification={notification}
            handleToggleRead={handleToggleRead}
          />
        ))}
      </List>
    </>
  );
};

export default Alerts;
