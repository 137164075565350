import { memo } from 'react';
import { makeStyles } from '@material-ui/core';

interface IProps {
  width?: string | number;
  height?: string | number;
  flex?: boolean;
}

const useStyles = makeStyles({
  root: {
    width: ({ width }: IProps) => width || 'auto',
    height: ({ height }: IProps) => height || 'auto',
  },
  flex: {
    display: 'flex',
    flexGrow: 1,
  },
});

const Spacer = ({ width, height, flex }: IProps): JSX.Element => {
  const classes = useStyles({ width, height, flex });
  return <div className={[classes.root, flex && classes.flex].join(' ')} />;
};

export default memo(Spacer);
