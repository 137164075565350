import dayjs, { Dayjs } from 'dayjs';

type ComparisonDate = 'yesterday' | 'monthToDate' | 'thirtyDays' | 'sevenDays';
export default function useCompareDate(comparisonDate: ComparisonDate): Dayjs {
  switch (comparisonDate) {
    case 'monthToDate':
      return dayjs()
        .subtract(2, 'days')
        .startOf('month');
    case 'yesterday':
      return dayjs().subtract(2, 'days');
    case 'thirtyDays':
      return dayjs().subtract(32, 'days');
    case 'sevenDays':
      return dayjs().subtract(7, 'days');
    default:
      return dayjs();
  }
}

export function useFormattedCompareDate(
  comparisonDate: ComparisonDate,
  format: string,
): string {
  const compareDate = useCompareDate(comparisonDate);
  return compareDate.format(format);
}

export function useCompareRange(comparisonDate: ComparisonDate): number {
  switch (comparisonDate) {
    case 'monthToDate':
      return dayjs()
        .subtract(2, 'days')
        .date();
    case 'yesterday':
      return 2;
    case 'thirtyDays':
      return 30;
    case 'sevenDays':
      return 7;
    default:
      return 0;
  }
}
