import { ReactNode, useRef, useCallback } from 'react';
import { IconButton } from '@material-ui/core';
import { FileCopyOutlined } from '@material-ui/icons';

export default function Copy(props: { children: ReactNode }) {
  const ref = useRef<any>(null);

  const copyTextToClipboard = useCallback(async () => {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(ref?.current?.innerText);
    } else {
      document.execCommand('copy', true, ref?.current?.innerText);
    }
  }, []);

  return (
    <span ref={ref}>
      {props.children}
      <IconButton
        onClick={copyTextToClipboard}
        size="small"
        style={{ marginLeft: 10 }}
        color="primary"
      >
        <FileCopyOutlined style={{ width: 15, height: 15, margin: 5 }} />
      </IconButton>
    </span>
  );
}
