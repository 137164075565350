import {
  createContext,
  createElement,
  FC,
  Suspense,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Stepper,
  Step as StepComponent,
  StepLabel,
  Button,
  Typography,
  Paper,
} from '@material-ui/core';
import PreloadCallback from '../../../ui/atoms/PreloaderCallback';
import steps from './steps';
import { Context, Steps } from './types';
import useStyle from './style';

export const WizardContext = createContext<Context>({
  steps,
  activeStep: steps[0],
  dynamicSteps: [steps[0]],
  setActiveStep: () => null,
  setDynamicSteps: () => null,
  handleNext: () => null,
  handleBack: () => null,
  handleReset: () => null,
  website: {},
  setWebsite: () => null,
});

const DeployWebsiteWizard: FC = () => {
  const [activeStep, setActiveStep] = useState(steps[0]);
  const [website, setWebsite] = useState<Partial<Context['website']>>({
    ssl: true,
  });
  const [dynamicSteps, setDynamicSteps] = useState([steps[0]]);
  const { Provider } = WizardContext;
  const style = useStyle();
  const handleNext = useCallback((nextStep: Steps) => {
    setActiveStep(steps[nextStep]);
    setDynamicSteps(s => [...s, steps[nextStep]]);
  }, []);

  const handleBack = useCallback(() => {
    setDynamicSteps(s => {
      const newSteps = [...s];
      newSteps.pop();
      return newSteps;
    });
  }, []);

  const handleReset = useCallback(() => {
    setActiveStep(steps[0]);
  }, []);

  useEffect(() => {
    setActiveStep(dynamicSteps[dynamicSteps.length - 1]);
  }, [dynamicSteps]);

  return (
    <Provider
      value={{
        activeStep,
        setActiveStep,
        steps,
        dynamicSteps,
        setDynamicSteps,
        website,
        setWebsite,
        handleNext,
        handleBack,
        handleReset,
      }}
    >
      <Suspense fallback={<PreloadCallback />}>
        <Paper elevation={1}>
          <Stepper activeStep={dynamicSteps.length - 1}>
            {dynamicSteps.map(step => {
              const stepProps = {};
              const labelProps = {};

              return (
                <StepComponent key={step.id} {...stepProps}>
                  <StepLabel {...labelProps}>{step.label}</StepLabel>
                </StepComponent>
              );
            })}
          </Stepper>
          <div>
            {steps.length && activeStep.id === steps.length ? (
              <div className={style.section} style={{ textAlign: 'center' }}>
                <Typography variant="body1" style={{ marginBottom: 20 }}>
                  All steps completed! You're new site it's created!
                  <span role="img" aria-label="all done">
                    {' '}
                    👍
                  </span>
                </Typography>
                <Button
                  color="primary"
                  onClick={handleReset}
                  className={style.button}
                >
                  Deploy new site
                </Button>
              </div>
            ) : (
              <div>
                {createElement(activeStep.component, {
                  handleNext,
                })}
              </div>
            )}
          </div>
        </Paper>
      </Suspense>
    </Provider>
  );
};

export default DeployWebsiteWizard;
