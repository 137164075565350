import { memo } from 'react';
import { Card, Typography } from '@material-ui/core';
import { WarningOutlined } from '@material-ui/icons';
import HelpTooltip from '../HelpTooltip';
import Toolbar from '../Toolbar';
import useBackLinksTrends from './useBackLinksTrends';
import useStyle from './style';
import TotalBacklinks from './sections/TotalBacklinks';
import LinkingDomains from './sections/LinkingDomains';
import AvgDr from './sections/AvgDr';
import LinkSources from './sections/LinkSources';
import Tlds from './sections/Tlds';

const BackLinksTrends = ({ selectedMoneySite, comparison }) => {
  const {
    totalTrend,
    drAvgTrend,
    domainsTrend,
    totalBacklinks,
    missingData,
    missingComparedData,
    domainRating,
    domains,
    sources,
    tlds,
    error,
  } = useBackLinksTrends({ selectedMoneySite, comparison });
  const style = useStyle({ missingData: missingData || missingComparedData });

  if (error) {
    return (
      <Card>
        <div style={{ padding: '24px', textAlign: 'center' }}>
          <Typography variant="h2">
            <span role="img" aria-label="no data">
              🤷‍♂️
            </span>
          </Typography>
          <Typography variant="h6">Something went wrong</Typography>
        </div>
      </Card>
    );
  }

  return (
    <div>
      <Toolbar>
        <Typography variant="h6">
          Backlinks Trends{' '}
          {missingData || missingComparedData ? (
            <WarningOutlined fontSize="small" />
          ) : (
            ''
          )}
        </Typography>
        <HelpTooltip
          source="Internal data"
          description="Insights on internal data generated by Kokos"
        />
      </Toolbar>
      <div
        className={style.wrapper}
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(5, 1fr)',
          gap: '24px',
          position: 'relative',
        }}
      >
        {(missingData || missingComparedData) && (
          <Card className={style.errorBox}>
            <Typography variant="body2">
              {missingData
                ? 'A temporary issue might cause incorrect data, please check again later.'
                : 'Data is missing for the time-range requested, the data trends might be incorrect'}
            </Typography>
          </Card>
        )}
        <Card>
          <TotalBacklinks totalBacklinks={totalBacklinks} trend={totalTrend} />
        </Card>
        <Card>
          <LinkingDomains domains={domains} trend={domainsTrend} />
        </Card>
        <Card>
          <AvgDr domainRating={domainRating} trend={drAvgTrend} />
        </Card>
        <Card>
          <LinkSources sources={sources} />
        </Card>
        <Card>
          <Tlds tlds={tlds} />
        </Card>
      </div>
    </div>
  );
};

export default memo(BackLinksTrends);
