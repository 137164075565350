import { TextField } from 'react-admin';
import { AdvancedFilter, List } from '../../ui/molecules';

const filters = [
  {
    source: 'registrant',
    type: 'search',
    alwaysOn: true,
  },
];

export default props => (
  <List
    responsive={['registrant_name', 'company', 'email', 'domains_count']}
    sort={{ field: 'registrant', order: 'ASC' }}
    listkey={'id'}
    {...props}
    advancedFilters
    filters={<AdvancedFilter customfilters={filters} />}
    defaultColumns={[
      'registrant_name',
      'company',
      'email',
      'domains_count',
      'created_at',
      'updated_at',
    ]}
  >
    <TextField source="registrant_name" />
    <TextField source="company" />
    <TextField source="email" />
    <TextField source="domains_count" />
    <TextField source="created_at" />
    <TextField source="updated_at" />
  </List>
);
