import { Edit } from 'react-admin';
import Toolbar from '../../ui/molecules/Toolbar';
import Form from './Form';

export default props => (
  <div>
    <Edit undoable={false} actions={<Toolbar show delete list />} {...props}>
      <Form title="Edit Proxy Server" />
    </Edit>
  </div>
);
