import { FC } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  Avatar,
  MenuItem,
  Chip,
} from '@material-ui/core';
import { Bookmark } from '@material-ui/icons';
import { constants } from '../../../constants';

interface Props {
  value: string;
  onChange: (params: any) => void;
}

const TargetMarketSelect: FC<Props> = ({ value, onChange }): JSX.Element => {
  return (
    <FormControl fullWidth variant="filled">
      <InputLabel id="select-multiple-target-market">Target markets</InputLabel>
      <Select
        labelId="select-multiple-target-market"
        fullWidth
        multiple
        value={value}
        onChange={onChange}
        renderValue={(selected: any) => (
          <div>
            {selected.map((value: any) => (
              <Chip
                key={value}
                label={constants.MARKETS.find(t => t.id === value)?.name}
                color="primary"
                avatar={
                  <Avatar>
                    <Bookmark />
                  </Avatar>
                }
              />
            ))}
          </div>
        )}
      >
        {constants.MARKETS.map(market => (
          <MenuItem key={market.id} value={market.id}>
            {market.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default TargetMarketSelect;
