import { lazy } from 'react';
import { Step } from './types';

const AskForFreshInstall = lazy(() => import('./Steps/AskForFreshInstall'));
const BuyDomain = lazy(() => import('./Steps/BuyDomain'));
const CheckDomainAvailable = lazy(() => import('./Steps/CheckDomainAvailable'));
const CheckEditDNS = lazy(() => import('./Steps/CheckEditDNS'));
const CheckWebsiteLive = lazy(() => import('./Steps/CheckWebsiteLive'));
const CheckWebsiteWorking = lazy(() => import('./Steps/CheckWebsiteWorking'));
const NotifyTech = lazy(() => import('./Steps/NotifyTech'));
const SaveWebsiteInKokos = lazy(() => import('./Steps/SaveWebsiteInKokos'));
const SelectDomainRegistrar = lazy(() =>
  import('./Steps/SelectDomainRegistrar'),
);
const SelectServerAndIp = lazy(() => import('./Steps/SelectServerAndIp'));
const SetupDNS = lazy(() => import('./Steps/SetupDNS'));
const WebsiteInformation = lazy(() => import('./Steps/WebsiteInformation'));

const steps: Step[] = [
  { id: 0, label: 'Enter Website Information', component: WebsiteInformation },
  {
    id: 1,
    label: 'Checking if is available',
    component: CheckDomainAvailable,
  },
  { id: 2, label: 'Select Domain Registrar', component: SelectDomainRegistrar },
  { id: 3, label: 'Buy domain', component: BuyDomain },
  { id: 4, label: 'Check if website is live', component: CheckWebsiteLive },
  { id: 5, label: 'Check if we can edit DNS', component: CheckEditDNS },
  {
    id: 6,
    label: 'Want to do a fresh install?',
    component: AskForFreshInstall,
  },
  { id: 7, label: 'Select Server and IP', component: SelectServerAndIp },
  { id: 8, label: 'Saving Website in Kokos', component: SaveWebsiteInKokos },
  { id: 9, label: 'Setting up DNS', component: SetupDNS },
  { id: 10, label: 'Notify Tech', component: NotifyTech },
  {
    id: 11,
    label: 'Checking if website is working',
    component: CheckWebsiteWorking,
  },
];

export default steps;
