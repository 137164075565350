import { FC } from 'react';

type Props = {
  label?: string;
  emoji?: string;
};

const Emoji: FC<Props> = ({ children, label = 'emoji', emoji }) => (
  <span aria-label={label} role="img">
    {emoji}
    {children}
  </span>
);

export default Emoji;
