import { FunctionField, NumberField, TextField } from 'react-admin';
import {
  DateField,
  EditButton,
  MonitorStatusField,
  UrlField,
} from '../../ui/atoms';
import { AdvancedFilter, List } from '../../ui/molecules';
import filters from './filters.map';
import Timeline from './Timeline';

export default props => {
  return (
    <>
      <List
        responsive={['url', 'type', 'status', 'last_check']}
        sort={{ field: 'updated_at', order: 'DESC' }}
        listkey={'id'}
        advancedFilters
        filters={<AdvancedFilter customfilters={filters} />}
        {...props}
        defaultColumns={['url', 'type', 'status', 'last_check']}
      >
        <UrlField source="url" />
        <TextField source="type" name="Type" />
        <TextField source="value" name="Value" />
        <NumberField source="retries" name="Retries" />
        <NumberField source="interval" name="Interval" />
        <MonitorStatusField source="status" name="Status" />
        <DateField source="last_check" name="Last check" />
        <FunctionField render={record => <Timeline record={record} />} />
        <EditButton />
      </List>
    </>
  );
};
