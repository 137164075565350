import { TextField } from 'react-admin';
import { FunctionField } from 'ra-ui-materialui';
import * as PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { AdvancedFilter, List } from '../../ui/molecules';

const filters = [
  {
    source: 'doc.hostname',
    type: 'search',
    alwaysOn: true,
    label: 'Hostname',
  },
];

function IconLinkField(props) {
  const { label, url } = props;
  return (
    <div>
      <span>{label}</span>
      <IconButton
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        aria-label="open"
        onClick={e => e.stopPropagation()}
      >
        <OpenInNewIcon />
      </IconButton>
    </div>
  );
}

IconLinkField.propTypes = { record: PropTypes.any };
const LighthouseMetrics = props => {
  return (
    <List
      filters={<AdvancedFilter customfilters={filters} />}
      responsive={[
        'doc.hostname',
        'doc.output.final_scores.performance',
        'doc.output.final_scores.accessibility',
        'doc.output.final_scores.bestPractices',
        'doc.output.final_scores.seo',
        'doc.output.final_scores.pwa',
      ]}
      sort={{ field: 'doc.hostname', order: 'ASC' }}
      listkey={'id'}
      {...props}
      advancedFilters
      defaultColumns={[
        'doc.hostname',
        'doc.output.final_scores.performance',
        'doc.output.final_scores.accessibility',
        'doc.output.final_scores.bestPractices',
        'doc.output.final_scores.seo',
        'doc.output.final_scores.pwa',
        'doc.output.lighthouseVersion',
      ]}
    >
      <FunctionField
        source="doc.hostname"
        label="Hostname"
        render={record => (
          <IconLinkField
            label={record.doc.hostname}
            url={record.doc.output.finalUrl}
          />
        )}
      />
      <FunctionField
        source="doc.output.final_scores.performance"
        label="Performance"
        render={record => record.doc.output.final_scores.performance}
      />
      <FunctionField
        source="doc.output.final_scores.accessibility"
        label="Accessibility"
        render={record => record.doc.output.final_scores.accessibility}
      />
      <FunctionField
        source="doc.output.final_scores.bestPractices"
        label="Best Practices"
        render={record => record.doc.output.final_scores.bestPractices}
      />
      <FunctionField
        source="doc.output.final_scores.seo"
        label="SEO"
        render={record => record.doc.output.final_scores.seo}
      />
      <FunctionField
        source="doc.output.final_scores.pwa"
        label="PWA"
        render={record => record.doc.output.final_scores.pwa}
      />
      <TextField source="doc.date" label="Date" />
      <TextField
        source="doc.output.lighthouseVersion"
        label="Lighthouse Version"
      />
    </List>
  );
};

export default LighthouseMetrics;
