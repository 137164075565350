import { FC, useCallback, useState } from 'react';
import shortid from 'shortid';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
} from '@material-ui/core';
import { WarningOutlined } from '@material-ui/icons';
import dayjs from 'dayjs';
import { useRefresh } from 'ra-core';
import Spacer from '../Spacer';
import { getJson } from '../../../components/Helpers/API';
import WarningItemTable from './WarningItemTable';
import { Warning } from './WarningsField';
import WarningItemDiff from './WarningItemDiff';

type Props = {
  singleWarning: Warning;
};

const SingleWarning: FC<Props> = ({ singleWarning }) => {
  const isTable = singleWarning.data;
  const isDiff = singleWarning.expected && singleWarning.found;
  const [expanded, setExpanded] = useState(false);
  const toggle = useCallback(() => setExpanded(e => !e), []);
  const refresh = useRefresh();

  const getClickableUrls = (text: string) => {
    if (text.indexOf('http')) {
      const words = text.split(' ');
      const linkableWords = words.map(word => {
        if (word.indexOf('http') === 0) {
          return `<a href="${word}" target="_blank">${word}</a>`;
        }
        return word;
      });
      return linkableWords.join(' ');
    }
    return text;
  };
  const handleIgnoreClick = async () => {
    if (typeof singleWarning.external_backlink_id !== 'undefined') {
      try {
        const response = await getJson(
          `/dms/api/v1/external-backlink-status/ignore-warning/${singleWarning.id}`,
          {
            method: 'PUT',
          },
        );
        //TODO: add a better alert
        alert(response);
        refresh(true);
      } catch (error) {
        alert(error);
      }
    }
  };

  return (
    <Accordion expanded={expanded} key={shortid.generate()}>
      <AccordionSummary
        aria-controls="panel1c-content"
        id="panel1c-header"
        onClick={toggle}
      >
        {singleWarning.important && (
          <>
            <WarningOutlined color="primary" />
            <Spacer width={20} />
          </>
        )}
        <div style={{ flexGrow: 1 }}>
          <b>{singleWarning.id || singleWarning.message}</b>

          <Spacer height={5} />
          {singleWarning?._id?.$oid && (
            <small>
              [
              {dayjs(
                parseInt(
                  singleWarning._id.$oid.toString().substring(0, 8),
                  16,
                ) * 1000,
              ).format('DD/MM/YYYY HH:mm:ss')}
              ] -{' '}
            </small>
          )}
          <small
            dangerouslySetInnerHTML={{
              __html: getClickableUrls(singleWarning.message || ''),
            }}
          />
        </div>
        <Button
          onClick={e => {
            e.stopPropagation();
            toggle();
          }}
          variant="outlined"
          size="small"
          color="primary"
        >
          {expanded ? 'Collapse' : 'More info'}
        </Button>
      </AccordionSummary>
      {(isTable || isDiff) && (
        <AccordionDetails>
          {isTable && <WarningItemTable data={isTable} />}
          {isDiff && <WarningItemDiff singleWarning={singleWarning} />}
        </AccordionDetails>
      )}
      <Divider />
      <AccordionActions>
        {/*<Button size="small" disabled>*/}
        {/*  RE-TEST*/}
        {/*</Button>*/}
        <Button size="small" color="primary" onClick={handleIgnoreClick}>
          IGNORE
        </Button>
      </AccordionActions>
    </Accordion>
  );
};

export default SingleWarning;
