import { memo } from 'react';
import { Typography } from '@material-ui/core';
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar';
import useStyle from '../style';
import { Trending } from '../../../../../ui/atoms';

const AvgDr = ({ domainRating, trend }) => {
  const style = useStyle();

  return (
    <div className={style.section}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h6">Average DR</Typography>
        <Trending
          trend={trend > 0 ? 'up' : trend === 0 ? 'flat' : 'down'}
          value={trend}
          variant="h6"
          iconSize="medium"
        />
      </div>
      <div className={style.main}>
        <div style={{ width: '75px' }}>
          <CircularProgressbarWithChildren
            value={domainRating}
            width={100}
            styles={buildStyles({
              pathColor: `hsl(${domainRating}, 100%, 35%)`,
            })}
          >
            <h1
              style={{
                color: `hsl(${domainRating}, 100%, 35%)`,
                margin: 0,
              }}
            >
              {domainRating}
            </h1>
          </CircularProgressbarWithChildren>
        </div>
      </div>
    </div>
  );
};

export default memo(AvgDr);
