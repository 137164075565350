import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  ListItem,
  ListItemAvatar,
  Badge,
  Avatar,
  ListItemText,
  Divider,
  useTheme,
  makeStyles,
  IconButton,
} from '@material-ui/core';
import {
  NotificationImportant,
  Warning,
  Info,
  ExpandLess,
  ExpandMore,
  OpenInNew,
} from '@material-ui/icons';
import dayjs from 'dayjs';
import { NotificationProps } from '../../organisms/NotificationsButton/NotificationsButton';
dayjs.extend(require('dayjs/plugin/relativeTime'));
dayjs.extend(require('dayjs/plugin/utc'));

type Props = {
  notification: NotificationProps;
  handleToggleRead: (e: MouseEvent, notification: NotificationProps) => void;
};

const useStyles = makeStyles({
  message: {
    display: 'block',
    overflow: 'hidden',
    '& > p': {
      margin: 0,
    },
  },
  badgeRoot: {
    flexGrow: 1,
    width: 270,
  },
  badgeBadge: {
    fontSize: 10,
    transform: 'scale(1) translate(0, -40%)',
  },
  readMore: {
    color: '#1212b3',
    fontSize: 12,
    fontWeight: 600,
    cursor: 'pointer',
    textDecoration: 'none',
  },
  readMoreIcon: {
    width: 14,
    height: 14,
    marginBottom: -3,
  },
});

const Notification = ({ notification, handleToggleRead }: Props) => {
  const history = useHistory();
  const [canExpand, setCanExpand] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [rawText, setRawText] = useState<string>('');
  const toggleExpand = useCallback(e => {
    e.stopPropagation();
    setExpanded(e => !e);
  }, []);
  const classes = useStyles();
  const theme = useTheme();
  const icons = {
    ALERT: <NotificationImportant />,
    WARNING: <Warning />,
    INFO: <Info />,
  };

  const handleNotificationAction = useCallback(
    e => {
      e.preventDefault();
      if (notification.link) {
        const isExternal = notification.link.indexOf('http') === 0;
        const path = notification.link.split('#')[1];
        if (isExternal) {
          window.open(notification.link);
          return;
        }
        if (path) {
          history.push(path);
          return;
        }
        window.open(notification.link);
        return;
      }
    },
    [notification, history],
  );

  useEffect(() => {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = notification.message;
    const text = tmp.textContent || tmp.innerText || '';
    setCanExpand(text.split(' ').length > 25);
    setRawText(
      text.split(' ').length > 25
        ? text
            .split(' ')
            .slice(0, 25)
            .join(' ') + '...'
        : text,
    );
  }, [notification.message]);

  return (
    <div>
      <ListItem
        // @ts-ignore
        button={false}
      >
        <div
          style={{
            minWidth: 20,
            height: 20,
            backgroundColor:
              !notification.read && notification.resolved !== 'yes'
                ? theme.palette.primary.light
                : 'transparent',
            border: `1px solid ${theme.palette.secondary.dark}`,
            borderRadius: '100%',
            marginRight: 10,
          }}
          onClick={e => handleToggleRead(e, notification)}
        />
        <ListItemAvatar>
          <Badge
            overlap="circle"
            variant="dot"
            color="primary"
            invisible={!!notification.read}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            <Avatar color="primary">{icons[notification.type]}</Avatar>
          </Badge>
        </ListItemAvatar>
        <Badge
          color="default"
          invisible={!notification?.created_at}
          classes={{ root: classes.badgeRoot, badge: classes.badgeBadge }}
          badgeContent={dayjs
            //@ts-ignore
            .utc(notification?.created_at)
            .local()
            .fromNow()}
        >
          <ListItemText
            primary={notification.title}
            secondary={
              <>
                <span
                  className={classes.message}
                  dangerouslySetInnerHTML={{
                    __html: expanded ? notification.message : rawText,
                  }}
                />
                {notification.link && (
                  <a
                    href={notification.link}
                    className={classes.readMore}
                    onClick={handleNotificationAction}
                  >
                    More details <OpenInNew className={classes.readMoreIcon} />
                  </a>
                )}
              </>
            }
          />
        </Badge>
        {canExpand && (
          <>
            {expanded ? (
              <IconButton onClick={toggleExpand}>
                <ExpandLess />
              </IconButton>
            ) : (
              <IconButton onClick={toggleExpand}>
                <ExpandMore />
              </IconButton>
            )}
          </>
        )}
      </ListItem>
      <Divider variant="inset" component="li" />
    </div>
  );
};

export default Notification;
