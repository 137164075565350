import { FunctionField, TextField } from 'react-admin';
import { IconButton } from '@material-ui/core';
import { Link } from '@material-ui/icons';
import {
  ReferenceArrayField,
  UrlField,
  BooleanField,
  DateField,
  EditButton,
  ReferenceField,
} from '../../ui/atoms';
import { List, AdvancedFilter } from '../../ui/molecules';
import filters from './filters.map';
import BulkImportModal from './BulkImportModal';

export default props => (
  <div>
    <List
      responsive={['name', 'type']}
      sort={{ field: 'updated_at', order: 'DESC' }}
      listkey={'url'}
      advancedFilters
      customActions={[<BulkImportModal />]}
      filters={<AdvancedFilter customfilters={filters} />}
      {...props}
      defaultColumns={[
        'name',
        'website_id',
        'deindexed',
        'nameservers',
        'expiration_date',
        'registrar_id',
        'registrant_id',
      ]}
    >
      <FunctionField
        source="name"
        render={row => (
          <UrlField
            source="name"
            name="name"
            prettyUrl={row.unicode_name}
            record={row}
          />
        )}
      />
      <FunctionField
        source="website_id"
        label="Website"
        render={row =>
          row.website_id && (
            <IconButton
              href={`#/dms/api/v1/websites/${row.website_id}/show`}
              onClick={e => e.stopPropagation()}
            >
              <Link />
            </IconButton>
          )
        }
      />
      <ReferenceArrayField
        label="Nameservers"
        reference="dms/api/v1/nameservers"
        source="nameservers"
        referenceSource="nameserver"
      />
      <ReferenceField
        source="registrar_id"
        reference="dms/api/v1/registrars"
        basePath="/dms/api/v1/registrars"
        label="Registrar"
        allowEmpty
        referenceSource="registrar"
        link={'show'}
      />
      <ReferenceField
        source="registrant_id"
        reference="dms/api/v1/registrants"
        basePath="/dms/api/v1/registrants"
        label="Registrant"
        allowEmpty
        referenceSource="registrant"
        link={'show'}
      />
      <BooleanField source="deindexed" />
      <BooleanField source="whois_protected" />
      <BooleanField source="auto_renew" />
      <BooleanField source="expiration_alerts" />
      <TextField source="pulled_from" />
      <DateField source="creation_date" />
      <DateField source="expiration_date" />
      <EditButton />
    </List>
  </div>
);
