import { Paper } from '@material-ui/core';
import { Grid, CreatedUpdatedBox } from '../../ui/molecules';
import { InfoCard, SinglePageHeader } from '../../ui/atoms';

export default ({ record }) => {
  return (
    <Paper
      style={{
        padding: 8,
        marginTop: 12,
      }}
    >
      <Grid>
        <SinglePageHeader
          label="Backlink"
          title={record.source_url}
          subtitle={`Target URL: ${record.url}`}
          lg={8}
        />
        <CreatedUpdatedBox record={record} lg={4} />
        <InfoCard lg={6} label="Anchor text" content={record.text.trim()} />
        <InfoCard lg={3} label="Source" content={record.source} />
        <InfoCard lg={3} label="Status" content={record.status} />
      </Grid>
    </Paper>
  );
};
