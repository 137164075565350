import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { getJson } from '../../../../components/Helpers/API';
import {
  useFormattedCompareDate,
  useCompareRange,
} from '../../../../hooks/useCompareDate';

const useConversionsMetric = ({ selectedMoneySite, comparison }) => {
  const [conversionsMetric, setConversionsMetric] = useState([]);
  const [avgs, setAvgs] = useState([]);
  const [avgsToCompare, setAvgsToCompare] = useState([]);
  const [error, setError] = useState();
  const [loaded, setLoaded] = useState(false);
  const [rawData, setRawData] = useState();
  const compareDate = useFormattedCompareDate(comparison, 'YYYYMMDD');
  const rangeInDays = useCompareRange(comparison);
  const fetchConversionsMetric = useCallback(() => {
    const startDate = dayjs()
      .subtract(66, 'day')
      .format('YYYY-MM-DD');
    const endDate = dayjs()
      .subtract(1, 'day')
      .format('YYYY-MM-DD');
    setRawData([]);
    setLoaded(false);
    setError(null);
    getJson(
      `/dms/api/v1/websites/${selectedMoneySite.id}/daily-conversion-metrics?start=${startDate}&end=${endDate}`,
    )
      .then(res => {
        setRawData(res.data);
      })
      .catch(err => setError(err))
      .finally(() => setLoaded(true));
  }, [selectedMoneySite.id]);

  useEffect(() => {
    const conversions = [];
    const avgs = [0, 0, 0];
    const avgsToCompare = [0, 0, 0];

    if (rawData) {
      Object.keys(rawData).forEach(day => {
        const data = rawData[day];
        if (Number(dayjs(day).format('YYYYMMDD')) >= Number(compareDate)) {
          conversions.push([
            dayjs(day).format('DD MMM'),
            Number(
              data.clicks && data.signups
                ? ((data.signups * 100) / data.clicks).toFixed(2)
                : 0.0,
            ),
            Number(
              data.clicks && data.FTD
                ? ((data.FTD * 100) / data.clicks).toFixed(2)
                : 0.0,
            ),
            Number(
              data.signups && data.FTD
                ? ((data.FTD * 100) / data.signups).toFixed(2)
                : 0.0,
            ),
          ]);
          avgs[0] += Number(
            data.clicks && data.signups
              ? (data.signups * 100) / data.clicks
              : 0.0,
          );
          avgs[1] += Number(
            data.clicks && data.FTD ? (data.FTD * 100) / data.clicks : 0.0,
          );
          avgs[2] += Number(
            data.signups && data.FTD ? (data.FTD * 100) / data.signups : 0.0,
          );
        }
        if (
          Number(dayjs(day).format('YYYYMMDD')) < Number(compareDate) &&
          Number(dayjs(day).format('YYYYMMDD')) >=
            Number(
              dayjs(compareDate)
                .subtract(rangeInDays, 'days')
                .format('YYYYMMDD'),
            )
        ) {
          avgsToCompare[0] += Number(
            data.clicks && data.signups
              ? (data.signups * 100) / data.clicks
              : 0.0,
          );
          avgsToCompare[1] += Number(
            data.clicks && data.FTD ? (data.FTD * 100) / data.clicks : 0.0,
          );
          avgsToCompare[2] += Number(
            data.signups && data.FTD ? (data.FTD * 100) / data.signups : 0.0,
          );
        }
      });
    }
    setConversionsMetric(conversions);
    setAvgsToCompare(
      avgsToCompare.map(avg => (avg ? avg / avgsToCompare.length : 0)),
    );
    setAvgs(avgs.map(avg => (avg ? avg / avgs.length : 0)));
  }, [rawData, compareDate, rangeInDays]);

  useEffect(() => {
    fetchConversionsMetric();
  }, [fetchConversionsMetric]);

  return {
    error,
    loaded,
    avgs,
    avgsToCompare,
    conversionsMetric,
  };
};

export default useConversionsMetric;
