import _ from 'lodash';

export function arrayRemove(arr, value) {
  return arr.filter(function(ele) {
    return ele !== value;
  });
}

export function difference(object, base) {
  function changes(object, base) {
    return _.transform(object, function(result, value, key) {
      if (!_.isEqual(value, base[key])) {
        result[key] =
          _.isObject(value) && _.isObject(base[key])
            ? changes(value, base[key])
            : value;
      }
    });
  }
  return changes(object, base);
}

export default function(o, s) {
  let string = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  string = s.replace(/^\./, ''); // strip a leading dot
  const a = string.split('.');
  for (let i = 0, n = a.length; i < n; ++i) {
    const k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
}

export const isJson = json => {
  try {
    JSON.stringify(json);
  } catch (e) {
    return false;
  }
  return true;
};
