import { makeStyles, Typography, Theme, Card } from '@material-ui/core';
const useStyles = makeStyles<Theme, { raw: boolean; link?: boolean }>(
  (theme: Theme) => ({
    wrapper: {
      padding: 10,
      height: '100%',
      boxSizing: 'border-box',
      borderRadius: theme.spacing(0.5),
    },
    label: {
      color: theme.palette.primary.dark,
      borderRadius: theme.spacing(0.3),
      background: theme.palette.primary.light,
      padding: '5px 10px',
    },
    subtitle: {
      color: theme.palette.primary.dark,
    },
    title: {
      paddingTop: 5,
      textTransform: ({ raw }: { raw: boolean }) =>
        raw ? 'none' : 'uppercase',
      cursor: ({ link }: { link?: boolean }) => (link ? 'pointer' : 'default'),
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }),
);

export default ({
  label,
  link,
  title,
  subtitle,
  raw = true,
}: {
  label?: string;
  title: string;
  link?: boolean;
  subtitle?: string;
  raw?: boolean;
}) => {
  const classes = useStyles({ raw, link });
  return (
    <Card className={classes.wrapper}>
      {label && (
        <Typography variant="caption" className={classes.label}>
          {label}
        </Typography>
      )}
      <div onClick={link ? () => window.open(title) : undefined}>
        <Typography variant="h6" className={classes.title}>
          {title}
        </Typography>
      </div>
      {subtitle && (
        <Typography variant="subtitle2" className={classes.subtitle}>
          {subtitle}
        </Typography>
      )}
    </Card>
  );
};
