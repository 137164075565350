import { TextField, NumberField, DateField } from 'react-admin';
import { EditButton } from '../../ui/atoms';
import { AdvancedFilter, List } from '../../ui/molecules';

const filters = [
  {
    source: 'name',
    type: 'search',
    alwaysOn: true,
  },
];

export default props => {
  return (
    <>
      <List
        responsive={['name', 'repo']}
        sort={{ field: 'created_at', order: 'ASC' }}
        listkey={'name'}
        {...props}
        advancedFilters
        filters={<AdvancedFilter customfilters={filters} />}
        defaultColumns={[
          'name',
          'repo',
          'url',
          'auto_deploy',
          'has_update',
          'created_at',
        ]}
      >
        <NumberField source="id" />
        <TextField source="name" />
        <TextField source="repo" />
        <TextField source="url" />
        <TextField source="auto_deploy" />
        <TextField source="has_update" />
        <DateField source="updated_at" showTime />
        <DateField source="created_at" showTime />
        <EditButton />
      </List>
    </>
  );
};
