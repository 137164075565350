import { put, takeEvery, select } from 'redux-saga/effects';
import { getAdminUiData } from '../store/selectors/getAdminUiData';

export default function* sidebarSagas() {
  yield takeEvery('RA/TOGGLE_SIDEBAR', setSidebarVisibility);
}

export function* setSidebarVisibility() {
  const visible = yield select(state => getAdminUiData(state));
  if (window.localStorage.getItem('sidebar')) {
    yield put({
      type: 'RA/SET_SIDEBAR_VISIBILITY',
      payload: window.localStorage.getItem('sidebar') === '1',
    });
    window.localStorage.setItem(
      'sidebar',
      window.localStorage.getItem('sidebar') === '1' ? 0 : 1,
    );
  } else {
    yield put({ type: 'RA/SET_SIDEBAR_VISIBILITY', payload: visible });
    window.localStorage.setItem('sidebar', visible ? '1' : 0);
  }
}
