import { refreshView, CRUD_CREATE_SUCCESS } from 'react-admin';
import { takeEvery, put } from 'redux-saga/effects';

export default function* taskSagas() {
  yield takeEvery([CRUD_CREATE_SUCCESS], clearForm);
}

export function* clearForm() {
  yield put(refreshView());
}
