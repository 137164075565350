import { SelectInput, SimpleForm, TextInput } from 'react-admin';
import { FormToolbar, Grid } from '../../ui/molecules';

const Form = props => {
  return (
    <SimpleForm {...props} redirect={'show'} w={12} toolbar={<FormToolbar />}>
      <Grid>
        <Grid lg={12}>
          <SelectInput
            source="environment"
            choices={[
              { id: 'production', name: 'Production' },
              { id: 'dms', name: 'DMS' },
              { id: 'users', name: 'Users' },
              { id: 'analytics', name: 'Analytics' },
              { id: 'local', name: 'Staging' },
              { id: 'test', name: 'Test' },
            ]}
            defaultValue="production"
            fullWidth
            lg={4}
          />
          <TextInput source="variable" fullWidth lg={4} />
          <TextInput source="value" fullWidth lg={4} />
        </Grid>
      </Grid>
    </SimpleForm>
  );
};

export default Form;
