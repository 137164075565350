import { ShowButton } from 'react-admin';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  wrapper: {
    textAlign: 'right',

    '& .MuiButton-label': {
      [theme.breakpoints.down('md')]: {
        '& span': {
          display: 'none',
        },
      },
    },
  },
}));

export default (props: any): JSX.Element => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <ShowButton {...props} />
    </div>
  );
};
