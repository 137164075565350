import { useCallback, useEffect, useState } from 'react';
import { Card, Typography } from '@material-ui/core';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import { Logo } from '../../ui/atoms/Logo';
import { Wrapper } from './Wrapper';
import { LoginForm } from './LoginForm';

type LoginPageProps = {
  login: (params: { email: string; password: string }) => void;
  googleLoginError: () => void;
  googleLoginSuccess: (response: CredentialResponse) => void;
};
const LoginPage = ({
  login,
  googleLoginError,
  googleLoginSuccess,
}: LoginPageProps) => {
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState(0);
  const tokenExpiresIn = localStorage.getItem('expires_in');

  const handleLogin = useCallback(
    async (email: string, password: string) => {
      setLoading(true);
      await login({ email, password });
      setLoading(false);
    },
    [login],
  );

  useEffect(() => {
    let intervalId: any;
    if (tokenExpiresIn) {
      if (Number(tokenExpiresIn) < 1_000_000) {
        setKey(k => k + 1);
        return;
      }
      intervalId = setInterval(() => {
        setKey(k => k + 1);
      }, Number(tokenExpiresIn) - 1_000_000);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [tokenExpiresIn]);

  return (
    <Wrapper>
      <Logo />
      <Card>
        <div
          style={{
            height: '300px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h5" color="textPrimary">
            Sign In
          </Typography>
          <Typography
            variant="caption"
            color="textPrimary"
            style={{ marginBottom: 20 }}
          >
            Please enter your details
          </Typography>
          <LoginForm handleLogin={handleLogin} loading={loading} />
          <GoogleLogin
            key={key}
            width="300px"
            onSuccess={googleLoginSuccess}
            onError={googleLoginError}
            useOneTap
            auto_select
            hosted_domain="dilantimedia.com"
          />
        </div>
      </Card>
    </Wrapper>
  );
};

export default LoginPage;
