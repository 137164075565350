import { lazy } from 'react';
import { TabbedIndex } from '../../ui/molecules';

const List = lazy(() => import('./List'));
const Diversity = lazy(() => import('./Diversity/Diversity'));

export default props => (
  <TabbedIndex>
    <List name="IP Addresses" {...props} />
    <Diversity name="Diversity" {...props} />
  </TabbedIndex>
);
