import { ReferenceField, TextField } from 'react-admin';
import { Paper } from '@material-ui/core';
import { JsonToTable } from 'react-json-to-table';
import { CreatedUpdatedBox, Grid } from '../../ui/molecules';
import { BooleanField, SinglePageHeader } from '../../ui/atoms';
import AgGrid from '../../ui/organisms/AgGrid';

export default ({ record }) => {
  return (
    <Paper
      style={{
        padding: 8,
        marginTop: 12,
      }}
    >
      <Grid>
        <SinglePageHeader
          label="Server"
          title={
            <ReferenceField
              label="Server"
              reference="dms/api/v1/servers"
              source="server_id"
              link={'show'}
            >
              <TextField source="name" />
            </ReferenceField>
          }
          subtitle={`Provider: ${record.provider}`}
          lg={4}
        />
        <SinglePageHeader
          label="Available"
          title={<BooleanField source="available" record={record} />}
          lg={4}
        />
        <CreatedUpdatedBox record={record} lg={4} />
        <div style={{ margin: '8px' }}>
          <JsonToTable json={record.info} />
        </div>
        <AgGrid
          pagination
          rows={record.websites}
          columns={[
            { field: 'hostname', headerName: 'Website' },
            { field: 'type', headerName: 'Type' },
          ]}
        />
      </Grid>
    </Paper>
  );
};
