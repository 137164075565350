import { OpenInNew } from '@material-ui/icons';
import { Button, Paper } from '@material-ui/core';
import { CreatedUpdatedBox, Grid } from '../../ui/molecules';
import {
  InfoCard,
  SinglePageHeader,
  TextField,
  TrendField,
} from '../../ui/atoms';

export default ({ record }) => {
  return (
    <Paper
      style={{
        padding: 8,
        marginTop: 12,
      }}
    >
      <Grid>
        <SinglePageHeader
          label="Backlink"
          title={
            <span>
              {record.backlink}
              <Button
                variant="text"
                size="small"
                href={record.backlink}
                target="_blank"
              >
                <OpenInNew />
              </Button>
            </span>
          }
          subtitle={`Anchor: ${record.anchor}`}
          lg={8}
        />
        <CreatedUpdatedBox record={record} lg={4} />
        <InfoCard w={8} label="URL" content={record.url} />
        <TrendField
          w={2}
          record={record}
          source="current_referring_domains"
          compare="referring_domains"
          historicData="backlink_stats.live_refdomains"
          label="Referring Domain"
        />
        <TrendField
          w={2}
          record={record}
          source="current_domain_rating"
          compare="domain_rating"
          historicData="domain_rating"
          label="Domain Rating"
        />
        <TextField
          label="Runcloud Name"
          content={record.runcloud_name}
          lg={3}
        />
        <TextField label="Duration (Days)" content={record.duration} lg={3} />
        <TextField
          label="Rel Attribute"
          content={record.rel_attribute}
          lg={3}
        />
        <TextField label="Price" content={record.price} lg={3} />
        <TextField label="Currency" content={record.currency} lg={3} />
        <TextField label="Start Date" content={record.start_date} lg={3} />
        <TextField label="End Date" content={record.end_date} lg={3} />
        <TextField label="IP" content={record.ip_info.ip} />
        <TextField label="IP Country" content={record.ip_info.country} />
        <TextField label="IP Host" content={record.ip_info.org} />
      </Grid>
    </Paper>
  );
};
