import Chart from 'react-google-charts';
import dayjs from 'dayjs';
import humanizeDuration from 'humanize-duration';
import { capitalize } from '../../components/Helpers/utils';

const options = {
  chartArea: { width: '100%', height: '100%' },
  timeline: {
    showRowLabels: false,
    showBarLabels: false,
  },
  colors: ['#30a82d', '#ce3730'],
  legend: 'none',
  axisFontSize: 0,
  fontSize: 0,
  tooltip: { isHtml: true },
  hAxis: {
    format: 'dd/MM',
  },
};

const Timeline = ({ record, extended }) => {
  if (!record?.up_down_changes?.length) {
    return null;
  }

  const data = [
    [
      { type: 'string', id: 'Timeline' },
      { type: 'string', id: 'state' },
      { type: 'string', role: 'tooltip', p: { html: true } },
      { type: 'date', id: 'start' },
      { type: 'date', id: 'end' },
    ],
    ...record.up_down_changes.map((change, i) => [
      null,
      `Status: ${change.status}`,
      generateTooltip(change, record?.up_down_changes?.[i + 1], extended),
      new Date(change.time * 1000),
      record?.up_down_changes?.[i + 1]?.time
        ? new Date(record?.up_down_changes?.[i + 1]?.time * 1000)
        : new Date(),
    ]),
  ];

  return (
    <div
      style={{
        height: extended ? 'auto' : '65px',
        paddingTop: extended ? '24px' : '0',
        paddingBottom: extended ? '24px' : '0',
      }}
    >
      <Chart
        chartType="Timeline"
        width={extended ? '100%' : '300px'}
        height="100px"
        options={{
          ...options,
          colors:
            record?.up_down_changes?.[0]?.status === 'down'
              ? [...options.colors].reverse()
              : options.colors,
        }}
        data={data}
      />
    </div>
  );
};

export default Timeline;

function generateTooltip(change, nextChange, extended) {
  const changeTime = dayjs(change.time * 1000);
  const nextChangeTime = nextChange ? dayjs(nextChange.time * 1000) : dayjs();
  const diff = nextChangeTime.diff(changeTime);

  return `
    <div style="padding: ${
      extended ? '0' : '10px'
    } 20px 10px; min-width: 180px; line-height: 15px">
      <p style="margin: 0"><strong>${capitalize(
        change.status,
      )}</strong>  (${humanizeDuration(diff)})</p>
      <hr style="margin: 0"/>
      <strong>From:</strong> ${changeTime.format('DD MMM HH:mm')}
      <strong>To:</strong> ${nextChangeTime.format('DD MMM HH:mm')}
    </div>
  `;
}
