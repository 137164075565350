export default [
  {
    source: 'word',
    type: 'search',
    alwaysOn: true,
  },
  {
    source: 'website_id',
    type: 'referenceAutocomplete',
    reference: 'dms/api/v1/websites',
    resource: 'dms/api/v1/websites',
    sort: { field: 'hostname', order: 'ASC' },
    filter: { type: 'MONEY' },
    label: 'Websites',
    optionText: 'hostname',
    alwaysOn: true,
  },
];
