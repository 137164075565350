import { makeStyles } from '@material-ui/core';
import { Filter } from 'react-admin';

const useStyles = makeStyles(() => ({
  form: {
    margin: 0,
    padding: '5px 14px ',
  },
}));

export default ({ children, ...props }: any) => {
  const classes = useStyles();
  return (
    <Filter classes={classes} {...props}>
      {children}
    </Filter>
  );
};
