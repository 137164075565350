import { useState } from 'react';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';

interface Props {
  initialSort?: string;
  initialOrder?: string;
}

export function useSort({ initialSort, initialOrder }: Props) {
  const [sort, setSort] = useState<string>(initialSort || 'name');
  const [order, setOrder] = useState<string>(initialOrder || 'asc');

  const handleSort = (sort: string) => {
    setSort(sort);
    setOrder(order === 'asc' ? 'desc' : 'asc');
  };

  const arrows = {
    asc: <ArrowDropUp />,
    desc: <ArrowDropDown />,
  };

  const arrow = arrows[order];

  return { order, sort, arrow, handleSort };
}
