import { Button, makeStyles, Tooltip } from '@material-ui/core';
import { Gavel } from '@material-ui/icons';
import { UrlEnsure } from '../../../components/Helpers/utils';

const useStyles = makeStyles((theme: any) => ({
  auctionField: {
    color: theme.palette.primary.dark,
    textDecoration: 'none',
    textTransform: 'initial',
    letterSpacing: '0.5px',
    border: '1px solid',
    padding: '3px 5px',
    whiteSpace: 'nowrap',
    maxWidth: 400,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'inline-block',
    verticalAlign: 'middle',
    '&:hover': {
      color: theme.palette.secondary.light,
      background: theme.palette.primary.dark,
    },
  },
  auction: {
    color: theme.palette.secondary.dark,
    textDecoration: 'none',
    letterSpacing: '0.5px',
    border: '1px solid',
    padding: '3px 8px',
    marginLeft: 10,
    lineHeight: '24px',
    borderRadius: '4px',
    cursor: 'not-allowed',
    display: 'inline-block',
    verticalAlign: 'middle',
    '&:hover': {
      color: theme.palette.secondary.light,
      background: theme.palette.secondary.dark,
    },

    '&.active': {
      cursor: 'pointer',
      color: theme.palette.primary.dark,
      '&:hover': {
        color: theme.palette.secondary.light,
        background: theme.palette.primary.dark,
      },
    },
  },
}));

export default (props: any) => {
  const url = props.record[props.source];
  const classes = useStyles();

  if (url) {
    let form_url = url.replace('http://', '').replace('https://', '');
    form_url =
      form_url.length >= 30 ? form_url.substr(0, 27) + '...' : form_url;

    const auction_url =
      props.record[props.refurl] && props.auction + props.record[props.refurl];

    return (
      <Tooltip title={url}>
        <>
          <Button
            href={UrlEnsure(url)}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.auctionField}
            onClick={e => e.stopPropagation()}
          >
            {form_url}
          </Button>
          <a
            href={auction_url}
            target="_blank"
            rel="noopener noreferrer"
            className={`${classes.auction}  ${auction_url && 'active'}`}
          >
            <Gavel style={{ fontSize: 15 }} />
          </a>
        </>
      </Tooltip>
    );
  }

  return null;
};
