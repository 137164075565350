import { TextField } from 'react-admin';
import { EditButton } from '../../ui/atoms';
import { AdvancedFilter, List } from '../../ui/molecules';

const filters = [
  {
    source: 'method',
    type: 'search',
    alwaysOn: true,
  },
  {
    source: 'route',
    type: 'search',
    alwaysOn: true,
  },
];

export default props => (
  <List
    responsive={['method', 'route']}
    sort={{ field: 'route', order: 'ASC' }}
    listkey={'route'}
    {...props}
    advancedFilters
    filters={<AdvancedFilter customfilters={filters} />}
    defaultColumns={['method', 'route']}
  >
    <TextField source="method" />
    <TextField source="route" />
    <EditButton />
  </List>
);
