import { Paper } from '@material-ui/core';
import { CreatedUpdatedBox, Grid } from '../../ui/molecules';
import { SinglePageHeader } from '../../ui/atoms';

export default ({ record }) => {
  return (
    <Paper
      style={{
        padding: 8,
        marginTop: 12,
      }}
    >
      <Grid>
        <SinglePageHeader
          label="Disavowed Domain"
          title={record.value}
          subtitle={record.type}
          lg={8}
        />
        <CreatedUpdatedBox record={record} lg={4} />
      </Grid>
    </Paper>
  );
};
