import { TextField } from 'react-admin';
import React from 'react';
import { AdvancedFilter, List } from '../../ui/molecules';
import { DateField, EditButton, UrlField } from '../../ui/atoms';
import filters from './filters.map';

export default props => (
  <List
    responsive={['domain', 'display_name', 'last_scraped']}
    sort={{ field: 'domain', order: 'ASC' }}
    listkey={'id'}
    {...props}
    defaultColumns={['domain', 'display_name', 'last_scraped']}
    filters={<AdvancedFilter customfilters={filters} />}
    advancedFilters
    bulkActionButtons={[]}
  >
    <UrlField source="domain" />
    <TextField source="display_name" />
    <DateField source="last_scraped" name="Last scrapped" />
    <TextField source="google_business_name" />
    <TextField source="google_business_name_list" />
    <EditButton />
  </List>
);
