import { ReferenceField, TextField } from 'react-admin';
import { AdvancedFilter, List } from '../../ui/molecules';

const filters = [
  {
    source: 'host',
    type: 'search',
    alwaysOn: true,
  },
  {
    source: 'ip',
    type: 'search',
  },
  {
    source: 'type',
    type: 'search',
  },
  {
    source: 'class',
    type: 'search',
  },
  {
    source: 'target',
    type: 'search',
  },
  {
    source: 'mname',
    type: 'search',
  },
  {
    source: 'rname',
    type: 'search',
  },
  {
    source: 'txt',
    type: 'search',
  },
];

export default props => (
  <List
    responsive={['host', 'type']}
    sort={{ field: 'host', order: 'ASC' }}
    listkey={'id'}
    {...props}
    advancedFilters
    filters={<AdvancedFilter customfilters={filters} />}
    defaultColumns={['host', 'type', 'domain_id']}
  >
    <TextField source="host" />
    <TextField source="type" />
    <TextField source="ip" />
    <TextField source="ttl" />
    <TextField source="class" />
    <TextField source="target" />
    <TextField source="mname" />
    <TextField source="rname" />
    <TextField source="serial" />
    <TextField source="refresh" />
    <TextField source="retry" />
    <TextField source="expire" />
    <TextField source="minimum_ttl" />
    <TextField source="pri" />
    <TextField source="txt" />
    <ReferenceField
      label="Domain"
      reference="dms/api/v1/domains"
      source="domain_id"
      link={'show'}
    >
      <TextField source="name" />
    </ReferenceField>
  </List>
);
