import { Grid } from '../../ui/molecules';
import { Spacer, TextField } from '../../ui/atoms';

export default ({ record }) => (
  <Grid>
    <Spacer height={4} w={12} />

    <TextField label="Environment" content={record.environment} lg={4} />
    <TextField label="Variable" content={record.variable} lg={4} />
    <TextField label="Value" content={record.value} lg={4} />
  </Grid>
);
