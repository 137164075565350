import { makeStyles, Avatar } from '@material-ui/core';
import { Close, Check } from '@material-ui/icons';
import { Label } from '..';

const useStyles = makeStyles((theme: any) => ({
  istrue: {
    background: theme.palette.primary.main,
    width: '30px',
    height: '30px',
  },
  isfalse: {
    background: theme.palette.grey[500],
    width: '30px',
    height: '30px',
  },
}));

const BooleanField = ({ source, label, ...props }: any) => {
  const classes = useStyles();
  return (
    <div>
      {label && <Label label={label} />}
      {props.record[source] ? (
        <Avatar className={classes.istrue}>
          <Check />
        </Avatar>
      ) : (
        <Avatar className={classes.isfalse}>
          <Close />
        </Avatar>
      )}
    </div>
  );
};

export default BooleanField;
