import { FunctionField, TextField } from 'react-admin';
import { BooleanField, EditButton, ReferenceField } from '../../ui/atoms';
import { AdvancedFilter, List } from '../../ui/molecules';

const filters = [
  {
    source: 'name',
    type: 'search',
    alwaysOn: true,
  },
];

export default props => (
  <List
    responsive={['name']}
    sort={{ field: 'name', order: 'ASC' }}
    listkey={'name'}
    {...props}
    advancedFilters
    filters={<AdvancedFilter customfilters={filters} />}
    defaultColumns={['website_id', 'frequency', 'localised', 'language']}
  >
    <ReferenceField
      source="website_id"
      reference="dms/api/v1/websites"
      basePath="/dms/api/v1/websites"
      label="Website"
      allowEmpty
      referenceSource="hostname"
      link={'show'}
    />
    <FunctionField
      source="frequency"
      render={row => (
        <span>
          {row.frequency_every} {row.frequency_unit}
        </span>
      )}
    />
    <BooleanField source="localised" />
    <TextField source="language" />
    <EditButton />
  </List>
);
