import { alpha, makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  wrapper: {
    border: ({ missingData }) =>
      missingData ? '2px solid ' + theme.palette.primary.main : 'none',
  },
  errorBox: {
    position: 'absolute',
    bottom: 0,
    background: theme.palette.primary.main,
    color: '#fff',
    width: 550,
    left: '50%',
    transform: 'translate(-50%, 0%)',
    borderRadius: '4px 4px 0 0',
    textAlign: 'center',
    zIndex: 99,
  },
  section: {
    flex: 1,
    height: 120,
    borderRight: `1px solid ${alpha(theme.palette.secondary.dark, 0.3)}`,
    flexGrow: 1,
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    '&:last-child': {
      borderRight: 'none',
    },
    '& h6': {
      fontSize: 14,
    },
  },
  main: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
  },
  chartWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  chartLegendWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));
