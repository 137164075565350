import { TextInput, SimpleForm, SelectInput } from 'react-admin';
import { Grid, FormToolbar, ReferenceTagArrayInput } from '../../ui/molecules';
import { Validation } from '../../ui/organisms';
import { constants } from '../../constants';

export default props => (
  <SimpleForm {...props} redirect={'show'} w={12} toolbar={<FormToolbar />}>
    <Grid fullWidth>
      <Validation w={12}>
        <TextInput fullWidth source="name" />
        <TextInput fullWidth source="main_ip" />
        <TextInput fullWidth source="location" />
        <TextInput fullWidth source="provider" />
        <TextInput fullWidth source="operating_system" />
        <TextInput fullWidth source="web_server" />
        <SelectInput
          fullWidth={true}
          source="type"
          required
          choices={constants.SERVER_TYPES}
        />
        <ReferenceTagArrayInput lg={12} />
      </Validation>
    </Grid>
  </SimpleForm>
);
