import { FunctionField, TextField } from 'react-admin';
import humanizeDuration from 'humanize-duration';
import { AdvancedFilter, List } from '../../ui/molecules';
import { localizeUtcDate } from '../../components/Helpers/utils';

const filters = [
  {
    source: 'command',
    type: 'search',
    alwaysOn: true,
  },
];

export default props => (
  <List
    responsive={['command', 'start_time', 'end_time']}
    listkey={'command'}
    {...props}
    advancedFilters
    filters={<AdvancedFilter customfilters={filters} />}
    defaultColumns={[
      'command',
      'start_time',
      'end_time',
      'total_time',
      'remaining_jobs',
    ]}
    sort={{ field: 'start_time', order: 'DESC' }}
    customActions={props.options.customActions}
  >
    <TextField source="command" />
    <FunctionField
      render={row => localizeUtcDate(row.start_time)}
      source="start_time"
    />
    <FunctionField
      render={row => localizeUtcDate(row.end_time)}
      source="end_time"
    />
    <TextField source="remaining_jobs" sortable={false} />
    <TextField source="successful_jobs_count" sortable={false} />
    <TextField source="failed_jobs_count" sortable={false} />

    <FunctionField
      source="total_time"
      render={record => humanizeDuration(record.total_time * 1000)}
    />
  </List>
);
