import { createTheme } from '@material-ui/core/styles';
import indigo from '@material-ui/core/colors/indigo';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';
import grey from '@material-ui/core/colors/grey';
import { shadows } from './shadows';

export const ThemeLight = createTheme({
  shadows,
  palette: {
    primary: {
      main: '#4687C2',
      dark: 'rgb(98, 110, 141)',
      light: '#e2ecf4',
    },
    background: {
      default: '#ecf0f4',
    },
    secondary: {
      main: grey['800'],
      light: '#fff',
    },
    grey: grey,
    error: indigo,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: 14,
      },
    },
    MuiToolbar: {
      root: {
        alignItems: 'center!important',
      },
    },
    MuiButton: {
      containedPrimary: {
        color: '#fff',
      },
      textPrimary: {
        color: grey['50'],
        background: '#4687C2',
        minHeight: 30.4,
        '&:hover': {
          backgroundColor: 'rgb(98, 110, 141)',
        },
        '& svg': {
          fontSize: 20,
        },
      },
      sizeSmall: {
        minHeight: 30.4,
        '& svg': {
          fontSize: 20,
        },
      },
    },
    MuiCardContent: {
      root: {
        // Name of the rule
        paddingTop: 0,
        '&:first-child': {
          paddingTop: '0px!important',
          color: '#000',
        },
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: '0.9rem',
        lineHeight: '1.7em',
        paddingTop: 10,
        paddingBottom: 10,
      },
    },
    MuiTabScrollButton: {
      root: {
        flex: '0 0 20px',
        background: 'rgba(0, 0, 0, 0.1)',
      },
    },
    MuiChip: {
      colorPrimary: {
        border: '1px solid #4687C2',
        backgroundColor: '#fff',
        color: '#4687C2',
      },
      label: {
        userSelect: 'unset',
        cursor: 'text',
      },
    },
    MuiExpansionPanelSummary: {
      content: {
        alignItems: 'center',
      },
    },
    MuiPaper: {
      root: {
        maxWidth: '100%',
      },
    },
    MuiTextField: {
      root: {
        '&.label-shrink': {
          '& .MuiInputLabel-root': {
            transform: 'translate(12px, 10px) scale(0.75)',
          },
        },
      },
    },
  },
});

export const ThemeD = createTheme({
  palette: {
    type: 'dark',
    primary: process.env.REACT_APP_ENV === 'development' ? blue : red,
    secondary: process.env.REACT_APP_ENV === 'development' ? blue : red,
    grey: grey,
    error: indigo,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  overrides: {
    MuiChip: {
      colorPrimary: {
        color: '#ffffff!important',
      },
    },
    MuiCardContent: {
      root: {
        // Name of the rule
        paddingTop: 0,
        '&:first-child': {
          paddingTop: '0px!important',
          color: '#000',
        },
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: '0.9rem',
        lineHeight: '1.7em',
      },
    },
    MuiTabScrollButton: {
      root: {
        flex: '0 0 20px',
        background: 'rgba(0, 0, 0, 0.1)',
      },
    },
  },
});
