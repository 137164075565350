import { lazy } from 'react';
import { Show } from '../../ui/organisms';
import { TabbedShow, Toolbar } from '../../ui/molecules';

const ShowSummary = lazy(() => import('./ShowSummary'));
const ShowWebsites = lazy(() => import('./ShowWebsites'));

export default props => (
  <div>
    <Show actions={<Toolbar list />} {...props}>
      <TabbedShow lazy {...props}>
        <ShowSummary {...props} label="Overview" />
        <ShowWebsites {...props} label="Websites" />
      </TabbedShow>
    </Show>
  </div>
);
