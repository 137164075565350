import { Card, makeStyles, Theme, Typography } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';

const useStyles = makeStyles<Theme, { color?: string; raw: boolean }>(
  (theme: Theme) => ({
    infoCard: {
      position: 'relative',
      overflow: 'hidden',
      padding: theme.spacing(1),
      flexGrow: 1,
      color: ({ color }: { color?: string }) =>
        color ?? theme.palette.text.primary,
    },
    infoCardIcon: {
      position: 'absolute',
      right: -50,
      top: '50%',
      transform: 'translateY(-50%)',
      '& svg': {
        fontSize: 150,
        color: ({ color }: { color?: string }) =>
          alpha(color ?? theme.palette.text.primary, 0.5),
        transform: 'rotate(15deg)',
      },
    },
    infoCardTitle: {
      textTransform: ({ raw }: { raw: boolean }) =>
        raw ? 'none' : 'uppercase',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }),
);

export default ({
  icon,
  label,
  content,
  color,
  raw = true,
}: InfoSectionProps & {
  icon: JSX.Element;
}) => {
  const classes = useStyles({ color, raw });
  return (
    <Card className={classes.infoCard}>
      <InfoSection label={label} content={content} color={color} raw={raw} />
      {icon && <div className={classes.infoCardIcon}>{icon}</div>}
    </Card>
  );
};

type InfoSectionProps = {
  label: string;
  content: string;
  color?: string;
  raw?: boolean;
};

export function InfoSection({
  label,
  content,
  color,
  raw = true,
}: InfoSectionProps) {
  const classes = useStyles({ color, raw });
  return (
    <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
      <Typography>
        <b>{label}</b>:
      </Typography>
      <Typography className={classes.infoCardTitle}>
        {content || 'N/A'}
      </Typography>
    </div>
  );
}
