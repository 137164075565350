import { DatagridBody } from 'react-admin';
import { TableRow, TableCell, Checkbox } from '@material-ui/core';
import { Children, cloneElement, memo } from 'react';

type Props = {
  record: Object;
  resource: string;
  id: string;
  onToggleItem: (id: string) => void;
  children: any;
  selected: boolean;
  basePath: string;
  isWarning: (record: Object) => boolean;
};

const DatagridRow = ({
  record,
  resource,
  id,
  onToggleItem,
  children,
  selected,
  basePath,
  isWarning,
}: Props) => {
  return (
    <TableRow
      key={id}
      style={{ backgroundColor: !isWarning(record) ? undefined : '#ecc387' }}
    >
      <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={() => onToggleItem(id)} />
      </TableCell>
      {Children.map(children, field => (
        <TableCell key={`${id}-${field.props.source}`}>
          {cloneElement(field, {
            record,
            basePath,
            resource,
          })}
        </TableCell>
      ))}
    </TableRow>
  );
};

const DatagridWithWarning = ({ isWarning, ...props }: Props) => (
  <DatagridBody
    {...props}
    row={<DatagridRow isWarning={isWarning} {...props} />}
  />
);

export default memo(DatagridWithWarning);
