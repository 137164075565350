import { Responsive } from 'react-admin';
import InlineFilter from './_partials/InlineFilter';
import AdvancedFilterModal from './_partials/AdvancedFilterModal';

export default (props: any) => {
  props.customfilters.forEach((filter: any) => {
    if (filter.remove && props.filterValues[filter.source]) {
      filter.remove.forEach((remove: string) => {
        const { search } = props.filterValues[filter.source];
        props.filterValues[filter.source].search = search.replaceAll(
          remove,
          '',
        );
      });
    }
  });

  return (
    <>
      <Responsive medium={<InlineFilter {...props} />} small={<></>} />
      <AdvancedFilterModal {...props} />
    </>
  );
};
