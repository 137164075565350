import { lazy } from 'react';
import { TabbedIndex } from '../../ui/molecules';

const List = lazy(() => import('./List'));
const OpCacheList = lazy(() => import('./OPcacheList'));
const ServiceStatus = lazy(() => import('./ServiceStatus'));

export default props => (
  <TabbedIndex>
    <List name="Servers" {...props} />
    <OpCacheList name="OpCache" {...props} />
    <ServiceStatus name="Service Status" {...props} />
  </TabbedIndex>
);
