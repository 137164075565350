import { memo } from 'react';
import { Typography } from '@material-ui/core';
import useStyle from '../style';
import { Trending } from '../../../../../ui/atoms';

const LinkingDomains = ({ domains, trend }) => {
  const style = useStyle();

  return (
    <div className={style.section}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h6">Linking Domains</Typography>
        <Trending
          trend={trend > 0 ? 'up' : trend === 0 ? 'flat' : 'down'}
          value={trend}
          variant="h6"
          iconSize="medium"
        />
      </div>
      <div className={style.main}>
        <Typography variant="h3">{domains}</Typography>
      </div>
    </div>
  );
};

export default memo(LinkingDomains);
