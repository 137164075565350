import { Divider, Link, List } from '@material-ui/core';
import { useCallback } from 'react';
import { getJson } from '../../../components/Helpers/API';
import { useNotification } from '../../../hooks';
import { Notification } from '../../molecules';
import { NotificationProps } from './NotificationsButton';

type Props = {
  notifications: NotificationProps[];
  unreadNotifications: NotificationProps[];
  fetchNotifications: () => void;
};

const Notifications = ({
  notifications,
  unreadNotifications,
  fetchNotifications,
}: Props) => {
  const notify = useNotification();

  const handleToggleRead = useCallback(
    (e, notification) => {
      e.stopPropagation();
      getJson(`/nms/api/v1/notifications/${notification.id}/mark-read`, {
        method: 'POST',
        body: JSON.stringify({ read: notification.read ? 0 : 1 }),
      })
        .then(res => {
          if (res.data) {
            fetchNotifications();
          } else {
            notify({
              text: 'Error fetching notifications',
              variant: 'error',
            });
          }
        })
        .catch(e => notification({ text: e, variant: 'error' }));
    },
    [notify, fetchNotifications],
  );

  const handleMarkAllRead = useCallback(() => {
    getJson('/nms/api/v1/notifications/mark-all-read', {
      method: 'POST',
    })
      .then(res => {
        if (res.data) {
          fetchNotifications();
        } else {
          notify({
            text: 'Error fetching notifications',
            variant: 'error',
          });
        }
      })
      .catch(e => notify({ text: e, variant: 'error' }));
  }, [notify, fetchNotifications]);

  return (
    <>
      {!notifications.length && (
        <>
          <div
            style={{
              paddingLeft: '8px',
              paddingRight: '8px',
              paddingTop: '5.6px',
              paddingBottom: '5.6px',
              width: '300px',
            }}
          >
            No notifications to show
          </div>
          <Divider />
        </>
      )}
      {!!unreadNotifications.length && (
        <>
          <div
            style={{
              paddingLeft: '8px',
              paddingRight: '8px',
              paddingTop: '5.6px',
              paddingBottom: '5.6px',
              width: '300px',
            }}
          >
            <Link onClick={handleMarkAllRead} style={{ cursor: 'pointer' }}>
              Mark all as read
            </Link>
          </div>
          <Divider />
        </>
      )}
      <List>
        {notifications.map(notification => (
          <Notification
            key={notification.id}
            notification={notification}
            handleToggleRead={handleToggleRead}
          />
        ))}
      </List>
    </>
  );
};

export default Notifications;
