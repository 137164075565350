import { FC, memo } from 'react';
import { Typography } from '@material-ui/core';
import { TrendingDown, TrendingFlat, TrendingUp } from '@material-ui/icons';

type Props = {
  trend: 'up' | 'down' | 'flat';
  value: number;
  variant?:
    | 'inherit'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | 'caption'
    | 'button'
    | 'overline'
    | 'srOnly';
  iconSize: 'small' | 'inherit' | 'default' | 'large';
  percentage?: boolean;
  allowNegative?: boolean;
  invertColors?: boolean;
};

const Trending: FC<Props> = ({
  trend,
  value,
  variant = 'caption',
  iconSize = 'small',
  percentage,
  allowNegative,
  invertColors,
}) => {
  let colors = {
    up: 'green',
    down: 'red',
    flat: 'grey',
  };
  if (invertColors) {
    colors = {
      up: 'red',
      down: 'green',
      flat: 'grey',
    };
  }
  const color = colors[trend];
  const Trend = () => {
    if (trend === 'up') {
      return (
        <TrendingUp style={{ color, marginRight: 5 }} fontSize={iconSize} />
      );
    }
    if (trend === 'down') {
      return (
        <TrendingDown style={{ color, marginRight: 5 }} fontSize={iconSize} />
      );
    }
    return (
      <TrendingFlat style={{ color, marginRight: 5 }} fontSize={iconSize} />
    );
  };
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Trend />
      <Typography variant={variant} component="sup" style={{ color }}>
        ({(allowNegative ? value : Math.abs(value)) + (percentage ? '%' : '')})
      </Typography>
    </div>
  );
};

export default memo(Trending);
