import { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';
import { useVersion, useNewVersion } from '../../../hooks/useVersion';

const Version = (): JSX.Element => {
  const version = useVersion();
  const newVersion = useNewVersion();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (version && newVersion && version !== newVersion) {
      setOpen(true);
    }
  }, [version, newVersion]);

  return (
    <>
      <Dialog open={open} title="New Version Available">
        <DialogContent>
          You're running an outdated version of Kokos, please refresh the page
          to get the latest version.
        </DialogContent>
        <DialogActions>
          <Button onClick={() => window.location.reload()} color="primary">
            Refresh now
          </Button>
          <Button onClick={() => setOpen(false)}>Not now</Button>
        </DialogActions>
      </Dialog>
      <small
        style={{
          position: 'absolute',
          bottom: 15,
          color: '#7b7b7b',
          width: '100%',
          textAlign: 'center',
        }}
      >
        {`v ${version}`}
      </small>
    </>
  );
};

export default Version;
