import { createSelector } from 'reselect';
import { getAdminData, AdminDataType } from './getAdminData';

export interface AdminUiDataType {
  sidebarOpen?: boolean;
}

export const getAdminUiData = createSelector(
  getAdminData,
  (AdminData: AdminDataType): AdminUiDataType => {
    return AdminData.ui || {};
  },
);
