import { AppBar, Responsive } from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import { ComponentProps } from 'react';
import { Breadcrumbs } from '../';
import { Spacer } from '../../atoms';
import LogoutButton from '../LogoutButton';
import NotificationsButton from '../NotificationsButton';
import HeartBeats from '../HeartBeat';
import { useUserData } from '../../../hooks/useUserData';
import AppBarTitle from './_partial/AppBarTitle';

export default (props: ComponentProps<typeof AppBar>) => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const { full_roles } = useUserData();
  const isAdmin = full_roles && full_roles.find(r => r.name === 'ADMIN');

  return (
    <Responsive
      small={
        <>
          <AppBar
            elevation={0}
            color="secondary.light"
            {...props}
            userMenu={<></>}
          >
            <AppBarTitle />
            <Spacer flex />
            {isMobile && <NotificationsButton />}
            <LogoutButton />
            {isAdmin && <HeartBeats />}
          </AppBar>
          <div>
            <Breadcrumbs />
          </div>
        </>
      }
      medium={
        <AppBar
          elevation={0}
          color="secondary.light"
          {...props}
          userMenu={<></>}
        >
          <AppBarTitle />
          <Breadcrumbs />
          <Spacer flex />
          {!isMobile && <NotificationsButton />}
          {isAdmin && <HeartBeats />}
          <LogoutButton />
        </AppBar>
      }
    />
  );
};
