import IconButton from '@material-ui/core/IconButton';
import { ExitToApp } from '@material-ui/icons';
import { useCallback } from 'react';

interface IProps {
  label?: string;
}

const LogoutButton = ({ label }: IProps): JSX.Element => {
  const logout = useCallback(() => {
    localStorage.clear();
    window.location.reload();
  }, []);
  return (
    <IconButton color="inherit" href="" onClick={logout}>
      <ExitToApp /> {label}
    </IconButton>
  );
};

export default LogoutButton;
