import { ReferenceField, SimpleShowLayout, TextField } from 'react-admin';
import React from 'react';
import { Paper } from '@material-ui/core';
import { Show } from '../../ui/organisms';
import { Toolbar } from '../../ui/molecules';
import { DateField } from '../../ui/atoms';

export default props => (
  <Paper>
    <Show actions={<Toolbar list />} {...props}>
      <SimpleShowLayout>
        <TextField source="type" label="type" />
        <TextField source="location" label="location" />
        <TextField source="hostname" label="hostname" />
        <TextField source="verified" label="verified" />
        <DateField source="last_checked" />
        <DateField source="updated_at" />
        <DateField source="created_at" />
        <ReferenceField
          link="show"
          label="Website"
          reference="dms/api/v1/websites"
          source="website_id"
          allowEmpty
        >
          <TextField source="hostname" />
        </ReferenceField>
      </SimpleShowLayout>
    </Show>
  </Paper>
);
