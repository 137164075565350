import {
  ChangeEvent,
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  GetApp,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Refresh,
  UnfoldLess,
  UnfoldMore,
} from '@material-ui/icons';
import { ToggleButton } from '@material-ui/lab';
import shortid from 'shortid';
import {
  Checkbox,
  Fab,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import { usePagination, useSort } from '../../../hooks';
import ScrollArrows from '../../organisms/ScrollArrows';
import Row from './Row';

interface Field {
  label: string;
  key: string;
  source?: string;
  component?: any;
  render?: (params: any) => JSX.Element;
  renderChild?: (params: any) => JSX.Element;
  disableSort?: boolean;
  limit?: number;
}

interface IAdvancedTable {
  items: any[];
  footerRow?: any;
  fields: Field[];
  count?: number;
  defaultPerPage?: number;
  initialSort?: string;
  initialOrder?: string;
  pagination?: {
    page: number;
    perPage: number;
  };
  setPagination?: Dispatch<
    SetStateAction<{
      page: number;
      perPage: number;
    }>
  >;
  setSort?: (params: { sortBy: string; sortOrder: string }) => void;
  loading?: boolean;
  onSelect?: (row: any) => {};
  onBulkSelect?: (checked: boolean) => {};
  onRowClick?: (row: any) => void;
  onRefresh?: () => void;
  selected?: any[];
  feSort?: boolean;
  zebra?: boolean;
  tableTitle?: string;
  tableSubTitle?: string;
  rowsPerPageOptions?: number[];
  exporter?: () => void;
  rowCount?: boolean;
}

const AdvancedTable = ({
  items,
  fields,
  count,
  pagination,
  setPagination,
  setSort,
  initialSort,
  initialOrder,
  loading,
  onSelect,
  onBulkSelect,
  onRowClick,
  selected,
  onRefresh,
  feSort,
  defaultPerPage = 5,
  zebra,
  footerRow,
  tableTitle,
  tableSubTitle,
  exporter,
  rowsPerPageOptions = [5, 10, 25, 50, 100, 500],
  rowCount = false,
  ...props
}: IAdvancedTable): JSX.Element => {
  const [feSortedItems, setFeSortedItems] = useState<any[]>([]);
  const [limitedFields, setLimitedFields] = useState<string[]>(
    fields.map(f => f.key),
  );
  const getId = useCallback(
    (row: any) =>
      row.id ? row.id : row._id ? row._id.$oid : shortid.generate(),
    [],
  );
  const {
    page,
    perPage,
    handleChangePage,
    handleChangePerPage,
  } = usePagination({ defaultPerPage, setPagination, pagination });

  const { order, sort, handleSort } = useSort({
    initialSort: initialSort || fields[0].key.split('.').pop(),
    initialOrder,
  });

  const handleBulkSelect = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      event.stopPropagation();
      onBulkSelect && onBulkSelect(!!event.target.checked);
    },
    [onBulkSelect],
  );

  const renderArrow = useCallback(
    (field: Field) => {
      if ((!setSort && !feSort) || field.disableSort) {
        return null;
      }
      if (field.key !== sort) {
        return <KeyboardArrowDown style={{ opacity: 0.3 }} />;
      }
      return order === 'asc' ? <KeyboardArrowDown /> : <KeyboardArrowUp />;
    },
    [order, sort, setSort, feSort],
  );

  const handleFeSort = useCallback(() => {
    setFeSortedItems(prev => {
      const newItems = Array.from(prev);
      const compare = (a: any, b: any) => {
        let aVal = a[sort];
        let bVal = b[sort];
        if (sort.split('.').length > 1) {
          aVal = sort.split('.').reduce(function(a: any, b: any) {
            return a?.[b];
          }, a);
          bVal = sort.split('.').reduce(function(a: any, b: any) {
            return a?.[b];
          }, b);
        }

        if (Array.isArray(a[sort])) {
          return a[sort].length - b[sort].length;
        }
        if (typeof aVal === 'string') {
          return aVal.localeCompare(bVal);
        }
        return (aVal ?? 0) - (bVal ?? 0);
      };
      return order === 'asc'
        ? newItems.sort(compare)
        : newItems.sort(compare).reverse();
    });
  }, [order, sort]);

  const toggleLimit = (e: any, key: string) => {
    e.stopPropagation();
    const newLimitedFields = Array.from(limitedFields);
    if (newLimitedFields.includes(key)) {
      newLimitedFields.splice(newLimitedFields.indexOf(key), 1);
      setLimitedFields(newLimitedFields);
      return;
    }
    newLimitedFields.push(key);
    setLimitedFields(newLimitedFields);
  };

  // useEffect((): void => {
  //   console.log({ page, perPage, setPagination });
  //   if (setPagination) {
  //     setPagination({ page, perPage });
  //   }
  // }, [page]);

  useEffect(() => {
    if (setSort) {
      setSort({ sortOrder: order, sortBy: sort });
    }
    if (feSort) {
      handleFeSort();
    }
  }, [order, sort, setSort, feSort, handleFeSort]);

  useEffect(() => {
    setFeSortedItems(items);
  }, [items]);

  return (
    <div
      style={{ width: '100%', overflowX: 'auto', overflowY: 'hidden' }}
      id="scrollableTable"
    >
      <ScrollArrows
        id="scrollableTable"
        alwaysOn={
          fields.some(f => f.limit) && limitedFields.length !== fields.length
        }
      />
      {onRefresh && (
        <Fab onClick={onRefresh} color="secondary" style={{ float: 'right' }}>
          <Refresh />
        </Fab>
      )}
      {exporter && (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Fab onClick={exporter} color="primary" size="small">
            <GetApp fontSize="small" />
          </Fab>
        </div>
      )}
      {setPagination && !!count && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ padding: '12px' }}>
            {tableTitle && <Typography variant="h6">{tableTitle}</Typography>}
            {tableSubTitle && (
              <Typography variant="caption">{tableSubTitle}</Typography>
            )}
          </div>
          <TablePagination
            style={{ flexGrow: 1 }}
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={count}
            rowsPerPage={perPage}
            page={page}
            onPageChange={handleChangePage} // Updated to use onPageChange
            onRowsPerPageChange={handleChangePerPage} // Updated to use onRowsPerPageChange
          />
        </div>
      )}
      {loading && <LinearProgress />}
      <Table size="small">
        <TableHead
          style={{ boxShadow: '0px -3px 0px 0px inset #4587c2', height: 50 }}
        >
          <TableRow>
            {rowCount && <TableCell />}
            {onBulkSelect && (
              <TableCell>
                <Checkbox onChange={handleBulkSelect} />
              </TableCell>
            )}
            {fields.map((field, k) => (
              <TableCell
                key={field.label + k}
                onClick={() =>
                  (feSort || setSort) &&
                  !field.disableSort &&
                  handleSort(field.key)
                }
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    cursor:
                      (setSort || feSort) && !field.disableSort
                        ? 'pointer'
                        : undefined,
                  }}
                >
                  <div style={{ display: 'flex' }}>
                    {field.label}
                    {renderArrow(field)}
                  </div>
                  {field.limit && (
                    <>
                      <ToggleButton
                        selected={limitedFields.includes(field.key)}
                        onClick={e => toggleLimit(e, field.key)}
                        value="expand"
                        size="small"
                      >
                        {!limitedFields.includes(field.key) ? (
                          <UnfoldLess style={{ transform: 'rotate(90deg)' }} />
                        ) : (
                          <UnfoldMore style={{ transform: 'rotate(90deg)' }} />
                        )}
                      </ToggleButton>
                    </>
                  )}
                </div>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {feSortedItems &&
            feSortedItems.map((row, i) => {
              return (
                <Row
                  key={getId(row)}
                  row={row}
                  fields={fields}
                  onSelect={onSelect}
                  selected={selected}
                  onRowClick={onRowClick}
                  limitedFields={limitedFields}
                  zebra={zebra}
                  rowCount={rowCount ? page * perPage + i + 1 : undefined}
                  {...props}
                />
              );
            })}
          {footerRow && (
            <Row
              key={getId(footerRow)}
              row={footerRow}
              fields={fields}
              onSelect={onSelect}
              selected={selected}
              onRowClick={onRowClick}
              limitedFields={limitedFields}
              footerRow
              {...props}
            />
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default memo(AdvancedTable);
