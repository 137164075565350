import { Component } from 'react';
import PropTypes from 'prop-types';
import Default from './Default';

class DeindexChecker extends Component {
  render() {
    const { output, header } = this.props;

    return (
      <Default
        output={output}
        header={header === 'Successes' ? 'Deindexed' : 'All good'}
      />
    );
  }
}

DeindexChecker.propTypes = {
  output: PropTypes.array.isRequired,
  header: PropTypes.string.isRequired,
};

export default DeindexChecker;
