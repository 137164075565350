import { makeStyles } from '@material-ui/core';
import { Datagrid, List, Responsive } from 'react-admin';
import React, { ReactElement } from 'react';
import CustomizableDatagrid from '../../organisms/Datagrid/src';
import reference_exporter from '../../../components/Helpers/reference_exporter';
import { Actions } from '../';
import { Pagination } from '../../atoms';

const useStyles = makeStyles(() => ({
  root: {
    background: '#ffffff',
    '& button[aria-label="Delete"]': {
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#4587c2',
      },
    },
  },
}));

export default ({
  advancedFilters,
  responsive,
  customActions,
  defaultColumns,
  customContent,
  resource,
  rowClick = 'show',
  ...props
}: any) => {
  const classes = useStyles();
  const children = (Array.isArray(props.children)
    ? props.children
    : [props.children]
  ).filter((child?: ReactElement) => child);
  const responsiveCols =
    responsive && responsive.length
      ? responsive
      : children.map((c: any, i: any) => (i > 2 ? c.props.source : false));
  const referenceFields: {
    field: string;
    collection: string;
    reference: string;
  }[] = children
    .filter((child: any) => child?.props?.reference)
    .map((child: any) => {
      return {
        field: child.props.source,
        collection: child.props.reference,
        reference: child.props.referenceSource || 'name',
      };
    });
  return (
    <List
      pagination={<Pagination />}
      perPage={25}
      resource={resource}
      filterDefaultValues={props.filterDefaultValues}
      actions={
        <Actions
          advancedFilters={advancedFilters}
          customActions={customActions}
          exporter={
            referenceFields.length
              ? (records: any[], fetchRelatedRecords: Function) =>
                  reference_exporter({
                    records,
                    fetchRelatedRecords,
                    referenceFields,
                    resource,
                  })
              : undefined
          }
          {...props}
        />
      }
      classes={classes}
      {...props}
    >
      {customContent ? (
        customContent
      ) : (
        <Responsive
          small={
            <Datagrid
              rowClick={rowClick ?? undefined}
              body={props.body || undefined}
            >
              {children.filter((child: any) =>
                responsiveCols.includes(child?.props?.source),
              )}
            </Datagrid>
          }
          medium={
            defaultColumns ? (
              // @ts-ignore
              <CustomizableDatagrid
                rowClick={rowClick ?? undefined}
                defaultColumns={defaultColumns}
                expand={props.expand}
                body={props.body || undefined}
              >
                {children}
              </CustomizableDatagrid>
            ) : (
              <Datagrid
                rowClick={rowClick ?? undefined}
                body={props.body || undefined}
              >
                {children}
              </Datagrid>
            )
          }
        />
      )}
    </List>
  );
};
