import { Button, makeStyles } from '@material-ui/core';
import { ControlPointDuplicate } from '@material-ui/icons';
import Spacer from '../Spacer';
import { useDuplicate } from '../../../hooks/useDuplicate';

const useStyles = makeStyles(theme => ({
  wrapper: {
    textAlign: 'right',

    '& .MuiButton-label': {
      [theme.breakpoints.down('md')]: {
        '& span': {
          display: 'none',
        },
      },
    },
  },
}));

export default (props: any): JSX.Element => {
  const {
    label = 'New from this',
    record,
    basePath,
    excludedFields,
    changedFields,
  } = props;
  const classes = useStyles();
  const handleDuplicate = useDuplicate();

  return (
    <div className={classes.wrapper}>
      <Button
        onClick={e =>
          handleDuplicate(e, {
            record,
            basePath,
            excludedFields,
            changedFields,
          })
        }
        variant="contained"
        color="primary"
        size="small"
        disableElevation
      >
        <div style={{ display: 'flex' }}>
          <ControlPointDuplicate fontSize="small" />
          <Spacer width="5px" />
          {label}
        </div>
      </Button>
    </div>
  );
};
