import { useState } from 'react';
import { TextField, Button, Grid, Paper } from '@material-ui/core';

const Signature = () => {
  const [name, setName] = useState('Your FirstName');
  const [surname, setSurame] = useState('Your LastName');
  const [role, setRole] = useState('Your Role');
  const [mobile, setMobile] = useState('Your Mobile');
  const [skype, setSkype] = useState('Your Skype');

  function selectText(containerid) {
    if (document.selection) {
      // IE
      const range = document.body.createTextRange();
      range.moveToElementText(document.getElementById(containerid));
      range.select();
    } else if (window.getSelection) {
      const range = document.createRange();
      range.selectNode(document.getElementById(containerid));
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
    }
    document.execCommand('copy');
  }

  return (
    <Paper>
      <Grid container spacing={24}>
        <Grid item xs={5} style={{ textAlign: 'center', padding: 30 }}>
          <TextField
            type="text"
            label="Name"
            fullWidth
            onChange={e => setName(e.target.value)}
          />
          <TextField
            type="text"
            label="Surname"
            fullWidth
            onChange={e => setSurame(e.target.value)}
          />
          <TextField
            type="text"
            label="Role"
            fullWidth
            onChange={e => setRole(e.target.value)}
          />
          <TextField
            type="text"
            label="Mobile"
            fullWidth
            onChange={e => setMobile(e.target.value)}
          />
          <TextField
            type="text"
            label="Skype"
            fullWidth
            onChange={e => setSkype(e.target.value)}
          />
          <br />
          <br />
          <Button color="primary" onClick={() => selectText('signature')}>
            Copy signature to clipboard
          </Button>
        </Grid>
        <Grid item xs={7} style={{ padding: 30 }}>
          <div
            id="signature"
            style={{ width: 525, background: '#fff', margin: '0 auto' }}
          >
            <table
              cellSpacing={0}
              cellPadding={0}
              style={{
                width: '525px',
                fontSize: '10pt',
                fontFamily: 'Arial,sans-serif',
              }}
            >
              <tbody>
                <tr>
                  <td
                    valign="middle"
                    style={{
                      fontFamily: 'Tahoma,sans-serif',
                      fontSize: '10pt',
                      paddingBottom: '10px',
                      paddingTop: '5px',
                      paddingLeft: '10px',
                      verticalAlign: 'middle',
                    }}
                  />
                </tr>
                <tr>
                  <td
                    align="left"
                    valign="top"
                    style={{
                      paddingBottom: '10px',
                      paddingLeft: '10px',
                      verticalAlign: 'top',
                    }}
                  />
                  <td
                    align="center"
                    valign="middle"
                    style={{
                      paddingBottom: '10px',
                      paddingRight: '10px',
                      verticalAlign: 'middle',
                      textAlign: 'center',
                    }}
                  />
                </tr>
                <tr>
                  <td
                    colSpan={2}
                    align="justify"
                    valign="top"
                    style={{
                      fontFamily: 'Arial,sans-serif',
                      fontSize: '10pt',
                      borderTop: '1px solid rgb(12,163,168)',
                      color: 'rgb(42,42,42)',
                      paddingTop: '10px',
                      textAlign: 'justify',
                      verticalAlign: 'top',
                    }}
                  />
                </tr>
              </tbody>
            </table>
            <table
              cellSpacing={0}
              cellPadding={0}
              style={{
                width: '525px',
                fontSize: '10pt',
                fontFamily: 'Arial,sans-serif',
              }}
            >
              <tbody>
                <tr>
                  <td
                    valign="top"
                    style={{
                      fontFamily: 'Arial,sans-serif',
                      width: '326px',
                      paddingBottom: '5px',
                      paddingLeft: '10px',
                      verticalAlign: 'top',
                    }}
                  >
                    <strong>
                      <span style={{ fontFamily: 'Tahoma,sans-serif' }}>
                        <font color="#000000" size={4}>
                          Best Regards,
                        </font>
                        <br />
                        <span
                          style={{
                            color: 'rgb(12,163,168)',
                            fontSize: '18pt',
                          }}
                        >
                          {name} {surname}
                        </span>
                      </span>
                    </strong>
                    <br />
                    <span
                      style={{
                        color: 'rgb(42,42,42)',
                        fontSize: '10pt',
                        fontFamily: 'Tahoma,sans-serif',
                      }}
                    >
                      {role}
                    </span>
                    &nbsp;
                    <span
                      style={{
                        color: 'rgb(42,42,42)',
                        fontSize: '10pt',
                        fontFamily: 'Tahoma,sans-serif',
                      }}
                    >
                      •&nbsp;
                    </span>
                    <span
                      style={{
                        color: 'rgb(42,42,42)',
                        fontSize: '10pt',
                        fontFamily: 'Tahoma,sans-serif',
                      }}
                    >
                      Dilanti Media Limited
                    </span>
                  </td>
                  <td
                    rowSpan={3}
                    align="center"
                    valign="top"
                    style={{
                      fontSize: '10pt',
                      width: '199px',
                      paddingRight: '10px',
                      verticalAlign: 'top',
                      textAlign: 'center',
                    }}
                  >
                    <a
                      href="https://www.dilantimedia.com/"
                      style={{ color: 'rgb(17,85,204)' }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="https://dilantimedia.com/wp-content/uploads/2019/04/award_dilanti.png"
                        alt="logo"
                        width={169}
                        border={0}
                        style={{
                          width: '169px',
                          height: 'auto',
                          border: '0px',
                        }}
                        className="CToWUd"
                      />
                    </a>
                  </td>
                </tr>
                <tr>
                  <td
                    valign="top"
                    style={{
                      fontFamily: 'Arial,sans-serif',
                      fontSize: '10pt',
                      color: 'rgb(140,140,140)',
                      paddingBottom: '5px',
                      paddingTop: '5px',
                      paddingLeft: '10px',
                      verticalAlign: 'top',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '10pt',
                        fontFamily: 'Tahoma,sans-serif',
                      }}
                    >
                      mobile: {mobile}
                    </span>
                    <br />
                    <span
                      style={{
                        fontSize: '10pt',
                        fontFamily: 'Tahoma,sans-serif',
                      }}
                    >
                      Skype: {skype}
                      <br />
                    </span>
                  </td>
                </tr>
                <tr>
                  <td
                    valign="middle"
                    style={{
                      fontFamily: 'Tahoma,sans-serif',
                      fontSize: '10pt',
                      paddingBottom: '10px',
                      paddingTop: '5px',
                      paddingLeft: '10px',
                      verticalAlign: 'middle',
                    }}
                  >
                    <span>
                      <strong>
                        <a
                          href="http://www.dilantimedia.com/"
                          style={{ color: 'rgb(17,85,204)' }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span
                            style={{
                              fontSize: '10pt',
                              fontFamily: 'Arial,sans-serif',
                              color: 'rgb(42,42,42)',
                            }}
                          >
                            <span
                              style={{
                                fontSize: '10pt',
                                fontFamily: 'Tahoma,sans-serif',
                              }}
                            >
                              www.dilantimedia.com
                            </span>
                          </span>
                        </a>
                      </strong>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td
                    align="left"
                    valign="top"
                    style={{
                      paddingBottom: '10px',
                      paddingLeft: '10px',
                      verticalAlign: 'top',
                    }}
                  >
                    <a
                      href="https://www.dilantimedia.com/"
                      style={{ color: 'rgb(17,85,204)' }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="https://dilantimedia.com/wp-content/uploads/2019/04/demo-24-logo-dark.png"
                        alt="banner"
                        width={326}
                        border={0}
                        style={{
                          width: '326px',
                          height: 'auto',
                          border: '0px',
                        }}
                        className="CToWUd"
                      />
                      &nbsp;
                    </a>
                    &nbsp;
                  </td>
                  <td
                    align="center"
                    valign="middle"
                    style={{
                      paddingBottom: '10px',
                      paddingRight: '10px',
                      verticalAlign: 'middle',
                      textAlign: 'center',
                    }}
                  >
                    <span>
                      <a
                        href="https://www.facebook.com/dilantimedia/"
                        style={{ color: 'rgb(17,85,204)' }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src="https://dilantimedia.com/wp-content/uploads/2019/06/fb.png"
                          alt="facebook icon"
                          width={23}
                          border={0}
                          style={{
                            border: '0px',
                            height: '23px',
                            width: '23px',
                          }}
                          className="CToWUd"
                        />
                      </a>
                      &nbsp;
                    </span>
                    <span>
                      <a
                        href="https://www.linkedin.com/company/dilanti-media/"
                        style={{ color: 'rgb(17,85,204)' }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src="https://dilantimedia.com/wp-content/uploads/2019/06/in.png"
                          alt="linkedin icon"
                          width={23}
                          border={0}
                          style={{
                            border: '0px',
                            height: '23px',
                            width: '23px',
                          }}
                          className="CToWUd"
                        />
                      </a>
                      &nbsp;
                    </span>
                    <span>
                      <a
                        href="https://www.instagram.com/dilantimedia/"
                        style={{ color: 'rgb(17,85,204)' }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src="https://dilantimedia.com/wp-content/uploads/2019/06/gr.png"
                          alt="instagram icon"
                          width={23}
                          border={0}
                          style={{
                            border: '0px',
                            height: '23px',
                            width: '23px',
                          }}
                          className="CToWUd"
                        />
                      </a>
                      &nbsp;
                    </span>
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan={2}
                    align="justify"
                    valign="top"
                    style={{
                      fontFamily: 'Arial,sans-serif',
                      fontSize: '10pt',
                      borderTop: '1px solid rgb(12,163,168)',
                      color: 'rgb(42,42,42)',
                      paddingTop: '10px',
                      textAlign: 'justify',
                      verticalAlign: 'top',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '10pt',
                        fontFamily: 'Tahoma,sans-serif',
                        color: 'rgb(140,140,140)',
                      }}
                    >
                      The content of this email is confidential and intended for
                      the recipient specified in message only. It is strictly
                      forbidden to share any part of this message with any third
                      party, without a written consent of the sender. If you
                      received this message by mistake, please reply to this
                      message and follow with its deletion, so that we can
                      ensure such a mistake does not occur in the future.
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div />
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Signature;
