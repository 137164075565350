import { stringify } from 'querystring';
import { useCallback, useEffect, useState } from 'react';
import { getJson } from '../../../../components/Helpers/API';
import { useFormattedCompareDate } from '../../../../hooks/useCompareDate';

const useRankSummary = ({ selectedMoneySite, comparison }) => {
  const [topPages, setTopPages] = useState();
  const [loaded, setLoaded] = useState(false);
  const startDate = useFormattedCompareDate(comparison, 'YYYY-MM-DD');
  const viewsQuery = stringify({
    start_date: startDate,
    end_date: 'yesterday',
    dimensions: 'pageTitle,pagePath',
    metric: 'activeUsers',
  });
  const eventQuery = stringify({
    start_date: startDate,
    end_date: 'yesterday',
    dimensions: 'eventName,pageTitle',
    metric: 'eventCount',
    filter: 'eventName=cta_click',
  });

  const fetchPages = useCallback(async () => {
    setLoaded(false);
    try {
      const [views, events] = await Promise.all([
        getJson(
          `/analytics/api/v1/google-analytics/report/${selectedMoneySite.id}?${viewsQuery}`,
        ),
        getJson(
          `/analytics/api/v1/google-analytics/report/${selectedMoneySite.id}?${eventQuery}`,
        ),
      ]);

      if (!views.data && !events.data) {
        if (views.message) {
          console.error(views.message);
        }
        if (events.message) {
          console.error(events.message);
        }
        setLoaded(true);
        setTopPages([]);
        return;
      }

      const formattedGoals = events.data.map(goal => ({
        pageTitle: goal.pageTitle,
        ctaClicks: goal.value,
      }));
      const mergedData = Object.values(
        [...views.data, ...formattedGoals].reduce((acc, curr) => {
          acc[curr.pageTitle] = {
            ...(acc[curr.pageTitle] || { ctaClicks: 0 }),
            ...curr,
          };
          return acc;
        }, {}),
      );
      const sortedData = mergedData.sort((a, b) => b.value - a.value);
      setTopPages(sortedData);
      setLoaded(true);
    } catch (e) {
      console.error(e);
      setLoaded(true);
    }
  }, [selectedMoneySite.id, viewsQuery, eventQuery]);

  useEffect(() => {
    fetchPages();
  }, [fetchPages, startDate]);

  return {
    loaded,
    topPages,
  };
};

export default useRankSummary;
