import { FunctionField } from 'ra-ui-materialui';
import { useEffect, useMemo } from 'react';
import { TextField } from 'react-admin';
import { WPIcon } from '../../ui/atoms';
import { AdvancedFilter, List } from '../../ui/molecules';

const filters = [
  {
    source: 'hostname',
    type: 'exact',
    alwaysOn: true,
  },
];

// const filters = [<TextInput label="Search" source="hostname" alwaysOn />];

const SimpleList = props => {
  const filter = new URLSearchParams(decodeURI(props.location.search)).get(
    'filter',
  );
  const searchedWebsite = useMemo(
    () => !!filter && !!JSON.parse(filter).hostname,
    [filter],
  );

  useEffect(() => {
    window.sessionStorage.removeItem('filterToList');
    if (filter) {
      window.sessionStorage.setItem('filterToList', props.location.search);
    }
  }, [filter, props.location]);

  return (
    <List
      responsive={['hostname']}
      filterDefaultValues={{ hostname: null }}
      sort={{ field: 'updated_at', order: 'DESC' }}
      listkey={'hostname'}
      customContent={
        !searchedWebsite && (
          <div style={{ padding: '16px', textAlign: 'center' }}>
            Please search a website
          </div>
        )
      }
      advancedFilters
      filters={<AdvancedFilter customfilters={filters} />}
      {...props}
      defaultColumns={['hostname']}
    >
      <FunctionField
        source="hostname"
        render={record => (
          <>
            <TextField source="hostname" />
            <WPIcon record={record} />
          </>
        )}
      />
    </List>
  );
};

export default SimpleList;
