import { useState, useEffect, FC } from 'react';
import { GET_LIST } from 'react-admin';
import {
  FormControl,
  InputLabel,
  Select,
  Avatar,
  MenuItem,
  Chip,
} from '@material-ui/core';
import { Bookmark } from '@material-ui/icons';
import dataProvider from '../../../dataProvider';

interface Props {
  value: string;
  onChange: (params: any) => void;
}

const TLDSelect: FC<Props> = ({ value, onChange }): JSX.Element => {
  const [tlds, setTlds] = useState<any[]>([]);

  const fetchTlds = () => {
    //type, resource, params
    dataProvider(GET_LIST, 'dms/api/v1/websites/tlds', {
      pagination: { page: 1, perPage: 2500 },
      sort: { field: 'name', order: 'ASC' },
      filter: {},
    })
      .then(response => {
        setTlds(response.data);
      })
      .catch(e => {
        console.error(e);
      });
  };

  useEffect(() => {
    fetchTlds();
  }, []);

  return (
    <FormControl fullWidth variant="filled">
      <InputLabel id="select-multiple-tag">TLDs</InputLabel>
      <Select
        labelId="select-multiple-tag"
        fullWidth
        multiple
        value={value}
        onChange={onChange}
        renderValue={(selected: any) => (
          <div>
            {selected.map((value: any) => (
              <Chip
                key={value}
                label={
                  (tlds.find(t => t.id === value) &&
                    tlds.find(t => t.id === value).name) ||
                  ''
                }
                color="primary"
                avatar={
                  <Avatar>
                    <Bookmark />
                  </Avatar>
                }
              />
            ))}
          </div>
        )}
      >
        {tlds.map(tld => (
          <MenuItem key={tld.id} value={tld.id}>
            {tld.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default TLDSelect;
