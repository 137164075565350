import { makeStyles, TextField } from '@material-ui/core';
import { useGetList } from 'ra-core';
import { CheckboxGroupInput } from 'ra-ui-materialui';
import { useState } from 'react';
import { TextInput, SimpleForm } from 'react-admin';
import { Grid, FormToolbar } from '../../ui/molecules';
import { Validation } from '../../ui/organisms';

const useStyles = makeStyles({
  permissions: {
    '& .MuiFormControlLabel-root': {
      width: '33%',
      marginRight: 0,
    },
    '& .MuiTypography-body1': {
      wordBreak: 'break-all',
    },
  },
});

const Form = props => {
  const [filter, setFilter] = useState('');
  const { permissions } = useStyles();
  const { data, loaded } = useGetList('ums/api/v1/permissions', {
    perPage: 999,
  });
  return (
    <SimpleForm {...props} redirect={'show'} w={12} toolbar={<FormToolbar />}>
      <Grid fullWidth>
        <Validation w={12}>
          <TextInput source="name" label="Name" fullWidth w={6} />
          <TextInput source="description" label="description" fullWidth w={6} />
          <TextField
            w={12}
            fullWidth
            variant="filled"
            onChange={e => setFilter(e.target.value)}
            label="Filter permissions by route"
          />
          <div w={12} className={permissions}>
            <CheckboxGroupInput
              w={12}
              choices={
                loaded
                  ? Object.values(data)
                      .filter(
                        p =>
                          !filter ||
                          `${p.method}: ${p.route}`.indexOf(filter) > -1,
                      )
                      .map(p => ({
                        id: p.id,
                        name: `${p.method}: ${p.route}`,
                      }))
                  : []
              }
              source="permissions"
            />
          </div>
        </Validation>
      </Grid>
    </SimpleForm>
  );
};

export default Form;
