import {
  DateField,
  ReferenceField,
  SimpleShowLayout,
  TextField,
} from 'react-admin';
import { Paper } from '@material-ui/core';
import { Show } from '../../ui/organisms';
import { Toolbar } from '../../ui/molecules';

export default props => (
  <Paper>
    <Show actions={<Toolbar list />} {...props}>
      <SimpleShowLayout>
        <TextField source="displayName" />
        <TextField source="name" />
        <TextField source="parent" />
        <TextField source="kind" />
        <ReferenceField
          source="website_id"
          reference="dms/api/v1/websites"
          link="show"
        >
          <TextField source="hostname" />
        </ReferenceField>
        <TextField source="userId" />
        <TextField source="accountId" />
        <DateField source="updated" showTime />
        <DateField source="created" showTime />
      </SimpleShowLayout>
    </Show>
  </Paper>
);
