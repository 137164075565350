import { BulkDeleteButton, TextField } from 'react-admin';
import { Fragment } from 'react';
import { ArrayField } from 'react-admin';
import { SingleFieldList } from 'react-admin';
import { ChipField } from 'react-admin';
import { AdvancedFilter, List } from '../../ui/molecules';
import { DateField, EditButton } from '../../ui/atoms';
import MergeRegistrars from './MergeRegistrars';

const filters = [
  {
    source: 'registrar',
    type: 'search',
    alwaysOn: true,
  },
  {
    source: 'nameserver_domain_names',
    type: 'search',
  },
  {
    source: 'other_registrar_names',
    type: 'search',
  },
  {
    source: 'homepage',
    type: 'search',
  },
];
const ListBulkActionButtons = props => (
  <Fragment>
    <MergeRegistrars {...props} />
    <BulkDeleteButton {...props} />
  </Fragment>
);
export default props => (
  <List
    responsive={['registrar', 'domains_count']}
    sort={{ field: 'registrar', order: 'ASC' }}
    listkey={'id'}
    {...props}
    advancedFilters
    filters={<AdvancedFilter customfilters={filters} />}
    defaultColumns={[
      'registrar',
      'domains_count',
      'nameservers_count',
      'created_at',
      'updated_at',
    ]}
    bulkActionButtons={<ListBulkActionButtons />}
  >
    <TextField source="registrar" />
    <TextField source="domains_count" />
    <TextField source="nameservers_count" />
    <ArrayField source="nameserver_domain_names">
      <SingleFieldList>
        <ChipField color="primary" source="domain" />
      </SingleFieldList>
    </ArrayField>
    <ArrayField source="other_registrar_names">
      <SingleFieldList>
        <ChipField color="primary" source="name" />
      </SingleFieldList>
    </ArrayField>
    <ArrayField source="nameservers">
      <SingleFieldList>
        <ChipField color="primary" source="nameserver" />
      </SingleFieldList>
    </ArrayField>
    <TextField source="homepage" />
    <DateField source="created_at" />
    <DateField source="updated_at" />
    <EditButton />
  </List>
);
