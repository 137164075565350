import { memo, useCallback, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  Table,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { DoneAllRounded, PlaylistAdd, Warning } from '@material-ui/icons';
import { useRefresh } from 'react-admin';
import { AdvancedTable } from '../../ui/molecules';
import { Spacer } from '../../ui/atoms';
import { getJson } from '../../components/Helpers/API';

const BulkImportModal = () => {
  const [open, setOpen] = useState(false);
  const toggleModal = useCallback(() => setOpen(p => !p), []);
  const [domains, setDomains] = useState([]);
  const [files, setFiles] = useState([]);
  const refresh = useRefresh();
  const [separator, setSeparator] = useState(',');
  const [loading, setLoading] = useState(false);

  const validateDomain = useCallback(domain => {
    const errors = {};
    if (!domain.name) {
      errors.name = 'Required';
    }
    return errors;
  }, []);

  const getFileContent = useCallback(
    e => {
      setDomains([]);
      const reader = new FileReader();
      if (reader && e.target.files && e.target.files[0]) {
        reader.readAsText(e.target.files[0]);
        reader.onload = () => {
          const content = reader.result;
          let lines = content.split('\n');
          if (lines[0].indexOf(`Name${separator} `) === 0) {
            lines = lines.slice(1);
          }
          lines = lines.map(line => {
            const arr = line.split(separator).map(c => c.trim());
            return {
              name: arr[0] || '',
              notes: arr[1] || '',
            };
          });
          setDomains(lines);
        };
        setFiles([]);
      }
    },
    [separator],
  );

  const importBulk = useCallback(async () => {
    const domainsArr = [...domains];
    setLoading(true);

    await Promise.all(
      domainsArr.map(async domain => {
        const errors = validateDomain(domain);
        if (errors && Object.values(errors).length) {
          domain.errors = errors;
          domain.status = 'Not valid';
          return;
        }
        try {
          const res = await getJson('/dms/api/v1/domains', {
            method: 'POST',
            body: JSON.stringify(domain),
          });
          if (
            res.data &&
            (res.data.id || res.message === 'The name has already been taken.')
          ) {
            domain.status = 'Imported';
          }
        } catch (e) {
          console.error(e);
          domain.status = 'Error';
          domain.message = e.message;
          domain.errors = e.data;
        }
      }),
    );

    setLoading(false);
    setDomains(domainsArr);
    refresh();
  }, [domains, validateDomain, refresh]);

  const renderStatus = status => {
    if (status === 'Imported') {
      return <DoneAllRounded style={{ color: 'green' }} />;
    }
    if (status === 'Not valid') {
      return <Warning style={{ color: 'red' }} />;
    }
    if (status === 'Error') {
      return <Warning style={{ color: 'red' }} />;
    }
    return null;
  };

  return (
    <>
      <Button onClick={toggleModal} color="primary" size="small">
        <PlaylistAdd />
        Bulk import
      </Button>
      <Dialog open={open} onClose={toggleModal} maxWidth="xl">
        <DialogTitle>Bulk Import Domains</DialogTitle>
        <DialogContent>
          <Typography>
            The CSV has to follow the columns structure: <br />
          </Typography>
          <Table size="small" style={{ border: '1px solid black' }}>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Notes</TableCell>
            </TableRow>
          </Table>
          <Spacer height={10} />
          <Typography>Select columns separator</Typography>
          <Select
            onChange={e => setSeparator(e.target.value)}
            value={separator}
          >
            <MenuItem value=",">,</MenuItem>
            <MenuItem value=";">;</MenuItem>
          </Select>
          {!!domains.length && (
            <AdvancedTable
              loading={loading}
              fields={[
                { key: 'name', label: 'Name' },
                { key: 'notes', label: 'Notes' },
                {
                  key: 'status',
                  label: 'Import status',
                  render: row =>
                    row.status && (
                      <Tooltip title={row.status || ''}>
                        {renderStatus(row.status)}
                      </Tooltip>
                    ),
                },
              ]}
              items={domains}
            />
          )}
          <Spacer height={10} />
          <Button
            onClick={() => window.open('/domains-bulk-sample.csv')}
            color="primary"
            variant="outlined"
            style={{ marginRight: 10 }}
            size="small"
          >
            Download Sample File
          </Button>
          <input
            accept="text/csv"
            id="raised-button-file"
            value={files}
            onChange={getFileContent}
            hidden
            type="file"
          />
          <label htmlFor="raised-button-file">
            <Button
              variant="outlined"
              component="span"
              color="primary"
              size="small"
            >
              Upload
            </Button>
          </label>
        </DialogContent>
        <DialogActions>
          <Button onClick={importBulk} color="primary" disabled={loading}>
            Import
          </Button>
          <Button onClick={toggleModal} color="primary" disabled={loading}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default memo(BulkImportModal);
