import { memo } from 'react';
import {
  Divider,
  alpha,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';

const useStyle = makeStyles(theme => ({
  wrapper: {
    position: 'absolute',
    zIndex: 9999,
    padding: theme.spacing(1),
    top: '50%',
    right: 0,
    transform: 'translateY(-50%)',
  },
  questionIcon: {
    opacity: 0.7,
  },
  divider: {
    backgroundColor: alpha('#ffffff', 0.3),
  },
}));

const HelpTooltip = ({ placement, source, description }) => {
  const style = useStyle({ placement });
  return (
    <div className={style.wrapper}>
      <Tooltip
        arrow
        title={
          <>
            <div>
              <Typography variant="body2">
                <b>Source:</b> {source}
              </Typography>
              <Divider className={style.divider} />
              <Typography variant="caption">{description}</Typography>
            </div>
          </>
        }
        placement="top"
      >
        <HelpOutline
          className={style.questionIcon}
          color="primary"
          fontSize="small"
        />
      </Tooltip>
    </div>
  );
};

export default memo(HelpTooltip);
