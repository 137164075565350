import { Language, Link, Money, SecurityOutlined } from '@material-ui/icons';
import { Paper } from '@material-ui/core';
import { CreatedUpdatedBox, Grid } from '../../ui/molecules';
import {
  ChipsCloud,
  InfoCard,
  SinglePageHeader,
  TextField,
} from '../../ui/atoms';

export default ({ record }) => {
  return (
    <Paper
      style={{
        padding: 8,
        marginTop: 12,
      }}
    >
      <Grid>
        <SinglePageHeader
          label="Hostname"
          title={record.hostname}
          subtitle={record.url}
          lg={6}
        />
        <SinglePageHeader label="Agency" title={record.agency} lg={3} />
        <CreatedUpdatedBox record={record} lg={3} />
        <ChipsCloud w={12}>
          <Money content={record.price} label="Price:" />
          <SecurityOutlined content={record.ssl} label="SSL:" />
          <Language content={record.language} label="Language:" />
          <Link content={record.ip_address} label="IP:" />
        </ChipsCloud>
        <InfoCard w={3} label="aHref Traffic" content={record.ahrefs_traffic} />
        <InfoCard w={3} label="Domain rating" content={record.domain_rating} />
        <InfoCard w={3} label="Citation flow" content={record.citation_flow} />
        <InfoCard w={3} label="Target Market" content={record.target_market} />
        <TextField label="Notes" content={record.noted} w={12} />
      </Grid>
    </Paper>
  );
};
