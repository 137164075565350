import { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core';
import { TabbedView } from '../index';

const useStyles = makeStyles({
  wrapper: {
    '& header': {
      margin: '-8px -25px 25px -25px',
      padding: '0 25px 8px 25px',
      background: '#fff',
      maxWidth: 'calc(100% + 49px)',
      width: 'calc(100% + 50px)',

      '& ~ div': {
        padding: 0,
      },
    },
  },
});

const TabbedIndex = (props: { children: ReactElement | ReactElement[] }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <TabbedView lazy variant="light">
        {props.children}
      </TabbedView>
    </div>
  );
};

export default TabbedIndex;
