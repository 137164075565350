import { lazy, Suspense } from 'react';
import { TabbedView } from '../../ui/molecules';

const List = lazy(() => import('./List'));
const SAWDStatus = lazy(() => import('./SAWD/SAWDStatus'));
const UptimeRobot = lazy(() => import('./UptimeRobot/UptimeRobot'));
const SEOTrends = lazy(() => import('./SEOTrends'));
const LinkNetworkOverview = lazy(() => import('./LinkNetworkOverview'));
const DeIndexedSites = lazy(() => import('./DeindexedSites'));

export default props => (
  <Suspense fallback={<div>Loading...</div>}>
    <TabbedView tabKey="List">
      <List name="Websites" {...props} />
      <SEOTrends name="SEOTrends" {...props} />
      <LinkNetworkOverview name="Link Network Overview" {...props} />
      <SAWDStatus name="SAWDStatus" {...props} />
      <UptimeRobot name="UptimeRobot" {...props} />
      <DeIndexedSites name="DeIndexed Sites" {...props} />
    </TabbedView>
  </Suspense>
);
