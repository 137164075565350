import { SimpleShowLayout } from 'react-admin';
import { Show } from '../../ui/organisms';
import { Toolbar } from '../../ui/molecules';
import ShowSummary from './ShowSummary';

export default props => (
  <Show actions={<Toolbar edit list delete />} {...props}>
    <SimpleShowLayout>
      <ShowSummary {...props} />
    </SimpleShowLayout>
  </Show>
);
