import { Component } from 'react';

const withAbort = WrappedComponent => {
  return class extends Component {
    abort = new AbortController();
    mounted = false;
    componentDidMount() {
      this.mounted = true;
    }

    componentWillUnmount() {
      this.mounted && this.abort.abort();
    }

    render() {
      return <WrappedComponent signal={this.abort.signal} {...this.props} />;
    }
  };
};

export default withAbort;
