import { TextInput, SimpleForm } from 'react-admin';
import { Grid, FormToolbar } from '../../ui/molecules';
import { Validation } from '../../ui/organisms';

const Form = props => {
  return (
    <SimpleForm {...props} redirect={'show'} w={12} toolbar={<FormToolbar />}>
      <Grid fullWidth>
        <Validation w={12}>
          <TextInput source="name" label="Name" fullWidth lg={6} />
          <TextInput source="ip_address" label="IP Address" fullWidth lg={6} />
        </Validation>
      </Grid>
    </SimpleForm>
  );
};

export default Form;
