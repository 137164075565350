import { useSelector } from 'react-redux';
import { KeyboardArrowRight, KeyboardArrowLeft } from '@material-ui/icons';
import { makeStyles, Theme } from '@material-ui/core';
import {
  getAdminUiData,
  AdminUiDataType,
} from '../../../store/selectors/getAdminUiData';

const useStyles = makeStyles<Theme, { sidebarOpen: boolean | undefined }>(
  (theme: Theme) => ({
    scrollLeft: {
      position: 'fixed',
      left: ({ sidebarOpen }: { sidebarOpen: boolean | undefined }) =>
        sidebarOpen ? 245 : 60,
      fontSize: 20,
      top: '50%',
      height: 90,
      background: theme.palette.primary.dark,
      display: 'flex',
      color: theme.palette.secondary.light,
      transition: '1s all ease',
      alignContent: 'center',
      alignItems: 'center',
    },
    scrollRight: {
      position: 'fixed',
      right: 5,
      fontSize: 20,
      top: '50%',
      height: 90,
      background: theme.palette.primary.dark,
      display: 'flex',
      color: theme.palette.secondary.light,
      alignContent: 'center',
      alignItems: 'center',
    },
  }),
);

export default ({ id, alwaysOn }: { id: string; alwaysOn?: boolean }) => {
  const { sidebarOpen } = useSelector(
    (state: any): AdminUiDataType => getAdminUiData(state),
  );
  const classes = useStyles({ sidebarOpen });
  let scrollLeftInt: any = null;
  const scrollableElement: HTMLElement | null = document.getElementById(id);
  const isScrollable =
    scrollableElement &&
    scrollableElement.scrollWidth > scrollableElement.clientWidth;

  if (!isScrollable && !alwaysOn) {
    return null;
  }

  const scrollLeft = () => {
    if (scrollableElement) {
      scrollLeftInt = setInterval((): void => {
        if (document) {
          scrollableElement.scrollLeft = scrollableElement.scrollLeft - 1;
        }
      }, 10);
    }
  };
  const scrollRight = () => {
    if (scrollableElement) {
      scrollLeftInt = setInterval((): void => {
        if (document) {
          scrollableElement.scrollLeft = scrollableElement.scrollLeft + 1;
        }
      }, 10);
    }
  };
  const clearScrolls = () => {
    clearInterval(scrollLeftInt);
  };

  return (
    <>
      <span
        onMouseOver={scrollLeft}
        onMouseOut={clearScrolls}
        className={classes.scrollLeft}
      >
        <KeyboardArrowLeft />
      </span>
      <span
        onMouseOver={scrollRight}
        onMouseOut={clearScrolls}
        className={classes.scrollRight}
      >
        <KeyboardArrowRight />
      </span>
    </>
  );
};
