import { Grid, CreatedUpdatedBox } from '../../ui/molecules';
import { Spacer, SinglePageHeader } from '../../ui/atoms';

export default ({ record }) => (
  <Grid>
    <Spacer height={4} w={12} />
    <SinglePageHeader label="Name" title={record.name} lg={8} />
    <CreatedUpdatedBox record={record} lg={4} />
  </Grid>
);
