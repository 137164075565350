import { lazy } from 'react';
import { Show } from '../../ui/organisms';
import { TabbedShow } from '../../ui/molecules';
import Toolbar from '../../ui/molecules/Toolbar';

const ShowSummary = lazy(() => import('./ShowSummary'));
const ShowInstalls = lazy(() => import('./ShowInstalls'));

export default props => (
  <Show {...props} actions={<Toolbar list edit delete />}>
    <TabbedShow lazy {...props}>
      <ShowSummary {...props} label="Overview" />
      <ShowInstalls {...props} label="Installs" />
    </TabbedShow>
  </Show>
);
