import { createSelector } from 'reselect';
import { getGlobalState, TypeGlobalState } from './globalStateSelector';

export interface UserDataType {
  data: {
    id: string;
    name: string;
    email: string;
    picture: string;
    roles: string[];
    full_roles: { [key: string]: string }[];
    target_markets: string[];
    full_permissions: { [key: string]: string };
    groupedFullPermissions: { [key: string]: string }[];
    last_login: string;
    last_activity: string;
  };
}

export const getUserData = createSelector(
  getGlobalState,
  (globalState: TypeGlobalState): UserDataType['data'] => {
    return globalState?.user?.data || {};
  },
);
