import { Divider } from '@material-ui/core';
import { memo, FC } from 'react';

type Props = {
  mt?: number;
  mb?: number;
};

const DividerComponent: FC<Props> = ({ mb, mt }) => (
  <Divider style={{ marginTop: mt, marginBottom: mb }} />
);

export default memo(DividerComponent);
