import { Create } from 'react-admin';
import Toolbar from '../../ui/molecules/Toolbar';
import Form from './Form';

export default props => (
  <div>
    <Create actions={<Toolbar show delete list />} {...props}>
      <Form title="Add Plugin" />
    </Create>
  </div>
);
