import { Grid, CreatedUpdatedBox } from '../../ui/molecules';
import { Spacer, SinglePageHeader, BooleanField } from '../../ui/atoms';

export default ({ record, ...props }) => (
  <Grid>
    <Spacer height={4} w={12} />
    <SinglePageHeader
      label="Name"
      title={record.name}
      subtitle={`IP Address: ${record.ip_address}`}
      lg={8}
    />
    <CreatedUpdatedBox record={record} lg={4} />
    <BooleanField
      source="banned_exp_dms"
      record={record}
      label="Banned on Expired Domains"
      {...props}
    />
  </Grid>
);
