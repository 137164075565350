import { Avatar, Chip, CircularProgress, Paper } from '@material-ui/core';
import { Link } from '@material-ui/icons';
import { useGetMany } from 'ra-core';
import { CreatedUpdatedBox, Grid } from '../../ui/molecules';
import { SinglePageHeader } from '../../ui/atoms';

export default ({ record }) => {
  const { data, loading } = useGetMany(
    'ums/api/v1/permissions',
    record.permissions || [],
  );

  return (
    <Paper
      style={{
        padding: 8,
        marginTop: 12,
      }}
    >
      <Grid>
        <SinglePageHeader
          label="name"
          title={record.name}
          subtitle={record.description}
          lg={8}
        />
        <CreatedUpdatedBox record={record} lg={4} />
        {loading ? (
          <CircularProgress />
        ) : (
          data.map(permission => (
            <div style={{ paddingBottom: '8px' }}>
              <Chip
                component="span"
                color="primary"
                avatar={
                  <Avatar component="span">
                    <Link />
                  </Avatar>
                }
                label={permission.method + ' ' + permission.route}
              />
            </div>
          ))
        )}
      </Grid>
    </Paper>
  );
};
