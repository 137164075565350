export default [
  {
    source: 'name|unicode_name',
    type: 'search',
    remove: ['https://', 'http://', '/', 'www.'],
    alwaysOn: true,
  },
  {
    source: 'pulled_from',
    type: 'search',
    alwaysOn: true,
  },
  {
    source: 'expiration_date',
    type: 'min_date',
    format: 'YYYY-MM-DD',
  },
  {
    source: 'expiration_date',
    type: 'max_date',
    format: 'YYYY-MM-DD',
    alwaysOn: true,
  },
];
