import {
  AutocompleteInput,
  ReferenceInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import { Grid } from '../../ui/molecules';
import { Validation } from '../../ui/organisms';

export default props => {
  return (
    <SimpleForm {...props} redirect={'show'} w={12}>
      <Grid fullWidth>
        <Validation w={12}>
          <TextInput fullWidth={true} source="value" w={6} />
          <ReferenceInput
            required
            w={6}
            source="website_id"
            reference="dms/api/v1/websites"
            resource="dms/api/v1/websites"
            sort={{ field: 'hostname', order: 'ASC' }}
            perPage={20}
            label="Website"
            filterToQuery={search => ({ hostname: { search } })}
            style={{ padding: 0 }}
          >
            <AutocompleteInput optionText="hostname" fullWidth />
          </ReferenceInput>
        </Validation>
      </Grid>
    </SimpleForm>
  );
};
