import { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  TextField,
  CircularProgress,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from '@material-ui/core';
import { getJson } from '../../../components/Helpers/API';
import withAbort from '../../../components/Helpers/withAbort';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '10px',
  },
  textField: {
    width: '98%',
    maxHeight: '300px',
    overflow: 'auto',
    margin: theme.spacing(),
  },
  dropdown: {
    width: '150px',
    margin: theme.spacing(),
  },
  row: {
    width: '100%',
  },
  resultRow: {
    padding: '2px 10px',
  },
  paper: {
    margin: '4px',
  },
});

class KeywordChecker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      cron_id: null,
      jobs: null,
      input: {
        keywords: 'Paid, Sponsors, Buy link, Add link, Buy Backlink',
        keyword_delimiter: ',',
        pages: '',
        page_delimiter: ',',
      },
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(e) {
    const { name, value } = e.target;
    this.setState({ input: { ...this.state.input, [name]: value } });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ loading: true });
    const $this = this;

    getJson('/dms/api/v1/backlinks/keyword-checker', {
      method: 'POST',
      body: JSON.stringify(this.state.input),
      signal: this.props.signal,
    })
      .then(response => {
        window.location = `/#/dms/api/v1/cron-jobs/${response.cron_id}/show`;
      })
      .catch(e => {
        console.error(e);
        $this.setState({ loading: false });
      });
  }

  render() {
    const { classes } = this.props;
    const { loading, input } = this.state;

    if (loading) {
      return <CircularProgress />;
    }

    return (
      <div>
        <Paper className={classes.paper}>
          <h3 style={{ padding: '10px' }}>
            This feature does not use proxies, so don't run this continuously on
            the same website.
          </h3>
          <form className={classes.container} onSubmit={this.handleSubmit}>
            <div className={classes.row}>
              <TextField
                onChange={this.handleInputChange}
                id="keywords"
                label="Keywords"
                margin="normal"
                name="keywords"
                value={input.keywords}
                className={classes.textField}
              />
            </div>
            <div className={classes.row}>
              <TextField
                onChange={this.handleInputChange}
                id="pages"
                label="Pages"
                margin="normal"
                name="pages"
                value={input.pages}
                className={classes.textField}
                multiline
              />
            </div>
            <div className={classes.row}>
              <FormControl className={classes.dropdown}>
                <InputLabel htmlFor="keyword_delimiter">
                  Keyword Delimiter
                </InputLabel>
                <Select
                  value={input.keyword_delimiter}
                  onChange={this.handleInputChange}
                  inputProps={{
                    name: 'keyword_delimiter',
                    id: 'keyword_delimiter',
                  }}
                >
                  <MenuItem value=",">Comma</MenuItem>
                  <MenuItem value=";">Semicolon</MenuItem>
                  <MenuItem value="newline">Newline</MenuItem>
                </Select>
              </FormControl>
              <FormControl className={classes.dropdown}>
                <InputLabel htmlFor="page_delimiter">Page Delimiter</InputLabel>
                <Select
                  value={input.page_delimiter}
                  onChange={this.handleInputChange}
                  inputProps={{
                    name: 'page_delimiter',
                    id: 'page_delimiter',
                  }}
                >
                  <MenuItem value=",">Comma</MenuItem>
                  <MenuItem value=";">Semicolon</MenuItem>
                  <MenuItem value="newline">Newline</MenuItem>
                </Select>
              </FormControl>
            </div>
            <Button type="submit" variant="contained" color="primary">
              Run!
            </Button>
          </form>
        </Paper>
      </div>
    );
  }
}

KeywordChecker.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withAbort(withStyles(styles)(KeywordChecker));
