import { Paper, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    padding: theme.spacing(),
    background: theme.palette.secondary.light,
  },
}));

interface IProps {
  children: JSX.Element | JSX.Element[];
}

export default (props: IProps): JSX.Element => {
  const classes = useStyles();
  return (
    <Paper className={classes.card} elevation={1}>
      {props.children}
    </Paper>
  );
};
