import { useCallback, memo, FC } from 'react';
import {
  FormControlLabel,
  Radio,
  Typography,
  RadioGroup as RadioGroupComponent,
} from '@material-ui/core';

type Props = {
  options: {
    id: string | number;
    name: string | number;
    disabled?: boolean;
  }[];
  selected?: string | number;
  label?: string;
  select?: (value: string) => void;
};

const RadioGroup: FC<Props> = ({ options, selected, select, label }) => {
  const handleChange = useCallback((_, value) => select && select(value), [
    select,
  ]);
  return (
    <div>
      <Typography variant="body2" align="center">
        Select {label}
      </Typography>
      <RadioGroupComponent
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
        }}
        color="primary"
        value={selected}
        onChange={handleChange}
      >
        {options.map(option => (
          <FormControlLabel
            key={option.id}
            value={option.id}
            control={<Radio color="primary" />}
            disabled={option.disabled}
            label={option.name}
          />
        ))}
      </RadioGroupComponent>
    </div>
  );
};

export default memo(RadioGroup);
