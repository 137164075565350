import { Forward } from '@material-ui/icons';
import { useEffect, useMemo } from 'react';
import { TextField } from 'react-admin';
import {
  BooleanField,
  ChipField,
  EditButton,
  ReferenceField,
  UrlField,
} from '../../ui/atoms';
import { AdvancedFilter, List } from '../../ui/molecules';
import filters from './filters.map';

export default props => {
  const filter = new URLSearchParams(decodeURI(props.location.search)).get(
    'filter',
  );
  const isWebsiteSelected = useMemo(
    () => !!filter && !!JSON.parse(filter).website_id,
    [filter],
  );

  useEffect(() => {
    window.sessionStorage.removeItem('filterToList');
    if (filter) {
      window.sessionStorage.setItem('filterToList', props.location.search);
    }
  }, [filter, props.location]);

  return (
    <>
      <List
        responsive={['permalink', 'website_id', 'link_confirmed']}
        sort={{ field: 'updated_at', order: 'DESC' }}
        listkey={'permalink'}
        customContent={
          !isWebsiteSelected && (
            <div style={{ padding: '16px', textAlign: 'center' }}>
              Please select a website
            </div>
          )
        }
        advancedFilters
        filters={<AdvancedFilter customfilters={filters} />}
        {...props}
        defaultColumns={[
          'casino_name',
          'website_id',
          'permalink',
          'redirect_to',
          'redirection_type',
        ]}
      >
        <TextField source="casino_name" name="casino_name" />
        <ReferenceField
          source="website_id"
          reference="dms/api/v1/websites"
          basePath="/dms/api/v1/websites"
          label="Website"
          allowEmpty
          referenceSource="hostname"
          link={'show'}
        />
        <UrlField source="permalink" name="permalink" />
        <UrlField source="redirect_to" name="redirect_to" />
        <BooleanField source="link_confirmed" name="link_confirmed" />
        <ChipField
          source="redirection_type"
          chip={<Forward />}
          label="Redirection type"
        />
        <EditButton />
      </List>
    </>
  );
};
