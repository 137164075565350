import { createSelector } from 'reselect';
import { getGlobalState, TypeGlobalState } from './globalStateSelector';
import { ResourceType } from './getResources';

export interface AdminDataType {
  auth: { isLoggedIn: boolean };
  customQueries: any;
  loading: number | boolean;
  notifications: [];
  record: any;
  references: any;
  resources: ResourceType;
  saving: boolean;
  ui: any;
}

export const getAdminData = createSelector(
  getGlobalState,
  (globalState: TypeGlobalState): AdminDataType => {
    return globalState.admin || {};
  },
);
