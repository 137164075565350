import { Typography } from '@material-ui/core';
import { CreatedUpdatedBox, Grid } from '../../ui/molecules';
import {
  ReferenceField,
  SinglePageHeader,
  Spacer,
  TextField,
} from '../../ui/atoms';

export default ({ record, ...props }) => (
  <Grid>
    <Spacer height={4} w={12} />
    <SinglePageHeader
      label="Title"
      title={record.title}
      subtitle={`Type: ${record.type}`}
      lg={8}
    />
    <CreatedUpdatedBox record={record} lg={4} />
    <TextField
      label="User"
      content={
        <ReferenceField
          reference="ums/api/v1/users"
          label="User"
          source="user_id"
          referenceSource="name"
          basePath={props.basePath}
          record={record}
          resource={props.resource}
          link={'show'}
          chip
        />
      }
      lg={4}
    />
    <TextField label="Recipients" content={record.recipients} lg={4} />
    <TextField label="Link" content={record.link} lg={4} />
    <div style={{ width: '12px', border: '1px solid', padding: '16px' }}>
      <Typography variant="caption">Content</Typography>
      <div dangerouslySetInnerHTML={{ __html: record.message }} />
    </div>
  </Grid>
);
