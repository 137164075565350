import { Grid } from '../../ui/molecules';
import AgGrid from '../../ui/organisms/AgGrid';

const columns = [
  {
    field: 'date',
    headerName: 'Date',
    width: 115,
  },
  {
    field: 'output.final_scores.accessibility',
    headerName: 'Accessibility',
    width: 140,
    cellRenderer: ({ data }) => data.output.final_scores.accessibility,
  },

  {
    field: 'output.final_scores.performance',
    headerName: 'Performance',
    width: 140,
    cellRenderer: ({ data }) => data.output.final_scores.performance,
  },
  {
    field: 'output.final_scores.seo',
    headerName: 'SEO',
    width: 80,
    cellRenderer: ({ data }) => data.output.final_scores.seo,
  },
  {
    field: 'output.final_scores.pwa',
    headerName: 'PWA',
    width: 85,
    cellRenderer: ({ data }) => data.output.final_scores.pwa,
  },
  {
    field: 'output.final_scores.bestPractices',
    headerName: 'Best Practices',
    width: 150,
    cellRenderer: ({ data }) => data.output.final_scores.bestPractices,
  },
  {
    field: 'hostname',
    headerName: 'Hostname',
  },
  {
    field: 'desktop',
    headerName: 'Desktop',
  },
  {
    field: 'output.finalUrl',
    headerName: 'Url',
    cellRenderer: ({ data }) => data.output.finalUrl,
  },
  {
    field: 'output.lighthouseVersion',
    headerName: 'Lighthouse Version',
    cellRenderer: ({ data }) => data.output.lighthouseVersion,
  },
];

export default ({ record: { desktop, mobile } }) => {
  if (!desktop) {
    return null;
  }

  return (
    <Grid xs={12}>
      <Grid lg={6}>
        <div>Desktop results</div>
        <AgGrid w={12} rows={desktop} columns={columns} search={false} />
      </Grid>
      <Grid lg={6}>
        <div>Mobile results</div>
        <AgGrid w={12} rows={mobile} columns={columns} search={false} />
      </Grid>
    </Grid>
  );
};
