import { Edit } from 'react-admin';
import Toolbar from '../../ui/molecules/Toolbar';
import Form from './Form';

export default props => (
  <Edit
    undoable={false}
    actions={
      <Toolbar
        show
        delete
        list
        filterToList={window.sessionStorage.getItem('filterToList')}
      />
    }
    {...props}
  >
    <Form title="Edit monitor" />
  </Edit>
);
