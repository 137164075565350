import { TextInput, SimpleForm } from 'react-admin';
import { Grid, FormToolbar } from '../../ui/molecules';
import { Validation } from '../../ui/organisms';

export default props => (
  <SimpleForm {...props} redirect={'show'} w={12} toolbar={<FormToolbar />}>
    <Grid fullWidth>
      <Validation w={12}>
        <TextInput fullWidth source="name" />
        <TextInput fullWidth source="repo" />
        <TextInput fullWidth source="url" />
        <TextInput fullWidth source="auto_deploy" />
      </Validation>
    </Grid>
  </SimpleForm>
);
