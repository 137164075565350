import { cloneElement, Suspense } from 'react';
import { TabbedShowLayout, TabbedShowLayoutTabs, Tab } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import { PreloaderCallback } from '../../atoms';

const useStyles = makeStyles({
  wrapper: {
    '& > div > hr': {
      display: 'none',
    },
  },
  tabs: {
    padding: '8px 0',
    margin: '0 -24px 0 -25px',
    background: '#fff',

    '& .show-tab:not(:last-of-type)': {
      borderRight: '1px solid #e0e0e0',
      padding: '0 12px',
      margin: '10px 0',
      minHeight: 30,
    },
  },
});

const TabbedShow = ({
  children,
  lazy,
  record,
}: {
  children: JSX.Element[] | JSX.Element;
  lazy?: boolean;
  record?: any;
}) => {
  const childrenList = Array.isArray(children) ? children : [children];
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <TabbedShowLayout
        tabs={
          <TabbedShowLayoutTabs
            className={classes.tabs}
            variant="scrollable"
            scrollButtons="auto"
          />
        }
      >
        {childrenList.map((child, index) => (
          <Tab
            path={
              child?.props?.path ? encodeURI(child?.props?.path) : undefined
            }
            label={(child.props && child.props.label) || index}
            key={`tabbed-show${index}`}
          >
            <div>
              {lazy ? (
                <Suspense fallback={<PreloaderCallback />}>
                  {cloneElement(child, { record })}
                </Suspense>
              ) : (
                <div>{child}</div>
              )}
            </div>
          </Tab>
        ))}
      </TabbedShowLayout>
    </div>
  );
};

export default TabbedShow;
