import { Button, TextField } from '@material-ui/core';
import { useCallback, useState } from 'react';
import { Spacer } from '../../../ui/atoms';

type LoginFormProps = {
  loading: boolean;
  handleLogin: (email: string, password: string) => void;
};

export function LoginForm(props: LoginFormProps) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = useCallback(() => {
    props.handleLogin(email, password);
  }, [email, password, props]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <TextField
        label="email"
        variant="outlined"
        value={email}
        onChange={e => setEmail(e.target.value)}
      />
      <Spacer height="10px" />
      <TextField
        label="password"
        type="password"
        variant="outlined"
        value={password}
        onChange={e => setPassword(e.target.value)}
      />
      <Spacer height="10px" />
      <Button
        color="primary"
        disableElevation
        disabled={props.loading || !email || !password}
        onClick={handleLogin}
        variant="contained"
      >
        Login
      </Button>
      <Spacer height="10px" />
    </div>
  );
}
