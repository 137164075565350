import shortid from 'shortid';
import {
  Button,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { FC, useCallback, useState } from 'react';
import { JsonToTable } from 'react-json-to-table';

type Props = {
  data: object[];
};
const WarningItemTable: FC<Props> = ({ data }) => {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          {Object.keys(data[0]).map(key => (
            <TableCell key={shortid.generate()}>{key}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((row: any) => (
          <Row row={row} />
        ))}
      </TableBody>
    </Table>
  );
};

function Row({ row }: any) {
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState<any>(null);
  const renderContent = useCallback((cell: any, key: any) => {
    if (typeof cell === 'string' || typeof cell === 'number') {
      return cell;
    }
    if (Array.isArray(cell)) {
      return (
        <Button
          onClick={() => {
            setOpen(e => !e);
            setContent(cell);
          }}
          size="small"
          color="primary"
        >
          {cell.length} elements in {key}
        </Button>
      );
    }
  }, []);

  return (
    <>
      <TableRow>
        {Object.entries(row).map(([key, cell]: any) => (
          <TableCell key={shortid.generate()}>
            {renderContent(cell, key)}
          </TableCell>
        ))}
      </TableRow>
      {Object.values(row).some(value => Array.isArray(value)) && (
        <TableRow>
          <TableCell
            colSpan={Object.entries(row).length}
            key={shortid.generate()}
            style={{ paddingBottom: 0, paddingTop: 0 }}
          >
            <Collapse in={open}>
              <div style={{ margin: '8px' }}>
                <JsonToTable json={content} />
              </div>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}

export default WarningItemTable;
