export default [
  {
    source: 'domain',
    type: 'search',
    alwaysOn: true,
  },
  {
    source: 'display_name',
    type: 'search',
  },
];
