import { SimpleShowLayout } from 'react-admin';
import { Show } from '../../ui/organisms';
import { Toolbar } from '../../ui/molecules';
import ShowSummary from './ShowSummary';

export default props => (
  <div>
    <Show actions={<Toolbar list />} {...props}>
      <SimpleShowLayout>
        <ShowSummary {...props} />
      </SimpleShowLayout>
    </Show>
  </div>
);
