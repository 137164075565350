import { ReferenceInput, SelectInput, SimpleForm } from 'react-admin';
import { FormToolbar, Grid } from '../../ui/molecules';
import { Validation } from '../../ui/organisms';

export default props => (
  <SimpleForm {...props} redirect={'show'} w={12} toolbar={<FormToolbar />}>
    <Grid fullWidth>
      <h3>Google Analytics Property: {props.record?.displayName}</h3>
      <Validation w={12}>
        <ReferenceInput
          fullWidth
          source="website_id"
          resource="dms/api/v1/websites"
          reference="dms/api/v1/websites"
          sort={{ field: 'hostname', order: 'ASC' }}
          filter={{ type: 'MONEY' }}
          perPage={900}
          label="Money site"
        >
          <SelectInput optionText="hostname" />
        </ReferenceInput>
      </Validation>
    </Grid>
  </SimpleForm>
);
