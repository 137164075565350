export default [
  {
    source: 'title',
    type: 'search',
    alwaysOn: true,
  },
  {
    source: 'content',
    type: 'search',
    alwaysOn: true,
  },
  {
    source: 'website_id',
    type: 'referenceAutocomplete',
    reference: 'dms/api/v1/websites',
    resource: 'dms/api/v1/websites',
    sort: { field: 'hostname', order: 'ASC' },
    label: 'Websites',
    optionText: 'hostname',
  },
  {
    source: 'word_count',
    type: 'min',
  },
  {
    source: 'word_count',
    type: 'max',
  },
  {
    source: 'separator',
    type: 'separator',
  },
  {
    source: 'outbound_links',
    type: 'min',
  },
  {
    source: 'outbound_links',
    type: 'max',
  },
  {
    source: 'separator',
    type: 'separator',
  },
  {
    source: 'type',
    type: 'search',
  },
  {
    source: 'status',
    type: 'search',
  },
];
