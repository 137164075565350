import { useQuery } from 'react-query';
import { Skeleton } from '@material-ui/lab';
import { getJsonPromise } from '../../../components/Helpers/API';

interface IProps {
  collection: string;
  ms: string;
  id: string;
  reference: string;
}

function Reference(props: IProps) {
  const { data, isLoading, isFetching } = useQuery(
    [props.collection, props.id],
    ({ queryKey: [collection, id] }) =>
      getJsonPromise(`/${props.ms}/api/v1/${collection}/${id}`),
    { enabled: !!props?.id },
  );

  if (isLoading || isFetching) {
    return <Skeleton variant="text" width={100} />;
  }

  return <span>{data[props.reference] ?? '-'}</span>;
}

const Website = (props: Pick<IProps, 'id'>) => (
  <Reference
    collection="websites"
    ms="dms"
    id={props.id}
    reference="hostname"
  />
);

Object.assign(Reference, { Website });

export default Reference;
