import React, { useCallback, useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import {
  Button,
  IconButton,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { ViewArray, ViewColumn, ViewList } from '@material-ui/icons';
import * as XLSX from 'xlsx';

const exportExcel = (data, title) => {
  const wb = XLSX.utils.book_new();
  const wsdata = XLSX.utils.json_to_sheet(data);

  XLSX.utils.book_append_sheet(wb, wsdata, title);
  XLSX.writeFile(wb, title + '.xlsx');
};

const useStyles = makeStyles({
  CustomAgGrid: {
    width: '100%',
    backgroundColor: 'transparent',

    '& .updated': {
      backgroundColor: '#e8ffee',
    },
    '& .bulk-upload': {
      backgroundColor: '#d1d1d1',
    },
    '& .ag-root-wrapper-body': {
      minHeight: 400,
    },
    '& .ag-select-list': {
      maxHeight: '250px!important',
      minWidth: '250px!important',
      overflowY: 'scroll',
    },

    '& .bulkUpload': {
      height: '50px!important',
      '& .ag-root-wrapper-body': {
        minHeight: 0,
      },
    },

    '& .ag-header-cell .ag-react-container': {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row-reverse',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      overflow: 'hidden',
    },
  },
});

const pageSizes = [10, 20, 50, 100, 200, 500];

export default ({
  rows,
  columns,
  className,
  onGridReady: onGridReadyProp,
  onRowSelected,
  search,
  fit,
  onSelectionChanged,
  domLayout = 'autoHeight',
  enableExport = true,
  enableResize = true,
  ...props
}) => {
  const classes = useStyles();
  const [quickFilter, setQuickFilter] = useState(props.quickFilter || '');
  const [gridApi, setGridApi] = useState(null);
  const [columnApi, setColumnApi] = useState(null);
  const [pageSize, setPageSize] = useState(props.pageSize || 10);

  const onGridReady = useCallback(
    params => {
      setGridApi(params.api);
      setColumnApi(params.columnApi);
      if (onGridReadyProp) {
        onGridReadyProp(params);
      }
    },
    [onGridReadyProp],
  );

  const sizeToFit = useCallback(() => {
    gridApi.sizeColumnsToFit();
    gridApi.resetRowHeights();
  }, [gridApi]);

  const autoSizeAll = useCallback(
    skipHeader => {
      columnApi.autoSizeColumns(
        columnApi.getAllColumns().map(column => column.colId),
        skipHeader,
      );
      gridApi.resetRowHeights();
    },
    [columnApi, gridApi],
  );

  const handleExport = useCallback(() => {
    exportExcel(
      rows.map(row => {
        const keys = Object.keys(row);
        const newObject = {};
        columns.forEach(column => {
          if (column.valueGetter) {
            newObject[column.headerName] = column.valueGetter({ data: row });
          } else if (keys.includes(column.field)) {
            newObject[column.headerName] = row[column.field]
              ?.toString()
              ?.trim();
          } else {
            newObject[column.headerName] = 'NOT EXPORTED';
          }
        });
        return newObject;
      }),
      'export',
    );
  }, [columns, rows]);

  useEffect(() => {
    if (fit && rows?.length && columnApi) {
      sizeToFit();
    }
  }, [rows, fit, columnApi, sizeToFit]);

  useEffect(() => {
    if (gridApi) {
      gridApi.paginationSetPageSize(pageSize);
    }
  }, [gridApi, pageSize]);

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {search && (
          <div
            style={{
              display: 'flex',
              flexGrow: 1,
              marginRight: '16px',
              marginBottom: '16px',
            }}
          >
            <TextField
              label="Search..."
              fullWidth
              value={quickFilter || ''}
              onChange={e => setQuickFilter(e.target.value)}
            />
          </div>
        )}
        {props.pagination && (
          <Select
            onChange={e => setPageSize(e.target.value)}
            value={pageSize}
            size="small"
            style={{ marginLeft: 10, marginRight: 10 }}
          >
            {pageSizes.map(size => (
              <MenuItem key={size} value={size}>
                {size}
              </MenuItem>
            ))}
          </Select>
        )}
        <div style={{ display: 'flex' }}>
          {enableExport && (
            <div style={{ marginRight: '8px' }}>
              <Button
                onClick={handleExport}
                size="small"
                variant="outlined"
                color="primary"
              >
                Export to Excel
              </Button>
            </div>
          )}
          {enableResize && (
            <>
              <IconButton onClick={sizeToFit} size="small">
                <ViewArray />
              </IconButton>
              <IconButton onClick={() => autoSizeAll(false)} size="small">
                <ViewColumn />
              </IconButton>
              <IconButton onClick={() => autoSizeAll(true)} size="small">
                <ViewList />
              </IconButton>
            </>
          )}
        </div>
      </div>
      <div className={classes.CustomAgGrid + ' ag-theme-alpine'}>
        <AgGridReact
          className={className}
          columnDefs={columns}
          rowData={props.isLoading ? null : rows}
          enableSorting={true}
          enableFilter={true}
          pagination={true}
          defaultColDef={{
            resizable: true,
            minWidth: 50,
            autoHeight: true,
            filter: true,
            sortable: true,
          }}
          domLayout={domLayout}
          overlayNoRowsTemplate="No Rows Found :("
          onGridReady={onGridReady}
          onRowSelected={onRowSelected}
          suppressFieldDotNotation={true}
          onSelectionChanged={onSelectionChanged}
          maintainColumnOrder={true}
          onRowValueChanges={params => fit && params.api?.sizeColumnsToFit()}
          onFirstDataRendered={params => fit && params.api?.sizeColumnsToFit()}
          quickFilterText={quickFilter}
          enableCellTextSelection
          paginationPageSize={10}
          {...props}
          rowSelection={props.rowSelection || 'multiple'}
          rowClass={props.rowClass}
          getRowClass={props.getRowClass}
        />
      </div>
    </div>
  );
};
