import { Grid } from '../../ui/molecules';
import AgGrid from '../../ui/organisms/AgGrid';
import columns from './columns';

export default ({ record: { doc } }) => {
  if (!doc || !doc.history) {
    return null;
  }

  const { history, ...rest } = doc;
  const newHistory = [rest, ...history];

  return (
    <Grid>
      <AgGrid w={12} rows={newHistory} columns={columns} search={false} />
    </Grid>
  );
};
