import { TextField } from 'react-admin';
import { FunctionField } from 'ra-ui-materialui';
import { AdvancedFilter, List } from '../../ui/molecules';
import { ChipField, ReferenceField } from '../../ui/atoms';
import filters from './filters.map';

export default props => (
  <div>
    <List
      responsive={['user_nicename', 'user_email', 'website_id']}
      sort={{ field: 'user_nicename', order: 'ASC' }}
      listkey={'url'}
      {...props}
      advancedFilters
      filters={<AdvancedFilter customfilters={filters} />}
      defaultColumns={[
        'user_login',
        'user_email',
        'roles',
        'user_registered',
        'website_id',
      ]}
    >
      <TextField source="user_login" />
      <TextField source="user_nicename" />
      <TextField source="display_name" />
      <TextField source="user_email" />
      <FunctionField
        source="roles"
        render={record =>
          record.roles.map(role => (
            <div style={{ paddingBottom: '4px' }}>
              <ChipField size="small" color="primary" content={role} />
            </div>
          ))
        }
      />
      <TextField source="user_url" />
      <TextField source="user_registered" />
      <TextField source="user_activation_key" />
      <TextField source="user_status" />
      <ReferenceField
        source="website_id"
        reference="dms/api/v1/websites"
        basePath="/dms/api/v1/websites"
        label="Website"
        allowEmpty
        referenceSource="hostname"
        link={'show'}
      />
    </List>
  </div>
);
