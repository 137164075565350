import { ChipField, ReferenceField, TextField } from 'react-admin';
import { Avatar, makeStyles, Theme } from '@material-ui/core';
import { Bookmarks } from '@material-ui/icons';

interface IProps {
  label: string;
  reference: string;
  source: string;
  referenceSource: string;
  chip?: boolean;
  avatar?: JSX.Element;
}

const useStyles = makeStyles((theme: Theme) => ({
  chip: {
    '& span': {
      color: theme.palette.secondary.light,
    },
  },
}));

export default ({
  label,
  reference,
  source,
  referenceSource,
  chip,
  avatar,
  ...props
}: IProps) => {
  const classes = useStyles();

  return (
    <ReferenceField
      label={label}
      reference={reference}
      source={source}
      {...props}
    >
      {chip ? (
        <ChipField
          className={classes.chip}
          source={referenceSource}
          color="primary"
          avatar={<Avatar>{avatar ? avatar : <Bookmarks />}</Avatar>}
        />
      ) : (
        <TextField source={referenceSource} />
      )}
    </ReferenceField>
  );
};
