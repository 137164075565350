import { Component } from 'react';
import PropTypes from 'prop-types';
import { LinearProgress, Paper, Typography } from '@material-ui/core';
import { getJson } from '../../components/Helpers/API';
import withAbort from '../../components/Helpers/withAbort';
import KeywordChecker from './OutputLayout/KeywordChecker';
import DeindexChecker from './OutputLayout/DeindexChecker';
import GetWordpressData from './OutputLayout/GetWordpressData';
import Default from './OutputLayout/Default';

const jobOutputStyle = {
  display: 'inline-block',
  width: '49%',
  margin: '2px',
  verticalAlign: 'top',
};

class Output extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cron: props.cron,
    };

    this.loadData = this.loadData.bind(this);
  }

  loadData() {
    const $this = this;
    getJson(this.props.basePath + `/${this.props.cron.id}`, {
      signal: this.props.signal,
    })
      .then(response => {
        $this.setState({ cron: response.data });

        if (response.data.remaining_jobs) {
          setTimeout(() => {
            $this.loadData();
          }, 5000);
        }
      })
      .catch(e => {
        console.error(e);
      });
  }

  componentDidMount() {
    this.loadData();
  }

  renderOutput(command, output, header) {
    switch (command) {
      case 'keywordChecker':
        return <KeywordChecker output={output} header={header} />;
      case 'deindexChecker':
        return <DeindexChecker output={output} header={header} />;
      case 'website:get_data {id?}':
        return <GetWordpressData output={output} header={header} />;
      default:
        return <Default output={output} header={header} />;
    }
  }

  render() {
    const { cron } = this.state;
    const progress = Math.floor((cron.done_jobs / cron.job_count) * 100);

    return (
      <div>
        <Typography variant="caption">
          <span>Progress</span>
          <span
            style={{ float: 'right' }}
          >{`${cron.done_jobs}/${cron.job_count}`}</span>
        </Typography>
        <Paper>{cron.output}</Paper>
        <LinearProgress variant="determinate" value={progress} />
        <Paper style={jobOutputStyle}>
          {this.renderOutput(cron.command, cron.job_output, 'Successes')}
        </Paper>
        <Paper style={jobOutputStyle}>
          {this.renderOutput(cron.command, cron.failed_job_output, 'Failures')}
        </Paper>
        <LinearProgress variant="determinate" value={progress} />
        <Typography variant="caption">
          <span>Progress</span>
          <span
            style={{ float: 'right' }}
          >{`${cron.done_jobs}/${cron.job_count}`}</span>
        </Typography>
      </div>
    );
  }
}

Output.propTypes = {
  cron: PropTypes.object.isRequired,
  basePath: PropTypes.string.isRequired,
};

export default withAbort(Output);
