import { memo, useCallback } from 'react';
import {
  Card,
  CircularProgress,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import Chart from 'react-google-charts';
import { Trending } from '../../../../ui/atoms';
import { TabbedView } from '../../../../ui/molecules';
import HelpTooltip from '../HelpTooltip';
import Toolbar from '../Toolbar';
import useGATraffic from './useGATraffic';

const GATraffic = ({ selectedMoneySite, comparison }) => {
  const isSmall = useMediaQuery('(max-width:1240px)');
  const {
    sessionDuration,
    users,
    activeUsers,
    sessions,
    bounceRate,
    loaded,
  } = useGATraffic({
    selectedMoneySite,
    comparison,
  });
  const tabs = [sessionDuration, users, activeUsers, sessions, bounceRate];

  const renderTrend = useCallback((data, invertColors) => {
    const { avg, avgToCompare } = data;
    const perc = ((avg - avgToCompare) * 100) / avgToCompare;
    return (
      <div>
        <Trending
          trend={perc > 0 ? 'up' : perc === 0 ? 'flat' : 'down'}
          percentage
          allowNegative
          value={perc.toFixed(2)}
          invertColors={!!invertColors}
          variant="body2"
          iconSize="default"
        />
        <Typography variant="caption">
          <b>{round(avg)}</b> ({round(avgToCompare)})
        </Typography>
      </div>
    );
  }, []);

  if (!loaded) {
    return (
      <Card>
        <div style={{ padding: '24px', textAlign: 'center' }}>
          <CircularProgress />
        </div>
      </Card>
    );
  }

  if (!sessionDuration || !sessionDuration.data) {
    return null;
  }

  if (loaded && sessionDuration.data.length < 2) {
    return (
      <Card>
        <div style={{ padding: '24px', textAlign: 'center' }}>
          <Typography variant="h2">
            <span role="img" aria-label="no data">
              🤷‍♂️
            </span>
          </Typography>
          <Typography variant="h6">No GA Data registered</Typography>
        </div>
      </Card>
    );
  }

  return (
    <Card>
      <div style={{ position: 'relative' }}>
        <Toolbar>
          <Typography variant="h6">GA Traffic</Typography>
          <HelpTooltip
            source="Google Analytics APIs"
            description="User experience metrics - Change in % in the top menu is vs. the comparable previous period, e.g last 30 days vs. the previous 30 days."
          />
        </Toolbar>
        <TabbedView
          tabsVariant={isSmall ? 'scrollable' : 'fullWidth'}
          variant="light"
        >
          {tabs.map(tab => (
            <Chart
              name={tab.shortLabel}
              icon={renderTrend(tab, tab.invertColors)}
              key={tab.label}
              width={'100%'}
              height={'350px'}
              chartType="AreaChart"
              loader={<CircularProgress />}
              data={tab.data}
              options={{
                hAxis: {
                  title: 'Date',
                },
                vAxis: {
                  ...((tab.options && tab.options.vAxis) || {}),
                  title: tab.label,
                },
                colors: [tab.color],
              }}
            />
          ))}
        </TabbedView>
      </div>
    </Card>
  );
};

function round(value) {
  return Math.round(value * 100) / 100;
}

export default memo(GATraffic);
