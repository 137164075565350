import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { refreshView } from 'react-admin';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core';
import { WarningTwoTone } from '@material-ui/icons';
import { getJson } from '../../../components/Helpers/API';
import { useNotification } from '../../../hooks';

type Props = {
  record: Record<string, unknown>;
};

function HasCaptchaField({ record }: Props) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const notify = useNotification();
  const toggleOpen = useCallback(() => setOpen(open => !open), []);

  const checkBacklink = useCallback(() => {
    setLoading(true);
    getJson(`/dms/api/v1/external-backlinks/check/${record.id}`, {
      method: 'PUT',
    })
      .then(() => {
        notify({
          text: 'External Backlink checked',
          variant: 'info',
        });
        dispatch(refreshView());
      })
      .catch(e => notify({ text: e, variant: 'error' }))
      .finally(() => {
        setLoading(false);
        setOpen(false);
      });
  }, [dispatch, notify, record.id]);

  if (!record.has_captcha) {
    return null;
  }

  return (
    <>
      <WarningTwoTone
        color="primary"
        onClick={toggleOpen}
        style={{ padding: 4, cursor: 'pointer' }}
      />
      <Dialog open={open} onClose={toggleOpen}>
        <DialogContent>
          <Typography>
            This external backlink are using a captcha and we cannot check that
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleOpen}>Close</Button>
          <Button onClick={checkBacklink} autoFocus disabled={loading}>
            {loading ? <CircularProgress size={20} /> : 'Check Again'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default HasCaptchaField;
