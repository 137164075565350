import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { FC } from 'react';

interface Props {
  value: string;
  onChange: (params: any) => void;
  includeMoneySites?: boolean;
}
const types = [
  { id: 'DIRECTORY', name: 'Website Directory' },
  { id: 'LINK', name: 'Link Site' },
  { id: 'SERVICE', name: 'Service Site' },
  { id: 'THIRD_PARTY', name: 'Third Party' },
];

const TypeSelect: FC<Props> = ({
  value,
  onChange,
  includeMoneySites,
}): JSX.Element => {
  const allTypes = includeMoneySites
    ? [...types, { id: 'MONEY', name: 'Money Sites' }]
    : types;

  return (
    <FormControl fullWidth variant="filled">
      <InputLabel htmlFor="select-type">Type</InputLabel>
      <Select fullWidth labelId="select-type" value={value} onChange={onChange}>
        {allTypes.map(t => (
          <MenuItem key={t.id} value={t.id}>
            {t.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default TypeSelect;
