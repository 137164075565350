import {
  CheckboxGroupInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  DateInput,
  AutocompleteInput,
} from 'react-admin';
import Moment from 'moment';
import _ from 'lodash';
import { beautify } from '../../../../components/Helpers/utils';

export default (props: any) => {
  const label = props.label ?? props.source;

  switch (props.type) {
    case 'exact':
      return (
        <TextInput
          label={`Search by ${beautify(label)}`}
          source={`${props.source}`}
          alwaysOn
        />
      );
    case 'search':
      return (
        <TextInput
          label={`Search by ${beautify(label)}`}
          source={`${props.source}.search`}
          alwaysOn
        />
      );
    case 'min':
    case 'max':
    case 'number':
      return (
        <NumberInput label={beautify(label)} source={props.source} alwaysOn />
      );
    case 'reference':
      return (
        <ReferenceInput
          filterToQuery={() => {}}
          source={props.source}
          resource={props.resource}
          reference={props.reference}
          sort={props.sort}
          perPage={900}
          label={beautify(label)}
        >
          <AutocompleteInput optionText={props.optionText} />
        </ReferenceInput>
      );
    case 'min_date':
    case 'max_date':
      const val = _.get(
        props.state,
        [props.source, props.type.replace('_date', '')],
        '',
      );
      const formattedDate =
        val && val.toString().indexOf('-') > -1
          ? val
          : val
          ? Moment(new Date(val * 1000)).format('YYYY-MM-DD')
          : '';
      return (
        <DateInput
          defaultValue={formattedDate}
          source={`${props.source}.${props.type.replace('_date', '')}`}
          label={beautify(`${props.source} ${props.type}`)}
          fullWidth
        />
      );
    case 'referenceAutocomplete':
      return (
        <ReferenceInput
          filterToQuery={(text: string) => {
            return {
              [props.optionText]: { search: text },
              ...(props.filterToQuery || {}),
            };
          }}
          source={props.source}
          resource={props.resource}
          reference={props.reference}
          sort={props.sort}
          perPage={50}
          label={props.label}
          fullWidth
        >
          <AutocompleteInput optionText={props.optionText} />
        </ReferenceInput>
      );
    case 'checkbox':
      return (
        <CheckboxGroupInput
          source={props.source}
          choices={props.choices}
          label={beautify(label)}
          alwaysOn
        />
      );
    case 'select':
      return (
        <SelectInput
          source={props.source}
          choices={props.choices}
          label={beautify(label)}
          allowEmpty
        />
      );
    default:
      return <></>;
  }
};
