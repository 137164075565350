import { useGetOne } from 'ra-core';
import { Card, Paper } from '@material-ui/core';
import { CreatedUpdatedBox, Grid } from '../../ui/molecules';
import {
  CardLabel,
  GoToIcon,
  InfoCard,
  SinglePageHeader,
} from '../../ui/atoms';

export default ({ record }) => {
  const { data: website, loading } = useGetOne(
    'dms/api/v1/websites',
    record.website_id,
  );
  return (
    <Paper
      style={{
        padding: 8,
        marginTop: 12,
      }}
    >
      <Grid>
        <SinglePageHeader
          label="Title"
          subtitle={`Website: ${loading ? '...' : website.hostname} - DR: ${
            record.website.domain_rating
          }`}
          title={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {record.title}
              <GoToIcon record={record} />
            </div>
          }
          lg={8}
        />
        <CreatedUpdatedBox record={record} lg={4} />
        <InfoCard
          label="Outbound links"
          content={record.outbound_links}
          w={3}
        />
        <InfoCard label="Word Count" content={record.word_count} w={3} />
        <InfoCard label="Status" content={record.status} w={3} />
        <InfoCard label="Type" content={record.type} w={3} />
        <Card w={12} border="1px solid" borderRadius="6px" p={1}>
          <div style={{ padding: '8px' }}>
            <CardLabel>Content</CardLabel>
            <div dangerouslySetInnerHTML={{ __html: record.content }} />
          </div>
        </Card>
      </Grid>
    </Paper>
  );
};
