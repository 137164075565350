import { LinearProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  preloader_callback: {
    position: 'absolute',
    width: '100%',
    top: 0,
    left: 0,
  },
});

export default () => {
  const classes = useStyles();
  return (
    <LinearProgress color="primary" className={classes.preloader_callback} />
  );
};
