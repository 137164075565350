import { useState, useEffect } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { makeStyles } from '@material-ui/core';
import { getJson } from '../../../components/Helpers/API';

interface Props {
  onChange: (v: any) => void;
  data: string;
  website_id: string;
}

const useStyle = makeStyles({
  editor: {
    '& .demo-editor': {
      border: '1px solid #f1f1f1',
      padding: '0 10px',
      minHeight: 'calc(100vh - 400px)',
      maxHeight: 'calc(100vh - 400px)',
      overflowY: 'auto',
    },
    '& .rdw-colorpicker-modal-header': {
      display: 'none',
    },
  },
});

const RichEditor = ({ onChange, data, website_id }: Props) => {
  const classes = useStyle();
  const [dataset, setDataset] = useState(false);
  const [invalidData, setInvalidData] = useState(false);
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty(),
  );

  useEffect(() => {
    if (data) {
      setInvalidData(data.indexOf('<figure') > -1);
      const blocksFromHtml =
        data.indexOf('<figure') > -1
          ? htmlToDraft("The editor doesn't support this content")
          : htmlToDraft(data);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap,
      );
      setEditorState(EditorState.createWithContent(contentState));
      setDataset(true);
    } else {
      setEditorState(EditorState.createEmpty());
    }
  }, [data]);

  const uploadImage = (file: File) => {
    if (website_id) {
      const form = new FormData();
      form.append('file1', file, file.name);
      return new Promise(resolve => {
        getJson(`/dms/api/v1/wordpress/${website_id}/insert-media`, {
          method: 'POST',
          body: form,
        })
          .then((response: { status: any }) => {
            resolve({ data: { link: response.status } });
          })
          .catch((e: Error) => {
            console.error(e);
          });
      });
    }
  };

  const toolbarOptions = {
    options: [
      'inline',
      'blockType',
      'fontSize',
      'fontFamily',
      // "image",
      'list',
      'textAlign',
      'colorPicker',
      'link',
      'embedded',
      'emoji',
      'remove',
      'history',
    ],
    colorPicker: {
      popupClassName: 'colorPicker',
    },
    inline: {
      inDropdown: true,
      options: ['bold', 'italic', 'underline', 'strikethrough'],
    },
    list: {
      inDropdown: true,
    },
    textAlign: {
      inDropdown: true,
    },
    image: {
      urlEnabled: true,
      uploadEnabled: true,
      alignmentEnabled: true,
      uploadCallback: uploadImage,
      previewImage: true,
      inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
      alt: { present: false, mandatory: false },
      defaultSize: {
        height: 'auto',
        width: 'auto',
      },
    },
  };

  const handleEditorStateChange = (content: EditorState) => {
    if (onChange) {
      onChange(draftToHtml(convertToRaw(content.getCurrentContent())));
    }
  };

  return (
    <div className={classes.editor}>
      {editorState && data && dataset && (
        <>
          <Editor
            initialEditorState={editorState}
            initialContentState={convertToRaw(editorState.getCurrentContent())}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            onEditorStateChange={handleEditorStateChange}
            toolbar={toolbarOptions}
            readOnly={invalidData}
          />
        </>
      )}
    </div>
  );
};

export default RichEditor;
