import { cloneElement, useEffect, useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import { Responsive } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { SET_SIDEBAR_VISIBILITY } from 'ra-core';
import { getAdminUiData } from '../../../store/selectors/getAdminUiData';
import { Version } from '..';

const size = 240;
const closedSize = 55;

interface IProps {
  children?: any;
  setSidebarVisibility?: any;
}

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    position: 'relative',
    minHeight: 'calc(100vh - 48px)',
    height: '100%',
    overflow: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: theme.palette.secondary.light,
    marginTop: '0',
    borderRight: 'none',
    [theme.breakpoints.only('xs')]: {
      marginTop: 0,
      minHeight: 'calc(100vh - 48px)',
      position: 'inherit',
    },
    [theme.breakpoints.up('md')]: {
      border: 'none',
      marginTop: '0',
    },
  },
}));

export default ({ children, ...rest }: IProps): JSX.Element => {
  const localSidebarOpen = window.localStorage.getItem('sidebar')
    ? !!parseInt(window.localStorage.getItem('sidebar') || '0', 10)
    : null;
  const { sidebarOpen } = useSelector((state: any) => getAdminUiData(state));
  const [open, setOpen] = useState<boolean>();
  const dispatch = useDispatch();
  const classes = useStyles();
  const toggleSidebar = () => {
    dispatch({ type: 'RA/TOGGLE_SIDEBAR' });
  };

  const handleClose = () => {
    dispatch({ type: 'RA/TOGGLE_SIDEBAR' });
  };

  useEffect(() => {
    setOpen(!!(localSidebarOpen || sidebarOpen));
    dispatch({
      type: SET_SIDEBAR_VISIBILITY,
      payload: localSidebarOpen !== null ? localSidebarOpen : sidebarOpen,
    });
  }, [localSidebarOpen, sidebarOpen, dispatch]);

  return (
    <Responsive
      xsmall={
        <Drawer
          variant="temporary"
          open={open}
          PaperProps={{
            className: classes.drawerPaper,
            style: { width: size },
            elevation: 4,
          }}
          onClose={toggleSidebar}
          {...rest}
        >
          {cloneElement(children, {
            onMenuClick: handleClose,
          })}
          <Version />
        </Drawer>
      }
      small={
        <Drawer
          variant="permanent"
          open={open}
          PaperProps={{
            className: classes.drawerPaper,
            style: {
              width: open ? size : closedSize,
            },
          }}
          onClose={toggleSidebar}
          {...rest}
        >
          {cloneElement(children, {
            dense: true,
            onMenuClick: handleClose,
          })}
          <Version />
        </Drawer>
      }
      medium={
        <Drawer
          variant="permanent"
          open={open}
          PaperProps={{
            className: classes.drawerPaper,
            elevation: 0,
            style: {
              width: open ? size : closedSize,
            },
          }}
          onClose={toggleSidebar}
          {...rest}
        >
          {cloneElement(children, { dense: true })}
          <Version />
        </Drawer>
      }
    />
  );
};
