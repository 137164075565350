import {
  CheckboxGroupInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  DateInput,
  AutocompleteInput,
} from 'react-admin';
import Moment from 'moment';
import _ from 'lodash';
import { beautify } from '../../../../components/Helpers/utils';

export default (props: any) => {
  switch (props.type) {
    case 'exact':
    case 'csv':
    case 'search':
      return (
        <TextInput
          defaultValue={_.get(props.state, [props.source, props.type], '')}
          source={`${props.source}.search`}
          label={`Search by ${beautify(props.source)}`}
          onChange={(e: any) => props.onChange(e.target.value)}
          fullWidth
        />
      );
    case 'min':
    case 'max':
    case 'number':
      return (
        <NumberInput
          defaultValue={_.get(props.state, [props.source, props.type], '')}
          source={`${props.source}.${props.type}`}
          type="number"
          label={`${props.source} ${props.type}`}
          onChange={(e: any) => props.onChange(e.target.value)}
          fullWidth
        />
      );
    case 'min_date':
    case 'max_date':
      const val = _.get(
        props.state,
        [props.source, props.type.replace('_date', '')],
        '',
      );
      const formattedDate =
        val && val.toString().indexOf('-') > -1
          ? val
          : val
          ? Moment(new Date(val * 1000)).format('YYYY-MM-DD')
          : '';
      return (
        <DateInput
          defaultValue={formattedDate}
          source={`${props.source}.${props.type}`}
          label={`${props.source} ${props.type}`}
          onChange={(e: any) =>
            props.onChange(
              props.format
                ? Moment(new Date(e.target.value)).format(props.format)
                : new Date(e.target.value).getTime() / 1000,
            )
          } //props.onChange(e.target.value)}
          fullWidth
        />
      );
    case 'reference':
      return (
        <ReferenceInput
          defaultValue={_.get(props.state, [props.source], '')}
          filterToQuery={() => {}}
          source={props.source}
          resource={props.resource}
          reference={props.reference}
          sort={props.sort}
          perPage={900}
          label={props.label}
          onChange={(e: any) => props.onChange(e.target.value)}
          fullWidth
          allowEmpty
        >
          <SelectInput optionText={props.optionText} fullWidth />
        </ReferenceInput>
      );
    case 'referenceAutocomplete':
      return (
        <ReferenceInput
          filterToQuery={(text: string) => {
            return {
              [props.optionText]: { search: text },
              ...(props.filterToQuery || {}),
            };
          }}
          source={props.source}
          resource={props.resource}
          reference={props.reference}
          sort={props.sort}
          perPage={50}
          onChange={(val: any) => {
            props.onChange(val);
          }}
          label={props.label}
          fullWidth
          allowEmpty
        >
          <AutocompleteInput optionText={props.optionText} />
        </ReferenceInput>
      );
    case 'checkbox':
      return (
        <CheckboxGroupInput
          defaultValue={_.get(props.state, [props.source], '')}
          source={props.source}
          choices={props.choices}
          onChange={(e: any) => props.onChange(e)}
          fullWidth
        />
      );
    case 'select':
      return (
        <SelectInput
          source={props.source}
          choices={props.choices}
          onChange={(e: any) => props.onChange(e.target.value)}
          allowEmpty
        />
      );
    case 'not_null':
      return (
        <CheckboxGroupInput
          source={props.source}
          onChange={(e: any) => {
            props.onChange(props.choices.filter((c: any) => e.includes(c.id)));
          }}
          choices={props.choices}
          label={props.label}
        />
      );
    case 'separator':
      return <div> </div>;
    default:
      return null;
  }
};
