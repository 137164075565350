import { memo } from 'react';
import { CircularProgress, Typography } from '@material-ui/core';
import Chart from 'react-google-charts';
import useStyle from '../style';
import { Emoji } from '../../../../../ui/atoms';

const Tlds = ({ tlds }) => {
  const style = useStyle();

  return (
    <div className={style.section}>
      <Typography variant="h6">TLDs</Typography>
      <div className={style.main}>
        <div className={style.chartWrapper}>
          <Chart
            width={'110px'}
            height={'110px'}
            chartType="PieChart"
            loader={<CircularProgress />}
            data={[
              ['Source', 'Perc.'],
              ...tlds.map(tld => [tld.tld, tld.perc]),
            ]}
            options={{
              legend: 'none',
              chartArea: { width: '100%', height: '80%' },
              tooltip: {
                trigger: 'none',
              },
              slices: {
                0: {
                  color: '#4D8F4F',
                  textStyle: { fontSize: 10, bold: true },
                },
                1: {
                  color: '#D96157',
                  textStyle: { fontSize: 10, bold: true },
                },
                2: {
                  color: '#FFCB52',
                  textStyle: { fontSize: 10, bold: true },
                },
              },
            }}
          />
          <div
            className={style.chartLegendWrapper}
            style={{ textAlign: 'center' }}
          >
            {tlds.map((tld, i) => (
              <Emoji
                key={tld + i}
                emoji={i === 0 ? '🟢 ' : i === 1 ? '🔴 ' : '🟠'}
              >
                <Typography variant="caption" style={{ marginLeft: 5 }}>
                  {tld.tld}
                </Typography>
              </Emoji>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Tlds);
