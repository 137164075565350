import { takeEvery, put } from 'redux-saga/effects';
import { getJson } from '../components/Helpers/API';

export default function* trackingSagas() {
  yield takeEvery('GET_USER_DATA', sagaFetchUserData);
}

export function* sagaFetchUserData() {
  try {
    const userData = yield getJson('/ums/api/v1/users/me');
    let groupedFullPermissions = {};
    if (userData && userData.data && userData.data.full_permissions) {
      groupedFullPermissions = Object.values(
        userData.data.full_permissions,
      ).reduce((grouped, permission) => {
        if (!grouped[permission.route]) {
          grouped[permission.route] = {};
        }
        grouped[permission.route][permission.method] = permission;
        return grouped;
      }, {});
    }
    yield put({
      type: 'GET_USER_DATA_SUCCESS',
      payload: { data: { ...userData.data, groupedFullPermissions } },
    });
  } catch (e) {
    yield put({ type: 'GET_USER_DATA_ERROR', payload: e });
  }
}
