import { Admin, Resource } from 'react-admin';
import { Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';
import { ThemeProvider } from '@material-ui/styles';
import { GoogleOAuthProvider } from '@react-oauth/google';
import errorSagas from './sagas/errorSagas';
import dataProvider from './dataProvider';
import Dashboard from './views/Dashboard/Dashboard';
import LoginPage from './views/LoginPage';
import Signature from './views/Signature/Signature';
import DeployWebsite from './views/DeployWebsite';
import { errorReducer } from './store/reducers/errorReducer';
import { userReducer } from './store/reducers/userReducer';
import { Layout } from './ui/organisms';
import { ThemeLight } from './Theme';
import sidebarSagas from './sagas/sidebarSagas';
import clearFormSagas from './sagas/clearFormSagas';
import trackingSagas from './sagas/trackingSaga';
import { sagaFetchUserData } from './sagas/fetchUserDataSaga';
import { useAuthentication } from './views/LoginPage/useAuthentication';
import LogoutButton from './ui/organisms/LogoutButton';
import { selectedMoneySiteReducer } from './store/reducers/selectedMoneySiteReducer';
import { resources } from './resources';

const i18nProvider = polyglotI18nProvider(() => englishMessages, 'en', {
  allowMissing: true,
});

const queryClient = new QueryClient();

const App = () => {
  const {
    login,
    googleLoginSuccess,
    googleLoginError,
    isAuthenticated,
  } = useAuthentication();

  return (
    <ContextWrapper>
      {isAuthenticated && <AppContent />}
      <div style={{ display: isAuthenticated ? 'none' : 'block' }}>
        <LoginPage
          login={login}
          googleLoginError={googleLoginError}
          googleLoginSuccess={googleLoginSuccess}
        />
      </div>
    </ContextWrapper>
  );
};

function AppContent() {
  return (
    <div>
      <Admin
        disableTelemetry
        i18nProvider={i18nProvider}
        layout={Layout}
        dashboard={Dashboard}
        dataProvider={dataProvider}
        logoutButton={LogoutButton}
        customSagas={[
          errorSagas,
          sidebarSagas,
          clearFormSagas,
          trackingSagas,
          sagaFetchUserData,
        ]}
        customReducers={{
          errorReducer: errorReducer,
          user: userReducer,
          selectedMoneySite: selectedMoneySiteReducer,
        }}
        theme={ThemeLight}
        customRoutes={[
          <Route path="/signature" component={Signature} />,
          <Route path="/deploy-website" component={DeployWebsite} />,
        ]}
      >
        {resources.map(resource => (
          <Resource
            key={resource.name}
            name={resource.name}
            icon={resource.icon}
            options={resource.options}
            {...(resource.data ?? {})}
          />
        ))}
      </Admin>
    </div>
  );
}

function ContextWrapper(props) {
  return (
    <ThemeProvider theme={ThemeLight}>
      <GoogleOAuthProvider clientId="34867909749-24t8cp6nf8m9obc44e61f77l0rr3t168.apps.googleusercontent.com">
        <QueryClientProvider client={queryClient}>
          {props.children}
        </QueryClientProvider>
      </GoogleOAuthProvider>
    </ThemeProvider>
  );
}

export default App;
