import { DeleteTwoTone } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { useRefresh } from 'ra-core';
import { useState } from 'react';
import { getJson } from '../../../components/Helpers/API';

export function DeleteNotification(props) {
  const [loading, setLoading] = useState(false);
  const refresh = useRefresh();

  const handleDeleteNotification = () => {
    setLoading(true);
    getJson(
      `/dms/api/v1/accuranker-domains/${props.record.id}/notifications/${props.notification._id.$oid}`,
      {
        method: 'DELETE',
      },
    )
      .catch(console.error)
      .finally(() => {
        setLoading(false);
        refresh();
      });
  };

  return (
    <IconButton onClick={handleDeleteNotification} disabled={loading}>
      <DeleteTwoTone color="error" />
    </IconButton>
  );
}
