import { TextField } from 'react-admin';
import { AdvancedFilter, List } from '../../ui/molecules';

const filters = [
  {
    source: '_id',
    type: 'search',
    alwaysOn: true,
  },
];

export default props => (
  <List
    responsive={['_id']}
    sort={{ field: '_id', order: 'ASC' }}
    listkey={'_id'}
    {...props}
    advancedFilters
    filters={<AdvancedFilter customfilters={filters} />}
    defaultColumns={['_id', 'count']}
  >
    <TextField source="_id" label="Hostname" />
    <TextField source="count" label="Total requests" />
  </List>
);
