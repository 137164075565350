import { TextField, NumberField } from 'react-admin';
import { ButtonField } from '../../ui/atoms';
import { AdvancedFilter, List } from '../../ui/molecules';
import filters from './filters.map';

export default props => (
  <div>
    <List
      responsive={['name', 'active_installs']}
      sort={{ field: 'name', order: 'DESC' }}
      listkey={'url'}
      {...props}
      advancedFilters
      filters={<AdvancedFilter customfilters={filters} />}
      defaultColumns={[
        'name',
        'total_installs',
        'active_installs',
        'inactive_installs',
      ]}
    >
      <ButtonField source="name" />
      <TextField source="description" />
      <TextField source="author" />
      <TextField source="text_domain" />
      <ButtonField source="total_installs" />
      <NumberField source="active_installs" />
      <NumberField source="inactive_installs" />
      <TextField source="latest_version" />
    </List>
  </div>
);
