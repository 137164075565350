import { createSelector } from 'reselect';
import { getResources, getResourcesList, ResourceType } from './getResources';
import { getLocationWalker, LocationWalkerType } from './getLocation';

interface DomainType {
  unicode_name?: string;
}

export interface SingleResourceType {
  domain?: DomainType;
  id?: string;
  name?: string;
  title?: string;
  created_at?: string;
  updated_at?: string;
  hostname?: string;
  url?: string;
}

export const getRecordById = createSelector(
  getResources,
  (_: any, props: { reference: string; id: string }) => ({
    reference: props.reference,
    id: props.id.toLowerCase(),
  }),
  (resourcesList: any, props): SingleResourceType | null => {
    const { reference, id } = props;
    if (
      resourcesList &&
      resourcesList[reference] &&
      resourcesList[reference].data &&
      resourcesList[reference].data[id]
    ) {
      return resourcesList[reference].data[id];
    }
    return null;
  },
);

export const getRecordLabelById = createSelector(
  getRecordById,
  (resource: SingleResourceType | null): string => {
    if (resource) {
      if (resource.title) {
        return resource.title;
      }
      if (resource.name) {
        return resource.name;
      }
      if (resource.domain && resource.domain.unicode_name) {
        return resource.domain.unicode_name;
      }
      if (resource.hostname) {
        return resource.hostname;
      }
      if (resource.url) {
        return resource.url;
      }
    }
    return '';
  },
);

export const getCurrentRecord = createSelector(
  getLocationWalker,
  getResourcesList,
  (locationWalker: LocationWalkerType, resourcesList: ResourceType[]) => {
    const { currentResourceId } = locationWalker;
    let currentRecord = {};
    if (!currentResourceId) {
      return null;
    }
    resourcesList.forEach(resource => {
      if (resource.data && resource.data[currentResourceId]) {
        currentRecord = resource.data[currentResourceId];
      }
    });
    return currentRecord;
  },
);
