import { SaveButton, Toolbar } from 'react-admin';
import { useDuplicate } from '../../../hooks/useDuplicate';

export default ({ enableDuplicate, ...props }: any): JSX.Element => {
  const handleDuplicate = useDuplicate();
  const list = props.filterToList
    ? `${props.basePath}${props.filterToList}`
    : 'list';
  return (
    <Toolbar {...props}>
      {!props.record.id && (
        <SaveButton
          disabled={props.disabled}
          label="Save and add"
          redirect="create"
          style={{ marginRight: 10 }}
        />
      )}
      {enableDuplicate && (
        <SaveButton
          disabled={props.disabled}
          style={{ marginRight: 10 }}
          redirect="list"
          onSuccess={({ data }: any) =>
            handleDuplicate(null, {
              record: data,
              basePath: props.basePath,
              changedFields: props.changedFields,
            })
          }
          submitOnEnter={false}
          label="Save and duplicate"
        />
      )}
      <SaveButton disabled={props.disabled} redirect={list} />
    </Toolbar>
  );
};
