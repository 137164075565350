import React, { useEffect, useState } from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  CheckboxGroupInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import { Grid } from '../../ui/molecules';
import { Validation } from '../../ui/organisms';
import { getJson } from '../../components/Helpers/API';

const Form = ({ history, ...props }) => {
  const [frequencyUnit, setFrequencyUnit] = useState();
  const [selectedLanguage, setSelectedLanguage] = useState('English');
  const [formType, setFormType] = useState('openAI');

  useEffect(() => {
    if (props.record) {
      setFrequencyUnit(props.record.frequency_unit);
    }
  }, [props]);
  const handleSave = async values => {
    await getJson(
      formType === 'openAI'
        ? '/dms/api/v1/open-ai-content'
        : '/dms/api/v1/narrativa/schedules',
      {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    if (formType === 'openAI') {
      history.push('/dms/api/v1/open-ai-content');
    } else {
      history.push('/dms/api/v1/narrativa/schedules');
    }
  };
  const renderForm = () => {
    if (formType === 'narrativa') {
      return (
        <Grid fullWidth>
          <Validation w={12}>
            <ReferenceInput
              required
              w={12}
              source="website_id"
              reference="dms/api/v1/websites"
              resource="dms/api/v1/websites"
              sort={{ field: 'hostname', order: 'ASC' }}
              perPage={20}
              label="Website"
              filterToQuery={search => ({ hostname: { search } })}
              style={{ padding: 0 }}
            >
              <AutocompleteInput optionText="hostname" fullWidth />
            </ReferenceInput>
            <SelectInput
              required
              w={8}
              source="language"
              choices={[
                { id: 'en', name: 'English' },
                { id: 'ja', name: 'Japanese' },
              ]}
              fullWidth
            />
            <BooleanInput fullWidth source="localised" lg={4} />
            <SelectInput
              required
              w={6}
              source="frequency_every"
              label="Repeat every"
              choices={new Array(15)
                .fill('')
                .map((_, i) => ({ id: i + 1, name: `${i + 1}` }))}
              fullWidth
            />
            <SelectInput
              required
              w={6}
              source="frequency_unit"
              label="Unit"
              choices={[
                { id: 'days', name: 'Days' },
                { id: 'weeks', name: 'Weeks' },
              ]}
              onChange={e => setFrequencyUnit(e.target.value)}
              fullWidth
            />
            {frequencyUnit === 'weeks' && (
              <CheckboxGroupInput
                required
                w={12}
                label="Repeat on"
                source="frequency_on"
                choices={[
                  { id: 'monday', name: 'Monday' },
                  { id: 'tuesday', name: 'Tuesday' },
                  { id: 'wednesday', name: 'Wednesday' },
                  { id: 'thursday', name: 'Thursday' },
                  { id: 'friday', name: 'Friday' },
                  { id: 'saturday', name: 'Saturday' },
                  { id: 'sunday', name: 'Sunday' },
                ]}
              />
            )}
          </Validation>
        </Grid>
      );
    } else {
      return (
        <div w={12}>
          <Validation w={12}>
            <ReferenceInput
              w={12}
              source="website_id"
              reference="dms/api/v1/websites"
              resource="dms/api/v1/websites"
              sort={{ field: 'hostname', order: 'ASC' }}
              perPage={20}
              label="Website"
              filterToQuery={search => ({ hostname: { search } })}
              style={{ padding: 0 }}
              fullWidth
            >
              <AutocompleteInput optionText="hostname" fullWidth />
            </ReferenceInput>

            <SelectInput
              required
              w={6}
              source="frequency_unit"
              label="Frequency"
              defaultValue="once"
              choices={[
                { id: 'once', name: 'Once' },
                // { id: 'days', name: 'Days' },
                // { id: 'weeks', name: 'Weeks' },
              ]}
              onChange={e => setFrequencyUnit(e.target.value)}
              fullWidth
            />
            {frequencyUnit === 'days' && (
              <SelectInput
                required
                w={6}
                source="frequency_every"
                label="Repeat every"
                choices={new Array(15)
                  .fill('')
                  .map((_, i) => ({ id: i + 1, name: `${i + 1}` }))}
                fullWidth
              />
            )}
            {frequencyUnit === 'weeks' && (
              <CheckboxGroupInput
                required
                w={12}
                label="Repeat on"
                source="frequency_on"
                choices={[
                  { id: 'monday', name: 'Monday' },
                  { id: 'tuesday', name: 'Tuesday' },
                  { id: 'wednesday', name: 'Wednesday' },
                  { id: 'thursday', name: 'Thursday' },
                  { id: 'friday', name: 'Friday' },
                  { id: 'saturday', name: 'Saturday' },
                  { id: 'sunday', name: 'Sunday' },
                ]}
              />
            )}
            <TextInput
              w={12}
              source="topic"
              label="Topic (required)"
              required
              fullWidth
            />
            <TextInput
              w={12}
              source="description"
              label="Description (optional)"
              multiline
              resettable
              fullWidth
            />
            <Grid w={12}>
              <NumberInput
                source="number_subsections"
                label="Number of subsections generated by AI"
                defaultValue={4}
                min={2}
                fullWidth
                w={4}
              />
              <TextInput
                source="title"
                label="Title (optional)"
                w={4}
                fullWidth
              />
              <SelectInput
                w={4}
                fullWidth
                source="create_introduction"
                label="Introduction?"
                defaultValue="yes"
                choices={[
                  { id: 'yes', name: 'Yes' },
                  { id: 'no', name: 'No' },
                ]}
              />
            </Grid>
            <Grid w={12}>
              <NumberInput
                fullWidth
                min={3}
                source="paragraphs"
                label="Number of paragraphs per subsection (optional)"
              />
              <SelectInput
                fullWidth
                w={6}
                source="language"
                label="Language"
                defaultValue="English"
                choices={[
                  { id: 'English', name: 'English' },
                  { id: 'Japanese', name: 'Japanese' },
                  { id: 'Spanish', name: 'Spanish' },
                  { id: 'Dutch', name: 'Dutch' },
                  { id: 'Chinese', name: 'Chinese' },
                  { id: 'Arabic', name: 'Arabic' },
                  { id: 'French', name: 'French' },
                  { id: 'German', name: 'German' },
                  { id: 'Portuguese', name: 'Portuguese' },
                  { id: 'Russian', name: 'Russian' },
                  { id: 'Swedish', name: 'Swedish' },
                  { id: 'Indonesian', name: 'Indonesian' },
                  { id: 'Thai', name: 'Thai' },
                  { id: 'Hindi', name: 'Hindi' },
                  { id: 'Vietnamese', name: 'Vietnamese' },
                ]}
                onChange={e => setSelectedLanguage(e.target.value)}
              />
              {selectedLanguage !== 'Japanese' && (
                <SelectInput
                  fullWidth
                  source="tone"
                  label="Writing Tone"
                  w={6}
                  defaultValue="Professional"
                  choices={[
                    { id: 'Professional', name: 'Professional' },
                    { id: 'Formal', name: 'Formal' },
                    { id: 'Informal', name: 'Informal' },
                    { id: 'Academic', name: 'Academic' },
                    { id: 'Joyful', name: 'Joyful' },
                    { id: 'Neutral', name: 'Neutral' },
                    { id: 'Descriptive', name: 'Descriptive' },
                    { id: 'Narrative', name: 'Narrative' },
                    { id: 'Technical', name: 'Technical' },
                    { id: 'Journalistic', name: 'Journalistic' },
                    { id: 'Conversational', name: 'Conversational' },
                    { id: 'Humorous', name: 'Humorous' },
                    { id: 'Critical', name: 'Critical' },
                  ]}
                />
              )}

              <TextInput
                source="keywords"
                label="Keywords (optional)"
                fullWidth
              />
              <TextInput
                source="persona"
                label="Persona (optional)"
                fullWidth
              />
              <TextInput
                source="backlink"
                label="Backlink on article (optional)"
                fullWidth
              />
              <TextInput
                source="backlink_2"
                label="Backlink on article (optional)"
                fullWidth
              />
            </Grid>
          </Validation>
        </div>
      );
    }
  };

  return (
    <SimpleForm {...props} redirect={'show'} w={12} fullWidth save={handleSave}>
      <Grid fullWidth>
        <Validation w={12}>
          <SelectInput
            required
            w={12}
            source="form_type"
            label="Form Type"
            defaultValue="openAI"
            choices={[
              { id: 'narrativa', name: 'Narrativa' },
              { id: 'openAI', name: 'OpenAI' },
            ]}
            onChange={e => setFormType(e.target.value)}
            fullWidth
          />
          {renderForm()} {}
        </Validation>
      </Grid>
    </SimpleForm>
  );
};

export default Form;
