import { Tooltip } from '@material-ui/core';
import Moment from 'moment';

interface IProps {
  record: any;
  source: string;
  format?: string;
}

export default ({
  record,
  source,
  format = 'DD-MM-YYYY, HH:mm:ss',
}: IProps): JSX.Element => {
  const date = record[source];
  if (!date) {
    return <></>;
  }
  const formattedDate =
    parseInt(date, 10).toString().length === 10 ? date * 1000 : date;

  const utcDate = Moment.utc(formattedDate);
  const localDate = Moment(utcDate).local();

  return (
    <Tooltip title={Moment(localDate).fromNow()}>
      <span>{localDate.format(format)}</span>
    </Tooltip>
  );
};
