import { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import withStyles from '@material-ui/core/styles/withStyles';
import { TextField, CircularProgress, MenuItem } from '@material-ui/core';
import { getJson } from '../../../components/Helpers/API';
import withAbort from '../../../components/Helpers/withAbort';

const styles = theme => ({
  paper: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    paddingTop: theme.spacing(),
  },
});

class FakeWpVersion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      loading: false,
      response: null,
      type: '',
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ loading: true });
    const $this = this;
    const { id } = this.props.record;

    const formData = new FormData(event.target);
    const version = formData.get('version');

    getJson(
      `/dms/api/v1/wordpress/${id}/update-the-generator?version=${version}`,
      { signal: this.props.signal },
    )
      .then(response => {
        $this.setState({ response: response, loading: false });
      })
      .catch(e => {
        console.error(e);
        $this.setState({ response: e.message, loading: false });
      });
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({
      open: false,
      loading: false,
      response: null,
      type: '',
    });
  };

  render() {
    const { classes } = this.props;
    const { response, loading } = this.state;

    if (loading) {
      return (
        <div>
          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={this.state.open}
            onClose={this.handleClose}
          >
            <div className={classes.paper}>
              <CircularProgress />
            </div>
          </Modal>
        </div>
      );
    }

    if (response) {
      return (
        <div>
          <MenuItem onClick={this.handleOpen}>Fake Generator</MenuItem>
          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={this.state.open}
            onClose={this.handleClose}
          >
            <div className={classes.paper}>
              {this.state.response.split('\n').map((i, key) => {
                return <div key={key}>{i}</div>;
              })}
            </div>
          </Modal>
        </div>
      );
    }

    return (
      <div>
        <MenuItem onClick={this.handleOpen}>Fake Generator</MenuItem>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.open}
          onClose={this.handleClose}
        >
          <div className={classes.paper}>
            <form onSubmit={this.handleSubmit}>
              <div>
                <TextField
                  id="version"
                  label="Version (WordPress X.X.X)"
                  margin="normal"
                  name="version"
                />
              </div>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </form>
          </div>
        </Modal>
      </div>
    );
  }
}

FakeWpVersion.propTypes = {
  classes: PropTypes.object.isRequired,
  record: PropTypes.object.isRequired,
};

export default withAbort(withStyles(styles)(FakeWpVersion));
