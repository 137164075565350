import { memo } from 'react';
import { Card, CircularProgress, Typography } from '@material-ui/core';
import { OpenInNew } from '@material-ui/icons';
import { AdvancedTable } from '../../../../ui/molecules';
import HelpTooltip from '../HelpTooltip';
import Toolbar from '../Toolbar';
import useTopPages from './useTopPages';

const TopPages = ({ selectedMoneySite, comparison }) => {
  const { topPages, loaded } = useTopPages({
    selectedMoneySite,
    comparison,
  });

  const fields = [
    {
      key: 'pageTitle',
      label: 'Page Title',
      render: page => (
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '90% 10%',
            width: '100%',
            padding: '1px',
          }}
        >
          <div
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {page.pageTitle}{' '}
          </div>
          <OpenInNew
            onClick={() =>
              window.open(`${selectedMoneySite.url}${page.pagePath}`, '_blank')
            }
            style={{ fontSize: '1rem', marginLeft: 10, cursor: 'pointer' }}
          />
        </div>
      ),
    },
    {
      key: 'ctaClicks',
      label: (
        <div style={{ textAlign: 'center', width: '100px' }}>Clicks to CTA</div>
      ),
      render: row => <div style={{ textAlign: 'center' }}>{row.ctaClicks}</div>,
    },
    {
      key: 'value',
      label: (
        <div style={{ textAlign: 'center', width: '100px' }}>Active Users</div>
      ),
      render: row => <div style={{ textAlign: 'center' }}>{row.value}</div>,
    },
  ];

  if (!loaded) {
    return (
      <Card>
        <div style={{ padding: '24px', textAlign: 'center' }}>
          <CircularProgress />
        </div>
      </Card>
    );
  }

  if (loaded && topPages && !topPages.length) {
    return (
      <Card>
        <div style={{ padding: '24px', textAlign: 'center' }}>
          <Typography variant="h2">
            <span role="img" aria-label="no data">
              🤷‍♂️
            </span>
          </Typography>
          <Typography variant="h6">No top pages data</Typography>
        </div>
      </Card>
    );
  }

  return (
    <Card style={{ height: 450 }}>
      <div style={{ position: 'relative' }}>
        <Toolbar>
          <div>
            <Typography variant="h6">Top Pages</Typography>
          </div>
          <HelpTooltip
            source="Google Analytics APIs"
            description="Top 10 pages per views in the selected time range"
          />
        </Toolbar>
        <AdvancedTable
          items={topPages ? topPages.slice(0, 11) : []}
          fields={fields}
        />
      </div>
    </Card>
  );
};

export default memo(TopPages);
