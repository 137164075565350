import { Layout } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import Menu from '../Menu';
import { AppBar, Sidebar } from '../';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    maxWidth: '100%',
    minWidth: '100%',
  },
  content: {
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.up('xs')]: {
      paddingLeft: 25,
      position: 'relative',
      minWidth: 100,
    },
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
}));

export default (props: any): JSX.Element => {
  const classes = useStyles();
  return (
    <Layout
      {...props}
      appBar={AppBar}
      menu={Menu}
      sidebar={Sidebar}
      classes={classes}
    />
  );
};
