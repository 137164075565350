import { useMemo, useState } from 'react';
import { Collapse, Divider, IconButton, Typography } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import AgGrid from '../../../ui/organisms/AgGrid';
import { Grid } from '../../../ui/molecules';
import { columns } from './columns';
import { EditNotification } from './EditNotification';
import { DeleteNotification } from './DeleteNotification';

export function Notifications({ record }) {
  const [openNotifications, setOpenNotifications] = useState(false);

  const toggleNotifications = () => setOpenNotifications(!openNotifications);

  const actionColumns = useMemo(() => {
    return [
      {
        field: 'action',
        headerName: '',
        cellRenderer: ({ data }) => (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <DeleteNotification notification={data} record={record} />
            <EditNotification notification={data} record={record} />
          </div>
        ),
      },
    ];
  }, [record]);

  return (
    <Grid>
      <div style={{ paddingTop: '16px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h5">Notifications</Typography>
          <ExpandSection
            open={openNotifications}
            toggle={toggleNotifications}
          />
        </div>
        <Divider />
        <Collapse in={openNotifications} timeout="auto" unmountOnExit>
          <div style={{ paddingTop: '16px', paddingBottom: '16px' }}>
            {record.notifications?.length > 0 ? (
              <AgGrid
                fit
                search
                rows={record.notifications || []}
                columns={[...columns, ...actionColumns]}
              />
            ) : (
              <Typography variant="body1" align="center">
                No notifications found
              </Typography>
            )}
          </div>
        </Collapse>
      </div>
    </Grid>
  );
}

function ExpandSection({ open, toggle }) {
  const Icon = open ? ExpandLess : ExpandMore;
  return (
    <IconButton onClick={toggle}>
      <Icon />
    </IconButton>
  );
}
