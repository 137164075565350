import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

import { useSelector } from 'react-redux';
import { getUserData } from '../../store/selectors/getUserData';

const useStyle = makeStyles({
  userCard: {
    backgroundSize: 'cover',
    textAlign: 'center',
    minHeight: 200,
    marginBottom: 30,
  },
  userCardClosed: {
    backgroundSize: 'cover',
    textAlign: 'center',
    marginBottom: 10,
  },
  avatar: {
    border: '3px solid #fff',
    margin: '30px auto 10px',
    width: 120,
    height: 120,
  },
  avatarClosed: {
    border: '2px solid #fff',
    margin: '10px auto',
    width: 40,
    height: 40,
  },
  name: {
    marginBottom: 30,
    display: 'block',
  },
});

const UserCard = ({ open }) => {
  const classes = useStyle();
  const { name, picture } = useSelector(getUserData);
  return (
    <div className={open ? classes.userCard : classes.userCardClosed}>
      <span data-hj-suppress>
        <Avatar
          className={open ? classes.avatar : classes.avatarClosed}
          src={picture}
        />
      </span>
      {open ? <span data-hj-suppress>{name}</span> : ''}
    </div>
  );
};

export default UserCard;
