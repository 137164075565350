import { Button, makeStyles, Tooltip } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => ({
  urlField: {
    color: theme.palette.primary.dark,
    textDecoration: 'none',
    textTransform: 'initial',
    letterSpacing: '0.5px',
    border: '1px solid',
    padding: '3px 5px',
    whiteSpace: 'nowrap',
    maxWidth: 400,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'inline-block',
    verticalAlign: 'middle',
    '&:hover': {
      color: theme.palette.secondary.light,
      background: theme.palette.primary.dark,
    },
  },
}));

interface IProps {
  source: string;
  content?: string;
  action?: any;
  record: any;
}

export default (props: IProps) => {
  const content = props.content ? props.content : props.record[props.source];
  const classes = useStyles();

  if (content) {
    return (
      <Tooltip title={content}>
        <Button onClick={props.action || null} className={classes.urlField}>
          {content}
        </Button>
      </Tooltip>
    );
  }

  return null;
};
