import { makeStyles } from '@material-ui/core/styles';
import { DateCard } from '../../atoms';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-bewteen',
    height: '100%',
  },
}));

export default ({ record }: { record: any }): JSX.Element | null => {
  const classes = useStyles();

  if (record.created_at || record.updated_at) {
    let created_at = record.created_at;
    let updated_at = record.updated_at;
    if (created_at?.toString().length === 10) {
      created_at = created_at * 1000;
    }
    if (updated_at?.toString().length === 10) {
      updated_at = updated_at * 1000;
    }
    return (
      <div className={classes.wrapper}>
        {created_at && <DateCard label="Created at:" date={created_at} />}
        {updated_at && <DateCard label="Updated at:" date={updated_at} />}
      </div>
    );
  } else {
    return null;
  }
};
