import { useCallback } from 'react';

export default function useWpLogin() {
  const token = localStorage.getItem('id_token');
  const authType = localStorage.getItem('auth_type');
  const encodedToken = Buffer.from(`Bearer ${token}`).toString('base64');
  return useCallback(
    (wpUrl: string) => {
      if (wpUrl) {
        window.open(`${wpUrl}/wp-login.php?k=${encodedToken}&t=${authType}`);
      }
    },
    [authType, encodedToken],
  );
}
