import { cloneElement, Fragment } from 'react';
import { TopToolbar, CreateButton, ExportButton } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import CopyToClipboard from '../../../components/Helpers/CopyToClipboard';

interface IProps {
  bulkActions?: any;
  basePath: string;
  currentSort?: any;
  displayedFilters?: boolean;
  exporter?: any;
  filters?: JSX.Element;
  advancedFilters?: boolean;
  filterValues?: any;
  onUnselectItems?: any;
  resource?: any;
  selectedIds?: any;
  showFilter?: boolean;
  hasCreate?: boolean;
  listkey?: any;
  customActions?: JSX.Element[];
}

const useStyles = makeStyles({
  cardActions: {
    '& > *': {
      marginRight: 10,
    },
  },
});

export default ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  advancedFilters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  hasCreate,
  listkey,
  customActions = [],
}: IProps) => {
  const classes = useStyles();
  return (
    <TopToolbar className={classes.cardActions}>
      {bulkActions &&
        cloneElement(bulkActions, {
          basePath,
          filterValues,
          resource,
          selectedIds,
          onUnselectItems,
        })}
      {!advancedFilters &&
        filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}

      {hasCreate && <CreateButton label="" basePath={basePath} size="small" />}
      <ExportButton
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
        label=""
        maxResults={5000}
      />
      {/* Add your custom actions */}
      <CopyToClipboard
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        listkey={listkey}
        label=""
      />
      {customActions.map((Action: any, key: number) => (
        <Fragment key={`action-toolbar-${key}`}>{Action}</Fragment>
      ))}
    </TopToolbar>
  );
};
