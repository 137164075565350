import { useCallback, useEffect, useState } from 'react';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { useRefresh } from 'react-admin';
import { Settings } from '@material-ui/icons';
import { getJson } from '../../../components/Helpers/API';
import { useNotification } from '../../../hooks';

const ListArtisanCommands = ({ ms }: { ms: 'umms' | 'dms' }) => {
  const [commands, setCommands] = useState([]);
  const [loading, setLoading] = useState(false);
  const [el, setEl] = useState<HTMLElement | null>(null);
  const notify = useNotification();
  const refresh = useRefresh();

  const getCommands = useCallback(() => {
    getJson(`/${ms}/api/v1/artisan`, { method: 'GET' })
      .then(setCommands)
      .catch(() => notify({ text: 'Error fetching artisan commands' }));
  }, [ms, notify]);

  const onClickHandler = useCallback(
    (command: string) => {
      setLoading(true);
      setEl(null);
      getJson(`/${ms}/api/v1/artisan`, {
        method: 'POST',
        body: JSON.stringify({ command }),
      })
        .then(refresh)
        .catch(() => notify({ text: 'Error executing artisan command' }))
        .finally(() => setLoading(false));
    },
    [ms, refresh, notify],
  );

  useEffect(() => {
    getCommands();
  }, [getCommands]);

  return (
    <>
      <Button
        color="primary"
        onClick={e => setEl(e.currentTarget)}
        size="small"
        disabled={loading}
      >
        <Settings style={{ fontSize: 20, marginRight: 10 }} /> Actions
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={el ?? undefined}
        open={!!el}
        onClose={() => setEl(null)}
      >
        {commands.map(command => (
          <MenuItem
            key={command}
            value={command}
            onClick={() => onClickHandler(command)}
          >
            {command}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ListArtisanCommands;
