export default [
  {
    source: 'permalink',
    type: 'search',
    alwaysOn: true,
  },
  {
    source: 'redirect_to',
    type: 'search',
  },
  {
    source: 'website_id',
    type: 'referenceAutocomplete',
    reference: 'dms/api/v1/websites',
    resource: 'dms/api/v1/websites',
    sort: { field: 'hostname', order: 'ASC' },
    filterToQuery: { type: ['MONEY'] },
    label: 'Websites',
    optionText: 'hostname',
    alwaysOn: true,
  },
];
