import { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';

const alert = output => {
  if (output.success === false) {
    return { backgroundColor: 'orange' };
  }

  return {};
};

class GetWordpressData extends Component {
  render() {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Message</TableCell>
            <TableCell>Error(s)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.props.output &&
            this.props.output.map((row, index) => {
              return (
                <TableRow key={`resultRow_${index}`} style={alert(row.output)}>
                  <TableCell>{row.output.message}</TableCell>
                  <TableCell>{row.output.error || row.output.errors}</TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    );
  }
}

GetWordpressData.propTypes = {
  output: PropTypes.array.isRequired,
  header: PropTypes.string.isRequired,
};

export default GetWordpressData;
