import { Children, cloneElement, Component } from 'react';
import T from 'prop-types';
import { Datagrid } from 'react-admin';
import { refreshView } from 'react-admin';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import get from 'lodash/get';

import ColumnIcon from '@material-ui/icons/ViewColumn';
import Button from '@material-ui/core/Button';

import ScrollArrows from '../../ScrollArrows';
import SelectionDialog from './SelectionDialog';
import LocalStorage from './LocalStorage';

const arrayToSelection = values =>
  values.reduce((selection, columnName) => {
    selection[columnName] = true;
    return selection;
  }, {});

// CustomizableDatagrid allows to show/hide columns dynamically
// the preferences are stored in local storage
class CustomizableDatagrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpened: false,
      selection: this.getInitialSelection(),
      tempSelection: this.getInitialSelection(),
    };
  }

  getColumnNames() {
    const { children } = this.props;
    return filter(
      Children.map(children, field => get(field, ['props', 'source'])),
    );
  }

  getColumnLabels() {
    const { children } = this.props;
    return filter(
      Children.map(
        children,
        field =>
          field && {
            source: get(field, ['props', 'source']),
            label: get(field, ['props', 'label']),
          },
      ),
      item => item && item.source,
    );
  }

  getInitialSelection() {
    const { defaultColumns, resource, storage } = this.props;

    const previousSelection = storage.get(resource);

    // if we have a previously stored value, let's return it
    if (!isEmpty(previousSelection)) {
      return previousSelection;
    }

    // if defaultColumns are set let's return them
    if (!isEmpty(defaultColumns)) {
      return arrayToSelection(defaultColumns);
    }

    // otherwise we fallback on the default behaviour : display all columns
    return arrayToSelection(this.getColumnNames());
  }

  // updates the storage with the internal state value
  updateStorage = () => {
    this.props.storage.set(this.props.resource, this.state.selection);
  };

  handleConfirm = () => {
    this.setState({ selection: this.state.tempSelection }, () => {
      this.updateStorage();
      this.props.refreshView();
    });
  };

  toggleColumn = columnName => {
    const previousSelection = this.state.tempSelection;
    const tempSelection = {
      ...previousSelection,
      [columnName]: !previousSelection[columnName],
    };
    this.setState({ tempSelection });
  };

  handleOpen = () => this.setState({ modalOpened: true });
  handleClose = () =>
    this.setState({ modalOpened: false, tempSelection: this.state.selection });

  renderChild = child => {
    const source = get(child, ['props', 'source']);
    const { selection } = this.state;

    // Show children without source, or children explicitly visible
    if (!source || selection[source]) {
      return cloneElement(child, {});
    }

    return null;
  };

  scrollLeft = () => {
    this.scrollLeft = setInterval(() => {
      document.getElementById('scrollableTable').scrollLeft =
        document.getElementById('scrollableTable').scrollLeft - 1;
    }, 100);
  };

  scrollRight = () => {
    this.scrollRight = setInterval(() => {
      document.getElementById('scrollableTable').scrollLeft =
        document.getElementById('scrollableTable').scrollLeft + 1;
    }, 100);
  };

  clearScrolls = () => {
    clearInterval(this.scrollLeft);
    clearInterval(this.scrollRight);
  };

  render() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { children, defaultColumns, refreshView, ...rest } = this.props;
    const { modalOpened, tempSelection } = this.state;

    return (
      <div>
        <div style={{ float: 'right', marginRight: '1rem', marginTop: '1rem' }}>
          <Button
            aria-label="add"
            color="primary"
            size="small"
            onClick={this.handleOpen}
          >
            <ColumnIcon />
          </Button>
        </div>
        {modalOpened && (
          <SelectionDialog
            selection={tempSelection}
            columns={this.getColumnLabels()}
            onColumnClicked={this.toggleColumn}
            onConfirm={this.handleConfirm}
            onClose={this.handleClose}
          />
        )}
        <div
          id="scrollableTable"
          style={{ width: '100%', overflowX: 'auto', overflowY: 'hidden' }}
        >
          <ScrollArrows id="scrollableTable" />
          <Datagrid
            {...rest}
            body={this.props.body || undefined}
            children={Children.map(children, this.renderChild)}
          />
        </div>
      </div>
    );
  }
}

CustomizableDatagrid.propTypes = {
  defaultColumns: T.arrayOf(T.string),
  storage: T.shape({
    get: T.func.isRequired,
    set: T.func.isRequired,
  }),
};

CustomizableDatagrid.defaultProps = {
  defaultColumns: [],
  storage: LocalStorage,
};

export default connect(null, {
  refreshView,
})(CustomizableDatagrid);
