import { TextInput, SimpleForm, NumberInput, BooleanInput } from 'react-admin';
import { Grid, FormToolbar } from '../../ui/molecules';
import { Validation } from '../../ui/organisms';

export default props => (
  <SimpleForm {...props} redirect={'show'} w={12} toolbar={<FormToolbar />}>
    <Grid fullWidth>
      <Validation w={12}>
        <TextInput
          fullWidth={true}
          source="name"
          helperText="The plugin name"
          w={12}
        />
        <TextInput
          fullWidth={true}
          source="title"
          helperText="The plugin title"
          w={12}
        />
        <TextInput
          fullWidth={true}
          source="slug"
          w={12}
          helperText={
            <span>
              For non-wp.org plugins this is the whatever comes after
              https://wordpress.org/plugins/ in the plugin directory url (ex.
              https://wordpress.org/plugins/blog-clock = blog-clock). <br />
              For all the non-wp.org plugins this is the plugin file relative
              path (ex. plugin-directory/plugin-file-name.php)
            </span>
          }
        />
        <NumberInput source="auto_update" />
        <TextInput
          fullWidth={true}
          source="source"
          type="url"
          w={12}
          helperText={
            <span>
              This is only required for non-wp.org plugins, and it's the remote
              url where the .zip plugin archive can be found. To avoid possible
              upload your plugin to https://backups.dilantimedia.com/, then
              share url paste it here <br />
              (ex. https://backups.dilantimedia.com/s/JjWPqzxJs6EdQ6N).
            </span>
          }
        />
        <BooleanInput source="public" />
      </Validation>
    </Grid>
  </SimpleForm>
);
