import {
  TextInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
} from 'react-admin';
import { Grid, FormToolbar } from '../../ui/molecules';
import { Validation } from '../../ui/organisms';

export default props => (
  <SimpleForm {...props} redirect={'show'} w={12} toolbar={<FormToolbar />}>
    <Grid fullWidth>
      <Validation w={12}>
        <TextInput fullWidth source="ip_address" />
        <TextInput fullWidth source="location" />
        <TextInput fullWidth source="provider" />
        <ReferenceInput
          fullWidth
          source="server_id"
          resource="dms/api/v1/servers"
          reference="dms/api/v1/servers"
          sort={{ field: 'name', order: 'ASC' }}
          perPage={900}
          label="Server"
          allowEmpty
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
      </Validation>
    </Grid>
  </SimpleForm>
);
