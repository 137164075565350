import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Collapse,
} from '@material-ui/core';
import classnames from 'classnames';
import { getResources } from 'ra-core';
import DefaultIcon from '@material-ui/icons/ViewList';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

import { DashboardMenuItem, MenuItemLink } from 'react-admin';
import UserCard from '../../../components/Layout/MyUserCard';

import { ResourceType } from '../../../store/selectors/getResources';
import { getAdminUiData } from '../../../store/selectors/getAdminUiData';
import { getLocation } from '../../../store/selectors/getLocation';
import { useUserData } from '../../../hooks/useUserData';

const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  menuItem: {
    color: theme.palette.secondary.main,
    '& svg': {
      color: theme.palette.secondary.main,
    },
  },
  parentItem: {
    paddingRight: 16,
    paddingLeft: 16,
    color: theme.palette.secondary.main,
    '& svg': {
      color: theme.palette.secondary.main,
    },
  },
  parentItemText: {
    padding: 0,
  },
  parentWrapper: {
    padding: 0,
  },
  parentWrapperActive: {
    padding: 0,
    paddingBottom: 1,
    background: '#E1E5E9',
    boxShadow: '0px 0px 0px inset #000, 0px 1px 0px inset #000',
  },
  active: {
    color: '#fff!important',
    background: theme.palette.primary.main + '!important',
    paddingLeft: '18px',
    paddingRight: '18px',
    '& svg': {
      color: theme.palette.secondary.light,
    },
  },
}));

const Menu = ({
  className,
  dense,
  hasDashboard,
  onMenuClick,
  ...rest
}: any): JSX.Element => {
  const [parentItems, setParentItems]: any = useState({});
  const { groupedFullPermissions, full_roles } = useUserData();
  const [filteredResources, setFilterResources] = useState<ResourceType[]>([]);
  const classes = useStyles();
  const resources = useSelector(getResources);
  const { sidebarOpen: open } = useSelector(getAdminUiData);
  const { pathname } = useSelector(getLocation);

  useEffect(() => {
    const resource: any = filteredResources.find((r: any): boolean =>
      pathname.includes(r.name),
    );
    setParentItems((prev: any) =>
      Object.fromEntries(
        filteredResources
          .filter((r: any): boolean => r.options.parent)
          .map((r: any) => [
            r.options.parent,
            {
              open:
                prev[r.options.parent]?.open ||
                resource?.options.parent === r.options.parent,
            },
          ]),
      ),
    );
  }, [filteredResources, pathname]);

  useEffect(() => {
    if (full_roles && full_roles.find(r => r.name === 'ADMIN')) {
      setFilterResources(resources);
      return;
    }
    setFilterResources(
      resources.filter(
        r =>
          groupedFullPermissions &&
          groupedFullPermissions['/' + r.name] &&
          groupedFullPermissions['/' + r.name].GET,
      ),
    );
  }, [full_roles, groupedFullPermissions, resources]);

  const handleClick = (key: string): void => {
    setParentItems({
      ...parentItems,
      [key]: { ...parentItems[key], open: !parentItems[key].open },
    });
  };

  const closeParents = () => {
    Object.keys(parentItems).forEach(key => {
      setParentItems({
        ...parentItems,
        [key]: { ...parentItems[key], open: false },
      });
    });
  };

  return (
    <div className={classnames(classes.main, className)} {...rest}>
      <UserCard open={open} />
      {hasDashboard && (
        <DashboardMenuItem
          onClick={onMenuClick}
          className={classes.menuItem}
          activeClassName={classes.active}
        />
      )}
      {parentItems &&
        Object.keys(parentItems).map(key => (
          <List
            component="nav"
            key={key}
            className={
              parentItems[key].open
                ? classes.parentWrapperActive
                : classes.parentWrapper
            }
          >
            <ListItem
              button
              className={classes.parentItem}
              onClick={() => handleClick(key)}
            >
              <ListItemIcon>
                {parentItems[key].open ? <ExpandLess /> : <ExpandMore />}
              </ListItemIcon>
              <ListItemText
                className={classes.parentItemText}
                inset
                primary={key}
              />
            </ListItem>
            <Collapse in={parentItems[key].open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {filteredResources
                  .filter(
                    (r: any): boolean => r.hasList && r.options.parent === key,
                  )
                  .map(
                    (resource: any): JSX.Element => {
                      return (
                        <MenuItemLink
                          key={resource.name}
                          to={`/${resource.name}`}
                          primaryText={resource.options.label}
                          leftIcon={
                            resource.icon ? <resource.icon /> : <DefaultIcon />
                          }
                          onClick={onMenuClick}
                          dense={dense}
                          className={classes.menuItem}
                          activeClassName={classes.active}
                        />
                      );
                    },
                  )}
              </List>
            </Collapse>
          </List>
        ))}
      {filteredResources
        .filter((r: any): boolean => r.hasList && !r.options.parent)
        .map(
          (resource: any): JSX.Element => {
            return (
              <MenuItemLink
                key={resource.name}
                to={`/${resource.name}`}
                primaryText={resource.options.label}
                leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
                onClick={() => {
                  onMenuClick();
                  closeParents();
                }}
                dense={dense}
                className={classes.menuItem}
                activeClassName={classes.active}
              />
            );
          },
        )}
    </div>
  );
};

export default Menu;
