import { FunctionField, NumberField, TextField } from 'react-admin';
import { AdvancedFilter, List } from '../../ui/molecules';
import IsDisavowed from '../../components/IsDisavowed';

const filters = [
  {
    source: '_id',
    type: 'search',
    alwaysOn: true,
    label: 'Target',
  },
];

const AhrefsMetrics = props => {
  return (
    <List
      filters={<AdvancedFilter customfilters={filters} />}
      responsive={[
        'doc.Target',
        'doc.domain_rating',
        'doc.backlink_stats.live',
        'doc.backlink_stats.live_refdomains',
        'doc.metrics.org_keywords',
        'doc.metrics.org_traffic',
      ]}
      sort={{ field: 'doc.Target', order: 'ASC' }}
      listkey={'id'}
      {...props}
      advancedFilters
      defaultColumns={[
        'doc.Target',
        'doc.date',
        'doc.domain_rating',
        'doc.backlink_stats.live',
        'doc.backlink_stats.live_refdomains',
        'doc.metrics.org_keywords',
        'doc.metrics.org_traffic',
        'Disavowed',
      ]}
    >
      <TextField source="doc.Target" label="Target" />
      <NumberField source="doc.domain_rating" label="Domain Rating" />
      <NumberField source="doc.backlink_stats.live" label="Current Backlinks" />
      <NumberField
        source="doc.backlink_stats.all_time"
        label="All time Backlinks"
      />
      <NumberField
        source="doc.backlink_stats.live_refdomains"
        label="Current RefDomains"
      />
      <NumberField
        source="doc.backlink_stats.all_time_refdomains"
        label="All time RefDomains"
      />
      <NumberField source="doc.metrics.org_keywords" label="Org Keywords" />
      <NumberField source="doc.metrics.org_traffic" label="Org Traffic" />
      <NumberField source="doc.metrics.org_cost" label="Org Cost" />
      <NumberField source="doc.metrics.paid_keywords" label="Paid Keywords" />
      <NumberField source="doc.metrics.paid_traffic" label="Paid traffic" />
      <NumberField source="doc.metrics.paid_cost" label="Paid Cost" />
      <NumberField source="doc.metrics.paid_pages" label="Paid Pages" />
      <TextField source="doc.date" label="Date" />
      <FunctionField
        sortable={false}
        source="Disavowed"
        render={row => <IsDisavowed link={row.doc.Target} />}
      />
    </List>
  );
};

export default AhrefsMetrics;
