import { ReferenceField, TextField } from 'react-admin';
import React from 'react';
import { AdvancedFilter, List } from '../../ui/molecules';
import { DateField } from '../../ui/atoms';

const filters = [
  {
    source: 'hostname',
    type: 'search',
    alwaysOn: true,
    label: 'Hostname',
  },
];

const ServiceDiscoveryList = props => {
  return (
    <List
      filters={<AdvancedFilter customfilters={filters} />}
      responsive={[
        'type',
        'location',
        'hostname',
        'verified',
        'last_checked',
        'website_id',
      ]}
      sort={{ field: 'hotsname', order: 'ASC' }}
      listkey={'id'}
      {...props}
      advancedFilters
      defaultColumns={[
        'type',
        'location',
        'hostname',
        'verified',
        'updated_at',
        'created_at',
        'last_checked',
        'website_id',
      ]}
    >
      <TextField source="type" label="type" />
      <TextField source="location" label="location" />
      <TextField source="hostname" label="hostname" />
      <TextField source="verified" label="verified" />
      <DateField source="last_checked" />
      <DateField source="updated_at" />
      <DateField source="created_at" />
      <ReferenceField
        link="show"
        label="Website"
        reference="dms/api/v1/websites"
        source="website_id"
        allowEmpty
      >
        <TextField source="hostname" />
      </ReferenceField>
    </List>
  );
};

export default ServiceDiscoveryList;
