import { makeStyles, Theme, Typography } from '@material-ui/core';

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  label: {
    borderRadius: theme.spacing(0.3),
    background: theme.palette.primary.light,
    padding: '5px 10px',
  },
}));

export default ({ children }: { children: string }): JSX.Element => {
  const classes = useStyles();
  return (
    <Typography variant="caption" className={classes.label}>
      {children}
    </Typography>
  );
};
