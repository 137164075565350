import { Typography } from '@material-ui/core';
import dayjs from 'dayjs';

export const columns = [
  {
    field: 'keyword',
    headerName: 'Keyword',
    headerCheckboxSelection: true,
    checkboxSelection: true,
    showDisabledCheckboxes: true,
  },
  {
    field: 'search_engine',
    headerName: 'Search Engine',
    cellRenderer: ({ data }) => data.search_engine.name,
  },
  {
    field: 'search_type',
    headerName: 'Search Type',
    cellRenderer: ({ data }) => (data.search_type === 1 ? 'Desktop' : 'Mobile'),
  },
  {
    field: 'search_volume',
    headerName: 'Search Volume',
    cellRenderer: ({ data }) => data.search_volume.search_volume,
  },
  {
    field: 'ranks',
    headerName: 'Ranks',
    cellRenderer: ({ data }) => (
      <div>
        {data.ranks.map(rank => {
          return (
            <Typography component="div" key={rank.created_at}>
              <b>{rank.rank ?? 'N/A'}</b> -{' '}
              {dayjs(rank.created_at).format('DD MMM YYYY, HH:mm')}
            </Typography>
          );
        })}
      </div>
    ),
  },
];
