import { EditButton, TextField } from 'react-admin';
import { List } from '../../ui/molecules';

export default props => (
  <List
    responsive={['environment', 'variable', 'value']}
    sort={{ field: 'variable', order: 'DESC' }}
    listkey={'id'}
    {...props}
    defaultColumns={['environment', 'variable', 'value']}
  >
    <TextField source="environment" />
    <TextField source="variable" />
    <TextField source="value" />
    <EditButton />
  </List>
);
