import { useCallback, useEffect, useMemo, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { CloseRounded, NotificationImportant } from '@material-ui/icons';
import { Badge, Drawer, makeStyles, Tab, Tabs } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { getJson } from '../../../components/Helpers/API';
import { useNotification } from '../../../hooks';
import { useIntervalWithIdle } from '../../../hooks/useIsIdle';
import Notifications from './Notifications';
import Alerts from './Alerts';

export type NotificationProps = {
  id: string;
  created_at?: string;
  title: string;
  user_id: string;
  message: string;
  link?: string;
  read: 1 | 0;
  resolved?: 'yes' | 'no';
  type: 'WARNING' | 'INFO';
};

const useTabsStyle = makeStyles({
  flexContainer: {
    justifyContent: 'space-between',
  },
});

const useDrawerStyle = makeStyles({
  paper: {
    width: 400,
  },
});

const useBadgeStyle = makeStyles({
  root: {
    width: '100%',
    justifyContent: 'center',
  },
  anchorOriginTopRightRectangle: {
    top: '50%',
    right: '0%',
    minWidth: 18,
    height: 18,
  },
});

const NotificationsButton = (): JSX.Element => {
  const [notifications, setNotifications] = useState<NotificationProps[]>([]);
  const dashboardMoneySite = useSelector(
    (state: any) => state.selectedMoneySite,
  );
  const [alerts, setAlerts] = useState<NotificationProps[]>([]);
  const [repeat] = useIntervalWithIdle();
  const [openPanel, setOpenPanel] = useState(false);
  const [selectedTab, setSelectedTab] = useState('notifications');
  const isDashboard = useSelector(
    (state: any) => state.router.location.pathname === '/',
  );
  const notify = useNotification();
  const tabClasses = useTabsStyle();
  const drawerClasses = useDrawerStyle();
  const badgeClasses = useBadgeStyle();
  const unreadNotifications = useMemo(
    () => notifications.filter(notification => !notification.read),
    [notifications],
  );
  const unreadAlerts = useMemo(
    () => alerts.filter(alert => alert.resolved !== 'yes'),
    [alerts],
  );
  const [hasNewUnreadNotifications, setHasNewUnreadNotifications] = useState(
    false,
  );
  const [hasNewUnreadAlerts, setHasNewUnreadAlerts] = useState(false);

  const togglePanel = useCallback(() => {
    setOpenPanel(prev => !prev);
  }, []);

  const fetchNotifications = useCallback(async () => {
    await getJson('/nms/api/v1/notifications/me')
      .then(res => {
        if (res.data) {
          setNotifications(prevData => {
            setHasNewUnreadNotifications(
              res.data
                .filter(({ read }: NotificationProps) => !read)
                .some(
                  ({ id }: NotificationProps) =>
                    !prevData
                      .filter(({ read }: NotificationProps) => !read)
                      .map(({ id }) => id)
                      .includes(id),
                ),
            );
            return res.data;
          });
        } else {
          notify({
            text: 'Error fetching notifications',
            variant: 'error',
          });
        }
      })
      .catch(e => notify({ text: e.message, variant: 'error' }));
  }, [notify]);

  const fetchAlerts = useCallback(async () => {
    if (dashboardMoneySite) {
      await getJson(`/dms/api/v1/websites/${dashboardMoneySite}/notifications`)
        .then(res => {
          if (res.data) {
            setAlerts(prevData => {
              setHasNewUnreadAlerts(
                res.data
                  .filter(({ read }: NotificationProps) => !read)
                  .some(
                    ({ id }: NotificationProps) =>
                      !prevData
                        .filter(({ read }: NotificationProps) => !read)
                        .map(({ id }) => id)
                        .includes(id),
                  ),
              );
              return res.data;
            });
          } else {
            notify({
              text: 'Error fetching alerts',
              variant: 'error',
            });
          }
        })
        .catch(e => notify({ text: e.message, variant: 'error' }));
    }
  }, [dashboardMoneySite, notify]);

  const handleChangeTab = useCallback((_, tab) => setSelectedTab(tab), []);

  useEffect(() => {
    repeat(async () => {
      await fetchAlerts();
      await fetchNotifications();
    });
  }, [repeat, fetchNotifications, fetchAlerts]);

  useEffect(() => {
    if (unreadAlerts.length && hasNewUnreadAlerts && isDashboard) {
      setSelectedTab('alerts');
      setOpenPanel(true);
      return;
    }
    if (
      unreadNotifications.length &&
      hasNewUnreadNotifications &&
      isDashboard &&
      !hasNewUnreadAlerts
    ) {
      setSelectedTab('notifications');
      setOpenPanel(true);
    }
  }, [
    hasNewUnreadAlerts,
    hasNewUnreadNotifications,
    isDashboard,
    unreadAlerts,
    unreadNotifications,
  ]);

  return (
    <>
      <IconButton color="inherit" href="" onClick={togglePanel}>
        <Badge
          badgeContent={unreadNotifications.length + unreadAlerts.length}
          color="primary"
        >
          <NotificationImportant />
        </Badge>
      </IconButton>
      <Drawer
        anchor="right"
        open={openPanel}
        onClose={togglePanel}
        classes={drawerClasses}
      >
        <Tabs
          value={selectedTab}
          onChange={handleChangeTab}
          classes={tabClasses}
        >
          <Tab
            label={
              <Badge
                badgeContent={unreadNotifications.length}
                color="primary"
                classes={badgeClasses}
              >
                <div>Notifications</div>
              </Badge>
            }
            value="notifications"
          />

          {alerts.length > 0 && (
            <Tab
              label={
                <Badge
                  badgeContent={unreadAlerts.length}
                  color="primary"
                  classes={badgeClasses}
                >
                  <div>Alerts</div>
                </Badge>
              }
              value="alerts"
            />
          )}
          <div>
            <IconButton onClick={togglePanel}>
              <CloseRounded color="primary" />
            </IconButton>
          </div>
        </Tabs>
        {selectedTab === 'notifications' ? (
          <Notifications
            fetchNotifications={fetchNotifications}
            notifications={notifications}
            unreadNotifications={unreadNotifications}
          />
        ) : (
          <Alerts alerts={alerts} fetchAlerts={fetchAlerts} />
        )}
      </Drawer>
    </>
  );
};

export default NotificationsButton;
