export const constants = {
  WEBSITE_STATUSES: [
    { id: 'QUEUED_FOR_DEPLOYMENT', name: 'Queue for deployment' },
    { id: 'PENDING_DEVELOPMENT', name: 'Pending Development' },
    { id: 'LIVE', name: 'Live' },
    { id: 'NO_KOKOS', name: 'Bypass Kokos' },
    { id: 'DELETED', name: 'Website Deleted' },
  ],
  WEBSITE_TYPES: [
    { id: 'MONEY', name: 'Money Site' },
    { id: 'DIRECTORY', name: 'Website Directory' },
    { id: 'LINK', name: 'Link Site' },
    { id: 'SERVICE', name: 'Service Site' },
    { id: 'THIRD_PARTY', name: 'Third Party' },
  ],
  TLDS: [
    { id: 'com', name: 'COM' },
    { id: 'nl', name: 'NL' },
    { id: 'uk', name: 'UK' },
    { id: 'org', name: 'ORG' },
    { id: 'net', name: 'NET' },
    { id: 'eu', name: 'EU' },
    { id: 'de', name: 'DE' },
    { id: 'be', name: 'BE' },
    { id: 'in', name: 'IN' },
    { id: 'se', name: 'SE' },
    { id: 'info', name: 'INFO' },
    { id: 'ws', name: 'WS' },
    { id: 'fm', name: 'FM' },
    { id: 'jp', name: 'JP' },
  ],
  USER_TAGS: {
    languages: [
      { id: 'GE', name: 'German' },
      { id: 'EN_UK', name: 'English UK' },
      { id: 'EN_US', name: 'English US' },
      { id: 'NL', name: 'Dutch' },
      { id: 'FR', name: 'French' },
      { id: 'JP', name: 'Japanese' },
      { id: 'ES', name: 'Spanish' },
      { id: 'ES_CA', name: 'Spanish (Catalan)' },
      { id: 'ES_CO', name: 'Spanish (Colombian)' },
      { id: 'IT', name: 'Italian' },
    ],
  },
  TASK_STATUS: [
    { id: 'DRAFT', name: 'Draft' },
    { id: 'REJECTED', name: 'Rejected' },
    { id: 'NOT_PLUCKED', name: 'Not plucked' },
    { id: 'IN_PROGRESS', name: 'In progress' },
    { id: 'COMPLETED', name: 'Completed' },
    { id: 'APPROVED', name: 'Approved' },
    { id: 'ARCHIVED', name: 'Archived' },
  ],
  TASK_TYPE: [
    {
      id: 'TAG_LINKS',
      name: 'Tag linksites',
      data_type: 'website_ids',
      disabled: true,
    },
    {
      id: 'UPDATE_LINKS',
      name: 'Update directory links',
      data_type: 'website_ids',
      disabled: true,
    },
    {
      id: 'CREATE_POST',
      name: 'Content',
      data_type: 'post_ids',
    },
    { id: 'QUALITY_CHECK', name: 'QA', data_type: 'task_ids', disabled: true },
  ],
  CURRENCIES: [
    { id: 'EUR', name: 'EUR - Euro' },
    { id: 'USD', name: 'USD - United States Dollar' },
    { id: 'GBP', name: 'GBP - British Pound Sterling' },
    { id: 'AED', name: 'AED - United Arab Emirates Dirham' },
    { id: 'AFN', name: 'AFN - Afghan Afghani' },
    { id: 'ALL', name: 'ALL - Albanian Lek' },
    { id: 'AMD', name: 'AMD - Armenian Dram' },
    { id: 'ANG', name: 'ANG - Netherlands Antillean Guilder' },
    { id: 'AOA', name: 'AOA - Angolan Kwanza' },
    { id: 'ARS', name: 'ARS - Argentine Peso' },
    { id: 'AUD', name: 'AUD - Australian Dollar' },
    { id: 'AWG', name: 'AWG - Aruban Florin' },
    { id: 'AZN', name: 'AZN - Azerbaijani Manat' },
    { id: 'BAM', name: 'BAM - Bosnia-Herzegovina Convertible Mark' },
    { id: 'BBD', name: 'BBD - Barbadian Dollar' },
    { id: 'BDT', name: 'BDT - Bangladeshi Taka' },
    { id: 'BGN', name: 'BGN - Bulgarian Lev' },
    { id: 'BHD', name: 'BHD - Bahraini Dinar' },
    { id: 'BIF', name: 'BIF - Burundian Franc' },
    { id: 'BMD', name: 'BMD - Bermudan Dollar' },
    { id: 'BND', name: 'BND - Brunei Dollar' },
    { id: 'BOB', name: 'BOB - Bolivian Boliviano' },
    { id: 'BRL', name: 'BRL - Brazilian Real' },
    { id: 'BSD', name: 'BSD - Bahamian Dollar' },
    { id: 'BTC', name: 'BTC - Bitcoin' },
    { id: 'BTN', name: 'BTN - Bhutanese Ngultrum' },
    { id: 'BWP', name: 'BWP - Botswanan Pula' },
    { id: 'BYN', name: 'BYN - Belarusian Ruble' },
    { id: 'BZD', name: 'BZD - Belize Dollar' },
    { id: 'CAD', name: 'CAD - Canadian Dollar' },
    { id: 'CDF', name: 'CDF - Congolese Franc' },
    { id: 'CHF', name: 'CHF - Swiss Franc' },
    { id: 'CLF', name: 'CLF - Chilean Unit of Account (UF)' },
    { id: 'CLP', name: 'CLP - Chilean Peso' },
    { id: 'CNH', name: 'CNH - Chinese Yuan (Offshore)' },
    { id: 'CNY', name: 'CNY - Chinese Yuan' },
    { id: 'COP', name: 'COP - Colombian Peso' },
    { id: 'CRC', name: 'CRC - Costa Rican Colón' },
    { id: 'CUC', name: 'CUC - Cuban Convertible Peso' },
    { id: 'CUP', name: 'CUP - Cuban Peso' },
    { id: 'CVE', name: 'CVE - Cape Verdean Escudo' },
    { id: 'CZK', name: 'CZK - Czech Republic Koruna' },
    { id: 'DJF', name: 'DJF - Djiboutian Franc' },
    { id: 'DKK', name: 'DKK - Danish Krone' },
    { id: 'DOP', name: 'DOP - Dominican Peso' },
    { id: 'DZD', name: 'DZD - Algerian Dinar' },
    { id: 'EGP', name: 'EGP - Egyptian Pound' },
    { id: 'ERN', name: 'ERN - Eritrean Nakfa' },
    { id: 'ETB', name: 'ETB - Ethiopian Birr' },
    { id: 'FJD', name: 'FJD - Fijian Dollar' },
    { id: 'FKP', name: 'FKP - Falkland Islands Pound' },
    { id: 'GEL', name: 'GEL - Georgian Lari' },
    { id: 'GGP', name: 'GGP - Guernsey Pound' },
    { id: 'GHS', name: 'GHS - Ghanaian Cedi' },
    { id: 'GIP', name: 'GIP - Gibraltar Pound' },
    { id: 'GMD', name: 'GMD - Gambian Dalasi' },
    { id: 'GNF', name: 'GNF - Guinean Franc' },
    { id: 'GTQ', name: 'GTQ - Guatemalan Quetzal' },
    { id: 'GYD', name: 'GYD - Guyanaese Dollar' },
    { id: 'HKD', name: 'HKD - Hong Kong Dollar' },
    { id: 'HNL', name: 'HNL - Honduran Lempira' },
    { id: 'HRK', name: 'HRK - Croatian Kuna' },
    { id: 'HTG', name: 'HTG - Haitian Gourde' },
    { id: 'HUF', name: 'HUF - Hungarian Forint' },
    { id: 'IDR', name: 'IDR - Indonesian Rupiah' },
    { id: 'ILS', name: 'ILS - Israeli New Sheqel' },
    { id: 'IMP', name: 'IMP - Manx pound' },
    { id: 'INR', name: 'INR - Indian Rupee' },
    { id: 'IQD', name: 'IQD - Iraqi Dinar' },
    { id: 'IRR', name: 'IRR - Iranian Rial' },
    { id: 'ISK', name: 'ISK - Icelandic Króna' },
    { id: 'JEP', name: 'JEP - Jersey Pound' },
    { id: 'JMD', name: 'JMD - Jamaican Dollar' },
    { id: 'JOD', name: 'JOD - Jordanian Dinar' },
    { id: 'JPY', name: 'JPY - Japanese Yen' },
    { id: 'KES', name: 'KES - Kenyan Shilling' },
    { id: 'KGS', name: 'KGS - Kyrgystani Som' },
    { id: 'KHR', name: 'KHR - Cambodian Riel' },
    { id: 'KMF', name: 'KMF - Comorian Franc' },
    { id: 'KPW', name: 'KPW - North Korean Won' },
    { id: 'KRW', name: 'KRW - South Korean Won' },
    { id: 'KWD', name: 'KWD - Kuwaiti Dinar' },
    { id: 'KYD', name: 'KYD - Cayman Islands Dollar' },
    { id: 'KZT', name: 'KZT - Kazakhstani Tenge' },
    { id: 'LAK', name: 'LAK - Laotian Kip' },
    { id: 'LBP', name: 'LBP - Lebanese Pound' },
    { id: 'LKR', name: 'LKR - Sri Lankan Rupee' },
    { id: 'LRD', name: 'LRD - Liberian Dollar' },
    { id: 'LSL', name: 'LSL - Lesotho Loti' },
    { id: 'LYD', name: 'LYD - Libyan Dinar' },
    { id: 'MAD', name: 'MAD - Moroccan Dirham' },
    { id: 'MDL', name: 'MDL - Moldovan Leu' },
    { id: 'MGA', name: 'MGA - Malagasy Ariary' },
    { id: 'MKD', name: 'MKD - Macedonian Denar' },
    { id: 'MMK', name: 'MMK - Myanma Kyat' },
    { id: 'MNT', name: 'MNT - Mongolian Tugrik' },
    { id: 'MOP', name: 'MOP - Macanese Pataca' },
    { id: 'MRO', name: 'MRO - Mauritanian Ouguiya (pre-2018)' },
    { id: 'MRU', name: 'MRU - Mauritanian Ouguiya' },
    { id: 'MUR', name: 'MUR - Mauritian Rupee' },
    { id: 'MVR', name: 'MVR - Maldivian Rufiyaa' },
    { id: 'MWK', name: 'MWK - Malawian Kwacha' },
    { id: 'MXN', name: 'MXN - Mexican Peso' },
    { id: 'MYR', name: 'MYR - Malaysian Ringgit' },
    { id: 'MZN', name: 'MZN - Mozambican Metical' },
    { id: 'NAD', name: 'NAD - Namibian Dollar' },
    { id: 'NGN', name: 'NGN - Nigerian Naira' },
    { id: 'NIO', name: 'NIO - Nicaraguan Córdoba' },
    { id: 'NOK', name: 'NOK - Norwegian Krone' },
    { id: 'NPR', name: 'NPR - Nepalese Rupee' },
    { id: 'NZD', name: 'NZD - New Zealand Dollar' },
    { id: 'OMR', name: 'OMR - Omani Rial' },
    { id: 'PAB', name: 'PAB - Panamanian Balboa' },
    { id: 'PEN', name: 'PEN - Peruvian Nuevo Sol' },
    { id: 'PGK', name: 'PGK - Papua New Guinean Kina' },
    { id: 'PHP', name: 'PHP - Philippine Peso' },
    { id: 'PKR', name: 'PKR - Pakistani Rupee' },
    { id: 'PLN', name: 'PLN - Polish Zloty' },
    { id: 'PYG', name: 'PYG - Paraguayan Guarani' },
    { id: 'QAR', name: 'QAR - Qatari Rial' },
    { id: 'RON', name: 'RON - Romanian Leu' },
    { id: 'RSD', name: 'RSD - Serbian Dinar' },
    { id: 'RUB', name: 'RUB - Russian Ruble' },
    { id: 'RWF', name: 'RWF - Rwandan Franc' },
    { id: 'SAR', name: 'SAR - Saudi Riyal' },
    { id: 'SBD', name: 'SBD - Solomon Islands Dollar' },
    { id: 'SCR', name: 'SCR - Seychellois Rupee' },
    { id: 'SDG', name: 'SDG - Sudanese Pound' },
    { id: 'SEK', name: 'SEK - Swedish Krona' },
    { id: 'SGD', name: 'SGD - Singapore Dollar' },
    { id: 'SHP', name: 'SHP - Saint Helena Pound' },
    { id: 'SLL', name: 'SLL - Sierra Leonean Leone' },
    { id: 'SOS', name: 'SOS - Somali Shilling' },
    { id: 'SRD', name: 'SRD - Surinamese Dollar' },
    { id: 'SSP', name: 'SSP - South Sudanese Pound' },
    { id: 'STD', name: 'STD - São Tomé and Príncipe Dobra (pre-2018)' },
    { id: 'STN', name: 'STN - São Tomé and Príncipe Dobra' },
    { id: 'SVC', name: 'SVC - Salvadoran Colón' },
    { id: 'SYP', name: 'SYP - Syrian Pound' },
    { id: 'SZL', name: 'SZL - Swazi Lilangeni' },
    { id: 'THB', name: 'THB - Thai Baht' },
    { id: 'TJS', name: 'TJS - Tajikistani Somoni' },
    { id: 'TMT', name: 'TMT - Turkmenistani Manat' },
    { id: 'TND', name: 'TND - Tunisian Dinar' },
    { id: 'TOP', name: "TOP - Tongan Pa'anga" },
    { id: 'TRY', name: 'TRY - Turkish Lira' },
    { id: 'TTD', name: 'TTD - Trinidad and Tobago Dollar' },
    { id: 'TWD', name: 'TWD - New Taiwan Dollar' },
    { id: 'TZS', name: 'TZS - Tanzanian Shilling' },
    { id: 'UAH', name: 'UAH - Ukrainian Hryvnia' },
    { id: 'UGX', name: 'UGX - Ugandan Shilling' },
    { id: 'UYU', name: 'UYU - Uruguayan Peso' },
    { id: 'UZS', name: 'UZS - Uzbekistan Som' },
    { id: 'VEF', name: 'VEF - Venezuelan Bolívar Fuerte' },
    { id: 'VND', name: 'VND - Vietnamese Dong' },
    { id: 'VUV', name: 'VUV - Vanuatu Vatu' },
    { id: 'WST', name: 'WST - Samoan Tala' },
    { id: 'XAF', name: 'XAF - CFA Franc BEAC' },
    { id: 'XAG', name: 'XAG - Silver Ounce' },
    { id: 'XAU', name: 'XAU - Gold Ounce' },
    { id: 'XCD', name: 'XCD - East Caribbean Dollar' },
    { id: 'XDR', name: 'XDR - Special Drawing Rights' },
    { id: 'XOF', name: 'XOF - CFA Franc BCEAO' },
    { id: 'XPD', name: 'XPD - Palladium Ounce' },
    { id: 'XPF', name: 'XPF - CFP Franc' },
    { id: 'XPT', name: 'XPT - Platinum Ounce' },
    { id: 'YER', name: 'YER - Yemeni Rial' },
    { id: 'ZAR', name: 'ZAR - South African Rand' },
    { id: 'ZMW', name: 'ZMW - Zambian Kwacha' },
    { id: 'ZWL', name: 'ZWL - Zimbabwean Dollar' },
  ],
  COUNTRIES: [
    { id: '', name: '' },
    { id: 'Europe', name: 'Europe' },
    { id: 'Random', name: 'Random' },
    { id: 'USA', name: 'USA' },
    { id: 'Canada', name: 'Canada' },
    { id: 'GB', name: 'GB' },
    { id: 'UK', name: 'UK' },
    { id: 'Germany', name: 'Germany' },
    { id: 'France', name: 'France' },
    { id: 'Spain', name: 'Spain' },
    { id: 'Italy', name: 'Italy' },
    { id: 'Sweden', name: 'Sweden' },
    { id: 'Greece', name: 'Greece' },
    { id: 'Portugal', name: 'Portugal' },
    { id: 'Netherlands', name: 'Netherlands' },
    { id: 'Belgium', name: 'Belgium' },
    { id: 'Russia', name: 'Russia' },
    { id: 'Ukraine', name: 'Ukraine' },
    { id: 'Poland', name: 'Poland' },
    { id: 'Israel', name: 'Israel' },
    { id: 'Turkey', name: 'Turkey' },
    { id: 'Australia', name: 'Australia' },
    { id: 'Malaysia', name: 'Malaysia' },
    { id: 'Thailand', name: 'Thailand' },
    { id: 'South Korea', name: 'South Korea' },
    { id: 'Japan', name: 'Japan' },
    { id: 'Philippines', name: 'Philippines' },
    { id: 'Singapore', name: 'Singapore' },
    { id: 'China', name: 'China' },
    { id: 'Hong Kong', name: 'Hong Kong' },
    { id: 'Taiwan', name: 'Taiwan' },
    { id: 'India', name: 'India' },
    { id: 'Pakistan', name: 'Pakistan' },
    { id: 'Iran', name: 'Iran' },
    { id: 'Indonesia', name: 'Indonesia' },
    { id: 'Azerbaijan', name: 'Azerbaijan' },
    { id: 'Kazakhstan', name: 'Kazakhstan' },
    { id: 'UAE', name: 'UAE' },
    { id: 'Mexico', name: 'Mexico' },
    { id: 'Brazil', name: 'Brazil' },
    { id: 'Argentina', name: 'Argentina' },
    { id: 'Chile', name: 'Chile' },
    { id: 'Peru', name: 'Peru' },
    { id: 'Ecuador', name: 'Ecuador' },
    { id: 'Colombia', name: 'Colombia' },
    { id: 'South Africa', name: 'South Africa' },
    { id: 'Egypt', name: 'Egypt' },
    { id: 'Angola', name: 'Angola' },
    { id: 'Cameroon', name: 'Cameroon' },
    {
      id: 'Central African Republic',
      name: 'Central African Republic',
    },
    { id: 'Chad', name: 'Chad' },
    { id: 'Benin', name: 'Benin' },
    { id: 'Ethiopia', name: 'Ethiopia' },
    { id: 'Djibouti', name: 'Djibouti' },
    { id: 'Gambia', name: 'Gambia' },
    { id: 'Ghana', name: 'Ghana' },
    { id: 'Kenya', name: 'Kenya' },
    { id: 'Liberia', name: 'Liberia' },
    { id: 'Madagascar', name: 'Madagascar' },
    { id: 'Mali', name: 'Mali' },
    { id: 'Mauritania', name: 'Mauritania' },
    { id: 'Mauritius', name: 'Mauritius' },
    { id: 'Morocco', name: 'Morocco' },
    { id: 'Mozambique', name: 'Mozambique' },
    { id: 'Nigeria', name: 'Nigeria' },
    { id: 'Senegal', name: 'Senegal' },
    { id: 'Seychelles', name: 'Seychelles' },
    { id: 'Zimbabwe', name: 'Zimbabwe' },
    { id: 'South Sudan', name: 'South Sudan' },
    { id: 'Sudan', name: 'Sudan' },
    { id: 'Togo', name: 'Togo' },
    { id: 'Tunisia', name: 'Tunisia' },
    { id: 'Uganda', name: 'Uganda' },
    { id: 'Zambia', name: 'Zambia' },
    { id: 'Afghanistan', name: 'Afghanistan' },
    { id: 'Bahrain', name: 'Bahrain' },
    { id: 'Bangladesh', name: 'Bangladesh' },
    { id: 'Bhutan', name: 'Bhutan' },
    { id: 'Myanmar', name: 'Myanmar' },
    { id: 'Cambodia', name: 'Cambodia' },
    { id: 'Iraq', name: 'Iraq' },
    { id: 'Jordan', name: 'Jordan' },
    { id: 'Lebanon', name: 'Lebanon' },
    { id: 'Maldives', name: 'Maldives' },
    { id: 'Mongolia', name: 'Mongolia' },
    { id: 'Oman', name: 'Oman' },
    { id: 'Qatar', name: 'Qatar' },
    { id: 'Saudi Arabia', name: 'Saudi Arabia' },
    { id: 'Turkmenistan', name: 'Turkmenistan' },
    { id: 'Uzbekistan', name: 'Uzbekistan' },
    { id: 'Yemen', name: 'Yemen' },
    { id: 'Albania', name: 'Albania' },
    { id: 'Andorra', name: 'Andorra' },
    { id: 'Austria', name: 'Austria' },
    { id: 'Armenia', name: 'Armenia' },
    { id: 'Bosnia and Herzegovina', name: 'Bosnia and Herzegovina' },
    { id: 'Bulgaria', name: 'Bulgaria' },
    { id: 'Belarus', name: 'Belarus' },
    { id: 'Croatia', name: 'Croatia' },
    { id: 'Cyprus', name: 'Cyprus' },
    { id: 'Czech Republic', name: 'Czech Republic' },
    { id: 'Denmark', name: 'Denmark' },
    { id: 'Estonia', name: 'Estonia' },
    { id: 'Finland', name: 'Finland' },
    { id: 'Georgia', name: 'Georgia' },
    { id: 'Hungary', name: 'Hungary' },
    { id: 'Iceland', name: 'Iceland' },
    { id: 'Ireland', name: 'Ireland' },
    { id: 'Latvia', name: 'Latvia' },
    { id: 'Liechtenstein', name: 'Liechtenstein' },
    { id: 'Lithuania', name: 'Lithuania' },
    { id: 'Luxembourg', name: 'Luxembourg' },
    { id: 'Monaco', name: 'Monaco' },
    { id: 'Moldova', name: 'Moldova' },
    { id: 'Montenegro', name: 'Montenegro' },
    { id: 'Norway', name: 'Norway' },
    { id: 'Romania', name: 'Romania' },
    { id: 'Serbia', name: 'Serbia' },
    { id: 'Slovakia', name: 'Slovakia' },
    { id: 'Slovenia', name: 'Slovenia' },
    { id: 'Switzerland', name: 'Switzerland' },
    { id: 'Macedonia', name: 'Macedonia' },
    { id: 'Bahamas', name: 'Bahamas' },
    { id: 'Belize', name: 'Belize' },
    { id: 'Costa Rica', name: 'Costa Rica' },
    { id: 'Cuba', name: 'Cuba' },
    { id: 'Dominica', name: 'Dominica' },
    { id: 'Haiti', name: 'Haiti' },
    { id: 'Honduras', name: 'Honduras' },
    { id: 'Jamaica', name: 'Jamaica' },
    { id: 'Aruba', name: 'Aruba' },
    { id: 'Panama', name: 'Panama' },
    { id: 'Puerto Rico', name: 'Puerto Rico' },
    { id: 'Trinidad and Tobago', name: 'Trinidad and Tobago' },
    { id: 'Fiji', name: 'Fiji' },
    { id: 'New Zealand', name: 'New Zealand' },
    { id: 'Bolivia', name: 'Bolivia' },
    { id: 'Paraguay', name: 'Paraguay' },
    { id: 'Uruguay', name: 'Uruguay' },
    { id: "Côte d'Ivoire", name: "Côte d'Ivoire" },
    { id: 'Syria', name: 'Syria' },
    { id: 'Vietnam', name: 'Vietnam' },
    { id: 'Malta', name: 'Malta' },
  ],
  MARKETS: [
    { id: 'All', name: 'All' },
    { id: 'International', name: 'International' },
    { id: 'Germany', name: 'Germany' },
    { id: 'Sweden', name: 'Sweden' },
    { id: 'Thailand', name: 'Thailand' },
    { id: 'UK', name: 'UK' },
    { id: 'Netherlands', name: 'Netherlands' },
    { id: 'Spain', name: 'Spain' },
    { id: 'India', name: 'India' },
    { id: 'Japan', name: 'Japan' },
    { id: 'Colombia', name: 'Colombia' },
    { id: 'Italy', name: 'Italy' },
    { id: 'USA', name: 'USA' },
    { id: 'Vietnam', name: 'Vietnam' },
  ],
  SERVER_TYPES: [
    { id: 'Analytics', name: 'Analytics' },
    { id: 'Hosting', name: 'Hosting' },
    { id: 'Kokos', name: 'Kokos' },
    { id: 'Remote Desktop', name: 'Remote Desktop' },
    { id: 'Other', name: 'Other' },
  ],
  PRODUCTS: [
    {
      id: 'visitorcounterplugin',
      name: 'visitorcounterplugin',
      product_count: 13805,
    },
    {
      id: 'better_health',
      name: 'better_health',
      product_count: 8738,
    },
    {
      id: 'minimal_lite',
      name: 'minimal_lite',
      product_count: 6942,
    },
    {
      id: 'kumle',
      name: 'kumle',
      product_count: 6901,
    },
    {
      id: 'minimal_grid',
      name: 'minimal_grid',
      product_count: 5187,
    },
    {
      id: 'educational_way',
      name: 'educational_way',
      product_count: 5091,
    },
    {
      id: 'quality_construction',
      name: 'quality_construction',
      product_count: 5059,
    },
    {
      id: 'news_base',
      name: 'news_base',
      product_count: 5035,
    },
    {
      id: 'bloge',
      name: 'bloge',
      product_count: 4753,
    },
    {
      id: 'sparker',
      name: 'sparker',
      product_count: 4348,
    },
    {
      id: 'minimal_blocks',
      name: 'minimal_blocks',
      product_count: 4270,
    },
    {
      id: 'retina_blog',
      name: 'retina_blog',
      product_count: 4065,
    },
    {
      id: 'perfect_magazine',
      name: 'perfect_magazine',
      product_count: 3814,
    },
    {
      id: 'shree',
      name: 'shree',
      product_count: 3644,
    },
    {
      id: 'online_blog',
      name: 'online_blog',
      product_count: 3465,
    },
    {
      id: 'blog_kit',
      name: 'blog_kit',
      product_count: 2817,
    },
    {
      id: 'education_x',
      name: 'education_x',
      product_count: 2701,
    },
    {
      id: 'guava',
      name: 'guava',
      product_count: 2663,
    },
    {
      id: 'businessway',
      name: 'businessway',
      product_count: 2652,
    },
    {
      id: 'news_one',
      name: 'news_one',
      product_count: 2442,
    },
    {
      id: 'blogclock',
      name: 'blogclock',
      product_count: 2431,
    },
    {
      id: 'eduberg',
      name: 'eduberg',
      product_count: 2322,
    },
    {
      id: 'capacious',
      name: 'capacious',
      product_count: 2127,
    },
    {
      id: 'agency_plus',
      name: 'agency_plus',
      product_count: 2077,
    },
    {
      id: 'magazine_base',
      name: 'magazine_base',
      product_count: 2065,
    },
    {
      id: 'insurance_hub',
      name: 'insurance_hub',
      product_count: 1940,
    },
    {
      id: 'consultant_lite',
      name: 'consultant_lite',
      product_count: 1939,
    },
    {
      id: 'best_education',
      name: 'best_education',
      product_count: 1875,
    },
    {
      id: 'newie',
      name: 'newie',
      product_count: 1818,
    },
    {
      id: 'placid',
      name: 'placid',
      product_count: 1465,
    },
    {
      id: 'wishlist',
      name: 'wishlist',
      product_count: 1378,
    },
    {
      id: 'danfe',
      name: 'danfe',
      product_count: 1346,
    },
    {
      id: 'sewa',
      name: 'sewa',
      product_count: 1142,
    },
    {
      id: 'glowmag',
      name: 'glowmag',
      product_count: 1126,
    },
    {
      id: 'construction_kit',
      name: 'construction_kit',
      product_count: 1097,
    },
    {
      id: 'mitra',
      name: 'mitra',
      product_count: 903,
    },
    {
      id: 'nexas',
      name: 'nexas',
      product_count: 884,
    },
    {
      id: 'kotre',
      name: 'kotre',
      product_count: 867,
    },
    {
      id: 'ezy',
      name: 'ezy',
      product_count: 824,
    },
    {
      id: 'artblog',
      name: 'artblog',
      product_count: 613,
    },
    {
      id: 'ithemer',
      name: 'ithemer',
      product_count: 566,
    },
    {
      id: 'deft',
      name: 'deft',
      product_count: 547,
    },
    {
      id: 'easycurrencyconverter',
      name: 'easycurrencyconverter',
      product_count: 444,
    },
    {
      id: 'border-image',
      name: 'border-image',
      product_count: 270,
    },
    {
      id: 'minimal_lite_pro',
      name: 'minimal_lite_pro',
      product_count: 125,
    },
    {
      id: 'minimal_grid_pro',
      name: 'minimal_grid_pro',
      product_count: 111,
    },
    {
      id: 'news_base_pro',
      name: 'news_base_pro',
      product_count: 75,
    },
    {
      id: 'visitorsrightnow',
      name: 'visitorsrightnow',
      product_count: 43,
    },
    {
      id: 'minimal_blocks_pro',
      name: 'minimal_blocks_pro',
      product_count: 39,
    },
    {
      id: 'perfect_magazine_pro',
      name: 'perfect_magazine_pro',
      product_count: 36,
    },
    {
      id: 'write_blog_pro',
      name: 'write_blog_pro',
      product_count: 26,
    },
    {
      id: 'online_blog_pro',
      name: 'online_blog_pro',
      product_count: 25,
    },
    {
      id: 'magazine_base_pro',
      name: 'magazine_base_pro',
      product_count: 18,
    },
    {
      id: 'consultant_lite_pro',
      name: 'consultant_lite_pro',
      product_count: 18,
    },
    {
      id: 'education_x_pro',
      name: 'education_x_pro',
      product_count: 15,
    },
    {
      id: 'retina_blog_pro',
      name: 'retina_blog_pro',
      product_count: 10,
    },
    {
      id: 'best_education_pro',
      name: 'best_education_pro',
      product_count: 4,
    },
  ],
};

export const getConstantName = (constantsList: string, id: string) => {
  if (constants[constantsList]) {
    const constant = constants[constantsList].find((c: any) => c.id === id);
    if (constant && constant.name) {
      return constant.name;
    }
  }
  return null;
};

export const getConstantAsObj = (
  constantsArray: { id: string; name: string }[],
): any => {
  const obj = {};
  constantsArray.forEach(constant => {
    obj[constant.id] = constant.name;
  });
  return obj;
};
