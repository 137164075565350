import dayjs from 'dayjs';
import { UrlField } from '../../../../ui/atoms';

export const columns = [
  {
    field: 'added',
    headerName: 'Added',
    cellRenderer: ({ data }) =>
      dayjs(data.added * 1000).format('DD MMM YYYY, HH:mm'),
  },
  {
    field: 'backlink',
    headerName: 'From',
    cellRenderer: ({ data }) => {
      let backlink = data.backlink;
      try {
        backlink = decodeURI(backlink);
      } catch (e) {
        console.error(e);
      }
      return <UrlField record={{ s: backlink }} source="s" />;
    },
  },
  {
    field: 'anchor',
    headerName: 'Anchor Text',
  },
  {
    field: 'target_url',
    headerName: 'To',
    cellRenderer: ({ data }) => {
      let targetUrl = data.target_url;
      try {
        targetUrl = decodeURI(targetUrl);
      } catch (e) {
        console.error(e);
      }
      return <UrlField record={{ s: targetUrl }} source="s" />;
    },
  },
];
