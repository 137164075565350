import {
  TextInput,
  SimpleForm,
  SelectArrayInput,
  BooleanInput,
} from 'react-admin';
import { useGetList } from 'ra-core';
import { useState } from 'react';
import { Grid, FormToolbar } from '../../ui/molecules';
import { constants } from '../../constants';
import { Validation } from '../../ui/organisms';

const { USER_TAGS } = constants;

const Form = props => {
  const { record } = props;
  const [isFormValid, setIsFormValid] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const passwordMismatch =
    password && confirmPassword && password !== confirmPassword;
  const { data: roles, loaded } = useGetList('ums/api/v1/roles', {
    perPage: 999,
  });

  return (
    <SimpleForm
      {...props}
      redirect={'show'}
      w={12}
      toolbar={<FormToolbar disabled={!isFormValid || passwordMismatch} />}
    >
      <Grid fullWidth>
        <Validation w={12} onValidityChange={setIsFormValid}>
          <TextInput required source="name" label="Name" fullWidth />
          <TextInput required source="email" label="Email" fullWidth />
          <TextInput source="picture" label="Picture" type="url" fullWidth />
          {!record.google_id && (
            <TextInput
              w={6}
              required={!record.id}
              source="new_password"
              type="password"
              label="Password"
              onChange={e => setPassword(e.target.value)}
              fullWidth
            />
          )}
          {!record.google_id && (
            <TextInput
              w={6}
              required={!record.id}
              source="verify_password"
              type="password"
              label="Confirm Password"
              onChange={e => setConfirmPassword(e.target.value)}
              error={passwordMismatch || undefined}
              helperText={
                passwordMismatch ? "Password doesn't match" : undefined
              }
              fullWidth
            />
          )}
          <SelectArrayInput
            fullWidth
            source="tags.languages"
            label="User Language"
            choices={USER_TAGS.languages}
            w={12}
          />
          <SelectArrayInput
            fullWidth
            required
            source="roles"
            label="Roles"
            choices={loaded ? Object.values(roles) : []}
            w={6}
          />
          <SelectArrayInput
            fullWidth
            required
            source="target_markets"
            label="Target Markets"
            choices={constants.MARKETS}
            w={6}
          />
          <BooleanInput source="adult" label="Adult content" />
        </Validation>
      </Grid>
    </SimpleForm>
  );
};

export default Form;
