import { Dispatch, useCallback, useState, SetStateAction } from 'react';

type Props = {
  defaultPerPage?: number;
  setPagination?: Dispatch<
    SetStateAction<{
      page: number;
      perPage: number;
    }>
  >;
  pagination?: {
    page: number;
    perPage: number;
  };
};

export function usePagination({
  defaultPerPage = 10,
  setPagination,
  pagination,
}: Props) {
  const [perPage, setPerPage] = useState<number>(defaultPerPage);
  const [page, setPage] = useState<number>(0);
  const [count] = useState<number>(0);

  const handleChangePage = useCallback(
    (event: any, page: number): void => {
      setPage(page);
      setPagination && setPagination(p => ({ ...p, page }));
    },
    [setPagination],
  );

  const handleChangePerPage = useCallback(
    (e: any): void => {
      setPerPage(e.target.value);
      setPagination && setPagination(p => ({ ...p, perPage: e.target.value }));
    },
    [setPagination],
  );

  return {
    page: pagination?.page ?? page,
    perPage: pagination?.perPage ?? perPage,
    count,
    handleChangePage,
    handleChangePerPage,
  };
}
