import {
  RadioButtonGroupInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import { FormToolbar, Grid, ReferenceTagArrayInput } from '../../ui/molecules';
import { constants } from '../../constants';
import { Validation } from '../../ui/organisms';

export default props => {
  return (
    <SimpleForm {...props} redirect={'show'} w={12} toolbar={<FormToolbar />}>
      <Grid fullWidth>
        <Validation w={12}>
          <TextInput fullWidth={true} source="url" required />
          <TextInput
            fullWidth={true}
            label="IP Address (Only change if the real IP is hidden)"
            source="ip_address"
          />
          <SelectInput
            fullWidth={true}
            source="target_market"
            required
            choices={constants.MARKETS}
          />
          <TextInput source="friendly_name" fullWidth />
          <ReferenceInput
            filterToQuery={() => {}}
            source="user_id"
            resource="ums/api/v1/users"
            reference="ums/api/v1/users"
            sort={{ field: 'name', order: 'ASC' }}
            perPage={100}
            label="Product Owner"
            allowEmpty
            fullWidth
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <TextInput fullWidth={true} source="uptime_robot_keyword" />
          <ReferenceTagArrayInput lg={8} />
          <>
            <RadioButtonGroupInput
              fullWidth
              source="type"
              choices={constants.WEBSITE_TYPES}
            />
            <RadioButtonGroupInput
              fullWidth
              source="status"
              choices={constants.WEBSITE_STATUSES}
            />
          </>

          <>
            <div>Remote Desktop</div>
            <TextInput source="remote_desktop.ip_address" label="IP Address" />
            <TextInput source="remote_desktop.username" label="Username" />
            <TextInput source="remote_desktop.password" label="Password" />
            <TextInput source="remote_desktop.location" label="Location" />
            <TextInput
              source="remote_desktop.hosting_company"
              label="Hosting Company"
            />
          </>
          <>
            <div>Google Account Information</div>
            <TextInput source="google_account.username" label="Username" />
            <TextInput source="google_account.password" label="Password" />
            <TextInput source="google_account.first_name" label="First Name" />
            <TextInput source="google_account.last_name" label="Last Name" />
            <TextInput source="google_account.birthday" label="Birthday" />
            <TextInput
              source="google_account.phone_number"
              label="Phone Number"
            />
            <TextInput
              source="google_account.phone_number_provider"
              label="Phone N. Provider"
            />
            <TextInput source="google_account.notes" label="Notes" />
          </>
          <TextInput source="notes" fullWidth />
          <TextInput
            source="http_auth_credentials"
            label="Basic Auth Credentials"
            fullWidth
          />
          <TextInput
            source="no_new_backlinks_threshold"
            label="No new backlinks threshold (days)"
            fullWidth
          />
        </Validation>
      </Grid>
    </SimpleForm>
  );
};
