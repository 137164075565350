import { FunctionField, TextField } from 'react-admin';
import { IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { List } from '../../ui/molecules';

const OpenAiAction = () => (
  <Button
    component={Link}
    to="/dms/api/v1/narrativa/schedules/create"
    variant="contained"
    color="primary"
  >
    New
  </Button>
);

const OpenAiList = props => (
  <>
    <List
      {...props}
      bulkActionButtons={false}
      sort={{ field: 'updated_at', order: 'DESC' }}
      customActions={[<OpenAiAction />]}
    >
      <TextField source="frequency_unit" />
      <TextField source="language" />
      <TextField source="topic" />
      <FunctionField
        source="website_id"
        label="Website"
        render={row => (
          <>
            <TextField source="website.hostname" />
            <IconButton
              href={`#/dms/api/v1/websites/${row.website_id}/show`}
              onClick={e => e.stopPropagation()}
            >
              <Link />
            </IconButton>
          </>
        )}
      />
      <TextField source="ready" />
    </List>
  </>
);

export default OpenAiList;
