import React, { useState, MouseEvent, useMemo, useCallback } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  Divider,
  LinearProgress,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { ArtTrack } from '@material-ui/icons';
import DialogContent from '@material-ui/core/DialogContent';
import { useRefresh } from 'ra-core';
import { getJson } from '../../../components/Helpers/API';
import { useNotification } from '../../../hooks';

type Props = {
  record?: { id: number; runcloud_alias_id?: string | null; type: string };
};

const StaticMenu = ({ record }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [loading, setLoading] = useState(false);
  const refresh = useRefresh();
  const notify = useNotification();
  const isStatic = record?.runcloud_alias_id !== null;

  const handleMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFinally = useCallback(() => {
    setLoading(false);
    handleClose();
    refresh();
  }, [refresh]);

  const generateStaticWebsite = useCallback(async () => {
    setLoading(true);
    getJson(`/dms/api/v1/websites/convert-to-static/${record?.id}`)
      .then(res => notify({ text: res.message, variant: 'info' }))
      .catch(err => notify({ text: err, variant: 'error' }))
      .finally(handleFinally);
  }, [record?.id, notify, handleFinally]);

  const rollbackWebsite = useCallback(async () => {
    setLoading(true);
    getJson(`/dms/api/v1/websites/rollback-static/${record?.id}`)
      .then(res => notify({ text: res.message, variant: 'info' }))
      .catch(err => notify({ text: err, variant: 'error' }))
      .finally(handleFinally);
  }, [record?.id, notify, handleFinally]);

  const publishWebsite = useCallback(async () => {
    setLoading(true);
    getJson(`/dms/api/v1/websites/publish-static/${record?.id}`)
      .then(res => notify({ text: res.message, variant: 'info' }))
      .catch(err => notify({ text: err, variant: 'error' }))
      .finally(handleFinally);
  }, [record?.id, notify, handleFinally]);

  const actions = useMemo(() => {
    if (isStatic) {
      return [
        { onClick: rollbackWebsite, label: 'Roll Back to WP' },
        { onClick: publishWebsite, label: 'Publish Static Changes' },
      ];
    }

    return [{ onClick: generateStaticWebsite, label: 'Convert to Static' }];
  }, [generateStaticWebsite, isStatic, publishWebsite, rollbackWebsite]);

  if (record?.type !== 'LINK') {
    return null;
  }

  return (
    <>
      <Dialog open={loading}>
        <LinearProgress />
        <DialogTitle>Operation in progress</DialogTitle>
        <Divider />
        <DialogContent style={{ paddingBottom: 16 }}>
          The operation requested is in progress and can take up to a minute,
          please hold on.
        </DialogContent>
      </Dialog>
      <Button
        startIcon={
          loading ? (
            <CircularProgress color="inherit" size={15} />
          ) : (
            <ArtTrack />
          )
        }
        disabled={loading}
        color="primary"
        size="small"
        onClick={handleMenuClick}
      >
        Static
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {actions.map(action => (
          <MenuItem
            disabled={loading}
            key={action.label}
            onClick={action.onClick}
          >
            {action.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default StaticMenu;
