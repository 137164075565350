import dayjs from 'dayjs';
import DenseKeywords from '../DenseKeywords';

export const columns = [
  {
    field: 'keywords',
    headerName: 'Keywords',
    cellRenderer: ({ data }) => <DenseKeywords keywords={data.keywords} />,
    width: 80,
  },
  {
    field: 'email',
    headerName: 'Email',
  },
  {
    field: 'phone_number',
    headerName: 'Phone Number',
  },
  {
    field: 'updated_at',
    headerName: 'Updated At',
    cellRenderer: ({ data }) =>
      dayjs(data.updated_at * 1000).format('DD MMM YYYY, HH:mm'),
  },
  {
    field: 'created_at',
    headerName: 'Created At',
    cellRenderer: ({ data }) =>
      dayjs(data.created_at * 1000).format('DD MMM YYYY, HH:mm'),
  },
];
