import TextDiff from 'text-diff';
import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
} from '@material-ui/core';
import { FC } from 'react';
import { Warning } from './WarningsField';

type Props = {
  singleWarning: Warning;
};

const WarningItemDiff: FC<Props> = ({ singleWarning }) => {
  const formatError = (error: any) => {
    return `<span style="background: #e3e3e3; color: #222222; padding: 2px; font-family: monospace">${error}</span>`;
  };
  const renderDiff = () => {
    const Diff = new TextDiff();
    let text_diff = '';
    text_diff = Diff.main(singleWarning.expected, singleWarning.found);
    text_diff = Diff.prettyHtml(text_diff);
    return formatError(text_diff);
  };

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Expected</TableCell>
          <TableCell>Found</TableCell>
          <TableCell>Differences</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell
            dangerouslySetInnerHTML={{
              __html: `[${formatError(singleWarning.expected)}]`,
            }}
          />
          <TableCell
            dangerouslySetInnerHTML={{
              __html: `[${formatError(singleWarning.found)}]`,
            }}
          />
          <TableCell
            dangerouslySetInnerHTML={{ __html: `[${renderDiff()}]` }}
          />
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default WarningItemDiff;
