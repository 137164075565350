import { lazy } from 'react';
import { Show } from '../../ui/organisms';
import { TabbedShow, Toolbar } from '../../ui/molecules';
const ShowSummary = lazy(() => import('./ShowSummary'));
const PageChanges = lazy(() => import('./PageChanges'));

export default props => {
  return (
    <Show
      actions={
        <Toolbar
          list
          delete
          filterToList={window.sessionStorage.getItem('filterToList')}
        />
      }
      {...props}
    >
      <TabbedShow lazy {...props}>
        <ShowSummary {...props} label="Overview" />
        <PageChanges {...props} path="changes" label="Changes" />
      </TabbedShow>
    </Show>
  );
};
