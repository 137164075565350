import { createSelector } from 'reselect';
import { getGlobalState, TypeGlobalState } from './globalStateSelector';

export interface ErrorType {
  message: any;
  data: any;
}

export const getErrors = createSelector(
  getGlobalState,
  (globalState: TypeGlobalState): ErrorType => {
    return globalState.errorReducer;
  },
);
