import { lazy } from 'react';
import { TabbedIndex } from '../../ui/molecules';

const List = lazy(() => import('./List'));

export default props => (
  <TabbedIndex>
    <List name="Link providers" {...props} />
  </TabbedIndex>
);
