import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { refreshView } from 'ra-core';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  CircularProgress,
  MenuItem,
} from '@material-ui/core';
import { getJson } from '../../../components/Helpers/API';
import withAbort from '../../../components/Helpers/withAbort';

const DisplayOutput = props => {
  const { response } = props;
  const success = response.success === true ? 'true' : 'false';
  let content = response.message ?? response?.error?.message ?? null;
  if (response.cron) {
    content = (
      <Button
        color={'primary'}
        href={`/#/dms/api/v1/cron-jobs/${response.cron.id}/show`}
      >
        Check action status
      </Button>
    );
  }

  return (
    <div>
      <div>Success: {success}</div>
      <div>{content}</div>
    </div>
  );
};

class UpdateWpData extends Component {
  constructor(props) {
    super(props);

    this.state = {
      response: {},
      loading: false,
      open: false,
    };

    this.handleFetching = this.handleFetching.bind(this);
  }

  handleClose = (e, reason) => {
    if (reason !== 'backdropClick') {
      e.stopPropagation();
      this.setState({ open: false });
    }
  };

  handleFetching(e, type) {
    e.stopPropagation();
    this.setState({ open: true, loading: true });
    const { id } = this.props.record;
    const $this = this;

    getJson(`/dms/api/v1/wordpress/${id}/${type}`, {
      signal: this.props.signal,
    })
      .then(response => {
        $this.setState({
          loading: false,
          response: response,
        });
        $this.props.refreshView();
      })
      .catch(e => {
        console.error(e);
        $this.setState({
          loading: false,
          response: { message: `Error: ${e.message}` },
        });
      });
  }

  render() {
    const { loading, response, open } = this.state;
    return (
      <div>
        <MenuItem onClick={e => this.handleFetching(e, 'update-status')}>
          Refresh Status
        </MenuItem>
        <MenuItem onClick={e => this.handleFetching(e, 'update-sitemap')}>
          Refresh Sitemap
        </MenuItem>
        <MenuItem onClick={e => this.handleFetching(e, 'update-statistics')}>
          Refresh Statistics
        </MenuItem>
        <MenuItem
          onClick={e => this.handleFetching(e, 'refresh-outbound-links')}
        >
          Refresh Outbound Links
        </MenuItem>
        <MenuItem onClick={e => this.handleFetching(e, 'update-posts')}>
          Refresh Post Content
        </MenuItem>
        <MenuItem onClick={e => this.handleFetching(e, 'update-core')}>
          Upgrade WP Core
        </MenuItem>
        <Dialog disableEscapeKeyDown open={open} onClose={this.handleClose}>
          <DialogTitle>Refreshing Data</DialogTitle>
          <DialogContent>
            {loading ? (
              <CircularProgress />
            ) : (
              <DisplayOutput response={response} />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

UpdateWpData.propTypes = {
  record: PropTypes.object.isRequired,
};

export default connect(null, { refreshView })(withAbort(UpdateWpData));
