import { Component } from 'react';
import PropTypes from 'prop-types';
import { UnfoldMore, UnfoldLess } from '@material-ui/icons';
import { JsonToTable } from 'react-json-to-table';

const styles = {
  expanded: {
    position: 'relative',
    padding: '10px 26px 10px 10px',
    wordBreak: 'break-all',
    borderTop: '1px solid #CCCCCC',
    fontSize: '14px',
    boxShadow:
      '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    margin: '1px',
  },
  collapsed: {
    position: 'relative',
    padding: '10px 26px 10px 10px',
    wordBreak: 'break-all',
    borderTop: '1px solid #CCCCCC',
    fontSize: '14px',
    maxHeight: '60px',
    overflow: 'hidden',
    margin: '1px',
  },
  icon: {
    top: '2px',
    right: '2px',
    position: 'absolute',
  },
};

class Default extends Component {
  constructor(props) {
    super(props);

    // Set initial state
    this.state = { expanded: [] };

    // Binding this keyword
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(index, more) {
    let { expanded } = this.state;
    if (more) {
      expanded.push(index);
    } else {
      expanded = expanded.filter(item => item !== index);
    }
    this.setState({ expanded });
  }

  render() {
    const { expanded } = this.state;

    return (
      <div style={{ padding: '2px' }}>
        <div style={{ padding: '10px' }}>
          {this.props.header} ({this.props.output && this.props.output.length})
        </div>
        <div>
          {this.props.output &&
            this.props.output.map((row, index) => {
              return (
                <div key={`unfold${index}`}>
                  <div
                    style={
                      expanded.includes(index)
                        ? styles.expanded
                        : styles.collapsed
                    }
                    key={`resultRow_${index}`}
                  >
                    {row.output.message ? (
                      row.output.message
                    ) : (
                      <JsonToTable json={row.output} w={12} />
                    )}
                    <div style={styles.icon}>
                      {expanded.includes(index) ? (
                        <UnfoldLess
                          onClick={() => this.handleClick(index, false)}
                        />
                      ) : (
                        <UnfoldMore
                          onClick={() => this.handleClick(index, true)}
                        />
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}

Default.propTypes = {
  output: PropTypes.array.isRequired,
  header: PropTypes.string.isRequired,
};

export default Default;
