import { Avatar, Chip, makeStyles, Theme, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chipItem: {
    margin: theme.spacing(0.5),
  },
  chip: {
    width: '100%',
    justifyContent: 'space-between',
    '& span': {
      color: theme.palette.text.primary,
    },
  },
}));

export default ({
  children,
  color,
}: {
  children: JSX.Element | JSX.Element[];
  color?: 'primary' | 'secondary' | 'default';
}): JSX.Element | null => {
  const childrenList = Array.isArray(children) ? children : [children];
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      {childrenList.map(
        (child: JSX.Element, key: number): JSX.Element | null => {
          const { props } = child;

          if (props.content === null) {
            return null;
          }

          return (
            <div className={classes.chipItem} key={`chips-cloud-${key}`}>
              <Typography variant="body1">
                <Chip
                  className={classes.chip}
                  component="span"
                  color={color || 'primary'}
                  avatar={<Avatar component="span">{child}</Avatar>}
                  label={props.label + ' ' + props.content}
                />
              </Typography>
            </div>
          );
        },
      )}
    </div>
  );
};
