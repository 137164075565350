import { lazy } from 'react';
import { Show } from '../../ui/organisms';
import { TabbedShow } from '../../ui/molecules';
import Toolbar from '../../ui/molecules/Toolbar';

const ShowSummary = lazy(() => import('./ShowSummary'));
const Websites = lazy(() => import('./Websites'));

export default props => (
  <Show {...props} actions={<Toolbar list />}>
    <TabbedShow lazy {...props}>
      <ShowSummary {...props} label="Overview" />
      <Websites {...props} label="Websites" />
    </TabbedShow>
  </Show>
);
