import { stringify } from 'querystring';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core';
import { getJson } from '../../../../components/Helpers/API';
import {
  useCompareRange,
  useFormattedCompareDate,
} from '../../../../hooks/useCompareDate';

const useGATraffic = ({ selectedMoneySite, comparison }) => {
  const startDate = dayjs()
    .subtract(66, 'day')
    .format('YYYY-MM-DD');
  const compareDate = useFormattedCompareDate(comparison, 'YYYYMMDD');
  const rangeInDays = useCompareRange(comparison);
  const theme = useTheme();
  const [users, setUsers] = useState();
  const [sessions, setSessions] = useState();
  const [bounceRate, setBounceRate] = useState();
  const [activeUsers, setActiveUsers] = useState();
  const [sessionDuration, setSessionDuration] = useState();

  const [loaded, setLoaded] = useState(false);
  const [usersData, setUsersData] = useState([]);
  const [sessionsData, setSessionsData] = useState([]);
  const [bounceRateData, setBounceRateData] = useState([]);
  const [activeUsersData, setActiveUsersData] = useState([]);
  const [sessionDurationData, setSessionDurationData] = useState([]);

  const fetchData = useCallback(() => {
    setLoaded(false);
    getJson(
      `/analytics/api/v1/google-analytics/report/${
        selectedMoneySite.id
      }?${stringify({
        start_date: startDate,
        end_date: 'yesterday',
        metric: 'activeUsers',
      })}`,
    )
      .then(res => setUsersData(res.data || []))
      .catch(err => console.error(err));
    getJson(
      `/analytics/api/v1/google-analytics/report/${
        selectedMoneySite.id
      }?${stringify({
        start_date: startDate,
        end_date: 'yesterday',
        metric: 'newUsers',
      })}`,
    )
      .then(res => setActiveUsersData(res.data || []))
      .catch(err => console.error(err));
    getJson(
      `/analytics/api/v1/google-analytics/report/${
        selectedMoneySite.id
      }?${stringify({
        start_date: startDate,
        end_date: 'yesterday',
        metric: 'sessions',
      })}`,
    )
      .then(res => setSessionsData(res.data || []))
      .catch(err => console.error(err))
      .finally(() => setLoaded(true));
    getJson(
      `/analytics/api/v1/google-analytics/report/${
        selectedMoneySite.id
      }?${stringify({
        start_date: startDate,
        end_date: 'yesterday',
        metric: 'bounceRate',
      })}`,
    )
      .then(res => setBounceRateData(res.data || []))
      .catch(err => console.error(err));
    getJson(
      `/analytics/api/v1/google-analytics/report/${
        selectedMoneySite.id
      }?${stringify({
        start_date: startDate,
        end_date: 'yesterday',
        metric: 'averageSessionDuration',
      })}`,
    )
      .then(res => setSessionDurationData(res.data || []))
      .catch(err => console.error(err));
  }, [startDate, selectedMoneySite]);

  const generateData = useCallback(
    data => {
      const dataToDate = data.filter(
        d => Number(d.date) >= Number(compareDate),
      );
      const dataToCompare = data
        ? data.filter(d => {
            return (
              Number(dayjs(d.date).format('YYYYMMDD')) < Number(compareDate) &&
              Number(dayjs(d.date).format('YYYYMMDD')) >=
                Number(
                  dayjs(compareDate)
                    .subtract(rangeInDays, 'days')
                    .format('YYYYMMDD'),
                )
            );
          })
        : [];
      const avg =
        dataToDate.reduce((a, b) => {
          a = a + Number(b.value);
          return a;
        }, 0) / dataToDate.length;
      const avgToCompare =
        dataToCompare.reduce((a, b) => {
          a = a + Number(b.value);
          return a;
        }, 0) / dataToCompare.length;
      const dataSet = [
        ['date', 'value'],
        ...dataToDate.reduce(
          (a, b) => [...a, [dayjs(b.date).format('DD MMM'), Number(b.value)]],
          [],
        ),
      ];
      return [dataSet, avg, avgToCompare];
    },
    [compareDate, rangeInDays],
  );

  const generateTimeData = useCallback(
    data => {
      const dataToDate = data.filter(
        d => Number(d.date) >= Number(compareDate),
      );
      const dataToCompare = data
        ? data.filter(d => {
            return (
              Number(dayjs(d.date).format('YYYYMMDD')) < Number(compareDate) &&
              Number(dayjs(d.date).format('YYYYMMDD')) >=
                Number(
                  dayjs(compareDate)
                    .subtract(rangeInDays, 'days')
                    .format('YYYYMMDD'),
                )
            );
          })
        : [];
      const avg =
        dataToDate.reduce((a, b) => {
          a = a + Number(b.value);
          return a;
        }, 0) / dataToDate.length;
      const avgToCompare =
        dataToCompare.reduce((a, b) => {
          a = a + Number(b.value);
          return a;
        }, 0) / dataToCompare.length;
      const dataSet = [
        ['date', 'value', { role: 'tooltip', type: 'string' }],
        ...dataToDate.reduce(
          (a, b) => [
            ...a,
            [
              dayjs(b.date).format('DD MMM'),
              [Math.floor(Number(b.value / 60)), Number(b.value) % 60],
              `${dayjs(b.date).format('DD MMM')}\n${Math.floor(
                Number(b.value / 60),
              )}m ${Math.floor(Number(b.value) % 60)}s`,
            ],
          ],
          [],
        ),
      ];
      return [dataSet, avg, avgToCompare];
    },
    [compareDate, rangeInDays],
  );

  const generatePercData = useCallback(
    data => {
      const dataToDate = data.filter(
        d => Number(d.date) >= Number(compareDate),
      );
      const dataToCompare = data
        ? data.filter(d => {
            return (
              Number(dayjs(d.date).format('YYYYMMDD')) < Number(compareDate) &&
              Number(dayjs(d.date).format('YYYYMMDD')) >=
                Number(
                  dayjs(compareDate)
                    .subtract(rangeInDays, 'days')
                    .format('YYYYMMDD'),
                )
            );
          })
        : [];
      const avg =
        dataToDate.reduce((a, b) => {
          a = a + Number(b.value);
          return a;
        }, 0) / dataToDate.length;
      const avgToCompare =
        dataToCompare.reduce((a, b) => {
          a = a + Number(b.value);
          return a;
        }, 0) / dataToCompare.length;
      const dataSet = [
        ['date', 'value', { role: 'tooltip', type: 'string' }],
        ...dataToDate.reduce(
          (a, b) => [
            ...a,
            [
              dayjs(b.date).format('DD MMM'),
              Number(b.value) / 100,
              `${dayjs(b.date).format('DD MMM')}\n${Number(b.value).toFixed(
                2,
              )}%`,
            ],
          ],
          [],
        ),
      ];
      return [dataSet, avg, avgToCompare];
    },
    [compareDate, rangeInDays],
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    const [data, avg, avgToCompare] = generateData(usersData);
    setUsers({
      data,
      avg,
      avgToCompare,
      label: 'Users',
      shortLabel: 'Users',
      color: theme.palette.primary.main,
    });
  }, [usersData, generateData, theme.palette.primary.main]);

  useEffect(() => {
    const [data, avg, avgToCompare] = generateData(activeUsersData);
    setActiveUsers({
      data,
      avg,
      avgToCompare,
      label: 'Active Users',
      shortLabel: 'Act. Users',
      color: theme.palette.primary.main,
    });
  }, [activeUsersData, generateData, theme.palette.primary.main]);

  useEffect(() => {
    const [data, avg, avgToCompare] = generateTimeData(sessionDurationData);

    setSessionDuration({
      data,
      avg,
      avgToCompare,
      label: 'Session Duration (mm:ss)',
      shortLabel: 'Sess. Dur.',
      color: theme.palette.primary.main,
    });
  }, [sessionDurationData, generateTimeData, theme.palette.primary.main]);

  useEffect(() => {
    const [data, avg, avgToCompare] = generatePercData(bounceRateData);
    setBounceRate({
      data,
      avg,
      avgToCompare,
      label: 'Bounce Rate',
      shortLabel: 'Bounce Rate',
      color: theme.palette.primary.main,
      options: {
        vAxis: { format: 'percent' },
      },
      invertColors: true,
    });
  }, [bounceRateData, generatePercData, theme.palette.primary.main]);

  useEffect(() => {
    const [data, avg, avgToCompare] = generateData(sessionsData);
    setSessions({
      data,
      avg,
      avgToCompare,
      label: 'Sessions',
      shortLabel: 'Sessions',
      color: theme.palette.primary.main,
    });
  }, [sessionsData, generateData, theme.palette.primary.main]);

  return { sessionDuration, users, activeUsers, sessions, bounceRate, loaded };
};

export default useGATraffic;
