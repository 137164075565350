import { useMemo, memo, FC } from 'react';
import { ReferenceField, FunctionField } from 'react-admin';
import { WarningOutlined, Input } from '@material-ui/icons';
import Moment from 'moment';
import { Button, Tooltip } from '@material-ui/core';
import { UrlEnsure } from '../../../components/Helpers/utils';
import { Spacer } from '../../atoms';

type Props = {
  record: any;
};

const LastVisit: FC<Props> = ({ record }) => {
  const renderLink = useMemo(() => {
    if (!record.website_id) {
      return (
        <Tooltip title="Visit to refresh" placement="top">
          <Button
            href={UrlEnsure(record.hostname)}
            target="_blank"
            rel="noopener noreferrer"
            size="small"
            onClick={e => e.stopPropagation()}
          >
            <Input />
          </Button>
        </Tooltip>
      );
    }
    if (record.page && typeof record.page === 'string') {
      return (
        <Tooltip title="Visit to refresh" placement="top">
          <Button
            href={UrlEnsure(record.page)}
            target="_blank"
            rel="noopener noreferrer"
            size="small"
            onClick={e => e.stopPropagation()}
          >
            <Input />
          </Button>
        </Tooltip>
      );
    }
    return (
      <ReferenceField
        record={record}
        source="website_id"
        reference="tms/api/v1/websites"
        basePath="tms/api/v1/websites"
        allowEmpty
        link={'show'}
      >
        <FunctionField
          render={(recordRef: any) => {
            const page = recordRef.pages.find(
              (page: any) => page._id.$oid === record.page_id,
            );
            return (
              <Tooltip title="Visit to refresh" placement="top">
                <Button
                  href={
                    page && page.page
                      ? page.page
                      : UrlEnsure(recordRef.hostname)
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  size="small"
                  onClick={e => e.stopPropagation()}
                >
                  <Input />
                </Button>
              </Tooltip>
            );
          }}
        />
      </ReferenceField>
    );
  }, [record]);
  const lastUpdateDate = useMemo(
    () =>
      Moment(
        record.page_id && record.page
          ? record.page.last_modified * 1000
          : record.website && record.website
          ? record.website.last_modified * 1000
          : record.last_modified * 1000,
      ).fromNow(),
    [record.last_modified, record.page, record.page_id, record.website],
  );
  const isOld = Moment().diff(
    Moment(
      record.page_id && record.page
        ? record.page.last_modified * 1000
        : record.website && record.website
        ? record.website.last_modified * 1000
        : record.last_modified * 1000,
    ),
    'hours',
  );

  if (Math.abs(isOld) > 168) {
    //IS OLDER THEN A WEEK
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <WarningOutlined color="primary" />
        <Spacer width={10} />
        {lastUpdateDate}
        <Spacer width={10} />
        {renderLink}
      </div>
    );
  }
  return <div>{lastUpdateDate}</div>;
};

export default memo(LastVisit);
