import { Fragment, useEffect, useState } from 'react';
import { Button } from 'react-admin';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Select,
  MenuItem,
  LinearProgress,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useRefresh } from 'ra-core';
import withAbort from '../../components/Helpers/withAbort';
import { getJson } from '../../components/Helpers/API';

const MergePlugins = props => {
  const [isOpen, setIsOpen] = useState(false);
  const [mainId, setMainId] = useState(null);
  const [merging, setMerging] = useState(false);
  const refresh = useRefresh();
  const plugins = useSelector(
    state => state.admin.resources[props.resource].data,
  );

  const handleClick = () => {
    setIsOpen(true);
  };

  const handleDialogClose = (_, reason) => {
    if (reason !== 'backdropClick') {
      setIsOpen(false);
    }
  };
  const handleMerge = () => {
    const filtered = props.selectedIds.filter(value => {
      return value !== mainId;
    });
    const query = filtered.join(',');
    setMerging(true);
    getJson(`/dms/api/v1/plugins/${mainId}/merge?ids=${query}`, {
      method: 'POST',
    })
      .catch(e => console.error(e))
      .finally(() => {
        setMerging(false);
        setIsOpen(false);
        refresh();
        document.querySelector('button[title="Unselect"]').click();
      });
  };

  const handleSelect = e => {
    setMainId(e.target.value);
  };

  useEffect(() => {
    setMainId(props.selectedIds[0]);
  }, [props.selectedIds]);

  return (
    <Fragment>
      <Button label="Merge" onClick={handleClick} />
      {isOpen ? (
        <Dialog disableEscapeKeyDown open onClose={handleDialogClose}>
          <DialogTitle>Merge Plugins</DialogTitle>
          <DialogContent>
            <p>Select which plugin stays still</p>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={mainId}
              onChange={handleSelect}
            >
              {props.selectedIds.map(id => (
                <MenuItem key={id} value={id}>
                  {plugins[id].slug}
                </MenuItem>
              ))}
            </Select>
            {merging ? <LinearProgress /> : null}
          </DialogContent>
          <DialogActions>
            <Button disabled={merging} label="Merge" onClick={handleMerge} />
            <Button label="Cancel" onClick={handleDialogClose} />
          </DialogActions>
        </Dialog>
      ) : null}
    </Fragment>
  );
};

export default withAbort(MergePlugins);
