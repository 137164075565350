import { useEffect, useState } from 'react';
import {
  DateInput,
  TextInput,
  SimpleForm,
  BooleanInput,
  AutocompleteInput,
} from 'react-admin';
import { Grid, FormToolbar } from '../../ui/molecules';
import { Validation } from '../../ui/organisms';
import { constants } from '../../constants';

export default props => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [duration, setDuration] = useState('');
  const [permanent, setPermanent] = useState(false);
  const [selfCreated, setSelfCreated] = useState(false);
  const relOptions = [
    { id: 'follow', name: 'follow' },
    { id: 'nofollow', name: 'nofollow' },
    { id: 'sponsored', name: 'sponsored' },
    { id: 'ugc', name: 'ugc' },
  ];

  useEffect(() => {
    if (props.record.permanent) {
      setPermanent(props.record.permanent);
    }
  }, [props.record]);

  useEffect(() => {
    if (startDate && duration) {
      const start = new Date(startDate).getTime();
      const duration_time = duration * 24 * 60 * 60 * 1000;
      const end = new Date(start + duration_time);
      setEndDate(
        end.getFullYear() + '-' + (end.getMonth() + 1) + '-' + end.getDate(),
      );
    }
  }, [startDate, duration]);

  return (
    <SimpleForm
      {...props}
      redirect={'show'}
      w={12}
      toolbar={
        <FormToolbar
          enableDuplicate
          changedFields={{
            backlink: '2_' + props.record.backlink,
          }}
        />
      }
    >
      <Grid fullWidth>
        <Validation w={12}>
          <TextInput
            fullWidth
            source="backlink"
            lg={3}
            md={3}
            xs={12}
            helperText="URL of the page hosting the backlink"
          />
          <TextInput
            fullWidth
            source="anchor"
            lg={3}
            md={3}
            xs={12}
            helperText="Backlink anchor text"
          />
          <TextInput
            fullWidth
            source="url"
            lg={3}
            md={3}
            xs={12}
            helperText="URL of our site being linked"
          />
          <AutocompleteInput
            fullWidth
            choices={relOptions}
            source="rel_attribute"
            helperText="Select a rel attribute"
            lg={3}
            md={3}
            xs={12}
          />
          <TextInput fullWidth source="price" lg={3} md={3} xs={12} />
          <AutocompleteInput
            fullWidth
            choices={constants.CURRENCIES}
            source="currency"
            lg={4}
            md={4}
            xs={12}
          />
          <Grid lg={4} md={4} xs={12}>
            <BooleanInput
              fullWidth
              label="Permanent"
              source="permanent"
              lg={6}
              md={6}
              onChange={e => setPermanent(e)}
            />
            <BooleanInput
              fullWidth
              label="Self Created"
              source="self_created"
              lg={6}
              md={2}
              onChange={e => setSelfCreated(e)}
            />
          </Grid>
          <Grid fullWidth w={12}>
            <DateInput
              fullWidth
              source="start_date"
              lg={4}
              md={4}
              onChange={(e, r) => setStartDate(r)}
            />
            {!permanent && (
              <>
                <TextInput
                  fullWidth
                  disabled={permanent}
                  type="number"
                  source="duration"
                  label="Duration (Days)"
                  lg={4}
                  md={4}
                  onChange={(e, r) => setDuration(r)}
                />
                <DateInput
                  disabled
                  fullWidth
                  source="end_date"
                  lg={4}
                  md={4}
                  value={endDate}
                  defaultValue={endDate}
                />
              </>
            )}
          </Grid>
          {!selfCreated && (
            <Grid fullWidth w={12}>
              <TextInput
                fullWidth
                source="contact.name"
                lg={4}
                md={4}
                xs={12}
              />
              <TextInput
                fullWidth
                source="contact.email"
                lg={4}
                md={4}
                xs={12}
              />
              <TextInput
                fullWidth={true}
                source="contact.im"
                lg={4}
                md={4}
                xs={12}
              />
            </Grid>
          )}

          <TextInput fullWidth={true} source="notes" w={12} />
        </Validation>
      </Grid>
    </SimpleForm>
  );
};
