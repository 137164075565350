export default [
  {
    source: 'name',
    type: 'search',
    alwaysOn: true,
  },
  {
    source: 'total_installs',
    type: 'min',
  },
  {
    source: 'total_installs',
    type: 'max',
  },
  {
    source: 'active_installs',
    type: 'min',
  },
  {
    source: 'active_installs',
    type: 'max',
  },
  {
    source: 'inactive_installs',
    type: 'min',
  },
  {
    source: 'inactive_installs',
    type: 'max',
  },
  {
    source: 'author',
    type: 'search',
  },
];
