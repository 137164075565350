export default (props: any, plainText: boolean): JSX.Element | null => {
  const data = props.content || (props.record && props.record[props.source]);
  if (!data) {
    return null;
  }
  const readableData = data
    .split('_')
    .join(' ')
    .toLowerCase();

  return plainText ? (
    readableData
  ) : (
    <span style={{ textTransform: 'capitalize' }}>{readableData}</span>
  );
};
