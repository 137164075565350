import {
  ReferenceInput,
  SelectInput,
  TextInput,
  SimpleForm,
  AutocompleteInput,
} from 'react-admin';
import { Grid, FormToolbar } from '../../ui/molecules';
import { Validation } from '../../ui/organisms';

export default props => {
  const filterToList = window.sessionStorage.getItem('filterToList');
  return (
    <SimpleForm
      {...props}
      redirect={'show'}
      w={12}
      toolbar={<FormToolbar filterToList={filterToList} />}
    >
      <Grid fullWidth>
        <Validation w={12}>
          <TextInput
            source="casino_name"
            label="Casino Name"
            w={12}
            fullWidth
          />
          <TextInput
            fullWidth={true}
            source="permalink"
            label="Permalink"
            required
            w={6}
          />
          <TextInput source="redirect_to" label="Redirect to" w={6} fullWidth />
          <ReferenceInput
            w={6}
            source="website_id"
            reference="dms/api/v1/websites"
            resource="dms/api/v1/websites"
            sort={{ field: 'hostname', order: 'ASC' }}
            perPage={20}
            label="Website"
            filterToQuery={search => ({
              hostname: { search },
              type: ['MONEY'],
            })}
            style={{ padding: 0 }}
          >
            <AutocompleteInput optionText="hostname" fullWidth />
          </ReferenceInput>
          <SelectInput
            w={6}
            source="redirection_type"
            label="Redirection Type"
            choices={[
              { id: 301, name: '301' },
              { id: 302, name: '302' },
            ]}
            fullWidth
          />
        </Validation>
      </Grid>
    </SimpleForm>
  );
};
