import { IconButton, Tooltip } from '@material-ui/core';
import { useCallback } from 'react';

export default (props: any): JSX.Element => {
  const redirect = useCallback(
    e => {
      e.stopPropagation();
      if (
        props?.record?.runcloud_server_id &&
        props?.record?.runcloud_webapp_id
      ) {
        window.open(
          `https://new.runcloud.io/servers/${props.record.runcloud_server_id}/webapplications/${props.record.runcloud_webapp_id}/dashboard`,
        );
      } else if (
        props.record.runcloud_server_id &&
        !props.record.runcloud_webapp_id
      ) {
        window.open(
          `https://new.runcloud.io/servers/${props.record.runcloud_server_id}/summary`,
        );
      }
    },
    [props?.record?.runcloud_server_id, props?.record?.runcloud_webapp_id],
  );
  return (
    <Tooltip title="Runcloud">
      <IconButton size="small" onClick={redirect}>
        <img
          src="https://manage.runcloud.io/favicon-196x196.png"
          width="24px"
        />
      </IconButton>
    </Tooltip>
  );
};
