import { List } from '../../ui/molecules';
import WpLoginField from '../../components/Helpers/WpLoginField';

const SimpleList = props => {
  return (
    <List
      rowClick={false}
      bulkActionButtons={false}
      responsive={['hostname']}
      listkey={'hostname'}
      {...props}
      defaultColumns={['hostname']}
    >
      <WpLoginField
        style={{ cursor: 'pointer', fontWeight: 'bold' }}
        label="hostname"
        urlField="url"
        {...props}
      />
    </List>
  );
};

export default SimpleList;
