import { useEffect, useState } from 'react';

const ContentPreview = props => {
  const [chunks, setChunks] = useState(props.record && props.record.content);

  useEffect(() => {
    if (props.record && props.record.content && props.searchedContent) {
      setChunks(
        props.record.content
          .split(props.searchedContent)
          .join(
            `<b style="background: #f5ff56; padding: 5px; color: #000">${props.searchedContent}</b>`,
          ),
      );
      return;
    }
    setChunks(props.record && props.record.content);
  }, [props.record, props.searchedContent]);

  return (
    <div>
      {chunks ? (
        <div dangerouslySetInnerHTML={{ __html: chunks }} />
      ) : (
        'No content visible'
      )}
    </div>
  );
};

export default ContentPreview;
