export default [
  {
    field: 'Target',
    headerName: 'Target',
  },
  {
    field: 'date',
    headerName: 'Date',
  },
  {
    field: 'domain_rating',
    headerName: 'DR',
  },
  {
    field: 'backlink_stats.live',
    headerName: 'Backlinks Live',
    cellRenderer: ({ data }) => data.backlink_stats.live ?? 'N/A',
  },
  {
    field: 'backlink_stats.all_time',
    headerName: 'Backlinks All Time',
    cellRenderer: ({ data }) => data.backlink_stats.all_time ?? 'N/A',
  },
  {
    field: 'backlink_stats.live_refdomains',
    headerName: 'Backlinks Live RefDomains',
    cellRenderer: ({ data }) => data.backlink_stats.live_refdomains ?? 'N/A',
  },
  {
    field: 'backlink_stats.all_time_refdomains',
    headerName: 'Backlinks All Time RefDomains',
    cellRenderer: ({ data }) =>
      data.backlink_stats.all_time_refdomains ?? 'N/A',
  },
  {
    field: 'metrics.org_keywords',
    headerName: 'Metrics Org Keywords',
    cellRenderer: ({ data }) => data.metrics.all_time_refdomains ?? 'N/A',
  },
  {
    field: 'metrics.org_keywords_1_3',
    headerName: 'Metrics Org Keywords 1-3',
    cellRenderer: ({ data }) => data.metrics.org_keywords_1_3 ?? 'N/A',
  },
  {
    field: 'metrics.org_traffic',
    headerName: 'Metrics Org Traffic',
    cellRenderer: ({ data }) => data.metrics.org_traffic ?? 'N/A',
  },
  {
    field: 'metrics.org_cost',
    headerName: 'Metrics Org Cost',
    cellRenderer: ({ data }) => data.metrics.org_cost ?? 'N/A',
  },
  {
    field: 'metrics.paid_keywords',
    headerName: 'Metrics Paid Keywords',
    cellRenderer: ({ data }) => data.metrics.paid_keywords ?? 'N/A',
  },
  {
    field: 'metrics.paid_traffic',
    headerName: 'Metrics Paid Traffic',
    cellRenderer: ({ data }) => data.metrics.paid_traffic ?? 'N/A',
  },
  {
    field: 'metrics.paid_cost',
    headerName: 'Metrics Paid Cost',
    cellRenderer: ({ data }) => data.metrics.paid_cost ?? 'N/A',
  },
  {
    field: 'metrics.paid_pages',
    headerName: 'Metrics Paid Pages',
    cellRenderer: ({ data }) => data.metrics.paid_pages ?? 'N/A',
  },
];
