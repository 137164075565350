import { Button, Paper } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useNotify } from 'react-admin';
import { CreatedUpdatedBox, Grid } from '../../ui/molecules';
import { InfoCard, SinglePageHeader, Spacer } from '../../ui/atoms';
import { getJson } from '../../components/Helpers/API';

const OpenAiShowSummary = ({ record }) => {
  const history = useHistory();
  const notify = useNotify();

  const handleButtonClick = async () => {
    try {
      const response = await getJson(
        `/dms/api/v1/open-ai-content/regenerate/${record.id}`,
        {
          method: 'GET',
        },
      );
      notify(`Response: ${response.message}`, 'info');
      setTimeout(() => {
        history.goBack();
      }, 3000);
    } catch (error) {
      notify(`Error: ${error.message}`, 'warning');
    }
  };
  return (
    <Paper
      style={{
        padding: 8,
        marginTop: 12,
      }}
    >
      <Grid>
        <Spacer height={4} w={12} />
        <Button
          onClick={handleButtonClick}
          variant="contained"
          color="primary"
          lg={12}
        >
          Regenerate
        </Button>
        <SinglePageHeader label="Form Type" title={record.form_type} lg={8} />
        <CreatedUpdatedBox record={record} lg={4} />
        <InfoCard label="Website" content={record.website_id} />
        <InfoCard label="Topic" content={record.topic} />
        <InfoCard label="Excerpt" content={record.excerpt} />
        <InfoCard label="ImageAlt" content={record.imageAltDescription} />
        <InfoCard label="Tweet" content={record.tweet} lg={8} />
        {/*<InfoCard label="Midjourney prompt" content={record.midjourney} />*/}
        <InfoCard label="Words Count" content={record.article_words} lg={4} />
        <InfoCard
          label="Chars Count"
          content={record.article_characters}
          lg={4}
        />
        <InfoCard
          label="Metadescription"
          content={record.metadescription}
          lg={8}
        />
        <InfoCard
          label="Ready to Publish?"
          content={record.ready ? 'Yes' : 'No'}
          lg={4}
        />
        <InfoCard label="Title" content={record.title} lg={12} />
      </Grid>
      <div style={{ width: '120px' }}>
        <div dangerouslySetInnerHTML={{ __html: record.html }} />
      </div>
    </Paper>
  );
};

export default OpenAiShowSummary;
