import { Fragment, useState } from 'react';
import {
  showNotification,
  TextInput,
  refreshView,
  CREATE,
  GET_LIST,
  withDataProvider,
} from 'react-admin';
import { useDispatch } from 'react-redux';
import IconContentAdd from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Button } from '@material-ui/core';

const TagQuickCreateButton = ({ dataProvider }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [value, setValue] = useState();
  const dispatch = useDispatch();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = () => {
    dataProvider(CREATE, 'dms/api/v1/tags', { data: { name: value } })
      .then(() => {
        dispatch(showNotification('Tag created'));
        dispatch(
          dataProvider(GET_LIST, 'dms/api/v1/tags', {
            pagination: { page: 1, perPage: 999 },
          }),
        );
      })
      .catch(error => {
        showNotification(error.message, 'error');
      })
      .finally(() => {
        dispatch(refreshView());
      });
  };

  return (
    <Fragment>
      <Button color="primary" onClick={handleClick} label="create-tag">
        Create <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Create new tag"
      >
        <DialogTitle>Create new tag</DialogTitle>
        <DialogContent>
          <TextInput source="name" onChange={e => setValue(e.target.value)} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} disabled={!value} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default withDataProvider(TagQuickCreateButton);
