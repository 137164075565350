import { useCallback, useEffect, useState } from 'react';
import { useNotify } from 'react-admin';
import dayjs from 'dayjs';
import { getJson } from '../../../components/Helpers/API';
import { useIntervalWithIdle } from '../../../hooks/useIsIdle';

// /process.env.REACT_APP_ENV === 'development'
const HeartBeat = (props: any): JSX.Element => {
  const [repeat] = useIntervalWithIdle();
  const [heartBeat, setHeartBeat] = useState<number | null>(null);
  const notify = useNotify();

  const fetchHeartBeat = useCallback(() => {
    getJson(props.endpoint)
      .then(res => {
        if (res) {
          setHeartBeat(res * 1000);
        } else {
          notify('Error fetching heartbeat', 'error');
        }
      })
      .catch(e => notify(e.message, 'error'));
  }, [notify, props.endpoint]);

  useEffect(() => {
    repeat(fetchHeartBeat);
  }, [repeat, fetchHeartBeat]);

  return (
    <>
      <div style={{ padding: '4px' }}>
        <div>{props.name}</div>
        <div title={heartBeat ? dayjs(heartBeat).format('DD-MM-YYYY') : '-'}>
          {heartBeat ? dayjs(heartBeat).format('HH:mm:ss') : '-'}
        </div>
      </div>
    </>
  );
};

const HeartBeats = (): JSX.Element => {
  return (
    <>
      {<HeartBeat name="Users" endpoint="/ums/health/heartbeat" />}
      {<HeartBeat name="Domains" endpoint="/dms/health/heartbeat" />}
      {<HeartBeat name="Notifications" endpoint="/nms/health/heartbeat" />}
      {<HeartBeat name="Uptime" endpoint="/umms/health/heartbeat" />}
      {<HeartBeat name="Analytics" endpoint="/analytics/health/heartbeat" />}
    </>
  );
};

export default HeartBeats;
