import { IconButton, Tooltip } from '@material-ui/core';
import { OpenInNew } from '@material-ui/icons';
import { useCallback } from 'react';

export default (props: any): JSX.Element => {
  const goTo = useCallback(
    e => {
      e.stopPropagation();
      if (props?.record?.url) {
        window.open(`${props.record.url}`);
      }
    },
    [props?.record?.url],
  );
  return (
    <Tooltip
      title={
        !props?.record?.url
          ? 'Direct link not available'
          : props.label || 'Open in new tab'
      }
    >
      <IconButton size="small" onClick={goTo} disabled={!props?.record?.url}>
        <OpenInNew />
      </IconButton>
    </Tooltip>
  );
};
