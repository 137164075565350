import { Button, makeStyles, Tooltip } from '@material-ui/core';
import { UrlEnsure } from '../../../components/Helpers/utils';

const useStyles = makeStyles((theme: any) => ({
  urlField: {
    color: theme.palette.primary.dark,
    textDecoration: 'none',
    textTransform: 'initial',
    letterSpacing: '0.5px',
    border: '1px solid',
    padding: '3px 5px',
    whiteSpace: 'nowrap',
    maxWidth: 400,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'inline-block',
    verticalAlign: 'middle',
    '&:hover': {
      color: theme.palette.secondary.light,
      background: theme.palette.primary.dark,
    },
  },
}));

export default (props: any) => {
  const url = props.record?.[props.source];
  const classes = useStyles();
  const { limit = 30 } = props;
  const { hideProtocol, prettyUrl } = props;

  if (url) {
    let name = url;
    if (prettyUrl) {
      name = prettyUrl;
    } else if (props.name) {
      name = props.record[props.name];
    }
    if (hideProtocol) {
      name = name.indexOf('//') > -1 ? name.split('//')[1] : name;
    }
    name = name.length >= limit ? name.substr(0, limit - 3) + '...' : name;

    let decoded = url;
    try {
      decoded = decodeURI(url);
    } catch (e) {
      console.warn(e);
    }
    return (
      <Tooltip title={decoded}>
        <Button
          href={UrlEnsure(url)}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.urlField}
          onClick={e => e.stopPropagation()}
        >
          {name}
        </Button>
      </Tooltip>
    );
  }

  return null;
};
