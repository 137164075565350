import dayjs from 'dayjs';
const advancedFormat = require('dayjs/plugin/advancedFormat');
const utc = require('dayjs/plugin/utc');
dayjs.extend(advancedFormat);
dayjs.extend(utc);

export const capitalize = (string: string | null): string => {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return '';
};

export const beautify = (string: string): string => {
  return string
    .split('_')
    .join(' ')
    .split('.')
    .join(' ');
};

export const UrlEnsure = (url: string): string => {
  if (!url) {
    return '';
  }

  let newUrl = url;
  if (url[0] === '#') {
    return newUrl;
  }
  if (newUrl.indexOf('http://') === -1 && newUrl.indexOf('https://') === -1) {
    newUrl = 'http://' + url;
  }
  return newUrl;
};

export const asyncForEach = async (array: any[], callback: Function) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};

export const parseAmount = (number: number, curr: string | undefined = '€') => {
  return `${number.toFixed(2)} ${curr}`;
};

export const humanFileSize = (b: number, si = false, dp = 1) => {
  const thresh = si ? 1000 : 1024;
  let bytes = b;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + ' ' + units[u];
};

export function localizeUtcDate(
  date: string | Date,
  format = 'Do, MMM YYYY, HH:mm:ss',
) {
  return (
    dayjs
      // @ts-ignore
      .utc(date)
      .local()
      .format(format)
  );
}

export function download(
  content: any,
  fileName: string,
  mimeType: string = 'application/octet-stream',
) {
  const a = document.createElement('a');

  if (navigator.msSaveBlob) {
    // IE10
    navigator.msSaveBlob(
      new window.Blob([content], {
        type: mimeType,
      }),
      fileName,
    );
  } else if (URL && 'download' in a) {
    //html5 A[download]
    a.href = URL.createObjectURL(
      new window.Blob([content], {
        type: mimeType,
      }),
    );
    a.setAttribute('download', fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } else {
    window.location.href =
      'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
  }
}

export function clearEmpties(o: Record<any, any>): Record<any, any> {
  for (const k in o) {
    if (!o[k] || typeof o[k] !== 'object') {
      continue; // If null or not an object, skip to the next iteration
    }

    // The property is an object
    clearEmpties(o[k]); // <-- Make a recursive call on the nested object
    if (Object.keys(o[k]).length === 0) {
      delete o[k]; // The object had no properties, so delete that property
    }
  }
  return o;
}
