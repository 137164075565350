import { useCallback, useEffect, useState } from 'react';
import { LinearProgress } from '@material-ui/core';
import Reference from '../ui/atoms/Reference';
import withAbort from './Helpers/withAbort';
import { getJson } from './Helpers/API';

const IsDisavowed = ({ link, signal }) => {
  const [loading, setLoading] = useState(false);
  const [domains, setDomains] = useState([]);
  const [urls, setUrls] = useState([]);

  const loadData = useCallback(() => {
    if (!link) {
      return;
    }
    setLoading(true);
    getJson(`/dms/api/v1/disavowed-domains/is-disavowed?value=${link}`, {
      signal,
    })
      .then(response => {
        if (response.data.domains) {
          setDomains(response.data.domains);
        }
        if (response.data.urls) {
          setUrls(response.data.urls);
        }
        setLoading(false);
      })
      .catch(e => {
        console.error(e);
      });
  }, [link, signal]);

  useEffect(() => loadData(), [loadData]);

  if (loading) {
    return <LinearProgress />;
  }

  return (
    <div>
      {domains.length > 0 && (
        <div>
          <span>Domain is disavowed on: </span>
          {domains.map(domain => (
            <Reference.Website key={domain.id} id={domain.website_id} />
          ))}
        </div>
      )}
      {urls.length > 0 && (
        <div>
          <span>URL is disavowed on</span>
          {urls.map(url => (
            <Reference.Website key={url.id} id={url.website_id} />
          ))}
        </div>
      )}
    </div>
  );
};

export default withAbort(IsDisavowed);
