import { useCallback, useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { DashboardOutlined } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { Grid, Header } from '../../ui/molecules';
import { getJson } from '../../components/Helpers/API';
import useLocalStorage from '../../hooks/useLocalStorage';
import { Spacer } from '../../ui/atoms';
import { useUserData } from '../../hooks/useUserData';
import RankSummary from './widgets/RankSummary';
import BackLinksTrends from './widgets/BackLinksTrends';
import GATraffic from './widgets/GATraffic';
import GSC from './widgets/GSC';
import TopPages from './widgets/TopPages';
import ConversionsMetric from './widgets/ConversionsMetric';
import RecentLinks from './widgets/RecentLinks';
import { DashboardHeader } from './DashboardHeader';
import { NoSelectedSite } from './NoSelectedSite';

const Dashboard = () => {
  const [moneySites, setMoneySites] = useState([]);
  const { name } = useUserData();
  const haveAccess = true;
  const dispatch = useDispatch();
  const [selectedMoneySiteId, setSelectedMoneySiteId] = useLocalStorage(
    'dashboardMoneySite',
  );
  const [selectedMoneySite, setSelectedMoneySite] = useState();
  const [comparison, setComparison] = useState('yesterday');

  const getMoneySites = useCallback(() => {
    if (haveAccess) {
      getJson(
        `/dms/api/v1/websites/?perPage=999&sortBy=friendly_name,hostname&sortOrder=asc&filter=${JSON.stringify(
          {
            type: ['MONEY'],
            status: ['LIVE'],
          },
        )}`,
      ).then(res => setMoneySites(res.data || []));
    }
  }, [haveAccess]);

  const handleChange = useCallback(
    (_, v) => {
      setSelectedMoneySite(v);
      setSelectedMoneySiteId(v ? v.id : '');
    },
    [setSelectedMoneySiteId],
  );

  const handleChangeComparison = useCallback((_, newValue) => {
    setComparison(newValue);
  }, []);

  useEffect(() => getMoneySites(), [getMoneySites]);

  useEffect(() => {
    if (moneySites.length && selectedMoneySiteId) {
      setSelectedMoneySite(
        moneySites.find(({ id }) => id === selectedMoneySiteId),
      );
    }
  }, [moneySites, selectedMoneySite, selectedMoneySiteId]);

  useEffect(() => {
    dispatch({
      type: 'UPDATE_SELECTED_MONEY_SITE',
      payload: selectedMoneySiteId,
    });
  }, [dispatch, selectedMoneySiteId]);

  if (!haveAccess) {
    return (
      <div style={{ paddingTop: '120px' }}>
        <Header>{name}</Header>
        <div
          style={{
            width: '120px',
            textAlign: 'center',
            padding: '40px',
            color: '#868686',
          }}
        >
          <DashboardOutlined fontSize="large" />
          <Typography variant="h3" style={{ fontWeight: 900 }}>
            Welcome to Kokos
          </Typography>
          <Spacer height={20} />
        </div>
      </div>
    );
  }

  return (
    <>
      <div style={{ paddingTop: '120px' }}>
        <DashboardHeader
          selectedMoneySite={selectedMoneySite}
          comparison={comparison}
          handleChangeComparison={handleChangeComparison}
          moneySites={moneySites}
          handleChangeMoneySite={handleChange}
        />
        <Grid spacing={2}>
          {!selectedMoneySite && (
            <NoSelectedSite
              w={12}
              handleChangeSelectedMoneySite={handleChange}
              selectedMoneySite={selectedMoneySite}
              moneySites={moneySites}
            />
          )}
          {selectedMoneySite && (
            <Grid spacing={3} w={12}>
              <BackLinksTrends
                w={12}
                selectedMoneySite={selectedMoneySite}
                comparison={comparison}
              />
              <Grid xs={12} md={6} lg={6}>
                <GSC
                  w={12}
                  selectedMoneySite={selectedMoneySite}
                  comparison={comparison}
                />
              </Grid>
              <Grid xs={12} md={6} lg={6}>
                <TopPages
                  w={12}
                  selectedMoneySite={selectedMoneySite}
                  comparison={comparison}
                />
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <RecentLinks
                  w={12}
                  selectedMoneySite={selectedMoneySite}
                  comparison={comparison}
                />
              </Grid>
              <Grid xs={12} md={8} lg={8}>
                <GATraffic
                  w={12}
                  selectedMoneySite={selectedMoneySite}
                  comparison={comparison}
                />
                <ConversionsMetric
                  w={12}
                  selectedMoneySite={selectedMoneySite}
                  comparison={comparison}
                />
              </Grid>
              <Grid xs={12} md={4} lg={4}>
                <RankSummary
                  w={12}
                  selectedMoneySite={selectedMoneySite}
                  comparison={comparison}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </div>
    </>
  );
};

export default Dashboard;
