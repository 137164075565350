import { memo, useCallback, useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Typography,
  useTheme,
} from '@material-ui/core';
import { AccountTree, OpenInNew, TableChart } from '@material-ui/icons';
import Chart from 'react-google-charts';
import dayjs from 'dayjs';
import { TabbedView } from '../../../../ui/molecules';
import HelpTooltip from '../HelpTooltip';
import Toolbar from '../Toolbar';
import { getJson } from '../../../../components/Helpers/API';
import AgGrid from '../../../../ui/organisms/AgGrid';
import { columns } from './columns';

const customTooltipStyles = `
	padding: 10px;
	display: flex;
	align-items: center;
`;

const RecentLinks = ({ selectedMoneySite }) => {
  const [loaded, setLoaded] = useState(false);
  const [backlinks, setBacklinks] = useState([]);
  const [sankeyData, setSankeyData] = useState([]);
  const ref = useRef(null);
  const theme = useTheme();

  const customTooltip = useCallback(
    (from, to) => `
<div style="${customTooltipStyles}">${from} <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc" focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="ArrowRightIcon"><path d="m10 17 5-5-5-5v10z"></path></svg> ${to}</div>
`,
    [],
  );

  const limitString = useCallback((string, percentage) => {
    if (string === null || string === undefined) {
      console.log('string is null or undefined');
      return '';
    }
    if (ref.current) {
      const limit =
        ref.current.getBoundingClientRect().width / (100 / percentage);

      if (string.length > limit / 12) {
        return string.substring(0, limit / 8) + '...';
      }
    }

    return string;
  }, []);

  useEffect(() => {
    getJson(`/dms/api/v1/websites/${selectedMoneySite.id}/backlinks/profile`)
      .then(res => {
        setBacklinks(
          res.data.sort((a, b) => (b.added ?? 0) - (a.added ?? 0))?.slice(0, 8),
        );
        setLoaded(true);
      })
      .catch(err => {
        console.error(err);
        setLoaded(true);
      });
  }, [selectedMoneySite.id]);

  useEffect(() => {
    if (backlinks.length) {
      setSankeyData(
        backlinks.reduce(
          (acc, item) => {
            let backlink = item.backlink;
            let targetUrl = item.target_url;
            try {
              backlink = decodeURI(backlink);
              targetUrl = decodeURI(targetUrl);
            } catch (e) {
              console.error(e);
            }

            acc.push([
              dayjs(item.added * 1000).format('DD MMM YYYY, HH:mm'),
              limitString(backlink, 18),
              1,
              customTooltip(
                dayjs(item.added * 1000).format('DD MMM YYYY, HH:mm'),
                backlink,
              ),
            ]);
            acc.push([
              limitString(backlink, 18),
              limitString(item.anchor, 18),
              1,
              customTooltip(backlink, item.anchor),
            ]);
            acc.push([
              limitString(item.anchor, 18),
              limitString(targetUrl, 33),
              1,
              customTooltip(item.anchor, targetUrl),
            ]);
            return acc;
          },
          [
            [
              'From',
              'To',
              'Weight',
              { role: 'tooltip', type: 'string', p: {} },
            ],
          ],
        ),
      );
    }
  }, [backlinks, customTooltip, limitString]);

  if (!loaded) {
    return (
      <Card>
        <div style={{ padding: '24px', textAlign: 'center' }}>
          <CircularProgress />
        </div>
      </Card>
    );
  }

  if (loaded && !backlinks.length) {
    return (
      <Card>
        <div style={{ padding: '24px', textAlign: 'center' }}>
          <Typography variant="h2">
            <span role="img" aria-label="no data">
              🤷‍♂️
            </span>
          </Typography>
          <Typography variant="h6">No recent links available</Typography>
        </div>
      </Card>
    );
  }

  return (
    <Card ref={ref}>
      <div style={{ position: 'relative' }}>
        <Toolbar>
          <Typography variant="h6">Recent Links</Typography>
          <HelpTooltip
            source="Kokos Backlinks System"
            description="The 10 most recent backlinks in a table and graphic view"
          />
        </Toolbar>
        <TabbedView variant="light" tabsVariant="fullWidth">
          <Box
            style={{ padding: '8px' }}
            name="Table view"
            icon={<TableChart />}
          >
            <div
              style={{
                padding: '8px',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <div>
                <Button
                  size="small"
                  href={`/#/dms/api/v1/websites/${selectedMoneySite.id}/show/5`}
                >
                  Go to full report <OpenInNew />
                </Button>
              </div>
            </div>
            <AgGrid
              fit
              rows={loaded ? backlinks : null}
              columns={columns}
              search={false}
              pagination={false}
              enableResize={false}
              enableExport={false}
            />
          </Box>
          <Box
            style={{ padding: '8px', height: `${sankeyData.length * 20}px` }}
            name="Chart view"
            icon={<AccountTree />}
          >
            <div
              style={{
                padding: '8px',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <div>
                <Button
                  size="small"
                  href={`/#/dms/api/v1/websites/${selectedMoneySite.id}/show/5`}
                >
                  Go to full report <OpenInNew />
                </Button>
              </div>
            </div>
            <Chart
              chartType="Sankey"
              loader={<CircularProgress />}
              data={sankeyData}
              options={{
                height: sankeyData.length * 20,
                sankey: {
                  node: {
                    interactivity: true,
                    width: 5,
                    colors: new Array(100).fill(theme.palette.primary.main),
                    label: { color: '#333333', fontSize: 12, bold: false },
                  },
                  link: {
                    interactivity: true,
                    color: { fill: '#cbcbcb', fillOpacity: 0.6 },
                  },
                },
                tooltip: {
                  isHtml: true,
                },
              }}
            />
          </Box>
        </TabbedView>
      </div>
    </Card>
  );
};

export default memo(RecentLinks);
