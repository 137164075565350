import { Tooltip } from '@material-ui/core';
import { Lock } from '@material-ui/icons';

export default (props: any) => {
  const url = props?.record?.[props.source] ?? null;
  const isSSL = url && url.indexOf('https') > -1;

  if (url && isSSL) {
    return (
      <Tooltip title="SSL Enabled">
        <Lock style={{ color: 'green' }} />
      </Tooltip>
    );
  }

  return null;
};
