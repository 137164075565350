import { Person, Watch, Public } from '@material-ui/icons';
import { Grid, CreatedUpdatedBox } from '../../ui/molecules';
import {
  Spacer,
  SinglePageHeader,
  ChipsCloud,
  ReferenceArrayField,
} from '../../ui/atoms';

export default ({ record }) => (
  <Grid>
    <Spacer height={4} w={12} />
    <SinglePageHeader
      label="Name"
      title={record.name}
      subtitle={`Email: ${record.email}`}
      lg={8}
    />
    <CreatedUpdatedBox record={record} lg={4} />
    <ChipsCloud w={6}>
      <Watch content={record.last_activity} label="Last activity:" />
      <Watch content={record.last_login} label="Last login:" />
    </ChipsCloud>
    <ChipsCloud w={6}>
      {(record.target_markets || []).map(market => (
        <Public content={market} key={market} label="Target Market:" />
      ))}
    </ChipsCloud>
    <ReferenceArrayField
      label="Roles"
      reference="ums/api/v1/roles"
      source="roles"
      referenceSource="name"
      chip
      avatar={<Person />}
    />
  </Grid>
);
