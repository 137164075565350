import { useState, useEffect, FC } from 'react';
import { GET_LIST } from 'react-admin';
import {
  FormControl,
  InputLabel,
  Select,
  Avatar,
  MenuItem,
  Chip,
} from '@material-ui/core';
import { Bookmark } from '@material-ui/icons';
import dataProvider from '../../../dataProvider';

interface Props {
  value: string;
  onChange: (params: any) => void;
}

const TagSelect: FC<Props> = ({ value, onChange }): JSX.Element => {
  const [tags, setTags] = useState<any[]>([]);

  const fetchTags = () => {
    //type, resource, params
    dataProvider(GET_LIST, 'dms/api/v1/tags', {
      pagination: { page: 1, perPage: 2500 },
      sort: { field: 'name', order: 'ASC' },
      filter: {},
    })
      .then(response => {
        setTags(response.data);
      })
      .catch(e => {
        console.error(e);
      });
  };

  useEffect(() => {
    fetchTags();
  }, []);

  return (
    <FormControl fullWidth variant="filled">
      <InputLabel id="select-multiple-tag">Tags</InputLabel>
      <Select
        labelId="select-multiple-tag"
        fullWidth
        multiple
        value={value}
        onChange={onChange}
        renderValue={(selected: any) => (
          <div>
            {selected.map((value: any) => (
              <Chip
                key={value}
                label={tags.find(t => t.id === value).name}
                color="primary"
                avatar={
                  <Avatar>
                    <Bookmark />
                  </Avatar>
                }
              />
            ))}
          </div>
        )}
      >
        {tags.map(tag => (
          <MenuItem key={tag.id} value={tag.id}>
            {tag.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default TagSelect;
