import {
  Collapse,
  Dialog,
  DialogActions,
  Divider,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import { useRefresh } from 'ra-core';
import { useState } from 'react';
import Button from '@material-ui/core/Button';
import {
  DeleteTwoTone,
  EditTwoTone,
  ExpandLess,
  ExpandMore,
} from '@material-ui/icons';
import { Grid } from '../../../ui/molecules';
import { getJson } from '../../../components/Helpers/API';
import AgGrid from '../../../ui/organisms/AgGrid';
import { columns } from '../Keywords/columns';

export function EditNotification(props) {
  const [openKeywords, setOpenKeywords] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openEditNotification, setOpenEditNotification] = useState(false);
  const [notification, setNotification] = useState(props.notification);
  const toggleKeywords = () => setOpenKeywords(!openKeywords);
  const refresh = useRefresh();

  const toggleEditNotification = () =>
    setOpenEditNotification(!openEditNotification);

  const handleEditNotification = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { _id, ...rest } = notification;
    setLoading(true);
    getJson(
      `/dms/api/v1/accuranker-domains/${props.record.id}/notifications/${props.notification._id.$oid}`,
      {
        method: 'PUT',
        body: JSON.stringify(rest),
      },
    )
      .catch(console.error)
      .finally(() => {
        setLoading(false);
        refresh();
      });
  };

  return (
    <>
      <IconButton onClick={toggleEditNotification}>
        <EditTwoTone color="action" />
      </IconButton>
      <Dialog
        open={openEditNotification}
        onClose={toggleEditNotification}
        maxWidth="xl"
      >
        <div style={{ padding: '16px', width: '900px' }}>
          <Typography variant="h6">Edit Notification</Typography>
          <Grid style={{ marginTop: 20 }}>
            {Object.values(notification.keywords)?.map(keyword => (
              <div key={keyword.id}>
                <TextField
                  label="Set a display value"
                  variant="outlined"
                  fullWidth
                  value={keyword.display_name}
                  onChange={event => {
                    setNotification({
                      ...notification,
                      keywords: {
                        ...notification.keywords,

                        [keyword.id]: {
                          id: keyword.id,
                          search_type: keyword.search_type,
                          search_volume: keyword.search_volume,
                          search_engine: keyword.search_engine,
                          keyword: keyword.keyword,
                          display_name: event.target.value,
                        },
                      },
                    });
                  }}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={() => {
                          const keywords = { ...notification.keywords };
                          delete keywords[keyword.id];
                          setNotification({
                            ...notification,
                            keywords,
                          });
                        }}
                      >
                        <DeleteTwoTone />
                      </IconButton>
                    ),
                  }}
                  helperText={`Display value for: ${keyword.keyword} (${
                    keyword.search_type === 1 ? 'Desktop' : 'Mobile'
                  })`}
                />
              </div>
            ))}
          </Grid>
          <Divider style={{ marginBottom: 10, marginTop: 20 }} />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Typography variant="h6">Add Keywords</Typography>
            <ExpandSection open={openKeywords} toggle={toggleKeywords} />
          </div>
          <Collapse in={openKeywords}>
            <div style={{ paddingTop: '16px', paddingBottom: '16px' }}>
              <AgGrid
                onRowSelected={({ data }) =>
                  setNotification(n => ({
                    ...n,
                    keywords: { ...n.keywords, [data.id]: data },
                  }))
                }
                search
                fit
                pageSize={7}
                rows={
                  props.record.keywords.filter(
                    ({ id }) =>
                      !Object.keys(notification.keywords).includes(String(id)),
                  ) || []
                }
                columns={columns}
              />
            </div>
          </Collapse>
          <Divider style={{ marginBottom: 20, marginTop: 10 }} />
          <Grid>
            <TextField
              w={6}
              variant="outlined"
              fullWidth
              label="Phone number"
              value={notification.phone_number}
              onChange={e => {
                setNotification({
                  ...notification,
                  phone_number: e.target.value,
                });
              }}
            />
            <TextField
              w={6}
              variant="outlined"
              fullWidth
              label="Email"
              value={notification.email}
              onChange={e => {
                setNotification({ ...notification, email: e.target.value });
              }}
            />
          </Grid>
        </div>
        <DialogActions>
          <div style={{ padding: '16px' }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={toggleEditNotification}
              style={{ marginRight: 10 }}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleEditNotification}
              disabled={loading}
            >
              Save
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}

function ExpandSection({ open, toggle }) {
  const Icon = open ? ExpandLess : ExpandMore;
  return (
    <IconButton onClick={toggle}>
      <Icon />
    </IconButton>
  );
}
