import { stringify } from 'querystring';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { getJson } from '../../../../components/Helpers/API';
import { useFormattedCompareDate } from '../../../../hooks/useCompareDate';

const useBackLinksTrends = ({ selectedMoneySite, comparison }) => {
  const [backLinksTrends, setBackLinksTrends] = useState();
  const [totalBacklinks, setTotalBacklinks] = useState();
  const [drAvgTrend, setDrAvgTrend] = useState();
  const [domainRating, setDomainRating] = useState();
  const [totalTrend, setTotalTrend] = useState();
  const [domains, setDomains] = useState();
  const [domainsTrend, setDomainsTrend] = useState();
  const [sources, setSources] = useState({ internal: 0, external: 0 });
  const [tlds, setTlds] = useState([]);
  const [error, setError] = useState();
  const [missingData, setMissingData] = useState(false);
  const [missingComparedData, setMissingComparedData] = useState(false);
  const compareDate = useFormattedCompareDate(comparison, 'YYYY-MM-DD');

  const fetchBackLinksTrends = useCallback(() => {
    getJson(
      `/dms/api/v1/websites/statistics?${stringify({
        filter: JSON.stringify({
          website_id: selectedMoneySite.id,
          day: dayjs()
            .subtract(1, 'day')
            .format('YYYY-MM-DD'),
        }),
      })}`,
    )
      .then(res => {
        if (res.data && res.data[0]) {
          if (res.data[0].backlink_statistics) {
            setError(false);
            setBackLinksTrends(res.data[0].backlink_statistics);
            setMissingData(!!res.data[0].no_data);
          } else {
            throw new Error('No Statistics');
          }
        } else {
          console.error('Something wrong with the earnings', res);
          setError(res);
        }
      })
      .catch(err => setError(err));
  }, [selectedMoneySite.id]);

  const fetchCompared = useCallback(() => {
    getJson(
      `/dms/api/v1/websites/statistics?${stringify({
        filter: JSON.stringify({
          website_id: selectedMoneySite.id,
          day: compareDate,
        }),
      })}`,
    )
      .then(res => {
        if (res.data && res.data[0]) {
          if (res.data[0].backlink_statistics) {
            setError(false);
            setMissingComparedData(!!res.data[0].no_data);
            setTotalTrend(
              backLinksTrends.total - res.data[0].backlink_statistics.total,
            );
            setDomainsTrend(
              backLinksTrends.domains - res.data[0].backlink_statistics.domains,
            );
            setDrAvgTrend(
              Math.round(
                backLinksTrends.average_domain_rating -
                  res.data[0].backlink_statistics.average_domain_rating,
              ),
            );
          } else {
            throw new Error('No Statistics');
          }
        } else {
          console.error('Something wrong with the earnings', res);
          setError(res);
        }
      })
      .catch(err => setError(err));
  }, [selectedMoneySite.id, backLinksTrends, compareDate]);

  useEffect(() => {
    fetchBackLinksTrends();
  }, [fetchBackLinksTrends]);

  useEffect(() => {
    if (backLinksTrends) {
      fetchCompared();
      setTotalBacklinks(backLinksTrends.total);
      setDomains(backLinksTrends.domains);
      setDomainRating(Math.round(backLinksTrends.average_domain_rating));
      setTlds(() => {
        const sorted = Object.entries(backLinksTrends.tlds)
          .map(([tld, val]) => ({ tld, val }))
          .sort((a, b) => b.val - a.val);
        const tot = sorted.reduce((tot, tld) => tot + tld.val, 0);
        const result = sorted.reduce((result, tld, index) => {
          if (index > 1) {
            result[2] = result[2]
              ? {
                  perc: ((result[2].val + tld.val) * 100) / tot,
                  tld: 'others',
                  val: result[2].val + tld.val,
                }
              : { perc: (tld.val * 100) / tot, tld: 'others', val: tld.val };
            return result;
          }
          result.push({ perc: (tld.val * 100) / tot, ...tld });
          return result;
        }, []);

        return result;
      });
      setSources(() => {
        const tot =
          backLinksTrends.source.internal + backLinksTrends.source.external;
        return {
          internal: (backLinksTrends.source.internal * 100) / tot,
          external: (backLinksTrends.source.external * 100) / tot,
        };
      });
    }
  }, [backLinksTrends, fetchCompared]);

  return {
    backLinksTrends,
    totalBacklinks,
    totalTrend,
    missingData,
    missingComparedData,
    drAvgTrend,
    domainsTrend,
    domainRating,
    domains,
    sources,
    tlds,
    error,
  };
};

export default useBackLinksTrends;
