import { Chip, Avatar, PropTypes } from '@material-ui/core';
import React from 'react';

interface IProps {
  color?: Exclude<PropTypes.Color, 'inherit'>;
  chip: JSX.Element;
  content?: string;
  source?: string;
  record?: any;
  size?: 'small' | 'medium' | undefined;
}

export default ({
  color,
  chip,
  content,
  source,
  record,
  size,
}: IProps): JSX.Element => (
  <Chip
    size={size}
    color={color ? color : 'default'}
    avatar={
      <Avatar>
        {size && chip ? React.cloneElement(chip, { fontSize: size }) : chip}
      </Avatar>
    }
    label={source ? record[source] : content}
  />
);
