import { Link } from '@material-ui/icons';
import { Paper } from '@material-ui/core';
import { Grid, CreatedUpdatedBox } from '../../ui/molecules';
import {
  ReferenceField,
  SinglePageHeader,
  ChipsCloud,
  InfoCard,
} from '../../ui/atoms';

export default ({ record, resource, basePath }) => {
  return (
    <Paper
      style={{
        padding: 8,
        marginTop: 12,
      }}
    >
      <Grid>
        <SinglePageHeader
          label="Host"
          title={record.host}
          subtitle={`IP: ${record.ip}`}
          lg={9}
        />
        <CreatedUpdatedBox record={record} lg={3} />
        <ChipsCloud w={12}>
          <Link content={record.type} label="Type:" />
          <Link content={record.ttl} label="TTL:" />
          <Link content={record.class} label="Class:" />
        </ChipsCloud>
        <InfoCard
          label="Domain"
          content={
            <ReferenceField
              reference="dms/api/v1/domains"
              label="Domain"
              source="domain_id"
              referenceSource="name"
              basePath={basePath}
              record={record}
              resource={resource}
              link={'show'}
            />
          }
        />

        <InfoCard label="target" content={record.target} />
        <InfoCard label="mname" content={record.mname} />
        <InfoCard label="rname" content={record.rname} />
        <InfoCard label="serial" content={record.serial} />
        <InfoCard label="refresh" content={record.refresh} />
        <InfoCard label="retry" content={record.retry} />
        <InfoCard label="expire" content={record.expire} />
        <InfoCard label="minimum_ttl" content={record.minimum_ttl} />
        <InfoCard label="pri" content={record.pri} />
        <InfoCard label="txt" content={record.txt} />
      </Grid>
    </Paper>
  );
};
