import {
  CRUD_CREATE_FAILURE,
  CRUD_UPDATE_FAILURE,
  CRUD_CREATE,
} from 'react-admin';
import { put, takeEvery } from 'redux-saga/effects';

export default function* errorSagas() {
  yield takeEvery(
    [CRUD_CREATE_FAILURE, CRUD_UPDATE_FAILURE],
    crudCreateFailure,
  );
  yield takeEvery([CRUD_CREATE], crudCreate);
}

export function* crudCreateFailure(action) {
  yield put({ type: 'CREATE_ERROR', payload: action.payload });
}

export function* crudCreate() {
  yield put({ type: 'RESET_ERROR' });
}
